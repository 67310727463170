import { getQueryStringFromSearchObject } from './utility';
import { takeLatest, put } from 'redux-saga/effects';
import { OK } from 'http-status-codes';
import { searchTypes, searchActions } from '.';
import { search } from 'state/api/search';
import { genFetch, withToken } from 'state/utils/endpoints';
import searchActionTypes from './types';
import { removePage } from '../events/utility';

export function* searchAction(action: searchTypes.IQueryPayloadAction):any {
  try {
    const { query } = action.payload;
    const queryObject: any = JSON.parse(query);
    const queryString = getQueryStringFromSearchObject(queryObject);
    const saveQ = removePage(JSON.parse(query));
    const searchResponse = yield genFetch(search.for(queryString))()(withToken());

    switch (searchResponse.status) {
      case OK:
        const body = yield searchResponse.json();

        /**
         * (1) Spit the tags into arrays.
         * (2) remove '/public' or '/member' from arrays
         * @Juan When no data comes back it body.row.content = "[]";
         */

        if (!body.rows.content) {
          body.rows.forEach((item:any) => {
            item.field_tags = item.field_tags === '' ? [] : item.field_tags.split(',');
            item.public_path = item.public_path.replace(/\/public|\/member|-ors$/g, '');
          });
        } else {
          body.rows = [];
        }
        yield put(searchActions.searchResults(body, saveQ));
        break;
      default:
        break;
    }

    yield;
  } catch (error) {
    console.log(error); // tslint:disable-line no-console
  }
}

export default function* connectSaga() {
  yield takeLatest(searchActionTypes.SEARCH, searchAction);
}
