import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

/**
 * scroll restoration based on @see https://reacttraining.com/react-router/web/guides/scroll-restoration.
 * @see https://gist.github.com/vivmaha/f34fdea72d0f1fa0467a671b5ec7321e
 * @see https://github.com/ReactTraining/react-router/issues/2019
 */
export const ScrollToTop = withRouter(
  class ScrollToTopWithoutRouter extends React.Component<RouteComponentProps<any>, any> {
    componentDidUpdate(prevProps: Readonly<RouteComponentProps<any>>) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    }

    render(): JSX.Element {
      return <div />;
    }
  }
);
