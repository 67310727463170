import { all, fork } from 'redux-saga/effects';

import { sagas } from '../../ducks';

function* forkSagas() {
  const globalSagasForks = Object.keys(sagas).map(key => fork(sagas[key]));

  yield all([...globalSagasForks]);
}

export default forkSagas;
