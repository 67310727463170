import useUkraineRequests, {
  AggregatedRequest,
} from "lib/hooks/useUkraineRequests";
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM  from "react-dom";
import RequestCell from "./RequestCell/RequestCell";
import SearchBox from "./SearchBox/SearchBox";
import { StyledTable } from "./styles";

const UkraineAssistanceColumns: any = [
  {
    title: " ",
    key: "title",
    render: (text: any, record: AggregatedRequest) => (
      <RequestCell record={record} />
    ),
  },
];

function UkraineAssistanceTable() {
  const { aggregatedRequests } = useUkraineRequests();
  const [searchText, setSearchText] = useState("")

  const filteredAggregatedRequests = useMemo(() => {
    if(searchText.length > 0) {
      return aggregatedRequests.filter(request => request.field_request_id.includes(searchText))
    }
    return aggregatedRequests;
  }, [searchText, aggregatedRequests])

  return (
    <>
      <SearchBox setSearchText={setSearchText}/>
      <StyledTable
        rowKey={(record: any) => record.nid}
        columns={UkraineAssistanceColumns}
        dataSource={filteredAggregatedRequests}
      />
    </>
  );
}

export default UkraineAssistanceTable;
