import "core-js"; // tslint:disable-line
import "react-app-polyfill/stable"; // tslint:disable-line
import "react-app-polyfill/ie9"; // tslint:disable-line
import "react-app-polyfill/ie11"; // tslint:disable-line

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./state/utils/store/createStore";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { themeGPWMD } from "view/utils/theme";
import AppRoutes from "state/utils/store/routerWrapper";
import RecaptchaProvider from "view/_reCaptcha";

require("typeface-montserrat");
require("typeface-hind");
require("typeface-roboto");

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={themeGPWMD}>
      <Router>
        <RecaptchaProvider>
          <AppRoutes />
        </RecaptchaProvider>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
