import { Reducer } from 'redux';
import searchActionTypes, {
  ISearchState,
  SearchActionsInterfaces,
  IQueryResultsPayload,
} from './types';
import { hashCode } from 'state/utils/hash';

const initialState: ISearchState = {};

const reducer: Reducer<ISearchState> = (state: ISearchState = initialState, action) => {
  const stateCopy: ISearchState = JSON.parse(JSON.stringify(state));
  switch ((action as SearchActionsInterfaces).type) {
    case searchActionTypes.SEARCH_SUCCESS: {
      const payload: IQueryResultsPayload = action.payload;
      const { results, query } = payload;
      const { pager, rows } = results;
      const hash = hashCode(query);

      // (1) Data Exists (update)
      // (2) Data Doesn't Exist - Create

      const hashCopy = stateCopy[hash];

      if (hashCopy) {
        hashCopy.pages[pager.current_page] = rows;
        hashCopy.pager = pager;
      } else {
        stateCopy[hash] = {
          label: query,
          pager: pager,
          pages: {
            [pager.current_page]: rows,
          },
        };
      }

      return stateCopy;
    }
    default:
      return stateCopy;
  }
};

export default reducer;
