import { GenericTypes } from 'state/utils';
import { IAction } from 'state/utils/redux/types';

/**
 * Layout's Typed Reducer State
 */
export interface ILayoutState {
  layout: LayoutState;
  font: FontState;
  breakpoint: Breakpoints;
}

/**
 * Enum class that determines the
 * device type a user is currently using
 */
export enum LayoutState {
  MOBILE,
  TABLET,
  DESKTOP,
}

/**
 * Enum class that determines the base font size
 * correct class.
 */
export enum FontState {
  FONT320 = 12,
  FONT360 = 14,
  FONT786 = 16,
}

/**
 * Designed for the 4 breakpoints suggested
 *  320px
 *  360px
 *  768px
 *  1600px
 */
export enum Breakpoints {
  MOBILE,
  MIN,
  SMALL,
  MEDIUM,
  LARGE,
}

export const layoutActionTypes = {
  CHANGE_BREAKPOINT: 'CHANGE_BREAKPOINT',
};

interface IPayloadBreakpoint {
  breakpoint: Breakpoints;
}

export interface IChangeBreakpointAction extends GenericTypes.IAction {
  payload: IPayloadBreakpoint;
}

export type LayoutActions = IAction | IChangeBreakpointAction;

export default layoutActionTypes;
