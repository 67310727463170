import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { createSelector } from "reselect";
import { IColumnedState } from "state/ducks/columned/types";
import { columned } from "state/ducks/columned/actions";
import { authentication } from "state/api/authentication";
import { genFetch, withToken } from "state/utils/endpoints";
import { publicPathToURL } from "state/utils";
import { Link, useHistory, useLocation } from "react-router-dom";
import { IApplicationState } from "state/ducks";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";
// import SkeletonButton from "antd/lib/skeleton/Button";
import { Logo } from "view/utils/forms/styles";
import { WelcomeMessage } from "./welcome/WelcomeMessage";
import { QuestionnaireLink } from "view/discussionBoard/styles";
import moment from "moment";
import MemberLoginDialog from "components/MemberLoginDialog/MemberLoginDialog";
import { ButtonContainer } from "view/ExportControl/styles";

export interface IAppProps {
  columned: IColumnedState;
  columnedAction: typeof columned;
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ContainerTwo = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 4rem 0;
`;

const Headline = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.colors.main};
  font-family: ${(props) => props.theme.fonts.main};
  width: 100%;
  margin: 0;
  padding: 3rem 0;
`;

const ECHHeadline = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${(props) => props.theme.gpwmd.green};
  font-family: ${(props) => props.theme.fonts.main};
  width: 100%;
  margin: 0;
`;

interface HeadshotProps {
  maxWidth?: string;
  width?: string;
  objectFit?: string;
  objectPosition?: string;
  height?: string;
}

/* prettier-ignore */
const HomeBanner = styled.div`
  width: 100%;
  min-height: 420px;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  & > div {
    max-width:800px;
    display: flex;
  }

  ::before {
    content: "";
    position: absolute;
    background-image: url(${publicPathToURL("/images/banners/home-1600x686.jpg")});
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
  }

  padding: 1rem 3rem;

  @media (min-width: 1025px) {
    padding: 4rem 6rem;
  }
`;

const BannerTextContainer = styled.div`
  width: 100%;
  @media (min-width: 1025px) {
    width: 60%;
  }
`;

const BannerButtonContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BannerText = styled.p`
  font-size: 1.2rem;
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 500;
  line-height: 2rem;
  @media (min-width: 1025px) {
    line-height: 36px;
  }
`;

const BannerLink = styled(Link)`
  min-width: 350px;
  min-height: 70px;
  font-size: 1.2rem;
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 600;
  border: solid 5px ${(props) => props.theme.gpwmd.green};
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background: ${(props) => props.theme.gpwmd.green};
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const getHomeState = () =>
  createSelector(
    (state: IApplicationState) => ({
      subWorkingGroups: state.vocabulary.subWorkingGroups,
      languages: state.languages
    }),
    (state) => {
      const { subWorkingGroups } = state;
      return {
        subWorkingGroups,
      };
    }
  );

const ECHContainer = styled.div`
  padding: 2rem;
  max-width: 1100px;
  width: 100%;

  & > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  p {
    line-height: 2;
    min-width: 500px;
    max-width: 600px;
  }

  a {
    min-width: 100px;
    margin-bottom: 1rem;
  }
`;

const WorkingGroupContainer = styled.div`
  padding: 2rem 2rem 5rem 2rem;
  min-height: 1100px;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  max-width: 1100px;
  padding: 1rem 0;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    justify-content: unset;
  }
`;

const WorkingGroupLink = styled(Link)`
  min-width: 200px;
  max-height: 100px;
  height: 100px;
  font-size: 2rem;
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.gpwmd.darkTan};
  font-weight: 600;
  border: solid 5px ${(props) => props.theme.gpwmd.green};
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    min-width: 300px;
    max-height: 150px;
    height: 150px;
  }

  :hover {
    background: ${(props) => props.theme.gpwmd.green};
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const GroupInfoContainer = styled.div`
  padding: 1rem 0;
  word-break: break-all;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  &:first-of-type a {
    margin-right: 6px;
  }

  @media (min-width: 1025px) {
    display: block;
    padding: 1rem 2rem;

    &:first-of-type a {
      margin-right: 1rem;
    }
  }
`;

const GroupText = styled.p`
  width: 100%;
  font-size: 1.5rem;
  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.main};
  font-weight: 300;
  line-height: 34px;
  padding: 0;
  margin-bottom: 6px;
  text-align: center;
  @media (min-width: 1025px) {
    text-align: left;
  }
`;

const GroupLink = styled(Link)`
  padding: 1rem 0.5rem;
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.secondary};
  background-color: ${(props) => props.theme.gpwmd.green};
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    padding: 1rem 4rem;
  }
`;

const Hr = styled.hr`
  width: 100%;
  margin: 2rem 0;
  border: solid 1px ${(props) => `${props.theme.gpwmd.green}`};
`;

const ECHHr = styled.hr`
  width: 100%;
  margin: 4rem 0;
  border: solid 4px ${(props) => `${props.theme.gpwmd.green}`};
`;

// prettier-ignore
const RecentContainer = styled.div`
  min-height: 600px;
  width: 100%;
  position: relative;
  padding: 3rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
      padding: 6rem 5rem;
  }

  @media (min-width: 1300px) {
    padding: 6rem 10rem;
    justify-content: space-between;
  }

  ::before {
    content: "";
    position: absolute;
    background-image: url(${publicPathToURL("/images/banners/home-1600x686.jpg")});
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
  }
`;

const RecentCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* background-color: ${(props) => props.theme.gpwmd.darkTan}; */
  border: solid 5px ${(props) => props.theme.gpwmd.slate};
  min-height: 400px;
  max-width: 300px;
  min-width: 300px;
  align-items: center;
  justify-content: space-between;
  margin: 0 2rem 2rem 2rem;

  @media (min-width: 1024px) {
    margin: 0 1rem 1rem 1rem;
  }
`;

const CardTitle = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: ${(props) => props.theme.colors.main};
  background: #fff;
  font-weight: 600;
`;

const CardBody = styled.div`
  padding: 2rem;
  flex: 1 1 auto;
  width: 100%;
  & > h1 {
    font-size: 18px;
    font-family: ${(props) => props.theme.fonts.heading};
    color: ${(props) => props.theme.colors.secondary};
  }

  & p {
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.main};
    color: ${(props) => props.theme.colors.secondary};
    font-weight: 300;
    margin: 0;
  }

  & > p:last-of-type {
    margin-bottom: 1rem;
  }
`;

const CardLink = styled(Link)`
  padding: 1rem 2rem;
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.secondary};
  background-color: ${(props) => props.theme.gpwmd.green};
  font-weight: 600;
  display: inline-flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const SkellyButtonHolder = styled.div`
  padding: 1rem 2rem;
  margin-bottom: 1.5rem;
  width: 100%;
  & > div {
    width: 100%;
  }
  & .ant-skeleton-button {
    width: 100%;
  }
`;

interface RecentWebinar {
  field_duration: string;
  field_thumbnail: string;
  field_topic: string;
  nid: string;
  title: string;
}

interface UpcomingEvent {
  field_banner_image: string;
  field_caption: string;
  field_country: string;
  field_date_range: string;
  field_event_files: string;
  field_learn_more: string;
  field_location: string;
  nid: string;
  title: string;
  field_event_type: string;
}

type NoData = "No Data";

const hosts = ["gpwmd-portal.org", "gpwmd-staging-client.pnl.gov"];
const exportControlHost =
  window.location.host === hosts[0]
    ? `https://hub.${hosts[0]}`
    : `https://hub.${hosts[1]}`;
const report = window.location.host === hosts[0]
? `https://${hosts[0]}/backend/web/sites/default/files/2024-01/GP%20Presidency%20Report%202023%20%28Japan%29.pdf`
: `https://gpwmd-staging-admin.pnl.gov/web/sites/default/files/2024-01/GP%20Presidency%20Report%202023%20%28Japan%29.pdf`
const annex = window.location.host === hosts[0]
? `https://${hosts[0]}/backend/web/sites/default/files/2024-01/GPWG%20Programming%20Annex%20%282023%29%20.pdf`
: `https://gpwmd-staging-admin.pnl.gov/web/sites/default/files/2024-01/GPWG%20Programming%20Annex%20%282023%29%20.pdf`

export default function Home() {
  const { subWorkingGroups } = useSelector(getHomeState());
  const [recentWebinar, setRecentWebinar] = useState<RecentWebinar | null>(
    null
  );
  const [upcomingEvent, setUpcomingEvent] = useState<
    UpcomingEvent | null | NoData
  >(null);

  useEffect(() => {
    genFetch(authentication.getRecentWebinar())()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) =>
            setRecentWebinar(data && data.length > 0 ? data[0] : null)
          )
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
    genFetch(authentication.getUpcomingEvent())()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) =>
            setUpcomingEvent(
              data && data.rows && data.rows.length > 0
                ? data.rows[0]
                : "No Data"
            )
          )
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [setRecentWebinar, setUpcomingEvent]);

  return (
    <Container>
      <ContainerTwo>
        <Logo
          maxWidth="900px"
          src="/images/icons/italy24/gp-collage-italy.jpg"
          alt="A collage of the GPWMD"
          radius="5px"
        />
        <WelcomeMessage />
        <ECHContainer>
          <ECHHr />
          <ECHHeadline>
          Japanese Presidency and Annex Reports (2023)
          </ECHHeadline>
          <div>
            <p>
              The Japanese Presidency Global Partnership Working Group Sessions and the GPWG Annual Activity Report (2023) can be found using the provided links.
            </p>
            <ButtonContainer>
<QuestionnaireLink
              as="a"
              href={report}
              style={{minWidth: "300px"}}
              target="_blank"
              rel="noopener noreferrer"
            >
              Presidency Report
            </QuestionnaireLink>
            <QuestionnaireLink
              as="a"
              href={annex}
              style={{minWidth: "300px"}}
              target="_blank" 
              rel="noopener noreferrer"
            >
              GPWG Annex Report
            </QuestionnaireLink>
            </ButtonContainer>
            
          </div>
          <Hr />
        </ECHContainer>
        <ECHContainer>
          <ECHHeadline>
            GP Nonproliferation and Strategic Trade Knowledge Hub
          </ECHHeadline>
          <div>
            <p>
              This tool is a central point for information and resources on
              nonproliferation of Weapons of Mass Destruction (WMD) and
              strategic trade topics. The Hub is a comprehensive, up-to-date,
              publicly available, and easily searchable library that enables
              users to be connected to resources on nonproliferation and
              strategic trade topics, covering the nuclear, chemical,
              biological, and radiological spectrum as well as export controls
              and compliance.
            </p>
            <ButtonContainer>
            <QuestionnaireLink
              as="a"
              href={exportControlHost}
              target="_blank"
              rel="noopener noreferrer"
            >
              Go To Knowledge Hub
            </QuestionnaireLink>
            </ButtonContainer>
          </div>
          <Hr />
        </ECHContainer>

        <WorkingGroupContainer>
          <Headline>Global Partnership Sub-Working Groups</Headline>
          {subWorkingGroups.map((group, index) => (
            <GroupContainer key={group.tid}>
              <WorkingGroupLink
                to={publicPathToURL(`/en/working-groups?group=${group.tid}`)}
              >
                {group.field_acronym}
              </WorkingGroupLink>
              <GroupInfoContainer>
                <GroupText>{group.name}</GroupText>
                <GroupLink
                  to={publicPathToURL(
                    `/en/discussion-board?group=${group.tid}`
                  )}
                >
                  View Discussion Board
                </GroupLink>
                <GroupLink
                  to={publicPathToURL(`/en/workshops?group=${group.tid}`)}
                >
                  View Recorded Events
                </GroupLink>
              </GroupInfoContainer>
              {index < subWorkingGroups.length - 1 && <Hr />}
            </GroupContainer>
          ))}
        </WorkingGroupContainer>
        <RecentContainer>
          <RecentCard>
            <CardTitle>Upcoming Events</CardTitle>
            {upcomingEvent && typeof upcomingEvent === "object" && (
              <>
                <CardBody>
                  <h1>{upcomingEvent.title}</h1>
                  <p style={{ fontWeight: 600 }}>
                    {upcomingEvent.field_event_type}
                  </p>
                  <p>{upcomingEvent.field_date_range}</p>
                  <p>{upcomingEvent.field_country}</p>
                  <p>{upcomingEvent.field_location}</p>
                </CardBody>
                <CardLink to={publicPathToURL("/en/calendar")}>
                  View More Events
                </CardLink>
              </>
            )}
            {upcomingEvent === null && (
              <>
                <div
                  style={{ width: "100%", flex: "1 1 auto", padding: "1.5rem" }}
                >
                  <Skeleton title active paragraph={{ rows: 4 }} />
                </div>
                <SkellyButtonHolder>
                  <Skeleton.Button active />
                </SkellyButtonHolder>
              </>
            )}
            {upcomingEvent === "No Data" && (
              <>
                <CardBody>
                  <h1>No upcoming events</h1>
                </CardBody>
                <CardLink
                  to={publicPathToURL(
                    `/en/calendar?min=2019-12-01&max=${moment().subtract(
                      1,
                      "day"
                    )}&page=1`
                  )}
                >
                  View Past Events
                </CardLink>
              </>
            )}
          </RecentCard>
          <RecentCard>
            <CardTitle>Recent Meeting</CardTitle>
            {recentWebinar ? (
              <>
                <CardBody>
                  <h1>{recentWebinar.title}</h1>
                  <p>{`${recentWebinar.field_topic} Meeting`}</p>
                  <p>{recentWebinar.title}</p>
                  <p>{`Duration: ${recentWebinar.field_duration}`}</p>
                </CardBody>
                <CardLink
                  to={`/en/webinar/${recentWebinar.nid.replace(
                    /\/member\//g,
                    ""
                  )}`}
                >
                  View Recording
                </CardLink>
              </>
            ) : (
              <>
                <div
                  style={{ width: "100%", flex: "1 1 auto", padding: "1.5rem" }}
                >
                  <Skeleton title active paragraph={{ rows: 4 }} />
                </div>
                <SkellyButtonHolder>
                  <Skeleton.Button active />
                </SkellyButtonHolder>
              </>
            )}
          </RecentCard>
          <RecentCard>
            <CardTitle>Discussion Board</CardTitle>
            <CardBody>
              <h1>Want to participate in the discussion?</h1>
              <p>
                Leave your submissions on the discussion board. Anyone with
                access to the portal can view your submission.
              </p>
            </CardBody>
            <CardLink to={publicPathToURL(`/en/discussion-board`)}>
              Join the Discussion
            </CardLink>
          </RecentCard>
        </RecentContainer>
      </ContainerTwo>
      <MemberLoginDialog />
    </Container>
  );
}
