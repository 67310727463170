import { PlayCircleOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

interface Props {
  className?: string;
  video: string;
  posterImage?: string;
  time?: number;
}

const VideoContainer = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 56.25%;
`;

const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
`;

interface VideoPlayerScrimButtonProps {
  $isPlaying: boolean;
  $thumbnail?: string;
}

const VideoPlayerScrimButton = styled.button<VideoPlayerScrimButtonProps>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  ${(props) =>
    props.$thumbnail
      ? css`
          background-image: linear-gradient(
              rgba(51, 51, 51, 0.7),
              rgba(51, 51, 51, 0.7)
            ),
            url(${props.$thumbnail});
          background-size: cover;
        `
      : css`
          background: #333333e5;
        `}

  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  transition-property: background-color, visibility, width, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  visibility: visible;
  border-radius: 8px;

  ${(props) =>
    props.$isPlaying &&
    css`
      visibility: hidden;
    `};

  svg {
    color: #bababa;
    font-size: 8rem;
  }

  &:hover {
    svg {
      color: #ca1228;
    }
  }
`;

const PauseScreen = styled.div``;

function Media(props: Props) {
  const { className, video, posterImage, time = 0 } = props;
  const [isPlaying, setIsPlaying] = useState(false);

  const ref = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const current = ref.current;
    if (current) {
      current.addEventListener("play", () => {
        setIsPlaying(true);
      });
    }
    return () => {
      if (current) {
        current.removeEventListener("play", () => {
          setIsPlaying(true);
        });
      }
    };
  }, [ref]);

  return (
    <VideoContainer>
      <VideoPlayerScrimButton
        $thumbnail={posterImage}
        $isPlaying={isPlaying}
        aria-label="Play Video"
        onClick={() => {
          const current = ref.current;
          if (current) {
            current.play();
          }
        }}
      >
       <PlayCircleOutlined />
      </VideoPlayerScrimButton>
      <VideoPlayer
        ref={ref}
        className={className}
        controls={isPlaying ? true : undefined}
        preload="auto"
      >
        <source src={`${video}#t=${time}`} />
        <PauseScreen data-state="play"></PauseScreen>
      </VideoPlayer>
    </VideoContainer>
  );
}

export default Media;
