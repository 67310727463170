export const publicPathToURL = (param?: string): string => `${process.env.PUBLIC_URL}${param}`;
export const imagePathToURL = (param?: string): string => `${process.env.REACT_APP_API}${param}`;
export const languageToUrl = (langCode: string, path: string): string => `/${langCode}${path}`;

export const getGaENV = (): string => `${process.env.REACT_APP_GA_UUID}`;
export const generateLink = (param: string, type: string): string => {
  if (type === 'media') {
    return imagePathToURL(param);
  } else if (type === 'internal') {
    return publicPathToURL(param);
  }
  return param;
};
