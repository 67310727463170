import React, { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";

const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY || "";
const CAPTCHA_VISIBLE = "--captcha-visible";
const RECAPTCHA_INCLUSION_ROUTES = [
  "/contact",
  "/forgot/password",
  "/register",
  "/reset/password",
];

type Props = {
  children?: React.ReactNode;
};

const RecaptchaProvider = ({ children }: Props) => {
  const location = useLocation();

  useEffect(() => {
    const isCaptchaVisible = RECAPTCHA_INCLUSION_ROUTES.some((route) =>
      location.pathname.includes(route)
    );

    if (isCaptchaVisible) {
      document.documentElement.style.setProperty(CAPTCHA_VISIBLE, "visible");
    } else {
      document.documentElement.style.setProperty(CAPTCHA_VISIBLE, "hidden");
    }
  }, [location]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY}>
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default RecaptchaProvider;
