import { Reducer } from "redux";
import eventSearchActionTypes, {
  IEventsState,
  EventSearchActionsInterfaces,
  IEventsMessageObject,
  IPagerObject,
} from "./types";
import { hashCode } from "state/utils/hash";
import { isArray } from "util";

const initialState: IEventsState = {};

const reducer: Reducer<IEventsState> = (
  state: IEventsState = initialState,
  action
) => {
  const stateCopy: IEventsState = JSON.parse(JSON.stringify(state));
  switch ((action as EventSearchActionsInterfaces).type) {
    case eventSearchActionTypes.EVENTS_SEARCH_SUCCESS: {
      const payload = action.payload;
      const { results, query } = payload;
      const { pager, rows } = results;
      const hash = hashCode(query);

      // End Of TODO

      // (1) Data Exists (update)
      // (2) Data Doesn't Exist - Create
      const hashCopy = stateCopy[hash];

      if (hashCopy) {
        hashCopy.pages[pager.current_page] = rows;
        hashCopy.pager = pager;
      } else {
        stateCopy[hash] = {
          label: query,
          pager: pager,
          pages: {
            [pager.current_page]: rows,
          },
        };
      }

      return stateCopy;
    }
    default:
      return stateCopy;
  }
};

export default reducer;
