import { ActionCreator } from 'redux';
import languagesActionTypes, { IChangeLanguageAction, LanguageCode } from './types';

/**
 * Action Creator that will accept a breakpoint to update the store.
 * @param language
 */
export const changeLanguage: ActionCreator<IChangeLanguageAction> = (language: LanguageCode) => ({
  type: languagesActionTypes.CHANGE_LANGUAGE,
  payload: {
    languageCode: language,
  },
});
