import React, { useState, useEffect } from "react";
import { DateRangeWrapper } from "./dateRangeWrapper";
import moment from "moment";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { IApplicationState } from "state/ducks";
import { searchFor } from "state/ducks/search/actions";
import { hashCode } from "state/utils/hash";
import { removePage } from "state/ducks/events/utility";
import {
  IEventSearchStateObject,
  IEventsMessageObject,
  IPagerObject,
} from "state/ducks/events/types";
import { Button } from "view/utils/forms/styles";
import { imagePathToURL, publicPathToURL } from "state/utils";
import { genFetch, withToken } from "state/utils/endpoints";
import { authentication } from "state/api/authentication";
import { StyledTag } from "view/webinars/styles";
const queryString = require("query-string");

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ContainerTwo = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1300px;
  padding: 2rem 0;
`;

const SearchButton = styled(Button)`
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 48px;

  :hover {
    background: ${(props) => props.theme.gpwmd.green};
  }
`;
const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
`;

interface CalendarProps extends IApplicationState {
  getEventArchive: typeof searchFor;
}
interface DateRangeProps {
  start: any;
  end: any;
}

const handleFirstTimeQuery = (o: any, getEventArchive: any, history: any) => {
  // Date: Min Max
  // Type of Events : Type

  let dateString = "";
  let typeString = "";
  let pageString = "";
  let pageNum = 1;
  const searchObject: any = {};

  if (o.min && o.max) {
    const min = moment(o.min).format("YYYY-MM-DD");
    const max = moment(o.max).format("YYYY-MM-DD");

    dateString = `min=${min}&max=${max}`;
    searchObject["max"] = max;
    searchObject["min"] = min;
  }

  if (o.type && o.type !== "all") {
    typeString = `${dateString ? "&" : ""}type=${o.type}`;
    searchObject["type"] = o.type;
  }

  if (o.page) {
    pageNum = Number(o.page);
  }

  pageString = `${dateString || typeString ? "&" : ""}page=${pageNum}`;
  searchObject["page"] = pageNum;

  getEventArchive(JSON.stringify(searchObject));
  history.push(`/en/calendar?${dateString}${typeString}${pageString}`);
};

const fileExtensionRegex = /[^\\]*\.(\w+)$/;

const renderNoEvents = () => {
  return (
    <div style={{ padding: "2rem 0" }}>
      <div>
        <p>No Events Found</p>
      </div>
      <div style={{ padding: "0 0 0 1rem" }}>
        <div>
          <p>Suggestions:</p>
        </div>
        <div>
          <ul>
            <li>
              <p>Make sure all the dates are correctly selected</p>
            </li>
            <li>
              <p>Make sure all options are correctly selected</p>
            </li>
            <li>
              <p>Try a larger date range</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const EventItemContainer = styled.div`
  display: flex;
  min-height: 200px;
  padding: 1rem;
`;
const StyledEventImage = styled.img`
  max-height: 200px;
  max-width: 355.55px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
`;

const StyledEventDetails = styled.div`
  padding: 0 1rem;
`;

const Right: any = (
  <svg viewBox="64 64 896 896" width=".8rem" fill="white" height=".8rem">
    <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z" />
  </svg>
);

const DoubleRight: any = (
  <svg viewBox="64 64 896 896" width=".8em" fill="white" height=".8em">
    <path d="M533.2 492.3L277.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H188c-6.7 0-10.4 7.7-6.3 12.9L447.1 512 181.7 851.1A7.98 7.98 0 0 0 188 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5zm304 0L581.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H492c-6.7 0-10.4 7.7-6.3 12.9L751.1 512 485.7 851.1A7.98 7.98 0 0 0 492 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5z" />
  </svg>
);

const Left: any = (
  <svg viewBox="64 64 896 896" width=".8em" fill="white" height=".8em">
    <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z" />
  </svg>
);

const DoubleLeft: any = (
  <svg viewBox="64 64 896 896" width=".8em" fill="white" height=".8em">
    <path d="M272.9 512l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L186.8 492.3a31.99 31.99 0 0 0 0 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H532c6.7 0 10.4-7.7 6.3-12.9L272.9 512zm304 0l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L490.8 492.3a31.99 31.99 0 0 0 0 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H836c6.7 0 10.4-7.7 6.3-12.9L576.9 512z" />
  </svg>
);

interface IPagerButtonProps {
  show: boolean;
}
const StyledPageButtons = styled.div<IPagerButtonProps>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  display: inline-block;

  & > button:first-of-type {
    margin: 0 0.5rem 0 0;
  }
`;

const StyledPagerButton = styled.button`
  background-color: ${(props) => props.theme.gpwmd.green};
  outline: 0;
  border: 0;
  border-radius: 5px;
  cursor: pointer;

  :hover,
  :focus,
  :active {
    background-color: ${(props) => props.theme.gpwmd.slate};
  }

  :disabled {
    background-color: ${(props) => props.theme.gpwmd.grey} !important;
    cursor: not-allowed;
  }
`;

interface IPageProps {
  selected: boolean;
}

const StyledIntegerSpan = styled.a<IPageProps>`
  color: ${(props) =>
    props.selected ? "#2D2D2F" : `${props.theme.gpwmd.green}`};
  font-size: 1rem;
  letter-spacing: 0;
  text-align: left;
  line-height: 1rem;
  padding: 0 1rem;
`;
interface IFCPagerProps {
  pager: IPagerObject;
  handleSearch: (num?: number) => void;
}

export const Pager: React.FC<IFCPagerProps> = ({ pager, handleSearch }) => {
  const { current_page, total_pages } = pager;
  //TODO use: items_per_page, total_items
  const LeftActive: boolean = current_page > 0;
  const RightActive: boolean = current_page < total_pages - 1;

  return (
    <div style={{ padding: "1rem" }}>
      <StyledPageButtons show>
        <StyledPagerButton
          disabled={!LeftActive}
          onClick={() => handleSearch()}
        >
          {DoubleLeft}
        </StyledPagerButton>
        <StyledPagerButton
          disabled={!LeftActive}
          onClick={() => handleSearch(current_page)}
        >
          {Left}
        </StyledPagerButton>
      </StyledPageButtons>
      <div style={{ padding: "0 1rem", display: "inline-block" }}>
        {Array(Number(total_pages))
          .fill(0)
          .map((_: any, index: number) => (
            <StyledIntegerSpan
              onClick={() => handleSearch(index + 1)}
              selected={current_page === index}
            >
              {index + 1}
            </StyledIntegerSpan>
          ))}
      </div>
      <StyledPageButtons show>
        <StyledPagerButton
          disabled={!RightActive}
          onClick={() => handleSearch(current_page + 2)}
        >
          {Right}
        </StyledPagerButton>
        <StyledPagerButton
          disabled={!RightActive}
          onClick={() => handleSearch(total_pages)}
        >
          {DoubleRight}
        </StyledPagerButton>
      </StyledPageButtons>
    </div>
  );
};

const Headline = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.main};
  font-family: ${(props) => props.theme.fonts.heading};
  width: 100%;
  margin: 0;
  padding: 0;
`;
const LetterText = styled.p`
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.main};
  font-weight: 300;
  margin-bottom: 5px;
`;

const StyledIcon = styled.img`
  width: 16px;
  margin-right: 0.5rem;
`;

const StyledButton = styled.button`
  align-items: center;
  display: flex;
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  color: ${(props) => props.theme.colors.main};
  font-size: 1rem;
  height: 32px;
  background-color: transparent;
  outline: 0;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;

  :hover,
  :focus,
  :active {
    color: ${(props) => props.theme.gpwmd.red} !important;
  }
`;
const ButtonText = styled.span``;
interface LearnMoreProps {
  link: string;
  title: string;
}

const renderEvents = (
  content: IEventSearchStateObject,
  handleSearch: (num?: number) => void
) => {
  const { pager, pages } = content;

  return (
    <div>
      {pages[pager.current_page].map((item: IEventsMessageObject) => {
        let learnMore: LearnMoreProps | undefined = undefined;
        if (item.field_learn_more && item.field_learn_more.length > 0) {
          try {
            learnMore = JSON.parse(item.field_learn_more);
          } catch (error) {}
        }

        let image: string | undefined = undefined;
        let ext = "";
        const total = item.field_event_files.match(fileExtensionRegex);
        if (total) {
          // const filename = total[0];
          const extension = total[1];
          ext = extension;
          switch (extension) {
            case "pdf":
              image = "/images/icons/pdf.svg";
              break;
            case "doc":
            case "docx":
              image = "/images/icons/word.svg";
              break;
            default:
              break;
          }
        }
        return (
          <EventItemContainer key={item.nid}>
            <StyledEventImage
              key={item.nid}
              src={imagePathToURL(item.field_banner_image)}
              onClick={() =>
                item.field_learn_more.length > 0 && learnMore
                  ? window.open(learnMore.link, "_blank")
                  : null
              }
            />
            <StyledEventDetails>
              <Headline>{item.title}</Headline>
              {item.field_event_type && item.field_event_type.length > 0 && (
                <StyledTag>{item.field_event_type}</StyledTag>
              )}
              <LetterText>{item.field_caption}</LetterText>
              <LetterText>{`${item.field_date_range}, ${item.field_country}`}</LetterText>
              <LetterText>{item.field_location}</LetterText>
              {item.field_learn_more &&
                item.field_learn_more.length > 0 &&
                learnMore && (
                  <a
                    href={learnMore.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {learnMore.title}
                  </a>
                )}
              {item.field_event_files?.length > 0 && (
                <StyledButton
                  title="Download event agenda"
                  onClick={() => {
                    genFetch(
                      authentication.getFileDownload(item.field_event_files)
                    )()(withToken())
                      .then((res) =>
                        res
                          .blob()
                          .then((blob: any) => {
                            const url = window.URL.createObjectURL(blob);

                            const a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = url;
                            a.download = `${item.title}.${ext}`;
                            a.click();
                            window.URL.revokeObjectURL(url);
                            document.body.removeChild(a);
                          })
                          .catch((error) => console.log(error))
                      )
                      .catch((error) => console.log(error));
                  }}
                >
                  <StyledIcon src={publicPathToURL(image)} />
                  <ButtonText>
                    {
                      // LOL - Try reading this again... hehe
                      decodeURI(item.field_event_files)
                        .split("/")
                        [item.field_event_files.split("/").length - 1].split(
                          "."
                        )[0]
                    }
                  </ButtonText>
                </StyledButton>
              )}
            </StyledEventDetails>
          </EventItemContainer>
        );
      })}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pager pager={pager} handleSearch={handleSearch} />
      </div>
    </div>
  );
};

const Calendar = (props: CalendarProps) => {
  const location = useLocation();
  const history = useHistory();
  const { getEventArchive } = props;
  const queryParsed = queryString.parse(location.search);
  const [content, setContent] = useState<IEventSearchStateObject | undefined>(
    undefined
  );

  let dateMin: any = "";
  let dateMax: any = "";
  // let pageIndex: any = 1;

  if (queryParsed.min && queryParsed.max) {
    const start = moment(queryParsed.min);
    const end = moment(queryParsed.max);
    if (start.isValid() && end.isValid()) {
      dateMin = start;
      dateMax = end;
    }
  } else {
    dateMin = moment();
    dateMax = moment().add(5, "year");

    queryParsed.min = dateMin;
    queryParsed.max = dateMax;
  }

  // if (queryParsed.page && typeof Number(queryParsed.page) === "number") {
  //   pageIndex = Number(queryParsed.page);
  // }

  const [dates, setDates] = useState<DateRangeProps>({
    start: dateMin,
    end: dateMax,
  });

  useEffect(() => {
    handleFirstTimeQuery(queryParsed, getEventArchive, history);
  }, []);

  useEffect(() => {
    const hash: string = hashCode(
      removePage(queryString.parse(location.search))
    );
    setContent(props.events[hash]);
  }, [props.events, location.search]);

  const handleSearch = (page: number = 1) => {
    // Date: Min Max
    // Type of Events : Type

    let dateString = "";
    let typeString = "";
    let pageString = "";
    const searchObject: any = {};

    if (dates.end && dates.start) {
      const min = dates.start.format("YYYY-MM-DD");
      const max = dates.end.format("YYYY-MM-DD");

      dateString = `min=${min}&max=${max}`;
      searchObject["max"] = max;
      searchObject["min"] = min;
    }

    // if (selected !== "all") {
    //   typeString = `${dateString ? "&" : ""}type=${selected}`;
    //   searchObject["type"] = selected;
    // }

    pageString = `${dateString || typeString ? "&" : ""}page=${page}`;
    searchObject["page"] = page;

    getEventArchive(JSON.stringify(searchObject));
    window.scrollTo(0, 0);
    history.push(
      `/${props.languages.code}/calendar?${dateString}${typeString}${pageString}`
    );
  };

  const keys: string[] = content ? Object.keys(content.pages) : [];

  return (
    <Container>
      <ContainerTwo>
        <h1>Calendar</h1>
        <SearchContainer>
          <DateRangeWrapper dates={dates} setDates={setDates} />
          <div style={{ display: "inline-block", width: "4rem" }}>
            <SearchButton onClick={() => handleSearch()}>GO</SearchButton>
          </div>
        </SearchContainer>

        {content && keys.length > 0 && content.pager.total_items > 0 && (
          <div style={{ width: "100%" }}>
            {renderEvents(content, handleSearch)}
          </div>
        )}
        {content && content.pager.total_items === 0 && (
          <div style={{ width: "100%" }}>{renderNoEvents()}</div>
        )}
      </ContainerTwo>
    </Container>
  );
};

export default Calendar;
