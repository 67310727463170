import React, { useCallback, useState } from "react";
import { authentication } from "state/api/authentication";
import { genFetch } from "state/utils/endpoints";

export interface ExportControlHubEntry {
  description: string;
  filters: string;
  keywords: string;
  scope: string;
  source: string;
  source_link: string;
  source_year: string;
  title: string;
}

function useECHSearch() {
  const [results, setResults] = useState<ExportControlHubEntry[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchECH = useCallback(
    async (query: string) => {
      setIsLoading(true);
      const response = await genFetch(authentication.getECHSearch(query))()();
      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data)) {
          setResults(data);
        } else {
          setResults([]);
        }
      }
      setIsLoading(false);
    },
    [setResults]
  );

  return { searchECH, results, isLoading };
}

export default useECHSearch;
