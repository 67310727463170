import React from "react";
import { useLocation, Redirect } from "react-router-dom";

interface Props {}

const Forwarder = (props: Props) => {
  const location = useLocation();
  return <Redirect to={`/en/login?forward=${location.pathname}`} />;
};

export default Forwarder;
