import { connect } from 'react-redux';
import { IApplicationState } from 'state/ducks';
import Footer from './footer';

const mapStateToProps = (state: IApplicationState) => ({
  languages: state.languages,
  connect: state.connect,
  navigation: state.navigation.navigation,
});

export default connect(mapStateToProps)(Footer);
