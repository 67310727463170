import React, { useState } from "react";
import styled, { css } from "styled-components";
import { AggregatedProposal, ProposalResponse } from "../proposals";
import { Form, Input, Select, List, Badge, Tag, Tooltip } from "antd";
import moment from "moment";
import { LabeledFormItem, Button } from "view/utils/forms/styles";
import { genFetch, withToken } from "state/utils/endpoints";
import { authentication } from "state/api/authentication";
import { useDispatch } from "react-redux";
import { addNotification } from "state/ducks/notifications/actions";
import { IMessage } from "state/ducks/notifications/types";
import { ListProps } from "antd/lib/list";
import { BadgeProps } from "antd/lib/badge";
import { countryCodeToCountry } from "view/utils/mappings/codeToCountry";
import flags from "view/utils/mappings/flags";

const failedMessage: IMessage = {
  type: "error",
  message: "The request failed",
  description: "ught oh.. Something went wrong",
};

const successMessage: IMessage = {
  type: "success",
  message: "Your message has been sent",
  description: "Thank you",
};

interface Props {
  record: AggregatedProposal;
}

interface ActionContainerProps {
  isOpen: boolean;
}
const ActionContainer = styled.div<ActionContainerProps>`
  height: auto;
  display: flex;
  flex-wrap: wrap;
`;

export const FormHref = styled.a`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 400;
  color: ${(props) => props.theme.colors.main};
  font-size: 1.15rem;
  max-width: 1000px;
  padding-right: 1rem;
  strong {
    background-color: rgb(255, 215, 0);
    padding: 0 0.5rem;
  }

  :hover,
  :focus,
  :active {
    color: ${(props) => props.theme.gpwmd.red} !important;
  }
`;

export const FormOpenHref = styled.a`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  color: ${(props) => props.theme.gpwmd.red} !important;

  font-size: 1rem;
  max-width: 1000px;
  padding-right: 1rem;
  :hover,
  :focus,
  :active {
    color: ${(props) => props.theme.gpwmd.grey};
  }
`;

export const FormOpenP = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  color: ${(props) => props.theme.colors.main};
  font-size: 1rem;
`;

export const StyledButton = styled.button`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 400;
  color: ${(props) => props.theme.colors.main};
  font-size: 1rem;
  height: 32px;
  min-width: 100px;
  background-color: transparent;
  border: 1px solid black;
  cursor: pointer;
  :hover,
  :focus,
  :active {
    color: ${(props) => props.theme.gpwmd.darkTan} !important;
    border-color: ${(props) => props.theme.gpwmd.green} !important;
    outline-color: ${(props) => props.theme.gpwmd.green} !important;
  }
`;

const RowHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 0.5rem 0;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-wrap: unset;
    justify-content: space-between;
  }
`;

const RowBody = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem 1rem;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    padding: 0;
  }
`;

const TitleText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 500;
  color: ${(props) => props.theme.colors.main};
  font-size: 0.95rem;
  width: 100%;
  margin: 0;
`;

const ResponseText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  color: ${(props) => props.theme.colors.main};
  font-size: 1rem;
  width: 100%;
  margin: 0;
`;

interface FundingProps {
  fundingLevel: string;
}
const FundingTag = styled(Tag)<FundingProps>`
  font-weight: 600;
  ${(props) => {
    const level = props.fundingLevel;
    switch (level) {
      case "Fully Funded":
        return css`
          color: #36b37e;
          /* background-color: #36B37E; */
          border-color: #36b37e;
        `;
      case "Partially Funded":
        return css`
          color: #ffab00;
          /* background-color: #ffab00; */
          border-color: #ffab00;
        `;
      case "Not Funded":
        return css`
          color: ${(props) => props.theme.gpwmd.red};
          /* background-color: ${props.theme.gpwmd.red}; */
          border-color: ${props.theme.gpwmd.red};
        `;
      default:
        return css`
          color: ${(props) => props.theme.gpwmd.grey};
          /* background-color: ${props.theme.gpwmd.grey}; */
          border-color: ${props.theme.gpwmd.grey};
        `;
    }
  }}
`;

const NotesTag = styled(Tag)`
  font-weight: 600;
`;

const SynopsisText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  color: ${(props) => props.theme.colors.main};
  font-style: italic;
  font-size: 0.95rem;
  width: 100%;
  margin: 0;
`;

const OrganizationText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  color: ${(props) => props.theme.colors.main};
  font-size: 0.95rem;
  width: 100%;
  margin: 0;
`;

export const CommentHeader = styled.div`
  min-height: 50px;
  background: #e1e1e1;
  margin-top: 1rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border-radius: 1px;
`;

const CommentHeaderText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 500;
  color: ${(props) => props.theme.colors.main};
  font-size: 1.5rem;
  width: 100%;
  margin: 0;
`;

const TextArea = styled(Input.TextArea)`
  min-height: 240px !important;

  @media (max-width: 1000px) {
    min-height: 150px !important;
  }
`;
const StyledForm = styled(Form)`
  max-width: 350px;
  width: 100%;
  @media (max-width: 1000px) {
    max-width: unset;
    padding: 1rem 2rem;
  }
`;

const StyledList = styled(List)<ListProps<ProposalResponse>>`
  overflow: auto;
  max-height: 600px;
  padding: 0 1rem 0 0.25rem;
  @media (max-width: 1000px) {
    overflow: unset;
    max-height: unset;
  }
`;

const StyledButtonHolder = styled.div`
  margin: 1rem 3rem 0 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media (min-width: 768px) {
    width: unset;
    margin: 0;
  }
`;

const StyledBadge = styled(Badge)<BadgeProps>`
  .ant-badge-count {
    background: ${(props) => props.theme.gpwmd.green};
  }
`;

const DateContainer = styled.div`
  float: right;
  margin: 10px 10px 0 10px;
  display: flex;
  min-height: 83px;
  width: 83px;
  min-width: 83px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  & .day {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 2rem;
    font-family: ${(props) => props.theme.fonts.main};
    font-weight: 600;
    left: 15px;
    line-height: 1rem;
    top: 0;
  }
  & .month {
    display: block;
    width: 100%;
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
  }
`;

const StyledTable = styled.table`
  width: auto !important;
  margin-bottom: 1rem;
  & th {
    font-size: 0.9rem;
    text-align: right;
  }
  & td {
    font-size: 0.9rem;
    padding-left: 1rem;
    text-align: left;
  }
`;

const NotesButton = styled.button`
  outline: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;

  :hover,
  :focus {
    color: ${(props) => props.theme.gpwmd.red};
  }
`;

const Action = (props: Props) => {
  const { record } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo) {
      return false;
    }
  };

  const handleSubmit = async () => {
    const formIsValid = await isValid();
    if (formIsValid) {
      const { subject, message } = form.getFieldsValue();

      genFetch(
        authentication.sendProposalComment(record.nid, subject, message)
      )()(withToken())
        .then((res) => {
          if (res.ok) dispatch(addNotification(successMessage));
          else dispatch(addNotification(failedMessage));
        })
        .catch((error) => dispatch(addNotification(failedMessage)));
    }
  };

  const downloadProposal = (route: string) => {
    genFetch(authentication.getProposalDownload(route))()(withToken())
      .then((res) => {
        res.blob().then((blob) => {
          const linkURL = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          if (link) {
            link.href = linkURL;
            const filename = decodeURI(
              route.substr(route.lastIndexOf("/") + 1)
            );
            link.setAttribute("download", filename);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            if (link.parentNode) link.parentNode.removeChild(link);
          }
        });
      })
      .catch((error) => dispatch(addNotification(failedMessage)));
  };

  const countries = record.field_proposal_country.split(", ");
  const date = moment(record.field_proposal_matchmaking_sessi);
  const MonthFromCreation = moment(record.created).add(1, "M");
  const isNew = moment().isBefore(MonthFromCreation);
  return (
    <ActionContainer isOpen={isOpen}>
      <RowHeader>
        <div style={{ padding: " 0 1rem 0 1rem" }}>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div style={{}}>
              <div>
                <FormHref
                  onClick={() => downloadProposal(record.field_proposal_file)}
                >
                  {isNew && (
                    <>
                      <strong>NEW</strong>
                      {" - "}
                    </>
                  )}
                  {record.title}
                </FormHref>
              </div>
              <div>
                <OrganizationText>
                  {record.field_proposal_implementing_orga}
                </OrganizationText>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem",
                  flexWrap: "wrap",
                }}
              >
                {record.field_proposal_funding_level?.length > 0 && (
                  <FundingTag
                    fundingLevel={record.field_proposal_funding_level}
                    title={record.field_proposal_funding_level}
                  >
                    {record.field_proposal_funding_level}
                  </FundingTag>
                )}
                {record.field_proposal_matchmaking_notes && (
                  <Tooltip
                    placement="left"
                    title={"Matchmaking Session Notes Available"}
                  >
                    <NotesTag>Notes Available</NotesTag>
                  </Tooltip>
                )}
                {record.field_proposal_country?.length > 0 &&
                  countries.map((country) =>
                    flags[country as keyof typeof flags]({
                      title: countryCodeToCountry[country],
                      width: 22 * 1.5,
                      height: 22,
                    })
                  )}
                {record.field_proposal_region?.length > 0 && (
                  <Tooltip
                    placement="left"
                    title={record.field_proposal_region || ""}
                  >
                    <Tag>{record.field_proposal_region}</Tag>
                  </Tooltip>
                )}
              </div>
              {record.field_proposal_synopsis?.length > 0 && (
                <div style={{ margin: "8px 0" }}>
                  <SynopsisText>{record.field_proposal_synopsis}</SynopsisText>
                </div>
              )}
            </div>
          </div>
        </div>
        <StyledButtonHolder>
          <StyledBadge
            title={"Number of Comments"}
            count={record.field_responses.length}
          >
            <StyledButton onClick={() => setIsOpen(!isOpen)}>
              {`${isOpen ? "Close" : "Learn More"}`}
            </StyledButton>
          </StyledBadge>
        </StyledButtonHolder>
      </RowHeader>

      {isOpen && (
        <RowBody>
          <StyledForm form={form} layout="vertical">
            <Form.Item
              label="Subject"
              name="subject"
              rules={[{ required: true, message: "Please select an Option!" }]}
            >
              <Select>
                {record.fixedResponses.map((item) => (
                  <Select.Option key={item.tid} value={item.tid}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <LabeledFormItem
              label="Message"
              name="message"
              rules={[
                { required: false, message: "Please input your Message!" },
              ]}
            >
              <TextArea />
            </LabeledFormItem>
            <Form.Item style={{ padding: "1rem 0" }}>
              <Button onClick={handleSubmit}>Send Message</Button>
            </Form.Item>
          </StyledForm>
          <div
            style={{
              padding: "1rem 2rem",
            }}
          >
            <FormOpenP>
              If you are interested in supporting this proposal or learning more
              about it, please send us a message. Upon review, it will be posted
              to the comments field below.
            </FormOpenP>
            <FormOpenHref
              onClick={() => downloadProposal(record.field_proposal_file)}
            >
              View Proposal
            </FormOpenHref>
            {record.field_proposal_matchmaking_notes && (
              <FormOpenHref
                onClick={() =>
                  downloadProposal(
                    record.field_proposal_matchmaking_notes || ""
                  )
                }
              >
                View Matchmaking Session Notes
              </FormOpenHref>
            )}

            <hr></hr>
            <StyledTable>
              <tr>
                <th>Expected Duration:</th>
                <td>{record.field_proposal_duration}</td>
              </tr>
              <tr>
                <th>Matchmaking Session Notes:</th>
                <td>
                  {record.field_proposal_matchmaking_notes ? (
                    <NotesButton
                      onClick={() =>
                        downloadProposal(
                          record.field_proposal_matchmaking_notes || ""
                        )
                      }
                    >
                      View Notes
                    </NotesButton>
                  ) : (
                    "Notes not available"
                  )}
                </td>
              </tr>
              <tr>
                <th>Total Cost:</th>
                <td>{record.field_proposal_total_cost}</td>
              </tr>
              <tr>
                <th>Funding Level:</th>
                <td>{record.field_proposal_funding_level}</td>
              </tr>
              <tr>
                <th>Countries:</th>
                <td>
                  {record.field_proposal_country.length > 0
                    ? countries.map((code) => `${countryCodeToCountry[code]}, `)
                    : "None"}
                </td>
              </tr>
              <tr>
                <th>Region:</th>
                <td>
                  {record.field_proposal_region.length > 0
                    ? record.field_proposal_region
                    : "None"}
                </td>
              </tr>
              <tr>
                <th>Organization:</th>
                <td>{record.field_proposal_implementing_orga}</td>
              </tr>
              <tr>
                <th>Point of Contact:</th>
                <td>{record.field_point_of_contact}</td>
              </tr>
            </StyledTable>
            <hr></hr>
            {Array.isArray(record.field_responses) && (
              <>
                <CommentHeader>
                  <CommentHeaderText>Comments</CommentHeaderText>
                </CommentHeader>
                <StyledList
                  itemLayout="horizontal"
                  dataSource={record.field_responses}
                  renderItem={(item: any) => {
                    const id = item.id;
                    const date = moment(item.field_date_submitted).format(
                      "MMMM Do YYYY"
                    );
                    const username = item.field_user_name;
                    const organization = item.field_user_organization;
                    const response = item.field_plain_text;
                    return (
                      <List.Item key={id}>
                        <List.Item.Meta
                          title={
                            <TitleText>{`${username}, ${organization} - ${date}`}</TitleText>
                          }
                          description={<ResponseText>{response}</ResponseText>}
                        />
                      </List.Item>
                    );
                  }}
                />
              </>
            )}
          </div>
        </RowBody>
      )}
    </ActionContainer>
  );
};

export const proposalColumns: any = [
  {
    title: " ",
    key: "title",
    render: (text: any, record: AggregatedProposal) => (
      <Action record={record} />
    ),
  },
];
