import React from 'react';
import useParsedLayout from 'view/utils/hooks/useParsedLayout';
import TextNode from './fieldTypes/text';
import { RowNode } from './fieldTypes/row';
import { ColumnNode } from './fieldTypes/col';
import ImageNode from './fieldTypes/image';
import BulletedListNode from './fieldTypes/bulletedList';
import LinkNode from './fieldTypes/link';
import ImageLinkNode from './fieldTypes/imageLink';
import MediaNode from './fieldTypes/media';
import ParticipantList from './fieldTypes/participantList';
interface Props {
  data: any;
  componentMap?: any;
}

const defaultComponents = new Map<string, any>([
  ['col', ColumnNode],
  ['row', RowNode],
  ['text', TextNode],
  ['image', ImageNode],
  ['bullet_list', BulletedListNode],
  ['link', LinkNode],
  ['image_link', ImageLinkNode],
  ['media', MediaNode],
  ['participants_list', ParticipantList ],
  // ['link', FieldTypes.LinkNode],
]);

/**
 * Builds a lookup for the component definitions that will be built out for a parsed content page.
 * @param props
 */
export const ParsedLayout: React.FC<Props> = props => {
  const [{ layout }] = useParsedLayout({ componentMap: defaultComponents, ...props });
  return layout;
};
