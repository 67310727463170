import React, { useState, useEffect, useMemo } from "react";
import { MainContainer, Logo } from "view/utils/forms/styles";
import { Link, useLocation } from "react-router-dom";
import { authentication } from "state/api/authentication";
import { genFetch, withToken } from "state/utils/endpoints";
import { imagePathToURL } from "state/utils";
import { Banner, Overlay } from "view/common/Banner";
import GroupToggle from "view/common/GroupToggle";
import {
  TwoColumnLayout,
  LeftColumn,
  RightColumn,
  BodyContainer,
} from "view/common/Layout";
import {
  FullLength,
  WebinarContainer,
  Column,
  TextContainer,
  FormHeader,
  StyledTag,
  Hr,
} from "./styles";
import ColumnMenu from "view/common/ColumnMenu";
import Webinar from "./Webinar";
import {
  ParticipateHeader,
  QuestionnaireLink,
  TextContainerBanner,
} from "view/discussionBoard/styles";
const queryString = require("query-string");
export interface WebinarItemProps {
  field_duration: string;
  field_thumbnail: string;
  field_topic: string;
  field_video_page: string;
  title: string;
}

function WebinarsArchive() {
  const [webinars, setWebinars] = useState<WebinarItemProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const tid = useMemo(() => {
    const query = queryString.parse(location.search);
    const tid = query["group"] || null;

    return tid;
  }, [location.search]);

  useEffect(() => {
    setIsLoading(true);
    if(tid) {
      genFetch(authentication.webinars(tid))()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: WebinarItemProps[]) => {
            setWebinars(data && data.length > 0 ? data : []);
            setIsLoading(false);
          })
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
    } else {
      genFetch(authentication.getPlenaryWebinars())()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) => {
            if (data && data.length > 0) {
              setWebinars(data);
            }
          })
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
    }

    
  }, [setWebinars, tid]);

  return (
    <MainContainer>
      <BodyContainer>
        <Banner>
          <Logo
            maxWidth="1600px"
            src="/images/banners/recorded-events-1600x400.jpg"
          />
          <Overlay>
            <div>
              <h1>Recorded Events</h1>
              <h4>
                View all available Global Partnership recorded events. Click on
                one or more of the Sub-Working Groups below to view only recorded
                events by the selected options.
              </h4>
            </div>
          </Overlay>
        </Banner>
        <GroupToggle all allOption="PLENARY" pathname="/en/workshops" />
        <TwoColumnLayout>
          <LeftColumn flex transparent>
            {!isLoading && webinars.length === 0 ? (
              <ParticipateHeader>
                <TextContainerBanner style={{width: "100%"}}>
                  <h1>No Recorded Events available</h1>
                  <p>
                    Select a different Sub-Working Group to view other recorded events.
                  </p>
                </TextContainerBanner>
              </ParticipateHeader>
            ) : (
              <Webinar webinars={webinars} />
            )}
          </LeftColumn>
          <RightColumn>
            <ColumnMenu />
          </RightColumn>
        </TwoColumnLayout>
      </BodyContainer>
    </MainContainer>
  );
}

export default WebinarsArchive;
