import { Table } from "antd";
import styled from "styled-components";

export const StyledTable = styled(Table)`
  width: 100%;
  background: #fff;
  padding: 0 2rem 0 2rem;
  display: inline-flex;

  .ant-spin-nested-loading {
    width: 100%;
  }

  .ant-table-thead tr {
    height: 50px;
  }

  .ant-table-thead {
    display: none;
  }

  .min-w-500 {
    min-width: 400px;
  }
`;