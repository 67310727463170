import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import BarItem from "sanskrit/Appbar/BarItem";
import Appbar from "sanskrit/Appbar/Appbar";

export const StyledMenuContainer = styled.div`
  max-width: 1600px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  outline: none;
  background: ${(props) => props.theme.gpwmd.slate};
  align-items: center;
  padding: 1rem;

  @media (min-width: 1440px) {
    padding: 1rem 2rem 1rem 1rem;
  }
`;

interface GPWMDLogoProps {
  $needsMargin?: boolean;
}

export const GPWMDLogo = styled.img<GPWMDLogoProps>`
  margin: ${(props) => (props.$needsMargin ? "0 3rem" : "0")};

  :hover {
    cursor: pointer;
  }

  @media (min-width: 320px) {
    height: 6em;
  }

  @media (min-width: 900px) {
    height: 7rem;
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.gpwmd.slate};
`;

export const StyledSubMenuItem = styled.div`
  display: flex;
  box-sizing: border-box;
  outline: none;
  align-items: center;

  @media (min-width: 200px) {
    padding: 0 0.5rem;
  }

  @media (min-width: 780px) {
    padding: 0 0.5rem;
  }

  @media (min-width: 800px) {
    padding: 0 1.25rem;
  }

  @media (min-width: 1224px) {
    padding: 0 1.25rem;
  }
`;

interface NavLinkProps {
  primary?: string;
  purpose?: "title" | "subtitle";
}

export const NavLink = styled(Link)<NavLinkProps>`
  text-decoration: none;
  text-align: center;
  color: ${(props) => props.theme.colors.secondary};
  ${(props) => css`
    font-family: ${props.primary
      ? props.theme.fonts.heading
      : props.theme.fonts.main};
    font-weight: ${props.primary ? 600 : 400};
  `}

  :hover {
    ${(props) => css`
      color: ${props.theme.gpwmd.slate};
    `}
  }
`;

export const TopNavLink = styled(Link)<NavLinkProps>`
  text-decoration: none;
  ${(props) => css`
    font-family: ${props.primary
      ? props.theme.fonts.heading
      : props.theme.fonts.main};
    color: ${props.primary
      ? props.theme.colors.secondary
      : props.theme.colors.main};
    font-weight: ${props.primary ? 600 : 400};
  `}

  :hover {
    color: ${(props) => props.theme.gpwmd.green};
  }
`;

export const NavButton = styled.button<NavLinkProps>`
  text-decoration: none;
  color: ${(props) => props.theme.colors.secondary};
  background: transparent;
  outline: none;
  border: 0;
  ${(props) => css`
    font-family: ${props.primary
      ? props.theme.fonts.heading
      : props.theme.fonts.main};
    font-weight: ${props.primary ? 600 : 400};
  `}

  :hover {
    color: ${(props) => props.theme.gpwmd.slate};
  }
`;

export const StyledMenuSubContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  outline: none;
  align-items: center;
  height: 100%;
  width: auto;
  justify-content: flex-end;
`;

export const HeaderContainer = styled.div<NavLinkProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  outline: none;
  align-items: center;
  height: 100%;
  width: auto;
  justify-content: center;
  margin: 0 auto;
`;

export const HeaderText = styled.p<NavLinkProps>`
  margin: 0;
  color: ${(props) => props.theme.colors.secondary};
  font-size: ${(props) => (props.purpose === "title" ? "3.5rem" : "1.1rem")};
  font-family: ${(props) => props.theme.fonts.heading};
  line-height: 1.3;
`;

export const MenuContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  min-height: 70px;
  height: 70px;
  width: 100%;
  background: ${(props) => props.theme.gpwmd.green};
`;

export interface IBarItemsProps {
  bar?: boolean;
}

export const BottomMenu = styled(Appbar)`
  height: auto !important;
  max-width: 1600px;
  width: 100%;
  background: ${(props) => props.theme.gpwmd.green};
  & > div {
    align-items: center;
    width: 50%;
  }

  justify-content: space-between;

  @media (min-width: 320px) {
    padding: 0 0.5rem !important;
  }
  @media (min-width: 780px) {
    padding: 0 0.5rem !important;
  }
  @media (min-width: 1024px) {
    padding: 0 1rem !important;
    & > div {
      width: auto;
    }
  }

  @media (min-width: 1280px) {
    padding: 0 1.5rem !important;
  }

  @media (min-width: 1440px) {
    padding: 0 2rem 0 0 !important;
  }
`;

export const BarItemOverride = styled(BarItem)`
  & > div {
    top: 0px;
  }

  @media (min-width: 320px) {
    margin: 0 0.5rem !important;
    padding: 0 0;
  }
  @media (min-width: 700px) {
    margin: 0 0.5rem !important;
    padding: 1.25rem 0;

    & > div {
      padding: 1.4rem;
    }
  }
  @media (min-width: 1024px) {
    margin: 0 1rem !important;
    padding: 1.25rem 0;

    & > div {
      padding: 1.4rem;
    }
  }
  @media (min-width: 1280px) {
    margin: 0 1.25rem !important;
    padding: 1.5rem 0;

    & > div {
      padding: 1.6rem;
    }
  }

  ${(props: IBarItemsProps) => css`
    ${props.bar === false
      ? css`
          > div {
            display: none;
          }
        `
      : css``};
  `};
`;

export const DropdownMenuContainer = styled(BarItemOverride)`
  position: unset;
  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.main};
  margin: 0 !important;
  padding: 1.5rem 1.25rem;
  :hover,
  :focus,
  :active {
    color: ${(props) => props.theme.gpwmd.red} !important;
    background-color: #ffffff;
  }
  :hover {
    & > #dropdown {
      visibility: visible;
      background-color: #ffffff;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  @media (min-width: 320px) {
    margin: 0;
    padding: 0 0.5rem;
  }
  @media (min-width: 700px) {
    padding: 1.25rem 0.5rem;

    & > div {
      padding: 1.4rem;
    }
  }
  @media (min-width: 1024px) {
    padding: 1.25rem 1rem;

    & > div {
      padding: 1.4rem;
    }
  }
  @media (min-width: 1280px) {
    padding: 1.5rem 1.25rem;
  }
`;
export const DropdownMenu = styled.div`
  position: absolute;
  visibility: hidden;
  display: none;
  opacity: 0;
  width: 100%;
  transition: all 0.5s ease;
  top: 70px !important;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 1000;
  transition-duration: 0.5s;
  padding: 0.25rem 0;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
`;

interface DropdownProps {
  width?: string;
}

export const Dropdown = styled.ul<DropdownProps>`
  display: block;
  list-style: none;
  ${(props) => css`
    width: ${props.width || "50%"};
  `}
  padding:0;
`;

export const DropdownItem = styled.li`
  width: 100%;
  padding: 0.1rem 0.5rem;
  display: flex;
  :hover {
    cursor: pointer;
  }
`;

export const NestedLink = styled.a`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 400;
  /* font-style: italic; */
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.main};
  font-size: 0.85rem;
  max-width: 1000px;
  :hover,
  :focus,
  :active {
    color: ${(props) => props.theme.gpwmd.red} !important;
  }
`;
