import { isNumber } from 'util';

/**
 * Builds a Querystring using a object key'd with params
 *   To query a date range you must include params 'max' and 'min' with an appropriate date.
 *   To query a Domestic or International you must include a 'type' parameter.
 *   To Change a page - you must include a 'page' parameter.
 * @param o A object with keyed Query Parameters
 */
export const getQueryStringFromObject = (o: any): string => {
  let dateString = '';
  let typeString = '';
  let pageString = '';
  let pageNum = 1;

  if (o['max'] && o['min']) {
    dateString = `field_date_range[min]=${o['min']} 00:00:00&field_date_range[max]=${
      o['max']
    } 12:59:59`;
  }

  if (o['type'] && (o['type'] === 'international' || o['type'] === 'US')) {
    typeString = `${dateString ? '&' : ''}${
      o['type'] === 'US' ? 'field_country=US' : 'field_country=US&field_country_op=not'
    }`;
  }

  if (o['page'] && isNumber(Number(o['page']))) {
    const num = Number(o['page']);
    pageNum = num - 1 <= 0 ? 0 : num - 1;
  }

  pageString = `${dateString || typeString ? '&' : ''}page=${pageNum}`;

  return `?${dateString}${typeString}${pageString}`;
};

/**
 * Accepts a object and removed the key 'page' from it.
 * @param o
 */
export const removePage = (o: any): string => {
  if (o['page']) delete o['page'];
  return JSON.stringify(o);
};
