import * as React from "react";
import { imagePathToURL } from "state/utils";
import styled, { css } from "styled-components";

interface CoChair {
  field_additional_titles: string;
  field_bio: string;
  field_picture: string;
  field_sub_working_group: string;
  field_working_group_title: string;
  name: string;
  splitLayout: boolean;
  numOfChairs: number;
}

interface ChairContainerProps {
  numOfChairs?: number;
}

const ChairContainer = styled.div<ChairContainerProps>`
  display: flex;
  width: 50%;
  align-items: center;
  flex-direction: column;
  
  ${(props) => props.numOfChairs && css`
    width: ${100 /  props.numOfChairs}%;
  `}

`;

const ChairName = styled.h1`
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.heading};
  margin: 0;
  margin-top: 1rem;
  font-weight: 600;
`;
const ChairTitle = styled.p`
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.heading};
  font-weight: 300;
  margin-bottom: 1rem;
  border-bottom: solid 1px ${(props) => props.theme.gpwmd.darkTan};
`;
const BioDescription = styled.p`
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  margin-bottom: 6px;
`;

const ChairAddTitles = styled.p`
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  margin: 0;
  font-style: italic;
  text-align: center;
  white-space: pre;
`;


function Chair(props: CoChair) {
  const {
    field_additional_titles,
    field_bio,
    field_picture,
    field_sub_working_group,
    field_working_group_title,
    name,
    splitLayout,
    numOfChairs,
  } = props;

  const bio = field_bio.split("<br />");
  const titles = field_additional_titles;

  if (!splitLayout) {
    return (
      <div style={{ width: "100%", display: "flex", padding: "2rem" }}>
        <ChairContainer style={{ width: "30%" }}>
          <img width={141} height={141} src={imagePathToURL(field_picture)} />
          <ChairName>{name}</ChairName>
          <ChairTitle>{field_working_group_title}</ChairTitle>
          <ChairAddTitles>{titles}</ChairAddTitles>
        </ChairContainer>
        <ChairContainer style={{ width: "70%", padding: "0 2rem" }}>
          {bio &&
            bio.map((item, index) => (
             <>
              {item.split(',').map((element, elementIndex) =>  <BioDescription key={`bio-${index}`}>{element}</BioDescription>)}
             </>
            ))}
        </ChairContainer>
      </div>
    );
  }

  return (
    <ChairContainer numOfChairs={numOfChairs}>
      <img width={141} height={141} src={imagePathToURL(field_picture)} />
      <ChairName>{name}</ChairName>
      <ChairTitle>{field_working_group_title}</ChairTitle>
      {titles.split(',').map((element, elementIndex) =>  <ChairAddTitles key={`bio-${element}}`}>{element.replaceAll('/', ',')}</ChairAddTitles>)}
    </ChairContainer>
  );
}
export default Chair;
