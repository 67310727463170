import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IApplicationState } from "../../state/ducks";
import { columnedTypes, columnedActions } from "../../state/ducks/columned";
import ActionPlan from "./ActionPlan";

const mapStateToProps = (state: IApplicationState) => ({
  columned: state.columned,
  languages: state.languages,
});

type actions = columnedTypes.ColumnedActions;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators(
    {
      columnedAction: columnedActions.columned,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlan);
