import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Select, Tooltip } from "antd";
import { IConnectState } from "state/ducks/connect/types";
import { ILanguageState } from "state/ducks/languages/types";
import { connectActions } from "state/ducks/connect";
import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js";
import {
  MainContainer,
  FormContainer,
  LabeledFormItem,
  Button,
  Logo,
  FormLink,
  HelpText,
  StyledAlert,
} from "view/utils/forms/styles";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Column, TwoColumnFrom } from "./styles";
import { countries } from "view/utils/forms/countries";
import { useHistory } from "react-router-dom";
import { publicPathToURL } from "state/utils";
import { genFetch, withToken } from "state/utils/endpoints";
import { authentication } from "state/api/authentication";
import { Vocabulary } from "state/ducks/vocabulary/types";
import { InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import {
  HeaderContainer,
  HeaderText,
  NavigationContainer,
  StyledMenuContainer,
} from "view/navigation/styles";

interface Props {
  connect: IConnectState;
  languages: ILanguageState;
  becomeAMemberRequest: typeof connectActions.becomeAMember;
  becomeAMemberReset: typeof connectActions.becomeAMemberReset;
  submitFirstName: (name: string) => void;
  isFormLoading: boolean;
}

const Registration = (props: Props) => {
  const [form] = Form.useForm();
  const [workingGroups, setWorkingGroups] = useState<Vocabulary[]>([]);
  const [token, setToken] = useState<string | null>(null);

  const verifyRecaptchaCallback = useCallback((token: string) => {
    setToken(token);
  }, []);

  useEffect(() => {
    genFetch(
      authentication.getVocabularyByMachineName("sub_working_groups")
    )()()
      .then((res) =>
        res
          .json()
          .then((data: Vocabulary[]) => setWorkingGroups(data))
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [setWorkingGroups]);

  const history = useHistory();

  if (props.connect.memberRequestSuccess && !props.connect.memberSending) {
    props.becomeAMemberReset();
    history.push(
      publicPathToURL(`/${props.languages.code}/register/thank-you`)
    );
  }

  const isValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo) {
      return false;
    }
  };

  const handleSubmit = async () => {
    const formIsValid = await isValid();
    if (formIsValid && token) {
      const {
        fullName,
        emailAddress,
        password,
        position,
        business,
        address,
        city,
        country,
        workNumber,
      } = form.getFieldsValue();

      props.submitFirstName(fullName);

      props.becomeAMemberRequest({
        fullName,
        emailAddress,
        password,
        position,
        business,
        address,
        city,
        country,
        workNumber,
      });
    }
  };

  return (
    <>
      <NavigationContainer style={{ backgroundColor: "#f9f9f9" }}>
        <StyledMenuContainer style={{ backgroundColor: "#f9f9f9" }}>
          <HeaderContainer>
            <HeaderText purpose="title" style={{ color: "#202C47" }}>
              GLOBAL PARTNERSHIP
            </HeaderText>
            <HeaderText style={{ color: "#202C47" }}>
              Against the Spread of Weapons and Materials of Mass Destruction
            </HeaderText>
          </HeaderContainer>
        </StyledMenuContainer>
      </NavigationContainer>
      <MainContainer
        style={{ padding: "3rem", flexDirection: "column", flexWrap: "nowrap" }}
      >
        <Logo
          maxWidth="650px"
          style={{ paddingBottom: "1rem" }}
          src="/images/icons/italy24/gp-g7-logo.png"
        />
        <FormContainer>
          <TwoColumnFrom
            layout="vertical"
            form={form}
            onValuesChange={(changedValues: any, allValues: any) => {
              if (changedValues.workNumber) {
                allValues.workNumber = new AsYouType("US").input(
                  changedValues.workNumber
                );
                form.setFieldsValue(allValues);
              }
            }}
          >
            <Column>
              <LabeledFormItem
                label="Full Legal Name"
                name="fullName"
                rules={[{ required: true, message: "Full Legal Name" }]}
              >
                <Input />
              </LabeledFormItem>
              <LabeledFormItem
                label="Email Address"
                name="emailAddress"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email Address!",
                  },
                  {
                    type: "email",
                    message: "Please input a valid Email Address",
                  },
                ]}
              >
                <Input />
              </LabeledFormItem>
              <LabeledFormItem
                height="140px"
                label="Password"
                name="password"
                style={{ minHeight: "100px" }}
                rules={[
                  { required: true, message: "Please input your password!" },
                  {
                    pattern: /^(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?=.*[0-9]).{6}/,
                    message: `Make sure your password has at least: 6 characters, 1 Special Character, 1 Uppercase, and 1 Number`,
                  },
                ]}
              >
                <Input.Password />
              </LabeledFormItem>
            </Column>
            <Column>
              <LabeledFormItem
                label="Position/Business Title"
                name="position"
                rules={[
                  {
                    required: true,
                    message: "Please input your Position Title!",
                  },
                ]}
              >
                <Input />
              </LabeledFormItem>
              <LabeledFormItem
                label="Employer/Business Name"
                name="business"
                rules={[
                  {
                    required: true,
                    message: "Please input your Employers Name!",
                  },
                ]}
              >
                <Input />
              </LabeledFormItem>
              <LabeledFormItem
                label="Business Street Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please input your Business Street Address!",
                  },
                ]}
              >
                <Input />
              </LabeledFormItem>
              <LabeledFormItem
                label="Work Phone Number"
                name="workNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your Work Phone Number!",
                  },
                  {
                    validator: (_, value) =>
                      parsePhoneNumberFromString(value, "US")?.isValid()
                        ? Promise.resolve()
                        : Promise.reject("Please input a phone number"),
                  },
                ]}
              >
                <Input placeholder="xxx xxx xxxx" />
              </LabeledFormItem>
              <LabeledFormItem
                label="City"
                name="city"
                rules={[{ required: true, message: "Please input your City!" }]}
              >
                <Input />
              </LabeledFormItem>
              <LabeledFormItem
                label="Country"
                name="country"
                rules={[
                  { required: true, message: "Please input your Country!" },
                ]}
              >
                <Select>{countries}</Select>
              </LabeledFormItem>

              <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
              <Form.Item style={{ padding: "1rem 0" }}>
                <Button disabled={props.isFormLoading} onClick={handleSubmit}>
                  {props.isFormLoading ? "...loading" : "Register"}
                </Button>
              </Form.Item>
              <FormLink to={`/${props.languages.code}/login`}>
                Back to Login
              </FormLink>
              <FormLink
                as="a"
                href="https://www.pnnl.gov/security-privacy"
                target="_blank"
              >
                Privacy Policy
              </FormLink>
            </Column>
          </TwoColumnFrom>
        </FormContainer>
      </MainContainer>
    </>
  );
};

export default Registration;
