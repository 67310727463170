import React from "react";
import styled, { css } from "styled-components";
import {
  Column,
  ParagraphBorder,
  ParseItemParent,
} from "state/ducks/columned/types";

interface Props extends ParseItemParent {
  data: Column;
  className?:string;
}

export interface ColumnProps {
  border?: ParagraphBorder;
  imageContainer?: boolean;
  span?: string;
}
export const Col = styled.div<ColumnProps>`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  ${(props) => css`
    width: ${props.span ? `${props.span}%` : "100%"};
    padding: ${props.imageContainer ? 0 : '0 0 1rem 0'};
    ${props.border &&
    css`
      border-radius: 1px;
      border-right: ${props.border.field_right[0].value
        ? "solid black 1px"
        : "none"};
      border-left: ${props.border.field_left[0].value
        ? "solid black 1px"
        : "none"};
      border-top: ${props.border.field_top[0].value
        ? "solid black 1px"
        : "none"};
      border-bottom: ${props.border.field_bottom[0].value
        ? "solid black 1px"
        : "none"};
    `}
  `}
`;

export const ColumnNode: React.FC<Props> = (props) => {
  const { data, componentMap, items, renderContentNode, className } = props;
  const { border, images, span } = data;

  const imageContainer = images.length > 0 ? images[0].value : undefined;
  const hasBorder = border.length > 0 ? border[0] : undefined;
  const hasSpan = span.length > 0 ? span[0].value : undefined;

  return (
    <Col className={className} border={hasBorder} imageContainer={imageContainer} span={hasSpan}>
      {renderContentNode(items, componentMap)}
    </Col>
  );
};
