import React from "react";
import { publicPathToURL } from "state/utils";
import Login from "view/login/_login";
import Registration from "view/registration/_registration";
import ForgotPassword from "view/forgotPassword/_forgotPassword";
import { Route, Switch, Redirect } from "react-router-dom";
import Connect from "view/connect/_connect";
import ResetPassword from "view/resetPassword/_resetPassword";
import EmailApproval from "view/emailApproval/emailApproval";
import Forwarder from "view/utils/routing/Forwarder";
import ExportControl from "view/ExportControl/ExportControl";
import PublicExportControl from "view/ExportControl/PublicExportControl";

const regex = /\/$/g;

interface Props {
  isApiPublic: boolean;
  match: any;
}

const PublicInterface = (props: Props) => {
  return (
    <Switch>
      <Route
        exact
        path={publicPathToURL(`${props.match.path}/login`)}
        component={Login}
      />
      <Route
        path={publicPathToURL(`${props.match.path}/register`)}
        component={Registration}
      />
      <Route
        exact
        path={publicPathToURL(`${props.match.path}/forgot/password`)}
        component={ForgotPassword}
      />
      <Route
        exact
        path={publicPathToURL(`${props.match.path}/approvals/:uuid/:status`)}
        component={EmailApproval}
      />
      <Route
        exact
        path={publicPathToURL(`${props.match.path}/reset/password/:token`)}
        component={ResetPassword}
      />
      <Route
        exact
        path={publicPathToURL(`${props.match.path}/export-control`)}
        component={PublicExportControl}
      />
      <Route
        exact
        path={publicPathToURL(`/:lang/`)}
        render={() => (
          <Redirect
            to={publicPathToURL(
              `${props.match.url}${regex.test(props.match.url) ? "" : "/"}login`
            )}
          />
        )}
      />
      <Route component={Forwarder} />
    </Switch>
  );
};

const Public = (props: Props) => {
  const { isApiPublic } = props;
  return isApiPublic ? <PublicInterface {...props} /> : <Connect />;
};

export default Public;
