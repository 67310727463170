import styled from "styled-components";

export const DocumentContainer = styled.div`
  padding: 0 3rem;
  width: 100%;
`;

export const ResourceLink = styled.a`
  display: block;
  font-style: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.gpwmd.green};
  font-weight: 300;
  text-decoration: none;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: block;
  border-bottom: solid 1px ${(props) => props.theme.gpwmd.grey};

  :hover {
    color: ${(props) => props.theme.colors.main};
  }
`;
