import { takeLatest, call, put, select, takeEvery } from "redux-saga/effects";
import { UNAUTHORIZED, OK } from "http-status-codes";
import { columnedTypes, columnedActions } from ".";
import { genRequest } from "state/api/subrequests";
import { columnedParser } from "./columnedparser";
import { genFetch, withToken } from "state/utils/endpoints";
import { isApiPublic } from "../connect/selectors";
import { getAPILangCode, getLanguageCode } from "../languages/selectors";

// TODO - GET RID OF THIS ( Handle this with an action consumption. )
// import { push } from 'react-router-redux';

/**
 * Possible Request States
 * PUBLIC_TRANSLATED = 0,
 * PUBLIC_ENGLISH = 1,
 * PUBLIC_FAIL = 2,
 * MEMBER_TRANSLATED = 10,
 * MEMBER_ENGLISH = 11,
 * MEMBER_FALLBACK = 0,
 */
enum FALLBACK_STATUS {
  PUBLIC_TRANSLATED = 0,
  PUBLIC_ENGLISH = 1,
  PUBLIC_FAIL = 2,
  MEMBER_TRANSLATED = 10,
  MEMBER_ENGLISH = 11,
  MEMBER_FALLBACK = 0,
}

/**
 * Gets the next iteration of enum mapping.
 * We could have incremented this by one then checked bounds...
 * but i felt like that wasn't very maintainable. or newb friendly
 *
 * @param status - FALLBACK_STATUS
 */
function getNextStep(status: FALLBACK_STATUS):any {
  switch (status) {
    case 0:
      return FALLBACK_STATUS.PUBLIC_ENGLISH;
    case 1:
      return FALLBACK_STATUS.PUBLIC_FAIL;
    case 2:
      return FALLBACK_STATUS.PUBLIC_FAIL;
    case 10:
      return FALLBACK_STATUS.MEMBER_ENGLISH;
    case 11:
      return FALLBACK_STATUS.PUBLIC_TRANSLATED;
    default:
      return FALLBACK_STATUS.PUBLIC_FAIL;
  }
}

/**
 * Determines the starting point of a request
 *
 * @param isPublic Boolean - is the user authenticated or not?
 */
const getStartingStatus = (isPublic = true): number =>
  isPublic
    ? FALLBACK_STATUS.PUBLIC_TRANSLATED
    : FALLBACK_STATUS.MEMBER_TRANSLATED;

/**
 * Navigated back home
 * ( SHOULD WE HAVE AN ERROR PAGE)
 * TODO - FIX THIS WITH AN ACTION
 */
function* publicFail():any {
  // const code = yield select(getLanguageCode);
  // TODO: Create a action to forward Route
  // yield put(push(`/${code}/login`));
}

/**
 * Updates the recursive call and provides a base case
 *
 * @param path Page Path
 * @param code Language Code
 * @param currentStatus FALLBACK_STATUS Enum
 * @param isPublic Is the api public
 */
function* processNextStep(
  path: string,
  code: string,
  currentStatus: FALLBACK_STATUS = FALLBACK_STATUS.PUBLIC_TRANSLATED,
  isPublic = false
): any {
  let apiPublic = isPublic;
  const nextStep = yield getNextStep(currentStatus);
  if (nextStep < 10) apiPublic = true;

  switch (nextStep) {
    case FALLBACK_STATUS.PUBLIC_FAIL:
      yield publicFail();
      break;
    case FALLBACK_STATUS.MEMBER_ENGLISH || FALLBACK_STATUS.PUBLIC_ENGLISH:
      yield fetchColumnedContent(path, "", nextStep, apiPublic);
      break;
    default:
      yield fetchColumnedContent(path, code, nextStep, apiPublic);
      break;
  }
}

/**
 * 1. Extracts the columned page
 * 2. Filters the content
 * 3. Updates the Store
 * @param columnedResponse ColumnedDataResponse
 */
function* parseColumnedResponse(columnedResponse: any, path: string):any {
  const body = yield columnedResponse.json();
  const languageCode = yield select(getLanguageCode);
  const parsedBody = yield call(
    columnedParser.filterContent,
    body.entity.field_page_content
  );
  yield put(
    columnedActions.returnColumnedSuccess(
      { label: body.label, value: parsedBody },
      `${languageCode}/${path}`
    )
  );
}

/**
 * The basic making of the request.
 *
 *
 * @param path Page Path
 * @param code Language Code
 * @param currentStatus FALLBACK_STATUS Enum
 * @param isPublic Is the api public
 */
function* fetchColumnedContent(
  path: string,
  code: string,
  currentStatus: FALLBACK_STATUS = FALLBACK_STATUS.PUBLIC_TRANSLATED,
  isPublic = false
):any {
  const columnedResponse = yield genFetch(
    genRequest.getColumnedData(path, code)
  )(isPublic)(withToken());
  const { status } = columnedResponse;

  switch (status) {
    case OK: {
      yield parseColumnedResponse(columnedResponse, path);
      break;
    }
    case UNAUTHORIZED:
      yield publicFail();
      break;
    default:
      yield processNextStep(path, code, currentStatus, isPublic);
      break;
  }
}

/**
 * Saga generator function that is called based on action, then takes response from api
 * and passes to parser. The parsed data returned is then sent to a reducer function to
 * add to store of application.
 * @param action
 */
export function* columnedData(action: columnedTypes.IColumnedAction):any {
  try {
    const { path } = action.payload;
    const LanguageCode = yield select(getAPILangCode);
    const isPublic = yield select(isApiPublic);
    const START_STATUS = getStartingStatus(isPublic);
    yield fetchColumnedContent(path, LanguageCode, START_STATUS, isPublic);
  } catch (e) {
    console.log(e); // tslint:disable-line no-console
  }
}

export function* newsData(action: columnedTypes.IColumnedAction):any {
  try {
    const { path } = action.payload;
    const code = yield select(getAPILangCode);
    const response = yield call(genRequest.blueprintNews, path, code);
    const { status } = response;

    switch (status) {
      case OK: {
        // Get response body and then parse out columns
        const body = yield response.json();
        const { entity } = body;
        const parsedBody = yield call(
          columnedParser.filterContent,
          entity.field_page_content
        );
        const header = {
          title: entity.title[0].value,
          date: entity.field_date[0].value,
          subject: entity.field_subject,
        };

        const object = {
          header,
          value: parsedBody,
        };

        yield put(
          columnedActions.returnColumnedSuccess(
            { label: body.entity.public_path, value: object },
            path
          )
        );
        break;
      }
      case UNAUTHORIZED: {
        break;
      }
    }
  } catch (e) {
    console.log(e); // tslint:disable-line no-console
  }
}

export function* getArchive(action: columnedTypes.IColumnedAction):any {
  try {
    const { path } = action.payload;
    const code = yield select(getAPILangCode);
    const response = yield call(genRequest.getArchiveNews, code, path);
    const { status } = response;

    switch (status) {
      case OK: {
        // Get response body and then parse out columns
        const body = yield response.json();
        // const parsedBody = yield call(columnedParser.filterContent, entity.field_page_content);
        yield put(
          columnedActions.returnColumnedSuccess(
            { label: path, value: body },
            path
          )
        );
        break;
      }
      case UNAUTHORIZED: {
        break;
      }
    }
  } catch (e) {
    console.log(e); // tslint:disable-line no-console
  }
}

export function* getEventsArchive(action: columnedTypes.IColumnedAction):any {
  try {
    const { path } = action.payload;
    const response = yield genFetch(genRequest.getAllEvents())()(withToken());
    const { status } = response;

    switch (status) {
      case OK: {
        // Get response body and then parse out columns
        const body = yield response.json();
        // const parsedBody = yield call(columnedParser.filterContent, entity.field_page_content);
        yield put(
          columnedActions.returnColumnedSuccess(
            { label: path, value: body },
            path
          )
        );
        break;
      }
      case UNAUTHORIZED: {
        break;
      }
    }
  } catch (e) {
    console.log(e); // tslint:disable-line no-console
  }
}

export function* getColumnedByNode(action: columnedTypes.IColumnedAction):any {
  try {
    const { path } = action.payload;
    const LanguageCode = yield select(getAPILangCode);
    const isPublic = yield select(isApiPublic);

    const columnedResponse = yield genFetch(
      genRequest.getColumnedDataByNode(path, LanguageCode)
    )(isPublic)(withToken());
    const { status } = columnedResponse;

    switch (status) {
      case OK: {
        yield parseColumnedResponse(columnedResponse, path);
        break;
      }
      case UNAUTHORIZED:
        yield publicFail();
        break;
      default:
        yield publicFail();
        break;
    }
  } catch (e) {
    console.log(e); // tslint:disable-line no-console
  }
}

export default function* columnedSaga() {
  const { columnedActionTypes } = columnedTypes;
  yield takeEvery(columnedActionTypes.COLUMNED, columnedData);
  yield takeEvery(columnedActionTypes.COLUMNED_BY_NODE, getColumnedByNode);
  yield takeLatest(columnedActionTypes.NEWS_ARTICLE, newsData);
  yield takeLatest(columnedActionTypes.NEWS_ARCHIVE, getArchive);
  yield takeLatest(columnedActionTypes.EVENTS_ARCHIVE, getEventsArchive);
}
