import {
  useECHQuery,
} from "lib/hooks/useECHQuery";
import { useHistory } from "react-router-dom";
import { VocabularyMap, Vocabulary } from "state/ducks/vocabulary/types";

type Props = {
  vocabularies: VocabularyMap;
};
function ExportControlActiveFilters({ vocabularies }: Props) {
  const query = useECHQuery();
  const { ech_filters, ech_keywords, ech_scope, ech_source } = vocabularies;

  const payload: { [x: string]: Vocabulary[] } = {};
  const { filters, keywords, scope, sources } = query;

  if (filters.length > 0) {
    payload["filters"] = filters
      .map((filter) =>
        ech_filters.find((echFilter) => echFilter.tid === filter)
      )
      .filter((item) => item !== undefined) as Vocabulary[];
  }

  if (keywords.length > 0) {
    payload["keywords"] = keywords
      .map((keyword) =>
        ech_keywords.find((echKeyword) => echKeyword.tid === keyword)
      )
      .filter((item) => item !== undefined) as Vocabulary[];
  }

  if (scope.length > 0) {
    payload["scope"] = scope
      .map((item) => ech_scope.find((echFilter) => echFilter.tid === item))
      .filter((item) => item !== undefined) as Vocabulary[];
  }

  if (sources.length > 0) {
    payload["sources"] = sources
      .map((source) => ech_source.find((echSource) => echSource.tid === source))
      .filter((item) => item !== undefined) as Vocabulary[];
  }

  const hasFilters = Object.keys(payload).some(key => payload[key].length > 0);

  if(!hasFilters){
    return null;
  }

  return (
    <>
      <p style={{ marginTop: "8px", marginBottom: "4px" }}><b>Active Filters:</b></p>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: "8px",
          rowGap: "4px",
          fontSize: "14px",
          marginBottom: "8px",
        }}
      >
        {Object.entries(payload).map(([label, value]) => (
          <div
            style={{
              display: "flex",
              columnGap: "8px",
              rowGap: "4px",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <p style={{ margin: 0, textTransform: "capitalize" }}>{label}:</p>
            {value.map((item) => (
              <button
                style={{
                  fontSize: "14px",
                  fontWeight: 700,
                  fontStyle: "italic",
                  borderRadius: "1pc",
                  outline: "none",
                  border: "none",
                }}
              >
                {item.name}
              </button>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

export default ExportControlActiveFilters;
