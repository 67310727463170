import { IRequest } from 'state/utils/endpoints';

export const events = {
  getAllEvents: (): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API : ''
      }/web/events?_format=json`,
    options: {
      method: 'GET',
    },
  }),
  getEventsBySearch: (query: string): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API : ''
      }/web/events-search${query}`,
    options: {
      method: 'GET',
    },
  }),
};
