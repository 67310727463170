import { AggregatedRequest } from "lib/hooks/useUkraineRequests";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { authentication } from "state/api/authentication";
import { addNotification } from "state/ducks/notifications/actions";
import { IMessage } from "state/ducks/notifications/types";
import { genFetch, withToken } from "state/utils/endpoints";
import { Form, Select, List, Tooltip, Tag } from "antd";
import {
  FormHref,
  FundingTag,
  RequestContainer,
  RowBody,
  RowHeader,
  StyledBadge,
  StyledButton,
  StyledButtonHolder,
  StyledForm,
  TextArea,
  CommentHeader,
  CommentHeaderText,
  FormOpenHref,
  ResponseText,
  StyledList,
  StyledTable,
  TitleText,
  RowId,
  TextDivArea,
} from "./styles";
import { Button, LabeledFormItem } from "view/utils/forms/styles";

interface Props {
  record: AggregatedRequest;
}

const failedMessage: IMessage = {
  type: "error",
  message: "The request failed",
  description: "ught oh.. Something went wrong",
};

const successMessage: IMessage = {
  type: "success",
  message: "Your message has been sent",
  description: "Thank you",
};

const RequestCell = (props: Props) => {
  const { record } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const submissionTextBox = `submission-${record.nid}`;

  const isValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo) {
      return false;
    }
  };

  const handleSubmit = async () => {
    const formIsValid = await isValid();
    if (formIsValid) {
      const { subject } = form.getFieldsValue();
      const message = document.getElementById(submissionTextBox)?.innerText;
      setIsSubmitting(true);
      genFetch(
        authentication.sendUkraineRequestComment(
          record.nid,
          subject,
          message || ""
        )
      )()(withToken())
        .then((res) => {
          if (res.ok) dispatch(addNotification(successMessage));
          else dispatch(addNotification(failedMessage));
          form.resetFields();
          setIsSubmitting(false);
        })
        .catch((error) => dispatch(addNotification(failedMessage)));
    }
  };

  const downloadProposal = (route: string) => {
    genFetch(authentication.getProposalDownload(route))()(withToken())
      .then((res) => {
        res.blob().then((blob) => {
          const linkURL = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          if (link) {
            link.href = linkURL;
            const filename = decodeURI(
              route.substr(route.lastIndexOf("/") + 1)
            );
            link.setAttribute("download", filename);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            if (link.parentNode) link.parentNode.removeChild(link);
          }
        });
      })
      .catch((error) => dispatch(addNotification(failedMessage)));
  };

  return (
    <RequestContainer isOpen={isOpen}>
      <RowId>{record.field_request_id}</RowId>
      <RowHeader>
        <div style={{ padding: " 0 1rem 0 1rem" }}>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div style={{}}>
              <div>
                <FormHref
                  onClick={() =>
                    downloadProposal(record.field_ukraine_request_file)
                  }
                >
                  {record.title}
                </FormHref>
              </div>
              <div>
                {record.field_funding_level &&
                  record.field_funding_level?.length > 0 && (
                    <FundingTag
                      fundingLevel={record.field_funding_level}
                      title={record.field_funding_level}
                    >
                      {record.field_funding_level}
                    </FundingTag>
                  )}
                {record.field_ukraine_tags?.length > 0 && (
                  <Tooltip
                    placement="left"
                    title={record.field_ukraine_tags || ""}
                  >
                    <Tag>{record.field_ukraine_tags}</Tag>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
        <StyledButtonHolder>
          <StyledBadge
            title={"Number of Comments"}
            count={record.field_responses.length}
          >
            <StyledButton onClick={() => setIsOpen(!isOpen)}>
              {`${isOpen ? "Close" : "Learn More"}`}
            </StyledButton>
          </StyledBadge>
        </StyledButtonHolder>
      </RowHeader>

      {isOpen && (
        <RowBody>
          <StyledForm form={form} layout="vertical">
            <Form.Item
              label="Subject"
              name="subject"
              rules={[{ required: true, message: "Please select an Option!" }]}
            >
              <Select>
                {record.fixedResponses.map((item) => (
                  <Select.Option key={item.tid} value={item.tid}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <LabeledFormItem
              label={
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <p style={{ width: "100%", margin: 0 }}>Message</p>
                  <p style={{ width: "100%", margin: 0, fontStyle: "italic" }}>
                    If you plan to fund this request in part or in full, or have
                    already funded this request in part or in full, please let
                    us know by providing the information in the box below.
                  </p>
                </div>
              }
              name={submissionTextBox}
            >
              <TextDivArea
                as="div"
                id={submissionTextBox}
                style={{ padding: "1rem " }}
                contentEditable
              >
                - GP Donor Country and Ministry / Agency:
                <br />
                &emsp; <br />
                - A Donor Point of Contact Name and Email:
                <br />
                &emsp; <br />
                - A list of items GP Donor Ministry / Agency is interested in
                providing:
                <br />
                &emsp; <br />
                - Request Status (Complete or In process): <br />
                &emsp; <br />- Please refrain from providing quantities{" "}
                <i>
                  <u>unless specifically requested by the Ukrainians.</u>
                </i>{" "}
                <br />
                &emsp; <br />
              </TextDivArea>
            </LabeledFormItem>
            <Form.Item style={{ padding: "1rem 0" }}>
              <Button disabled={isSubmitting} onClick={handleSubmit}>
                {isSubmitting ? "Sending Message..." : "Send Message"}
              </Button>
            </Form.Item>
          </StyledForm>
          <div
            style={{
              padding: "1rem 2rem",
              width: "100%",
            }}
          >
            {record.field_ukraine_request_file &&
              record.field_ukraine_request_file.length > 1 && (
                <FormOpenHref
                  onClick={() =>
                    downloadProposal(record.field_ukraine_request_file)
                  }
                >
                  View Request
                </FormOpenHref>
              )}

            <hr></hr>
            <StyledTable>
              <tbody>
                <tr>
                  <th>Country:</th>
                  <td>{record.field_country}</td>
                </tr>
                <tr>
                  <th>Organization:</th>
                  <td>{record.field_organization}</td>
                </tr>
                <tr>
                  <th>Point of Contact:</th>
                  <td>
                    {record.field_point_of_contact &&
                      record.field_point_of_contact
                        .split("|")
                        .map((item: string) => (
                          <div style={{ borderBottom: "1px solid #e4e4e4" }}>
                            {item}
                          </div>
                        ))}
                  </td>
                </tr>
                <tr>
                  <th>Funding Level:</th>
                  <td>{record.field_funding_level}</td>
                </tr>
              </tbody>
            </StyledTable>
            <hr></hr>
            {record.field_comments_file &&
              record.field_comments_file.length > 1 && (
                <FormOpenHref
                  onClick={() => downloadProposal(record.field_comments_file)}
                >
                  View Contributions
                </FormOpenHref>
              )}
          </div>
        </RowBody>
      )}
    </RequestContainer>
  );
};

export default RequestCell;
