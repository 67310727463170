import { getApiAttribute } from 'state/utils/endpoints';
import { connectActionTypes } from './../connect/types';
import { ConnectActions } from 'state/ducks/connect/types';
import { Reducer } from 'redux';
import { INavigationState } from './types';
import LanguagesActionTypes from '../languages/types';

const initialState: INavigationState = {
  navigation: {
    public: [],
    member: [],
  },
};

const reducer: Reducer<INavigationState> = (state: INavigationState = initialState, action) => {
  const stateCopy: INavigationState = JSON.parse(JSON.stringify(state));
  switch ((action as ConnectActions).type) {
    case connectActionTypes.AUTHENTICATE_SUCCESS: {
      const { items, isPublic } = action.payload;
      stateCopy.navigation[getApiAttribute(isPublic)] = items;
      return { ...stateCopy };
    }
    case LanguagesActionTypes.CHANGE_LANGUAGE_SUCCESS: {
      const { items, isPublic } = action.payload;
      stateCopy.navigation[getApiAttribute(isPublic)] = items;
      return { ...stateCopy };
    }
    case connectActionTypes.INITIALIZE_APPLICATION_SUCCESS: {
      const { items, isPublic } = action.payload;
      stateCopy.navigation[getApiAttribute(isPublic)] = items;
      return { ...stateCopy };
    }
    case connectActionTypes.LOGOUT_SUCCESS: {
      const { items, isPublic } = action.payload;
      stateCopy.navigation[getApiAttribute(isPublic)] = items;
      return {
        ...stateCopy,
      };
    }
    default:
      return stateCopy;
  }
};

export default reducer;
