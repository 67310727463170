import { useEffect } from "react";
import styled from "styled-components";
import { Skeleton } from "antd";
import { Banner } from "view/common/Banner";
import { ParsedLayout } from "view/parsed-layout/parsedLayout";
import UkraineAssistanceTable from "view/UkraineAssistanceTable/UkraineAssistanceTable";
import { MainContainer } from "view/utils/forms/styles";
import useColumnedParser from "view/utils/hooks/useColumnedParser";
import ColumnMenu from "view/common/ColumnMenu";
import { Logo, RightColumn, LeftColumn } from "./styles";

import { TwoColumnLayout, BodyContainer } from "view/common/Layout";

//Components
import TextNode from "view/parsed-layout/fieldTypes/text";
import { RowNode } from "view/parsed-layout/fieldTypes/row";
import { ColumnNode } from "view/parsed-layout/fieldTypes/col";
import ImageNode from "view/parsed-layout/fieldTypes/image";
import BulletedListNode from "view/parsed-layout/fieldTypes/bulletedList";
import LinkNode from "view/parsed-layout/fieldTypes/link";
import ImageLinkNode from "view/parsed-layout/fieldTypes/imageLink";
import MediaNode from "view/parsed-layout/fieldTypes/media";
import ParticipantList from "view/parsed-layout/fieldTypes/participantList";
import UkraineGeneralAssistanceSection from "view/UkraineGeneralAssistanceTable/UkraineGeneralAssistanceSection";

const Column = styled(ColumnNode)``;

const Row = styled(RowNode)`
  padding: 0;
`;

const BulletList = styled(BulletedListNode)`
  height: 100%;
`;

const defaultComponents = new Map<string, any>([
  ["col", Column],
  ["row", Row],
  ["text", TextNode],
  ["image", ImageNode],
  ["bullet_list", BulletList],
  ["link", LinkNode],
  ["image_link", ImageLinkNode],
  ["media", MediaNode],
  ["participants_list", ParticipantList],
  // ['link', FieldTypes.LinkNode],
]);

const ProposalsDescription = styled.div`
  background: rgb(242, 242, 242);
  width: 100%;
  max-width: 1600px;
  min-height: 180px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 0 3rem 0 3rem;
  margin-bottom: 3rem;
`;

const Description = styled.p`
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 38px;
  color: ${(props) => props.theme.colors.main};
  font-family: ${(props) => props.theme.fonts.main};
  max-width: 1300px;
  width: 100%;
  margin: 0;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem 3rem;
  h1 {
    margin: 0;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 900;
    color: ${(props) => props.theme.colors.main};
    letter-spacing: 1px;
    font-family: ${(props) => props.theme.fonts.main};

    strong {
      background-color: rgb(255, 215, 0);
      padding: 0 0.5rem;
    }
  }
`;

const SectionContainer = styled.section`
  background-color: #fff;
  margin-bottom: 4rem;
  width: 100%;
  hr {
    border: 1px solid #f0f0f0;
    width: 100%;
  }
`;

const UkraineRequests = () => {
  const [invitation, invitationIsLoading, fetch] = useColumnedParser("", false); // eslint-disable-line

  useEffect(() => {
    fetch(`/member/ukraine-assistance`.toLowerCase());
  }, [fetch]);

  return (
    <MainContainer>
      <BodyContainer>
        <Banner>
          <Logo maxWidth="1600px" src={`/images/banners/ukraine-flag.jpg`} />
        </Banner>
        <ProposalsDescription>
          {invitation ? (
            <Description>
              <ParsedLayout
                data={invitation}
                componentMap={defaultComponents}
              />
            </Description>
          ) : (
            <Skeleton style={{ padding: "0 2rem" }} active />
          )}
        </ProposalsDescription>
        <TwoColumnLayout>
          <LeftColumn style={{ background: "transparent" }}>
            <SectionContainer>
              <TableHeader>
                <h1>
                  <strong>NEW</strong> - UKRAINE ASSISTANCE SUMMARY
                </h1>
                <hr />
              </TableHeader>
              <p style={{ padding: "0 3.5rem" }}>
                GP members continue to receive and fund requests from Ukraine in
                a variety of ways. To better capture this assistance, to the
                extent possible, this summary is intended to reflect all
                assistance provided to Ukraine by GP members. It will be updated
                on a rolling basis.
              </p>
              <hr />
              <UkraineGeneralAssistanceSection />
            </SectionContainer>
            <SectionContainer>
              <TableHeader>
                <h1>UKRAINE ASSISTANCE REQUESTS</h1>
                <hr />
              </TableHeader>
              <p style={{ padding: "0 3.5rem" }}>
                GP Members are requested to review the following assistance
                requests from Ukraine. Please indicate the ability to fund,
                either in part or in full, in the message boxes associated with
                each request. (Note: This assistance information will be
                automatically included in the summary tracker above.)
              </p>
              <hr />
              <UkraineAssistanceTable />
            </SectionContainer>
          </LeftColumn>
          <RightColumn>
            <div id="search-table-portal"></div>
            <ColumnMenu />
          </RightColumn>
        </TwoColumnLayout>
      </BodyContainer>
    </MainContainer>
  );
};

export default UkraineRequests;
