import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IApplicationState } from 'state/ducks';
import { connectTypes, connectActions } from 'state/ducks/connect';
import Navigation from './navigation';
import { searchTypes, searchActions } from 'state/ducks/search';
import { languagesActions } from 'state/ducks/languages';

const mapStateToProps = (state: IApplicationState) => ({
  breakpoint: state.layout.breakpoint,
  isPublic: state.connect.public,
  navigation: state.navigation.navigation,
  member: state.connect.member,
  languages: state.languages,
});

type actions = connectTypes.ConnectActions & searchTypes.SearchActionsInterfaces;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators(
    {
      logout: connectActions.logout,
      searchFor: searchActions.searchFor,
      setLanguageCode: languagesActions.changeLanguage,
    },
    dispatch
  );

export default (
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Navigation)
);
