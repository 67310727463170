import { TypographyWeights } from "sanskrit/Typography/Typography";


export const convertToTypographyFont = (value: string): string => {
  switch (value) {
    case "Hind-Regular":
      return "roboto";
    case "Montserrat-Regular":
      return "montserrat";
    default:
      return "roboto";
  }
};

export const convertToTypographyVariants = (value: string): string => {
  switch (value) {
    case "xlarge (3.0 rem)":
      return "3rem";
    case "large (1.5 rem)":
      return "1.5rem";
    case "medium (1.13 rem)":
      return "1.3rem";
    case "default (1.0 rem)":
      return "1rem";
    case "small (0.875 rem)":
      return ".875rem";
    case "xsmall (0.75 rem)":
      return ".75rem";
    default:
      return "1rem";
  }
};

export const convertToPadding = (value: string): string => {
  switch (value) {
    case "medium (1.13 rem)":
      return "0 0 .5rem 0";
    default:
      return "";
  }
};

export const convertToTypographyWeight = (value: string): TypographyWeights => {
  switch (value) {
    case "bold":
      return "bold";
    case "regular":
      return "regular";
    case "light":
      return "light";
    default:
      return "regular";
  }
};
