import { Radio } from "antd";
import styled from "styled-components";

export const StyledRadio = styled(Radio)`
  display: block;
  width: 100%;
  .ant-radio-wrapper {
    display: block;
  }
`;

export const OptionTitle = styled.p`
  margin-top: 10px;
  margin-bottom: 0;

  :first-child {
    margin: 0;
  }
`;

export const TwoColumnLayout = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  width: 100%;
  max-width: 1400px;
  /* justify-content: space-between; */
  background: rgb(242, 242, 242);
  /* flex-wrap: wrap; */
  position: relative;

  @media (min-width: 1100px) {
    display:block
  }
`;


export const RightColumn = styled.div`
  width: 100%;
  flex: auto 1 auto;
  background: #fff;
  padding: 1rem;
  display: inline-flex;
  float: left;
  @media (min-width: 1100px) {
    width: 20%;
    position: sticky;
    top: 10px;
  }
`;