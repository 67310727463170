import * as React from 'react';
import styled, { css } from 'styled-components';

export type TypographyFonts = 'hind' | 'montserrat';
export type TypographyVariants = 'x-large' | 'large' | 'medium' | 'default' | 'small' | 'x-small';
export type TypographyWeights = 'light' | 'regular' | 'bold';
export type TypographyAlign = 'left' | 'center' | 'right';
export type TypographyHighlight = boolean;
export type TypographyTruncate = boolean;
/**
 * Typography properties
 */
export interface ITypographyProps {
  /** Sets the type of font.
   * @param {string} hind
   * @param {string} montserrat
   * @default 'hind'
   */
  font?: TypographyFonts;

  /** sets the fontsize
   * @param {string} {display-title}
   * @param {string} {page-title}
   * @param {string} {display-text}
   * @param {string} heading1
   * @param {string} heading2
   * @param {string} heading3
   * @param {string} body
   * @default 'body'
   */
  variant?: TypographyVariants;

  /** Determines the highlighting
   *  @default false
   */
  highlight?: TypographyHighlight;

  /** Determines the font weight
   * @param {string} light
   * @param {string} regular
   * @param {string} bold
   * @default regular
   */
  weight?: TypographyWeights;

  /** Determines Text Truncation
   * @default false
   */
  truncate?: TypographyTruncate;

  /** Determines how Text Aligns
   * @param {string} left
   * @param {string} center
   * @param {string} right
   * @default left
   */
  align?: TypographyAlign;

  /** Allows children to pass through */
  children?: React.ReactNode;

  id?: string;
  react_key?: string;
  /** className for overriding components */
  className?: any;
}

const baseSpacing = 22;
const scale = 6;

const baseFontSize = baseSpacing * 0.75;
const fontScale = baseSpacing / scale;

const borderWidth = 2;

const size = {
  'x-large': { size: '2rem' },
  large: { size: '1.5rem' },
  medium: { size: '1.13rem' },
  default: { size: '1rem' },
  small: { size: '.875rem' },
  'x-small': { size: '.75rem' },
};

const family = {
  hind: `Hind`,
  montserrat: `Montserrat`,
};

const weight = {
  light: 200,
  regular: 400,
  bold: 600,
};

const truncateCSS = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const weightStyle = (props?: TypographyWeights) => {
  const fontWeight: TypographyWeights = props || 'regular';
  const result = weight[fontWeight];
  return css`
    font-weight: ${result};
  `;
};

const variantStyle = (props?: TypographyVariants) => {
  const fontSize: TypographyVariants = props || 'default';
  const result = size[fontSize];
  return css`
    font-size: ${result.size};
  `;
};

const fontStyle = (props?: TypographyFonts) => {
  const fontFamily: TypographyFonts = props || 'hind';
  const result = family[fontFamily];
  return css`
    font-family: ${result};
  `;
};

const fontHighlight = (props?: TypographyHighlight) => {
  const fontColor: TypographyHighlight = props || false;

  return css`
    color: ${fontColor ? '#f2672b' : '#464749'};
  `;
};

const textAlignStyle = (props?: TypographyAlign) => {
  const alignment: TypographyAlign = props || 'left';

  return css`
    text-align: ${alignment};
  `;
};

const truncateStyle = (props?: TypographyTruncate) => {
  const result: TypographyTruncate = props || false;

  if (result) return truncateCSS;
  return null;
};

interface StyledTypographyProps {
  variant?: TypographyVariants;
  font?: TypographyFonts;
  weight?: TypographyWeights;
  highlight?: TypographyHighlight;
}
const StyledTypography = styled.span<StyledTypographyProps>`
  ${props => variantStyle(props.variant)}
  ${props => weightStyle(props.weight)}
  ${props => fontStyle(props.font)}
  ${props => fontHighlight(props.highlight)}
  
`;

interface StyledTextProps {
  truncate?: TypographyTruncate;
  align?: TypographyAlign;
}
const StyledText = styled.div<StyledTextProps>`
  ${props => truncateStyle(props.truncate)}
  ${props => textAlignStyle(props.align)}
`;
const TextWrapper = ({ children }: any) => <StyledText>{children}</StyledText>;

/**
 * Typography class export
 */
class Typography extends React.Component<ITypographyProps, any> {
  render() {
    const {
      font,
      highlight,
      variant,
      weight,
      truncate,
      align,
      children,
      id,
      className,
      react_key,
    } = this.props;

    return (
      <StyledTypography
        font={font}
        highlight={highlight}
        variant={variant}
        weight={weight}
        id={id}
        key={react_key}
        className={className}
      >
        {TextWrapper({ children })}
      </StyledTypography>
    );
  }
}

export default Typography;
