import { Tag } from "antd";
import styled from "styled-components";

export const WebinarContainer = styled.div`
  display: flex;
  padding: 2rem;
  flex-wrap: wrap;
`;

export const FullLength = styled.div`
  width: 100%;
  display: flex;
`;

export const Column = styled.div`
  width: 100%;
`;

export const TextContainer = styled.div`
  padding: 0 1rem;
  min-height: 120px;
  max-width: 400px;
  & h1 {
    padding: 0;
    font-weight: 600;
    margin: 0;
  }

  & p {
    padding: 0;
    font-weight: 300;
    font-size: 1rem;
    color: ${(props) => props.theme.gpwmd.slate};
    margin: 0;
  }
  @media (min-width: 1100px) {
    max-width: unset;
  }
`;

export const FormHeader = styled.h1`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 1.5rem;
`;

export const StyledTag = styled(Tag)`
  color: ${(props) => props.theme.colors.secondary};
  background-color: ${(props) => props.theme.gpwmd.green};
  border-color: transparent;
  padding: 0.25rem 1rem;
  font-size: 1rem;
  border-radius: 0;
`;

export const Hr = styled.hr`
  width: 100%;
  margin: 3rem 0 0 0rem;
  border: solid 1px ${(props) => props.theme.gpwmd.darkTan};
`;
