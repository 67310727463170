import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import { authentication } from "state/api/authentication";
import { IApplicationState } from "state/ducks";
import { columnedActions } from "state/ducks/columned";
import { genFetch, withToken } from "state/utils/endpoints";
import styled, { css } from "styled-components";
import { Banner, Overlay } from "view/common/Banner";
import ColumnMenu from "view/common/ColumnMenu";
import GroupToggle from "view/common/GroupToggle";
import {
  BodyContainer,
  LeftColumn,
  RightColumn,
  TwoColumnLayout,
} from "view/common/Layout";
import { StyledImage } from "view/parsed-layout/fieldTypes/image";
import { ParsedLayout } from "view/parsed-layout/parsedLayout";
import { MainContainer } from "view/utils/forms/styles";
import { WebinarItemProps } from "view/webinars/WebinarsArchive";
import CoChair from "./CoChair";
const queryString = require("query-string");

const getWorkingGroupState = () =>
  createSelector(
    (state: IApplicationState) => ({
      columned: state.columned,
      subWorkingGroups: state.vocabulary.subWorkingGroups,
    }),
    (state) => {
      const { columned, subWorkingGroups } = state;
      return {
        columned,
        subWorkingGroups,
      };
    }
  );

const CoChairContainer = styled.div`
  background-color: #dedede;
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0;
  width: 100%;
`;

/**
 * All - Plenary Events
 * other - action/co-char
 *  - Greeting
 *  - Co Chairs
 *  - Action Plan
 */

interface Chair {
  field_additional_titles: string;
  field_bio: string;
  field_picture: string;
  field_sub_working_group: string;
  field_working_group_title: string;
  name: string;
}

interface CoChairMap {
  [x: string]: Chair[];
}

const PartnerLinks = styled.div`
  max-width: 1600px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* background-color: ${(props) => props.theme.gpwmd.grey}; */
  position: relative;
  margin-bottom: 5rem;
`;

const PlenaryContainer = styled.div`
  ${StyledImage} {
    width: auto;
    height: 100%;
    margin: auto;
  }

  padding: 1rem 5rem;
`;
interface PartnerLinkProps {
  direction: string;
}
const PartnerLink = styled.img<PartnerLinkProps>`
  padding: 1rem;
  ${(props) => {
    return props.direction === "long"
      ? css`
          max-height: 90px;
          height: 100%;
          width: auto;
        `
      : css`
          max-height: 175px;
          height: 100%;
          width: auto;
        `;
  }}
`;

const Headline = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.colors.main};
  font-family: ${(props) => props.theme.fonts.heading};
  width: 100%;
  margin: 0;
  padding: 3rem 0;
`;

const StyledHr = styled.hr`
  border-color: ${(props) => props.theme.gpwmd.green};
  width: 100%;
  margin: 0 2rem;
  outline: none;
  border-style: solid;
`;

interface LogoProps {
  maxWidth?: string;
  radius?: string;
  top?: boolean;
}

export const Logo = styled.img<LogoProps>`
  position: absolute;
  left: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;

  ${(props) =>
    props.maxWidth
      ? css`
          max-width: ${props.maxWidth};
          width: 100%;
          padding: 0 2rem;
          @media (min-width: 768px) {
            padding: 0;
          }
        `
      : null}

  ${(props) =>
    props.radius
      ? css`
          border-radius: ${props.radius};
        `
      : null}

${(props) =>
    props.top &&
    css`
      opacity: 1;
    `}
`;

interface Partner {
  img: string;
  direction: string;
  link: string;
}

const nrswgPartners: Partner[] = [
  { img: "GICNT.png", direction: "long", link: "http://www.gicnt.org/" },
  { img: "UNICRI.png", direction: "wide", link: "http://www.unicri.it/" },
  { img: "IAEA.png", direction: "wide", link: "https://www.iaea.org/" },
  {
    img: "INTERPOL.png",
    direction: "wide",
    link: "https://www.interpol.int/en",
  },
  { img: "NTI.png", direction: "long", link: "https://www.nti.org/" },
  {
    img: "STCU.png",
    direction: "wide",
    link: "https://www.istc.net/?Section=Home",
  },
  {
    img: "UNCCT.png",
    direction: "long",
    link: "https://www.un.org/counterterrorism/",
  },
  { img: "STIMSON.png", direction: "long", link: "https://www.stimson.org/" },
  { img: "UNODC.png", direction: "wide", link: "https://www.unodc.org/" },
  { img: "WINS.png", direction: "long", link: "https://wins.org/" },

  {
    img: "EU-T.png",
    direction: "wide",
    link: "https://europa.eu/european-union/index_en",
  },
];

const cswgPartners: Partner[] = [
  {
    img: "OPCW.png",
    direction: "long",
    link: "https://www.opcw.org/",
  },
  { img: "STIMSON.png", direction: "long", link: "https://www.stimson.org/" },
  {
    img: "INTERPOL.png",
    direction: "wide",
    link: "https://www.interpol.int/en",
  },
  {
    img: "iccss.png",
    direction: "wide",
    link: "https://www.iccss.eu/",
  },

  {
    img: "UNOCT.png",
    direction: "long",
    link: "https://www.un.org/counterterrorism/",
  },
  {
    img: "CRDFGLOBAL.png",
    direction: "long",
    link: "https://www.crdfglobal.org/gci",
  },
  {
    img: "CWCGREEN.png",
    direction: "wide",
    link: "https://www.globalgreen.org/",
  },
  {
    img: "FAO.png",
    direction: "long",
    link: "http://www.fao.org/home/en",
  },
  {
    img: "icta.png",
    direction: "long",
    link: "https://www.icta-chem.org/",
  },
  {
    img: "istc.png",
    direction: "wide",
    link: "http://istc.int/",
  },
  {
    img: "nato.png",
    direction: "long",
    link: "https://www.nato.int/",
  },
  {
    img: "oie.gif",
    direction: "long",
    link: "https://www.oie.int/",
  },
  {
    img: "osce.png",
    direction: "long",
    link: "https://www.osce.org/",
  },
  {
    img: "UNICRIv2.png",
    direction: "long",
    link: "http://www.unicri.it/unoct",
  },
  {
    img: "VERTIC.png",
    direction: "long",
    link: "https://www.vertic.org/",
  },
  {
    img: "WHO.png",
    direction: "long",
    link: "https://www.who.int/",
  },
  {
    img: "WCO.png",
    direction: "long",
    link: "http://www.wcoomd.org/",
  },
];

function WorkingGroup() {
  const { columned, subWorkingGroups } = useSelector(getWorkingGroupState());
  const [coChairs, setCoChairs] = useState<CoChairMap>({});
  const [webinars, setWebinars] = useState<WebinarItemProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

  const getColumned = useCallback(
    (path: string) => {
      dispatch(columnedActions.columned(path));
    },
    [dispatch]
  );

  const tid = useMemo(() => {
    const query = queryString.parse(location.search);
    const tid = query["group"] || null;

    return tid;
  }, [location.search]);

  useEffect(() => {
    let group = "plenary";
    if (tid && subWorkingGroups) {
      const ok = subWorkingGroups.find((wgroup) => wgroup.tid === tid);
      if (ok) group = ok.field_acronym.toLowerCase();
    }

    setSelectedGroup(group);
  }, [tid, subWorkingGroups, setSelectedGroup]);

  useEffect(() => {
    if (selectedGroup) {
      getColumned(`${selectedGroup}-action-plan`);
      getColumned(`${selectedGroup}-welcome-message`);
      // Fetch for chairs
      genFetch(authentication.getCoChair(tid, selectedGroup === "plenary"))()(
        withToken()
      )
        .then((res) =>
          res
            .json()
            .then((data: any) => {
              if (data && data.length > 0) {
                const copy = { ...coChairs };
                copy[selectedGroup] = data;
                setCoChairs(copy);
              }
            })
            .catch((error) => console.log(error))
        )
        .catch((error) => console.log(error));
    }
  }, [tid, selectedGroup, setCoChairs, setWebinars]);

  const actionPlan = columned[`en/${selectedGroup}-action-plan`];
  const welcomeMessage = columned[`en/${selectedGroup}-welcome-message`];

  return (
    <MainContainer>
      <BodyContainer>
        <Banner minHeight="400px">
          <Logo
            top={selectedGroup === "plenary"}
            maxWidth="1600px"
            src={`/images/banners/plenary-1600x400.jpg`}
          />
          <Logo
            top={selectedGroup === "bswg"}
            maxWidth="1600px"
            src={`/images/banners/bswg-1600x400.jpg`}
          />
          <Logo
            top={selectedGroup === "cswg"}
            maxWidth="1600px"
            src={`/images/banners/cswg-1600x400.jpg`}
          />
          <Logo
            top={selectedGroup === "cbrnwg"}
            maxWidth="1600px"
            src={`/images/banners/cbrnwg-1600x400.jpg`}
          />
          <Logo
            top={selectedGroup === "nrswg"}
            maxWidth="1600px"
            src={`/images/banners/nrswg-1600x400.jpg`}
          />
          <Overlay>
            <div>
              <h1>Sub-Working Groups</h1>
              <h4>
                The Global Partnership consists of four Sub-Working Groups.
                Select one of the Sub-Working Groups below to view more
                information, including action plans, chair and co-chair
                information, and links to other resources.
              </h4>
            </div>
          </Overlay>
        </Banner>
        <GroupToggle all allOption="PLENARY" pathname="/en/working-groups" />
        <TwoColumnLayout>
          <LeftColumn flex={!(tid && selectedGroup)}>
            {selectedGroup && (
              <>
                {welcomeMessage && selectedGroup !== "plenary" && (
                  <div style={{ padding: "1rem 5rem" }}>
                    <ParsedLayout data={welcomeMessage.value} />
                  </div>
                )}
                {welcomeMessage && selectedGroup === "plenary" && (
                  <PlenaryContainer>
                    <ParsedLayout data={welcomeMessage.value} />
                  </PlenaryContainer>
                )}
                <>
                  {selectedGroup && coChairs[selectedGroup]?.length > 0 && (
                    <div style={{ width: "100%" }}>
                      <CoChairContainer>
                        {coChairs[selectedGroup].map((chair, index) => (
                          <>
                            <CoChair
                              key={chair.name}
                              splitLayout={
                                coChairs[selectedGroup][0].field_bio.length ===
                                0
                              }
                              {...chair}
                              numOfChairs={coChairs[selectedGroup].length}
                            />
                            {coChairs[selectedGroup][0].field_bio.length > 1 &&
                              index < coChairs[selectedGroup].length - 1 && (
                                <StyledHr />
                              )}
                          </>
                        ))}
                      </CoChairContainer>
                    </div>
                  )}
                </>
                {actionPlan && (
                  <div style={{ padding: "1rem 5rem" }}>
                    <ParsedLayout data={actionPlan.value} />
                  </div>
                )}
              </>
            )}
            {selectedGroup === "nrswg" && (
              <PartnerLinks>
                <Headline>NRSWG Partners & Participants</Headline>
                {nrswgPartners.map((partner: Partner) => (
                  <a
                    key={partner.link}
                    href={partner.link}
                    target="_blank"
                    rel="noreferrer"
                    style={{ zIndex: 1 }}
                  >
                    <PartnerLink
                      direction={partner.direction}
                      src={`/images/partners/${partner.img}`}
                    />
                  </a>
                ))}
              </PartnerLinks>
            )}
            {selectedGroup === "cswg" &&
              (welcomeMessage || coChairs || actionPlan) && (
                <PartnerLinks>
                  <Headline>CSWG Partners & Participants</Headline>
                  {cswgPartners.map((partner: Partner) => (
                    <a
                      key={partner.link}
                      href={partner.link}
                      target="_blank"
                      rel="noreferrer"
                      style={{ zIndex: 1 }}
                    >
                      <PartnerLink
                        direction={partner.direction}
                        src={`/images/partners/${partner.img}`}
                      />
                    </a>
                  ))}
                </PartnerLinks>
              )}
          </LeftColumn>
          <RightColumn>
            <ColumnMenu />
          </RightColumn>
        </TwoColumnLayout>
      </BodyContainer>
    </MainContainer>
  );
}

export default WorkingGroup;
