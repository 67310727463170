import React from "react";
import { ParticipantsList } from "state/ducks/columned/types";
import styled, { css } from "styled-components";

interface Props {
  data: ParticipantsList;
}

const Participants = styled.ul`
  max-height: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1300px;
  overflow: auto;
  list-style: none;
`;

const ParticipantListItem = styled.li`
  width: 250px;
`;

interface TextProps {
  highlight?: boolean;
  fontSize?: string;
  fontFamily?: string;
  fontWeight?: boolean;
  inline?: boolean;
  italics?: boolean;
}
const Text = styled.p<TextProps>`
  display: block;
  width: 100%;
  ${(props) => css`
    font-size: ${props.fontSize ? props.fontSize : "1rem"};
    font-weight: ${props.fontWeight ? 700 : 400};
    font-family: ${props.fontFamily ? props.fontFamily : "roboto"};
    font-style: ${props.italics ? "italic" : "normal"};
  `}
`;

const ParticipantList = (props: Props) => {
  const { data } = props;
  const { field_list } = data;
  let reactList;
  try {
    if (field_list && field_list.length > 0) {
      const list = JSON.parse(field_list[0].value).sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        return -1;
      });
      reactList = (
        <Participants>
          {list.map((item: any, index: any) => (
            <ParticipantListItem key={`${item.name} ${index}`}>
              {item.name}
            </ParticipantListItem>
          ))}
        </Participants>
      );
    }
  } catch (error) {
    console.log(error);
  }

  return (
    <div style={{ width: "100%" }}>
      <Text fontWeight>Participants List</Text>
      {reactList}
    </div>
  );
};

export default ParticipantList;
