import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Form, Skeleton } from "antd";
import { IConnectState } from "state/ducks/connect/types";
import { ILanguageState } from "state/ducks/languages/types";
import { connectActions } from "state/ducks/connect";
import {
  MainContainer,
  FormContainer,
  LabeledFormItem,
  Button,
  FormTextArea,
  Logo,
} from "view/utils/forms/styles";
import { TwoColumnFrom, Column, FullColumn } from "view/registration/styles";
import { genFetch, withToken } from "state/utils/endpoints";
import { authentication } from "state/api/authentication";
import { useDispatch } from "react-redux";
import { IMessage } from "state/ducks/notifications/types";
import { addNotification } from "state/ducks/notifications/actions";
import { DiscussionBoardData } from "../discussionBoard";
import { useHistory, useLocation } from "react-router-dom";
import {
  BodyContainer,
  LeftColumn,
  RightColumn,
  TwoColumnLayout,
} from "view/common/Layout";
import { Banner, Overlay } from "view/common/Banner";
import { SecondaryBanner, TextContainer } from "./styles";
import ColumnMenu from "view/common/ColumnMenu";
const queryString = require("query-string");
interface Props {
  connect: IConnectState;
  languages: ILanguageState;
  submitContactUs: typeof connectActions.submitContactUs;
}

interface HeaderProps {
  highlight?: boolean;
}
const FormHeader = styled.h1<HeaderProps>`
  font-family: ${(props) => props.theme.fonts.heading};
  display: inline-block;
  padding-right: 1rem;
  text-align: center;
  width: 100%;
  color: ${(props) =>
    props.highlight ? props.theme.gpwmd.red : props.theme.colors.main};
`;

const failedMessage: IMessage = {
  type: "error",
  message: "The request failed",
  description: "ught oh.. Something went wrong",
};

const successMessage: IMessage = {
  type: "success",
  message: "Your message has been sent",
  description: "Thank you",
};

const DiscussionBoardForm = (props: Props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [discussionBoard, setDiscussionBoard] = useState<DiscussionBoardData[]>(
    []
  );

  const tid = useMemo(() => {
    const query = queryString.parse(location.search);
    const tid = query["group"] || null;

    return tid;
  }, [location.search]);

  useEffect(() => {
    genFetch(authentication.getDiscussionBoard(tid))()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) =>
            setDiscussionBoard(data && data.length ? data : [])
          )
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [tid]);

  const isValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo) {
      return false;
    }
  };

  const handleSubmit = async () => {
    const formIsValid = await isValid();
    if (formIsValid) {
      const answers = form.getFieldsValue();
      const reqBody = Object.keys(answers).map((key) => ({
        question: discussionBoard[Number(key)].title[0].value,
        answer: answers[key],
      }));

      genFetch(authentication.sendDiscussionBoardSubmission(reqBody))()(
        withToken()
      )
        .then((res) => {
          if (res.ok) {
            dispatch(addNotification(successMessage));
            history.push("/en/discussion-board");
          } else {
            dispatch(addNotification(failedMessage));
          }
        })
        .catch((error) => dispatch(addNotification(failedMessage)));
    }
  };

  const skellys = [...Array(5)].map((value) => <Skeleton key={value} active />);

  return (
    <MainContainer>
      <BodyContainer>
        <Banner>
          <Logo
            maxWidth="1600px"
            src="/images/banners/discussion-1600x400.jpg"
          />
          <Overlay>
            <div>
              <h1>Discussion Board</h1>
              <h4>
                View Discussion Boards for the Global Partnership Working
                Groups. Submissions will be reviewed and posted to the
                Discussion Board. Please note that anyone with access to the
                portal can view your submission.
              </h4>
            </div>
          </Overlay>
        </Banner>
        <SecondaryBanner>
          <TextContainer>
            <h1>Be a part of the conversation!</h1>
            <p>
              Submissions will be reviewed and posted to the Discussion Board.
            </p>
            <p>
              Please note that anyone with access to the portal can view your
              submission.
            </p>
          </TextContainer>
        </SecondaryBanner>
        <TwoColumnLayout>
          <LeftColumn style={{ padding: "2rem 0" }}>
            <FormHeader>Discussion Board Questionnaire </FormHeader>
            <FormContainer>
              <TwoColumnFrom layout="vertical" form={form}>
                {discussionBoard.length > 0 ? (
                  <FullColumn>
                    {discussionBoard.map((value, index) => {
                      const question =
                        value.title.length > 0 ? value.title[0].value : "";
                      const subtext =
                        value.field_sub_text.length > 0
                          ? value.field_sub_text[0].value
                          : "";
                      return (
                        <LabeledFormItem
                          label={`${question} ${subtext}`}
                          name={index}
                        >
                          <FormTextArea rows={4} />
                        </LabeledFormItem>
                      );
                    })}
                  </FullColumn>
                ) : (
                  skellys
                )}
                {discussionBoard.length > 0 && (
                  <div
                    style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                  >
                    <Column>
                      <Form.Item style={{ padding: "1rem 0" }}>
                        <Button onClick={handleSubmit}>Submit</Button>
                      </Form.Item>
                    </Column>
                  </div>
                )}
                <div
                  style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                ></div>
              </TwoColumnFrom>
            </FormContainer>
          </LeftColumn>
          <RightColumn>
            <ColumnMenu />
          </RightColumn>
        </TwoColumnLayout>
      </BodyContainer>
    </MainContainer>
  );
};

export default DiscussionBoardForm;
