import { Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { search } from "state/api/search";
import { imagePathToURL } from "state/utils";
import { genFetch, withToken } from "state/utils/endpoints";
import styled from "styled-components";
import NoDataMessage from "view/Archive/utils/NoDataMessage";
import { SearchDefinition } from "view/Archive/utils/useArichiveQuery";
import { DocumentContainer } from "./styles";
import { proposalColumns } from "view/proposals/utils/table";
import { authentication } from "state/api/authentication";

interface Props {
  query: SearchDefinition;
}

const StyledTable = styled(Table)`
  width: 100%;
  background: #fff;
  display: inline-flex;

  .ant-spin-nested-loading {
    width: 100%;
  }

  .ant-table-thead tr {
    height: 50px;
  }

  .ant-table-thead {
    display: none;
  }

  .min-w-500 {
    min-width: 400px;
  }
`;

export interface ProposalResponse {
  field_date_submitted: string;
  field_plain_text: string;
  field_user_name: string;
  field_user_organization: string;
  id: string;
}
interface Proposal {
  field_proposal_file: string;
  field_responses: ProposalResponse[];
  field_proposal_country: string;
  field_proposal_synopsis: string;
  field_proposal_funding_level: string;
  field_proposal_implementing_orga: string;
  field_proposal_matchmaking_sessi: string;
  field_proposal_matchmaking_notes: string | null;
  field_proposal_region: string;
  field_proposal_total_cost: string;
  field_proposal_duration: string;
  nid: string;
  title: string;
}

interface FixedResponse {
  tid: string;
  name: string;
}
export interface AggregatedProposal extends Proposal {
  fixedResponses: FixedResponse[];
}

const Proposals = (props: Props) => {
  const { query } = props;

  const [proposals, setProposals] = useState<Proposal[] | undefined>(undefined);
  const [fixedResponses, setFixedResponses] = useState<
    FixedResponse[] | undefined
  >(undefined);
  const [aggregatedProposals, setAggregatedProposals] = useState<
    AggregatedProposal[] | undefined
  >(undefined);

  useEffect(() => {
    genFetch(search.archiveProposals(query))()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) => {
            try {
              // JUAN MADE ME HAVE TO PARSE THE RESPONSES....
              data.forEach((element: any) => {
                try {
                  const parsedResponses = JSON.parse(element.field_responses);
                  element.field_responses =
                    parsedResponses && parsedResponses.length > 0
                      ? parsedResponses
                      : [];
                  if (element.field_proposal_matchmaking_notes.length === 0) {
                    element.field_proposal_matchmaking_notes = null;
                  }
                } catch (error) {
                  element.field_responses = [];
                }
              });
              if (data && data.length > 0) setProposals(data);
              if (data && data.length === 0) setProposals([]);
            } catch (error) {
              setProposals(undefined);
            }
          })
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));

    genFetch(authentication.getProposalsFixedResponses())()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) => setFixedResponses(data))
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [query, setProposals]);

  const createAggregatedProposals = useCallback(() => {
    if (proposals && fixedResponses) {
      let aggregatedProposals = proposals.map((item) => ({
        ...item,
        fixedResponses,
      }));
      aggregatedProposals = aggregatedProposals.sort((a, b) => {
        return b.field_responses.length - a.field_responses.length;
      });
      setAggregatedProposals(aggregatedProposals);
    } else {
      setAggregatedProposals(undefined);
    }
  }, [proposals, fixedResponses]);

  useEffect(() => {
    createAggregatedProposals();
  }, [createAggregatedProposals]);

  return (
    <DocumentContainer>
      <h1>Proposals</h1>
      {aggregatedProposals && aggregatedProposals.length > 0 ? (
        <StyledTable
          rowKey={(record: any) => record.nid}
          columns={proposalColumns}
          dataSource={aggregatedProposals}
        />
      ) : (
        <NoDataMessage />
      )}
    </DocumentContainer>
  );
};

export default Proposals;
