import React, { useEffect, useState, useCallback, useMemo } from "react";
import { authentication } from "state/api/authentication";
import { genFetch, withToken } from "state/utils/endpoints";
import { Alert, Skeleton, Table } from "antd";
import { proposalColumns } from "./utils/table";
import { MainContainer, Logo } from "view/utils/forms/styles";
import styled, { css } from "styled-components";
import { Banner, Overlay } from "view/common/Banner";
import {
  TwoColumnLayout,
  RightColumn,
  BodyContainer,
} from "view/common/Layout";
import ColumnMenu from "view/common/ColumnMenu";
import { Link, useLocation } from "react-router-dom";
import GroupToggle from "view/common/GroupToggle";
import useColumnedParser from "view/utils/hooks/useColumnedParser";
import { ParsedLayout } from "view/parsed-layout/parsedLayout";
import {
  ParticipateHeader,
  QuestionnaireLink,
  TextContainerBanner,
} from "view/discussionBoard/styles";

//Components
import TextNode from "view/parsed-layout/fieldTypes/text";
import { RowNode } from "view/parsed-layout/fieldTypes/row";
import { ColumnNode } from "view/parsed-layout/fieldTypes/col";
import ImageNode from "view/parsed-layout/fieldTypes/image";
import BulletedListNode from "view/parsed-layout/fieldTypes/bulletedList";
import LinkNode from "view/parsed-layout/fieldTypes/link";
import ImageLinkNode from "view/parsed-layout/fieldTypes/imageLink";
import MediaNode from "view/parsed-layout/fieldTypes/media";
import ParticipantList from "view/parsed-layout/fieldTypes/participantList";
import { IApplicationState } from "state/ducks";
import { useSelector } from "react-redux";

const Column = styled(ColumnNode)``;

const Row = styled(RowNode)`
  padding: 0;
`;

const BulletList = styled(BulletedListNode)`
  height: 100%;
`;

const defaultComponents = new Map<string, any>([
  ["col", Column],
  ["row", Row],
  ["text", TextNode],
  ["image", ImageNode],
  ["bullet_list", BulletList],
  ["link", LinkNode],
  ["image_link", ImageLinkNode],
  ["media", MediaNode],
  ["participants_list", ParticipantList],
  // ['link', FieldTypes.LinkNode],
]);

const queryString = require("query-string");

export interface Value {
  value: string;
}
export interface ProposalResponse {
  field_date_submitted: string;
  field_plain_text: string;
  field_user_name: string;
  field_user_organization: string;
  id: string;
}
interface Proposal {
  field_proposal_file: string;
  field_responses: ProposalResponse[];
  field_proposal_country: string;
  field_proposal_synopsis: string;
  field_proposal_funding_level: string;
  field_proposal_implementing_orga: string;
  field_proposal_matchmaking_sessi: string;
  field_proposal_matchmaking_notes: string | null;
  field_proposal_region: string;
  field_proposal_total_cost: string;
  field_proposal_duration: string;
  field_point_of_contact: string;
  created: string;
  nid: string;
  title: string;
}

interface FixedResponse {
  tid: string;
  name: string;
}

export interface AggregatedProposal extends Proposal {
  fixedResponses: FixedResponse[];
}

const StyledTable = styled(Table)`
  width: 100%;
  background: #fff;
  padding: 2rem;
  display: inline-flex;

  @media (min-width: 1100px) {
    margin: 0;
    width: 78%;
  }

  .ant-spin-nested-loading {
    width: 100%;
  }

  .ant-table-thead tr {
    height: 50px;
  }

  .ant-table-thead {
    display: none;
  }

  .min-w-500 {
    min-width: 400px;
  }
`;

const StaticText = styled.p`
  font-size: 1.3rem;
  font-weight: 300;
  font-family: ${(props) => props.theme.fonts.main};
`;
const StatValue = styled.span`
  font-style: normal;
  font-weight: 600;
`;

const ProposalsDescription = styled.div<{ topPadding?: boolean }>`
  background: rgb(242, 242, 242);
  width: 100%;
  max-width: 1600px;
  min-height: 180px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: ${(props) =>
    props.topPadding ? "3rem 3rem 0 3rem" : "0 3rem 0 3rem"};
  margin-bottom: 3rem;
`;

const Description = styled.p`
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 38px;
  color: ${(props) => props.theme.colors.main};
  font-family: ${(props) => props.theme.fonts.main};
  max-width: 1300px;
  width: 100%;
  margin: 0;
`;

const DescriptionSeparator = styled.div`
  width: 2px;
  height: 100%;
  background: ${(props) => props.theme.gpwmd.green};
  margin: 0 3rem;
`;

const StyledAlert = styled(Alert)`
  border-radius: 4px;

  .ant-alert-description {
    font-size: 1.3rem;
    font-weight: 500;
  }
  margin-bottom: 2rem;
`;

interface ProposalProps {
  isPublic: boolean;
  member: any;
}

function Proposals(props: ProposalProps) {
  const { isPublic, member } = props;

  const [proposals, setProposals] = useState<Proposal[] | undefined>(undefined);
  const [fixedResponses, setFixedResponses] = useState<
    FixedResponse[] | undefined
  >(undefined);
  const [aggregatedProposals, setAggregatedProposals] = useState<
    AggregatedProposal[] | undefined
  >(undefined);
  const subworkingGroups = useSelector(
    (state: IApplicationState) => state.vocabulary.subWorkingGroups
  );
  const location = useLocation();

  const tid = useMemo(() => {
    const query = queryString.parse(location.search);
    const tid = query["group"] || null;

    return tid;
  }, [location.search]);

  const group = useMemo(() => {
    if (tid) {
      const swgGroup = subworkingGroups.find((group) => group.tid === tid);
      if (swgGroup) {
        return swgGroup.field_acronym;
      }
    }
    return "plenary";
  }, [subworkingGroups, tid]);

  useEffect(() => {
    genFetch(authentication.getProposals(tid))()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) => {
            try {
              // JUAN MADE ME HAVE TO PARSE THE RESPONSES....
              data.forEach((element: any) => {
                try {
                  const parsedResponses = JSON.parse(element.field_responses);
                  element.field_responses =
                    parsedResponses && parsedResponses.length > 0
                      ? parsedResponses
                      : [];
                  if (element.field_proposal_matchmaking_notes.length === 0) {
                    element.field_proposal_matchmaking_notes = null;
                  }
                } catch (error) {
                  element.field_responses = [];
                }
              });
              if (data && data.length > 0) {
                setProposals(data);
              } else {
                setProposals(undefined);
              }
            } catch (error) {
              setProposals(undefined);
            }
          })
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));

    genFetch(authentication.getProposalsFixedResponses())()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) => setFixedResponses(data))
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [setProposals, setFixedResponses, tid]);

  const createAggregatedProposals = useCallback(() => {
    if (proposals && fixedResponses) {
      let aggregatedProposals = proposals.map((item) => ({
        ...item,
        fixedResponses,
      }));
      aggregatedProposals = aggregatedProposals.sort((a, b) => {
        return b.field_responses.length - a.field_responses.length;
      });
      setAggregatedProposals(aggregatedProposals);
    } else {
      setAggregatedProposals(undefined);
    }
  }, [proposals, fixedResponses]);

  useEffect(() => {
    createAggregatedProposals();
  }, [createAggregatedProposals]);

  const fullyFundedProposals = useMemo(
    () =>
      aggregatedProposals?.length
        ? aggregatedProposals.reduce(
            (accumulator, currentValue) =>
              currentValue.field_proposal_funding_level === "Fully Funded"
                ? accumulator + 1
                : accumulator,
            0
          )
        : "?",
    [aggregatedProposals]
  );

  // const [data] = useColumnedParser("/node/203"); // eslint-disable-line
  const [invitation, invitationIsLoading, fetch] = useColumnedParser("", false); // eslint-disable-line

  useEffect(() => {
    if (group) {
      fetch(`/member/${group}-proposal-invitation`.toLowerCase());
    }
  }, [group, fetch]);

  return (
    <MainContainer>
      <BodyContainer>
        <Banner>
          <Logo
            maxWidth="1600px"
            src="/images/banners/proposals-1600x530.jpg"
          />
          <Overlay>
            <div>
              <h1>Proposal Matchmaking</h1>
              <h4>
                The following information is for Members of the Global
                Partnership only. Please do not distribute outside of this
                forum.
              </h4>
            </div>
          </Overlay>
        </Banner>
        {member &&
        member.roles &&
        member.roles.some((item: any) => item.target_id === "member_state") ? (
          <>
            <GroupToggle all pathname={"/en/matchmaking"} />

            <ProposalsDescription>
              <Description>
                <>
                  {invitation ? (
                    <ParsedLayout
                      data={invitation}
                      componentMap={defaultComponents}
                    />
                  ) : (
                    <Skeleton active />
                  )}
                </>
              </Description>
            </ProposalsDescription>

            <TwoColumnLayout>
              {aggregatedProposals && aggregatedProposals.length > 0 ? (
                <StyledTable
                  rowKey={(record: any) => record.nid}
                  columns={proposalColumns}
                  dataSource={aggregatedProposals}
                />
              ) : (
                <ParticipateHeader
                  style={{
                    width: "78%",
                    display: "inline-flex",
                  }}
                >
                  <TextContainerBanner>
                    <h1>{`Proposals are forthcoming.`}</h1>
                    <p>
                      No Proposals are available at this time. Please check back
                      later or select another Sub-Working Group.
                    </p>
                  </TextContainerBanner>
                </ParticipateHeader>
              )}
              <RightColumn>
                <ColumnMenu />
              </RightColumn>
            </TwoColumnLayout>
          </>
        ) : (
          <ProposalsDescription topPadding>
            <Description>
              <>
                {invitation ? (
                  <span>
                    <StaticText>
                      The Global Partnership is a delivery mechanism through
                      which like-minded states, who share concerns and want to
                      take action against proliferators, can co-ordinate their
                      efforts to best effect. It is also a very useful forum for
                      co-ordinating work with, and support for, international
                      organisations such as the International Atomic Energy
                      Agency (IAEA), Organisation for the Prohibition of
                      Chemical Weapons (OPCW) and the UN Security Council’s 1540
                      Committee.
                    </StaticText>
                    <StaticText>
                      Thanks to the efforts of its Members, the Global
                      Partnership continues to make tangible contributions to
                      international security through specific cooperation
                      projects to prevent the proliferation of CBRN weapons and
                      related materials.
                    </StaticText>
                    <StaticText>
                      Please review the General Guidance and Proposal Template
                      Documents for details.
                    </StaticText>
                    <Link to="/en/resources">
                      <b>
                        View GP Matchmaking Donor Information 1-Pagers on the
                        Plenary Resources Page
                      </b>
                    </Link>
                  </span>
                ) : (
                  <Skeleton active />
                )}
              </>
            </Description>
          </ProposalsDescription>
        )}
      </BodyContainer>
    </MainContainer>
  );
}

export default Proposals;
