import React, { useState } from "react";
import { DatePicker } from "antd";
import "antd/lib/date-picker/style/css";
import styled from "styled-components";
const { RangePicker } = DatePicker;
// import "react-dates/lib/initialize";
// import { DateRangePicker } from "react-dates";
// import "react-dates/lib/css/_datepicker.css";

interface DateRangeWrapperProps {
  dates: {
    start: any | null;
    end: any | null;
  };
  setDates: (args: any) => void;
}

const defaultProps = {
  startDateId: "startDate",
  endDateId: "endDate",
  showClearDates: true,
  keepOpenOnDateSelect: true,
};

const StyledRangePicker = styled(RangePicker)`
  &.ant-picker-range {
    height: 48px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid;
    border-right: none;
  }
`;

export const DateRangeWrapper: React.FC<DateRangeWrapperProps> = (props) => {
  const [focusedInput, setFocusedInput] = useState<any>(null);

  return (
    <div style={{ display: "inline-block", height: "100%" }}>
      <StyledRangePicker
        value={[props.dates.start, props.dates.end]}
        onChange={(a, b) => a && props.setDates({ start: a[0], end: a[1] })}
      />
      {/* <DateRangePicker
        {...defaultProps}
        enableOutsideDays={true}
        isOutsideRange={() => false }
        startDate={props.dates.start}
        endDate={props.dates.end}
        onDatesChange={({ startDate, endDate }: any) =>
          props.setDates({ start: startDate, end: endDate })
        }
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
      /> */}
    </div>
  );
};
