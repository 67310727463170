import styled, { css } from "styled-components";

export const BodyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1600px;
  background: rgb(242, 242, 242);
  @media (min-width: 1100px) {
    padding: 0 0 3rem 0;
  }
`;

export const TwoColumnLayout = styled.div`
  /* display: flex; */
  width: 100%;
  max-width: 1400px;
  /* justify-content: space-between; */
  background: rgb(242, 242, 242);
  /* flex-wrap: wrap; */
  position: relative;
`;

export const RightColumn = styled.div`
  width: auto;
  flex: auto 1 auto;
  background: #fff;
  padding: 1rem;
  display: inline-flex;
  float: right;
  @media (min-width: 1100px) {
    width: 20%;
  }
`;

interface LeftColumnProps {
  flex?: boolean;
  transparent?: boolean;
}

export const LeftColumn = styled.div<LeftColumnProps>`
  width: 100%;

  flex: auto 1 auto;
  margin: 0 0 1rem 0;
  display: inline-flex;
  flex-wrap: wrap;
  ${(props) => css`
    background: ${props.transparent ? "transparent" : "#FFF"};
  `}
  ${(props) =>
    props.flex &&
    css`
      display: inline-flex;
      justify-content: center;
      flex-wrap: wrap;
    `}

    @media (min-width: 1100px) {
    margin: 0;
    width: 78%;
    height: 100%;
    ${(props) =>
      props.flex &&
      css`
        height: unset;
      `}
  }
`;
