import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { IApplicationState } from 'state/ducks';
import { connectTypes, connectActions } from 'state/ducks/connect';
import Connect from './connect';
import { isApiPublic } from 'state/ducks/connect/selectors';
const mapStateToProps = (state: IApplicationState) => ({
  isApiPublic: isApiPublic(state),
});

type actions = connectTypes.ConnectActions;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators(
    {
      authenticate: connectActions.authenticate,
      verifyToken: connectActions.verifyUserToken,
      initializeApplication: connectActions.initializeApplication,
      logout: connectActions.logout,
      getMemberInfo: connectActions.getMemberAccount,
    },
    dispatch
  );

export default withRouter<any,any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Connect)
);
