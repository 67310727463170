import { useEffect, useState } from "react";
import { authentication } from "state/api/authentication";
import { Vocabulary } from "state/ducks/vocabulary/types";
import { genFetch } from "state/utils/endpoints";

const VOCABULARIES = [
  "ech_filters",
  "ech_keywords",
  "ech_scope",
  "ech_source",
] as const;

type VocabularyMap = { [K in (typeof VOCABULARIES)[number]]: Vocabulary[] };

const defaultVMap = (): VocabularyMap => ({
  ech_filters: [],
  ech_keywords: [],
  ech_scope: [],
  ech_source: [],
});
function useECHVocabularies() {
  const [isLoading, setIsLoading] = useState(true);
  const [vocabularies, setVocabularies] = useState<VocabularyMap>(
    defaultVMap()
  );

  useEffect(() => {
    const getVocabularies = async () => {
      try {
        setIsLoading(true);
        const responses = await Promise.all(
          VOCABULARIES.map((key) =>
            genFetch(authentication.getVocabularyByMachineName(key))()()
          )
        );

        const isOkay = responses.every((response) => response.ok === true);
        if (isOkay) {
          const data = await Promise.all(
            responses.map((response) => response.json())
          );

          let vMap: VocabularyMap = defaultVMap();

          VOCABULARIES.forEach((key, index) => {
            vMap[key] = data[index];
          });
          setVocabularies(vMap);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    getVocabularies();
  }, [setVocabularies, setIsLoading]);

  return { vocabularies, isLoading };
}

export default useECHVocabularies;
