import { Combobox } from "@headlessui/react";
import { Skeleton } from "antd";
import styled, { css } from "styled-components";

export const ComboBoxContainer = styled.div`
  width: 100%;
  font-size: 0.85rem;
  height: 300px;
  margin-bottom: 1rem;

  input {
    width: 100%;
    padding: 4px 8px;
    border-radius: 4px;
    height: 32px;
    border: 1px solid ${(props) => props.theme.gpwmd.grey};
    margin-bottom: 4px;
    margin-top: 2px;
  }

  label {
    font-weight: 800;
  }
`;

export const StyledComboBoxOptions = styled(Combobox.Options)<any>`
  padding-inline-start: 0;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  overflow: auto;
  list-style: none;
  cursor: pointer;
  padding: 4px 0;
  overflow-y: auto;
  width: 100%;

  height: calc(300px - 65px);
`;

export const StyledComboBoxOption = styled.li<any>`
  color: ${(props) => props.theme.colors.main};
  border: transparent solid 2px;
  background-color: transparent;
  margin: 0;

  display: flex;
  align-items: center;

  padding: 4px;
  border-radius: 4px;

  //Text Ellipsis
  span:last-child {
    width: calc(100% - 24px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :active,
  :focus,
  :hover {
    border: ${(props) => props.theme.gpwmd.grey} solid 2px;
    color: ${(props) => props.theme.colors.main};
  }

  ${(props) =>
    props.$active &&
    css`
      border: ${(props) => props.theme.gpwmd.grey} solid 2px;
    `}
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

interface StyledCheckboxProps {
  checked: boolean;
}

export const StyledCheckbox = styled.span<StyledCheckboxProps>`
  display: inline-flex;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  align-items: center;
  background: ${(props) => (props.checked ? props.theme.gpwmd.green : "#FFF")};
  border: solid 1px ${(props) => props.theme.gpwmd.darkTan};
  border-radius: 3px;
  transition: all 150ms;
  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

export const ListItemLoader = styled(Skeleton.Input)`
  width: 95%;
  margin: 2px 0;
`;
