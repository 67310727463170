import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const SubWorkingGroupContainer = styled.div`
  background: rgb(242, 242, 242);
  width: 100%;
  max-width: 1600px;
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 3rem;
`;
interface WorkingGroupLinkProps {
  isActive?: boolean;
}

export const WorkingGroupLink = styled(Link)<WorkingGroupLinkProps>`
  min-width: 200px;
  max-height: 100px;
  height: 100px;
  font-size: 2rem;
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.gpwmd.darkTan};
  font-weight: 600;
  border: solid 5px ${(props) => props.theme.gpwmd.green};
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 1rem;

  :hover {
    background: ${(props) => props.theme.gpwmd.green};
    color: ${(props) => props.theme.colors.secondary};
  }

  :active {
    box-shadow: none;
    transition: all 150ms ease;
    transform: scale(0.96);
  }

  ${(props) =>
    props.isActive &&
    css`
      background: ${props.theme.gpwmd.green};
      color: ${props.theme.colors.secondary};
    `}

  @media (min-width: 1024px) {
    min-width: 250px;
    max-height: 125px;
    height: 150px;

    margin-right: 2rem;
    margin-bottom: 2rem;
  }
`;
