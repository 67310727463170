import React from 'react';
import styled, { css } from 'styled-components';
import { ParseItemParent, BulletedList } from "state/ducks/columned/types";

interface Props extends ParseItemParent {
  data: BulletedList;
  componentMap: any, 
  renderContentNode: any, 
  items:any,
  className?:string;
}

export interface ListProps {
  hasDirectChildrenLinks: boolean | undefined;
}

const BulletList = styled.ul<ListProps>`
  padding-bottom: 1rem;
  padding-inline-start: 1.5rem;
  margin: 0;
  li,
  p {
    margin-bottom: 0;
  }
  ${props =>
    props.hasDirectChildrenLinks &&
    css`
      padding-inline-start: 1rem;
      list-style: none;
      padding: 0 0 1rem 0;
    `}
`;

const ListItem = styled.li`
  & > p {
    margin: 0;
  }
  margin-bottom: 0.5rem;
`;

interface ListContainerProps {
  hasHeader?: boolean;
}
const StyledList = styled.div<ListContainerProps>`
  ${props =>
    props.hasHeader &&
    css`
      & > p {
        margin-bottom: 10px;
      }
    `}
`;

const BulletedListNode: React.FC<Props> = props => {
  const { data, componentMap, renderContentNode, items, className } = props;
  const { header_paragraph } = data;

  const hasHeader = header_paragraph.length > 0;
  const hasDirectChildrenLinks = items.some((item:any) => item.type === 'link') || undefined;
  const hasChildren = items.length > 0;

  return (
    <StyledList className={className} hasHeader>
      {hasHeader && renderContentNode(header_paragraph, componentMap)}
      <BulletList hasDirectChildrenLinks={hasDirectChildrenLinks}>
        {hasChildren &&
          items.map((item: any) => (
            <ListItem key={item.id[0].value}>{renderContentNode([item], componentMap)}</ListItem>
          ))}
      </BulletList>
    </StyledList>
  );
};

export default BulletedListNode;
