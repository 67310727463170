import React, { useEffect, useState } from "react";
import { IConnectState } from "state/ducks/connect/types";
import { INavigationObject, IMenuItems } from "state/ducks/navigation/types";
import { ILanguageState } from "state/ducks/languages/types";
import { getApiAttribute } from "state/utils/endpoints";

import {
  StyledLink,
  Container,
  FooterContainer,
  StyledRouteContainer,
  StyledLinkHolder,
  LinkSeparator,
  StyledATag,
} from "./styles";
import { isExternalURL } from "view/navigation/navigation";

interface Props {
  languages: ILanguageState;
  connect: IConnectState;
  navigation: INavigationObject;
}

const Footer = (props: Props) => {
  const { languages, connect, navigation } = props;
  const [items, setItems] = useState({ primary: [], nonPrimary: [] });

  useEffect(() => {
    const primary: any = [];
    const nonPrimary: any = [];

    navigation[getApiAttribute(connect.public)].forEach(
      (navItem: IMenuItems) => {
        const isExternal = isExternalURL(navItem.item.to);
        if (navItem.item.primary) {
          if (!isExternal) {
            primary.push(
              <StyledLink
                key={navItem.item.to}
                to={`/${languages.code}${navItem.item.to}`}
                primary={"true"}
              >
                {navItem.item.text}
              </StyledLink>
            );
          } else {
            primary.push(
              <StyledLink
                as="a"
                key={navItem.item.to}
                href={navItem.item.to}
                target="_blank"
                rel="noopener noreferrer"
                primary={"true"}
              >
                {navItem.item.text}
              </StyledLink>
            );
          }
        } else {
          if (!isExternal) {
            nonPrimary.push(
              <StyledLink
                key={navItem.item.to}
                to={`/${languages.code}${navItem.item.to}`}
              >
                {navItem.item.text}
              </StyledLink>
            );
          } else {
            nonPrimary.push(
              <StyledLink
                as="a"
                key={navItem.item.to}
                href={navItem.item.to}
                target="_blank"
                rel="noopener noreferrer"
              >
                {navItem.item.text}
              </StyledLink>
            );
          }
        }
      }
    );

    setItems({ primary, nonPrimary });
  }, [navigation, connect.public, languages.code]);

  return (
    <Container>
      <FooterContainer>
        <StyledRouteContainer>
          <StyledLinkHolder>
            {items.primary.map((item: any, index: number) =>
              index < items.primary.length - 1 ? (
                <span key={index}>
                  {item}
                  <LinkSeparator> / </LinkSeparator>
                </span>
              ) : (
                <span key={index}>{item}</span>
              )
            )}
          </StyledLinkHolder>
          <StyledLinkHolder middle>
            {items.nonPrimary.map((item: any, index: number) =>
              index < items.nonPrimary.length - 1 ? (
                <span key={index}>
                  {item}
                  <LinkSeparator> / </LinkSeparator>
                </span>
              ) : (
                <span key={index}>{item}</span>
              )
            )}
          </StyledLinkHolder>
          <StyledLinkHolder>
            <StyledATag
              href="https://www.pnnl.gov/security-privacy"
              target="_blank"
            >
              Privacy Policy
            </StyledATag>
          </StyledLinkHolder>
        </StyledRouteContainer>
      </FooterContainer>
    </Container>
  );
};

export default Footer;
