import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IApplicationState } from "state/ducks";
import { connectTypes, connectActions } from "state/ducks/connect";
import ContactUs from "./contact";

const mapStateToProps = (state: IApplicationState) => ({
  ...state,
});

type actions = connectTypes.ConnectActions;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators(
    {
      submitContactUs: connectActions.submitContactUs,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
