import { Listbox } from "@headlessui/react";
import styled, { css } from "styled-components";

/* eslint-disable @typescript-eslint/no-explicit-any */

const BUTTON_OPTION_WIDTH=165;

export const StyledListBoxOptions = styled(Listbox.Options)<any>`
  padding-inline-start: 0;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  overflow: auto;
  list-style: none;
  width: ${BUTTON_OPTION_WIDTH}px;
  cursor: pointer;
  padding: 4px 0;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 0;
  position: absolute;
  p {
    padding: 4px;
  }

  ${(props) =>
    props.$length && typeof props.$length === "number" && props.$length < 10
      ? css`
          height: calc(${props.$length} * 50px);
        `
      : css`
          height: calc(${10} * 50px);
        `}
`;

export const StyledListBoxOption = styled.li<any>`
  color: ${(props) => props.theme.colors.main};
  border: transparent solid 2px;
  background-color: transparent;
  margin: 0;

  display: flex;
  justify-content: start;

  padding: 4px;
  border-radius: 4px;
  padding-right: 20px;

  :active,
  :focus,
  :hover {
    border: ${(props) => props.theme.gpwmd.blue} solid 2px;
    color: ${(props) => props.theme.gpwmd.blue};
  }

  ${(props) =>
    props.$active &&
    css`
      border: ${(props) => props.theme.gpwmd.blue} solid 2px;
    `}
`;

export const OptionsSpacer = styled.div`
  width: 20px;
  color: ${(props) => props.theme.gpwmd.blue};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OptionsListLabel = styled.p`
  width: calc(100% - 24px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
`;

export const StyledListboxButton = styled(Listbox.Button)<any>`
  
  padding: 10px;
  margin-right: 8px;
  display: flex;
  width: ${BUTTON_OPTION_WIDTH}px;
  max-width: 300px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => "#FFF"};
  color: ${(props) => "#000"};
  border: 2px solid transparent;
  z-index: 1;



  :hover {
    background-color: #fafafa;
    outline: none;
    cursor: pointer;
  }

  ${(props) =>
    props.$open &&
    css`
      border-color: ${(props) => props.theme.gpwmd.blue};
      outline: none;
    `}

  ${(props) =>
    props.$hasFilter &&
    css`
      border-color: ${(props) => props.theme.gpwmd.blue};
      color: ${(props) => props.theme.gpwmd.blue};
    `}
`;

export const FilterLabel = styled.label`
  max-width: 250px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  align-items: center;
  font-size: 1rem;
`;

export const ListBoxContainer = styled.div`
  position: relative;
  font-size: 1rem;
  z-index:1;
`;
