import React, { useMemo, useState } from "react";
import { Combobox } from "@headlessui/react";
import {
  StyledComboBoxOptions,
  ComboBoxContainer,
  StyledComboBoxOption,
  StyledCheckbox,
  Icon,
  ListItemLoader,
} from "./styles";
import { Vocabulary } from "state/ducks/vocabulary/types";
import { echQueryBuilder, SearchECHDefinition } from "lib/hooks/useECHQuery";
import { useHistory } from "react-router-dom";

interface Props {
  label: string;
  list: Vocabulary[];
  query: SearchECHDefinition;
  dataIndex: keyof Omit<SearchECHDefinition, "_format" | "search" | "sort_by" | "sort_order">;
}

function ComboBoxFilterList(props: Props) {
  const { label, list, query, dataIndex } = props;
  const [search, setSearch] = useState("");

  const history = useHistory();

  const selectedListItems = useMemo(() => {
    return query[dataIndex]
      .map((item) => list.find((listItem) => listItem.tid === item))
      .filter((item) => item !== undefined);
  }, [list, query, dataIndex]);

  const filteredItems = useMemo(() => {
    return list.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search, list]);

  return (
    <ComboBoxContainer>
      <Combobox
        value={selectedListItems}
        onChange={(values: Vocabulary[]) => {
          const cpy = JSON.parse(JSON.stringify(query));
          cpy[dataIndex] = values.map((item) => item.tid);
          setSearch("");
          history.push(echQueryBuilder(cpy));
        }}
        multiple
        nullable
      >
        <Combobox.Label>{label}</Combobox.Label>
        <Combobox.Input
          placeholder={`Search ${label}`}
          onChange={(event: any) => setSearch(event.target.value)}
        ></Combobox.Input>

        <StyledComboBoxOptions static>
          {list.length === 0 &&
            Array.from(Array(8)).map((_, index) => (
              <ListItemLoader key={index} size="default" active={true} />
            ))}
          {filteredItems.map((item) => (
            <Combobox.Option as={React.Fragment} key={item.tid} value={item}>
              {({ active, selected }) => (
                <StyledComboBoxOption $active={active} title={item.name}>
                  <StyledCheckbox checked={selected}>
                    <Icon viewBox="0 0 24 24">
                      <polyline points="20 6 9 17 4 12" />
                    </Icon>
                  </StyledCheckbox>
                  <span>{item.name}</span>
                </StyledComboBoxOption>
              )}
            </Combobox.Option>
          ))}
        </StyledComboBoxOptions>
      </Combobox>
    </ComboBoxContainer>
  );
}

export default ComboBoxFilterList;
