import React, { useEffect } from "react";
// import queryString from 'query-string';
import { publicPathToURL } from "state/utils";
import { Redirect, Switch, Route } from "react-router-dom";
import Home from "view/home/home";
import Navigation from "view/navigation/_navigation";
import { connectActions } from "state/ducks/connect";
import Footer from "view/footer/_footer";
import Login from "view/login/_login";
import ForgotPassword from "view/forgotPassword/_forgotPassword";
import Registration from "view/registration/_registration";
import { AdminRoute } from "view/utils/routing/AdminRoute";
import Approvals from "view/approvals/_approvals";
import Test from "view/test/test";
import ActionPlan from "view/actionPlan/_ActionPlan";
import WebinarsArchive from "view/webinars/WebinarsArchive";
import Calendar from "view/calendar/_calendar";
import Contact from "view/contact/_contact";
import ResetPassword from "view/resetPassword/_resetPassword";
import DiscussionBoard from "view/discussionBoard/discussionBoard";
import QuestionnaireForm from "view/discussionBoard/util/form";
import Proposals from "view/proposals/_proposals";
import { MemberStateRoute } from "view/utils/routing/MemberStateRoute";
import WorkingGroup from "view/workingGroup/WorkingGroup";
import Resources from "view/resources/Resources";
import AdminApproval from "../emailApproval/AdminApproval";
import EmailApproval from "view/emailApproval/emailApproval";
import Archive from "view/Archive";
import AccessProtectedFile from "view/AccessProtectedFile/AccessProtectedFile";
import UkraineRequests from "view/ukraineRequests/ukraineRequests";
import ExportControl from "view/ExportControl/ExportControl";

const ForwardRedirect = (props: any) => {
  return (
    <Redirect
      to={publicPathToURL(
        `${props.match.url}${regex.test(props.match.url)}) ? "" : "/"}home`
      )}
    />
  );
};

const regex = /\/$/g;

interface Props {
  authenticate: typeof connectActions.authenticate;
  verifyToken: typeof connectActions.verifyUserToken;
  initializeApplication: typeof connectActions.initializeApplication;
  getMemberInfo: typeof connectActions.getMemberAccount;
  logout: typeof connectActions.logout;
  match: any;
}

const Connect = (props: Props) => {
  const { initializeApplication, getMemberInfo, logout, match } = props;

  useEffect(() => {
    initializeApplication();
    getMemberInfo();
  }, [initializeApplication, getMemberInfo]);

  return (
    <>
      <div style={{ height: "100%" }}>
        <Navigation />
        <div
          id="content"
          style={{
            minHeight: "calc(100% - 180px)",
            height: "auto",
          }}
        >
          <Switch>
            <Route
              exact
              path={publicPathToURL(`${match.path}/forward`)}
              render={() => <ForwardRedirect {...props} />}
            />
            <AdminRoute
              exact
              path={publicPathToURL(`${match.path}/admin/approvals`)}
              render={() => <Approvals />}
            />
            <AdminRoute
              exact
              path={publicPathToURL(
                `${match.path}/admin/approvals/:uuid/approval-role`
              )}
              render={() => <AdminApproval />}
            />

            <Route
              exact
              path={publicPathToURL(`${match.path}/matchmaking`)}
              render={() => <Proposals />}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/home`)}
              component={Home}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/access/:route`)}
              component={AccessProtectedFile}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/resources`)}
              component={Resources}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/working-groups`)}
              component={WorkingGroup}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/action-plan`)}
              component={ActionPlan}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/workshops`)}
              component={WebinarsArchive}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/webinar/:slug`)}
              component={Test}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/calendar`)}
              component={Calendar}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/contact-us`)}
              component={Contact}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/test/:slug`)}
              component={Test}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/view`)}
              component={Test}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/archives`)}
              component={Archive}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/login`)}
              component={Login}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/logout`)}
              render={() => {
                logout();
                return <Redirect to={`${match.url}/home`} />;
              }}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/register`)}
              component={Registration}
            />
            <Route
              exact
              path={publicPathToURL(
                `${match.path}/discussion-board/questionnaire`
              )}
              component={QuestionnaireForm}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/discussion-board`)}
              component={DiscussionBoard}
            />
            <MemberStateRoute
              exact
              path={publicPathToURL(`${match.path}/ukraine-requests`)}
              component={UkraineRequests}
            />

            <Route
              exact
              path={publicPathToURL(`${match.path}/forgot/password`)}
              component={ForgotPassword}
            />
            <Route
              exact
              path={publicPathToURL(`${match.path}/reset/password/:token`)}
              component={ResetPassword}
            />
            <Route
              exact
              path={publicPathToURL(
                `${props.match.path}/approvals/:uuid/:status`
              )}
              component={EmailApproval}
            />
            <Route
              exact
              path={publicPathToURL(`/:lang/`)}
              render={() => (
                <Redirect
                  to={publicPathToURL(
                    `${match.url}${regex.test(match.url) ? "" : "/"}home`
                  )}
                />
              )}
            />
          </Switch>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Connect;
