import { Reducer } from 'redux';
import { INotificationState, NotificationActions, notificationActionTypes } from './types';

export const initialState: INotificationState = {
  messages: [],
};

const reducer: Reducer<INotificationState> = (state: INotificationState = initialState, action) => {
  const stateCopy: INotificationState = JSON.parse(JSON.stringify(state));

  switch ((action as NotificationActions).type) {
    case notificationActionTypes.ADD_NOTIFICATION:
      stateCopy.messages.push(action.payload);
      return stateCopy;
    case notificationActionTypes.REMOVE_NOTIFICATION:
      stateCopy.messages.pop();
      return stateCopy;
    default:
      return stateCopy;
  }
};

export default reducer;
