import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { IConnectState } from "state/ducks/connect/types";
import { ILanguageState } from "state/ducks/languages/types";
import { connectActions } from "state/ducks/connect";
import {
  MainContainer,
  FormContainer,
  LabeledFormItem,
  StyledForm,
  Button,
  FormLink,
  Logo,
} from "view/utils/forms/styles";
import { useHistory, useLocation } from "react-router-dom";
import {
  HeaderContainer,
  HeaderText,
  NavigationContainer,
  StyledMenuContainer,
} from "view/navigation/styles";
import { StyledATag } from "view/footer/styles";
const queryString = require("query-string");

interface Props {
  connect: IConnectState;
  languages: ILanguageState;
  authenticate: typeof connectActions.authenticate;
  isFormLoading: boolean;
}

const Login = (props: Props) => {
  const { connect, languages } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const { forward } = queryString.parse(location.search);

  useEffect(() => {
    if (connect.authenticated) {
      if (forward && forward !== "/en/home") {
        history.push(`${forward}`);
      } else {
        history.push(`/${languages.code}/home?show=true`);
      }
    }
  }, [connect.authenticated, languages, history, forward]);

  const isValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo) {
      return false;
    }
  };

  const handleSubmit = async () => {
    const formIsValid = await isValid();
    if (formIsValid) {
      const { username, password } = form.getFieldsValue();
      props.authenticate(username, password);
    }
  };

  return (
    <>
      <NavigationContainer style={{ backgroundColor: "#f9f9f9" }}>
        <StyledMenuContainer style={{ backgroundColor: "#f9f9f9" }}>
          <HeaderContainer>
            <HeaderText purpose="title" style={{ color: "#202C47" }}>
              GLOBAL PARTNERSHIP
            </HeaderText>
            <HeaderText style={{ color: "#202C47" }}>
              Against the Spread of Weapons and Materials of Mass Destruction
            </HeaderText>
          </HeaderContainer>
        </StyledMenuContainer>
      </NavigationContainer>
      <MainContainer
        style={{ height: "calc(100% - 128px)", minHeight: "800px" }}
      >
        <div style={{ padding: "1rem" }}>
          <Logo maxWidth="350px" src="/images/icons/italy24/gp-g7-stacked.png" />
          <FormContainer>
            <StyledForm layout="vertical" form={form}>
              <LabeledFormItem
                label="Username"
                name="username"
                style={{}}
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input />
              </LabeledFormItem>

              <LabeledFormItem
                label="Password"
                name="password"
                style={{ minHeight: "100px" }}
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </LabeledFormItem>
              <Form.Item style={{ padding: "1rem 0" }}>
                <Button onClick={handleSubmit}>Sign In</Button>
              </Form.Item>
              <FormLink to={`/${props.languages.code}/register`}>
                Request Access
              </FormLink>
              <FormLink to={`/${props.languages.code}/forgot/password`}>
                Forgot your Password?
              </FormLink>
              <FormLink
                as="a"
                href="https://www.pnnl.gov/security-privacy"
                target="_blank"
              >
                Privacy Policy
              </FormLink>
            </StyledForm>
          </FormContainer>
        </div>
      </MainContainer>
    </>
  );
};

export default Login;
