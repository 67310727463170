import { useEffect, useMemo, useState } from "react";
import { authentication } from "state/api/authentication";
import { genFetch, withToken } from "state/utils/endpoints";

export interface Request {
  field_country: string;
  field_organization: string;
  field_point_of_contact: string;
  field_responses: RequestResponse[];
  field_comments_file: string;
  field_ukraine_request_file: string;
  field_funding_level?: string;
  field_ukraine_tags: string;
  field_request_id: string;
  nid: string;
  title: string;
}

export interface RequestResponse {
  field_date_submitted: string;
  field_plain_text: string;
  field_user_name: string;
  field_user_organization: string;
  id: string;
}

interface FixedResponse {
  tid: string;
  name: string;
}

export interface AggregatedRequest extends Request {
  fixedResponses: FixedResponse[];
}

const useUkraineRequests = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [requests, setRequests] = useState<Request[]>([]);
  const [fixedResponses, setFixedResponses] = useState<FixedResponse[]>([]);

  useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true);
      const response = await genFetch(authentication.getUkraineRequests())()(
        withToken()
      );

      if (response.ok) {
        const body = await response.json();

        // Juan still makes me do this because his normalizer are weak.
        body.forEach((element: any) => {
          try {
            const parsedResponses = JSON.parse(element.field_responses);
            element.field_responses =
              parsedResponses && parsedResponses.length > 0
                ? parsedResponses
                : [];
          } catch (error) {
            element.field_responses = [];
          }
        });

        setRequests(body);
      }
    };

    const fetchResponses = async () => {
      setIsLoading(true);
      const response = await genFetch(
        authentication.getUkraineRequestFixedResponses()
      )()(withToken());

      if (response.ok) {
        const body = await response.json();

        setFixedResponses(body);
      }
    };

    fetchRequests();
    fetchResponses();
  }, [setRequests]);

  const aggregatedRequests: AggregatedRequest[] = useMemo(() => {
    if (fixedResponses.length > 0 && requests.length > 0) {
      let aggregatedProposals = requests
        .map((item: any) => ({
          ...item,
          fixedResponses,
        }))
        .sort((a: any, b: any) => {
          const bValue = Number(b.field_request_id.split("#")[1]);
          const aValue = Number(a.field_request_id.split("#")[1]);
          return  bValue - aValue;
        });

      return aggregatedProposals;
    }

    return [];
  }, [fixedResponses, requests]);

  return {
    isLoading,
    requests,
    fixedResponses,
    aggregatedRequests,
  };
};

export default useUkraineRequests;
