import { Dialog } from "@headlessui/react";
import styled from "styled-components";
import React, { useState } from "react";
import { ECHBannerButton } from "view/utils/forms/styles";

type Props = {};

const Panel = styled(Dialog.Panel)`
  margin: 0 auto; /* Equivalent to mx-auto */
  width: auto;
  border-radius: 0.375rem; /* Equivalent to rounded */
  background: #fff;
  border: 3px solid #ca1228;
  border-radius: 8px;
  padding: 3rem;
  max-width: 1000px;
  color: #605a52;
  padding: 6rem;
  max-height: 90vh;
  overflow: auto;
  
  h2 {
    font-size: 32px;
    color: #605a52;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
`;

const CenterContainer = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  width: 100vw; /* Equivalent to w-screen */
  align-items: center;
  justify-content: center;
`;

const ActionRow = styled.div`
  display: flex;
  column-gap: 1rem;
  justify-content: flex-end;
  margin-top: 4rem;
`;

const AboutECHDialog = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <Dialog
        style={{ position: "relative", zIndex: 50 }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Backdrop aria-hidden="true" />
        <CenterContainer>
          <Panel>
            <Dialog.Title as="h2">
              About GP Nonproliferation and Strategic Trade Knowledge Hub
            </Dialog.Title>
            <Dialog.Description>
              <p>
                The Global Partnership Nonproliferation and Strategic Trade
                Knowledge Hub was developed and is maintained and updated by the
                Strategic Trade Research Institute (STRI) and is supported by
                the United Kingdom’s Counter-proliferation and Arms Control
                Center (CPACC), with technical and IT support provided by United
                States Pacific Northwest National Laboratory (PNNL).
              </p>
              <p>
                The power of information cannot be overestimated. The Hub’s
                objective is to connect users to the resources they need to
                prioritise proliferation threats, identify appropriate
                responses, develop forward-thinking approaches, learn from
                existing best practices, and mitigate uncertainty.
              </p>
              <p>
                The Hub fills a critical resource gap by helping governments and
                other stakeholders locate resources easily and efficiently and
                facilitate its application to the implementation of effective
                nonproliferation and strategic trade activities, from
                policymaking, to research, to industry export control
                compliance, and more. The STRI team carefully reviews and
                selects Hub resources in order to help the broad spectrum of
                nonproliferation stakeholders be connected to information and
                resources that can help them make an impact.
              </p>
            </Dialog.Description>

            <ActionRow>
              <ECHBannerButton
                autoFocus
                style={{ transform: "unset" }}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Close
              </ECHBannerButton>
            </ActionRow>
          </Panel>
        </CenterContainer>
      </Dialog>
      <ECHBannerButton onClick={() => setIsOpen(true)}>About</ECHBannerButton>
    </React.Fragment>
  );
};

export default AboutECHDialog;
