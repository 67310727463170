import React from "react";
import styled from "styled-components";
import { MainContainer, Logo, Button, FormText } from "view/utils/forms/styles";
import { useHistory } from "react-router-dom";

interface Props {
  name: string;
}

const Container = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const NameText = styled.span`
  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.main};
  font-size: 1.2rem;
  margin: 0;
  font-weight: 700;
`;

const StyledFormText = styled(FormText)`
  padding: 1rem;
  font-size: 1.2rem;
`;

const ThankYou = (props: Props) => {
  const history = useHistory();
  return (
    <MainContainer style={{ height: "100%" }}>
      <Container>
        <Logo maxWidth="300px" src="/images/icons/italy24/gp-g7-stacked.png" />

        <StyledFormText>
          <NameText>{props.name}</NameText>
          {`, your request has been submitted. You will receive an email
          notification upon approval.`}
        </StyledFormText>
        <Button onClick={() => history.push("/en/login")}>
          Return to Login
        </Button>
      </Container>
    </MainContainer>
  );
};

export default ThankYou;
