import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Search, SearchClearButton } from "./styles";
import { SearchECHDefinition } from "lib/hooks/useECHQuery";

type Props = {
  query: SearchECHDefinition;
  queryBuilder: any;
};

function SearchBar({ query, queryBuilder }: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const history = useHistory();

  const handleFocus = () => {
    if (isSearchActive) {
      if (ref.current && document.activeElement !== ref.current) {
        setIsSearchActive(false);
      }
    } else {
      if (ref.current && document.activeElement === ref.current) {
        setIsSearchActive(true);
      }
    }
  };

  const handleSearch = () => {
    if (ref.current && ref.current.value) {
      history.push(
        queryBuilder({
          ...query,
          search: ref.current.value,
        })
      );
    }
  };

  useEffect(() => {
    if(ref.current && query.search !== ref.current.value ){
        ref.current.value = query.search ?? "";
    }
  }, [query.search, ref])
  

  return (
    <Search.Form isActive={isSearchActive}>
      <Search.Input
        ref={ref}
        type="search"
        placeholder="Search"
        defaultValue={query.search ? query.search : ""}
        onFocus={handleFocus}
        onBlur={handleFocus}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSearch();
          }
        }}
      />
      {ref.current && ref.current.value.length > 0 && (
        <SearchClearButton
          onClick={(e) => {
            e.preventDefault();
            if (ref.current) {
              ref.current.value = "";
            }

            history.push(
              queryBuilder({
                ...query,
                search: null,
              })
            );
          }}
        >
          <CloseOutlined />
        </SearchClearButton>
      )}

      <Search.Button
        type="submit"
        value="Go"
        onClick={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      ></Search.Button>
    </Search.Form>
  );
}

export default SearchBar;
