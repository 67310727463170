import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IApplicationState } from "state/ducks";
import { connectTypes, connectActions } from "state/ducks/connect";
import ForgotPassword from "./forgotPassword";

const mapStateToProps = (state: IApplicationState) => ({
  connect: state.connect,
  languages: state.languages,
});

type actions = connectTypes.ConnectActions;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators(
    {
      authenticate: connectActions.authenticate,
      forgotPassword: connectActions.forgotPassword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
