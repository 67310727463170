import { useMemo } from "react";
import { publicPathToURL } from "state/utils";
const queryString = require("query-string");
/**
 * Query
 * {
 *  view: "node/2"
 *  group: 69
 *  contentType= 123
 *  presidency= 113
 *  contentYear= 242
 *  alias= 0 or 1
 * }
 */

export interface SearchDefinition {
  view?: string;
  group?: string;
  contentType?: string;
  presidency?: string;
  contentYear?: string;
  plenary?: 1
  alias?: 1
}

export function archiveQueryBuilder(q: SearchDefinition) {
  const query = JSON.parse(JSON.stringify(q));
  const url = queryString.stringify(query);
  return publicPathToURL(`/en/archives?${url}`);
}

export const useArchiveQuery = (
  search: string
): [url: string, query: SearchDefinition] => {
  const [url, query] = useMemo(() => {
    const queryObject = queryString.parse(search);
    
    const view = queryObject["view"] ? queryObject["view"] : undefined;
    const alias = queryObject["alias"] ? 1 : undefined;

    const group = queryObject["group"] ? queryObject["group"] : undefined;

    const plenary = group ? undefined : 1;

    const contentType = queryObject["contentType"]
      ? queryObject["contentType"]
      : undefined;

    const presidency = queryObject["presidency"]
      ? queryObject["presidency"]
      : undefined;

    const contentYear = queryObject["contentYear"]
      ? queryObject["contentYear"]
      : undefined;



    const query: SearchDefinition = {
      view,
      group,
      plenary,
      alias,
      contentType,
      contentYear,
      presidency,
    };

    const url: string = queryString.stringify(query, {
      skipEmptyString: true,
    });

    return [url, query];
  }, [search]);

  return [url, query];
};
