import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IApplicationState } from "state/ducks";
import { connectTypes, connectActions } from "state/ducks/connect";
import Login from "./login";

const mapStateToProps = (state: IApplicationState) => ({
  connect: state.connect,
  languages: state.languages,
  isFormLoading: state.formLoading.isLoading
});

type actions = connectTypes.ConnectActions;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators(
    {
      authenticate: connectActions.authenticate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
