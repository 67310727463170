import { Reducer } from "redux";
import { FormLoadingState } from "./types";
import { FormLoadingTypes } from "./actions";

const initialState: FormLoadingState = {
  isLoading: false,
};

const reducer: Reducer<FormLoadingState> = (
  state: FormLoadingState = initialState,
  action
) => {
  const stateCopy: FormLoadingState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case FormLoadingTypes.UPDATE_FORM_LOADING: {
      const { loading } = action.payload;
      return {...stateCopy, isLoading: loading};
    }
    default:
      return stateCopy;
  }
};

export default reducer;
