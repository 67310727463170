import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { publicPathToURL } from "state/utils";

import queryString from "query-string";

export interface SearchECHDefinition {
  keywords: string[];
  filters: string[];
  scope: string[];
  sources: string[];
  sort_by: string,
  sort_order: string,
  search?: string;
  _format: "json";
}

export const getDefaultECHQuery = (): SearchECHDefinition => ({
  keywords: [],
  filters: [],
  scope: [],
  sources: [],
  sort_by: "search_api_relevance",
  sort_order: "DESC",
  _format: "json",
});


export const SEARCH_OPTIONS = [
  { id: 1, label: "Most Relevant", update: {sort_by: "search_api_relevance", sort_order: "DESC"}},
  { id: 2, label: "Newest First", update: {sort_by: "field_source_year", sort_order: "DESC"}},
  {
    id: 3,
    label: "Oldest First",
    update: {sort_by: "field_source_year", sort_order: "ASC"}
  },
] as const;


export function echQueryBuilder(q: SearchECHDefinition) {
  const query = JSON.parse(JSON.stringify(q));
  delete query["_format"];

  const url = queryString.stringify(query, {
    arrayFormat: "index",
  });
  return publicPathToURL(`?${url}`);
}

export const useECHQuery = (): SearchECHDefinition => {
  const { search } = useLocation();

  const query = useMemo(() => {
    const queryObject: any = queryString.parse(search, {
      arrayFormat: "index",
    });

    const keywords = queryObject["keywords"] ? queryObject["keywords"] : [];
    const filters = queryObject["filters"] ? queryObject["filters"] : [];
    const scope = queryObject["scope"] ? queryObject["scope"] : [];
    const sources = queryObject["sources"] ? queryObject["sources"] : [];
    const searchString = queryObject["search"] ? queryObject["search"] : undefined;
    const sortBy = queryObject["sort_by"] && (queryObject["sort_by"] === "field_source_year" || queryObject["sort_by"] === "search_api_relevance") ? queryObject["sort_by"] : "search_api_relevance";
    const orderBy = queryObject["sort_order"] && (queryObject["sort_order"] === "ASC" || queryObject["sort_order"] === "DESC") ? queryObject["sort_order"] : "search_api_relevance"; 

    const query: SearchECHDefinition = {
      keywords,
      filters,
      scope,
      sources,
      sort_by: sortBy,
      sort_order: orderBy,
      search: searchString,
      _format: "json",
    };

    return query;
  }, [search]);

  return query;
};
