import { Action } from 'redux';

export interface IMessage {
  message: string;
  description: string;
  type: 'success' | 'info' | 'warning' | 'error';
}

export interface INotificationState {
  messages: IMessage[];
}

export const notificationActionTypes = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
};

export interface IAction extends Action {
  type: string;
}

export interface INotificationAction extends IAction {
  payload: IMessage;
}

export type NotificationActions = IAction | INotificationAction;
