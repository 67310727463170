import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IApplicationState } from "state/ducks";
import { connectTypes, connectActions } from "state/ducks/connect";
import ResetPassword from "./resetPassword";

const mapStateToProps = (state: IApplicationState) => ({
  languages: state.languages,
});

type actions = connectTypes.ConnectActions;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators(
    {
      logInAfterPasswordChange: connectActions.logInAfterPasswordChange,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
