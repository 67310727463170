import * as React from 'react';
import styled, { css } from 'styled-components';

/**
 * Menu Properties
 */
export interface MenuProps {
  /**
   * Allows you to override CSS properties
   */
  className?: any;

  /**
   * Allows you to add Menu Items
   * @param {React.ReactNode}
   * @default null
   */
  children?: any;

  id?: string;

  selectedKey?: number | string;

  onClick?: (ev: React.ReactEventHandler<HTMLElement>, index: number) => void;
}

interface ISelected {
  key: number;
  array: string;
}

interface MenuContainerProps {
  selectedKey: any;
}

const MenuContainer = styled.div<MenuContainerProps>`
  display: flex;
  box-sizing: border-box;
  outline: none;
  max-width: 100%;
  padding: 0 44px;
  height: 5.5rem;
  fill: #ffffff;
  background: #ffffff;
`;
const StyledLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;
`;

const StyledRight = styled(StyledLeft)`
  justify-content: flex-end;
`;

function Appbar(props: MenuProps) {
  const { className, id, onClick, children, selectedKey } = props;
  let left: any[] = [];
  let right: any[] = [];

  children.map((child: any, index: any) => {
    switch (child.props.side) {
      case 'left': {
        left.push(
          React.cloneElement(child, {
            onClick: onClick,
            active: index === selectedKey || child.key === selectedKey ? true : false,
          })
        );
        break;
      }
      case 'right': {
        right.push(
          React.cloneElement(child, {
            onClick: onClick,
            active: index === selectedKey || child.key === selectedKey ? true : false,
          })
        );
        break;
      }
    }
  });
  return (
    <MenuContainer id={id} className={className} selectedKey={selectedKey}>
      <StyledLeft>{left}</StyledLeft>
      <StyledRight>{right}</StyledRight>
    </MenuContainer>
  );
}

export default Appbar;
