import { Table } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledTable = styled(Table)`
  width: 100%;
  background: #fff;

  display: inline-flex;

  .ant-table-thead {
    display: none;
  }

  .ant-table-cell {
    padding: 0;
    border-bottom: none;
  }

  .min-w-500 {
    min-width: 400px;
  }
  .ant-spin-nested-loading {
    width: 100%;
  }
`;

export const WebinarContainer = styled.div`
  width: 100%;
  padding: 0 3rem;
`;

export const WebinarTitle = styled.p`
  margin: 0;
`;
export const WebinarLink = styled(Link)`
  display: block;
  font-style: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.gpwmd.green};
  font-weight: 300;
  text-decoration: none;
  font-size: 1rem;
  display: block;
  margin-bottom: 0.5rem;
  border-bottom: solid 1px ${(props) => props.theme.gpwmd.grey};
  :hover {
    color: ${(props) => props.theme.colors.main};
  }
`;
