import React from "react";
import Footer from "view/footer/_footer";
import Navigation from "view/navigation/_navigation";
import ExportControl from "./ExportControl";

type Props = {};

const PublicExportControl = (props: Props) => {
  return (
    <React.Fragment>
      <Navigation />
      <ExportControl />
      <Footer />
    </React.Fragment>
  );
};

export default PublicExportControl;
