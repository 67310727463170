import { ExportControlHubEntry } from "lib/hooks/useECHSearch";
import EntryCell from "./EntryCell/EntryCell";
import LoaderCell from "./EntryCell/LoaderCell";
import { StyledTable } from "./styles";

const EntryColumns: any = [
  {
    title: " ",
    key: "title",
    render: (text: any, record: ExportControlHubEntry) => (
      <EntryCell record={record} />
    ),
  },
];

interface Props {
  entries: ExportControlHubEntry[];
  isLoading: boolean;
}

function ExportControlTable({ entries, isLoading }: Props) {
  return (
    <>
      {isLoading ? (
        Array.from(Array(4)).map((_, index) => <LoaderCell />)
      ) : (
        <StyledTable
          rowKey={(record: any) => record.nid}
          columns={EntryColumns}
          dataSource={entries}
        />
      )}
    </>
  );
}

export default ExportControlTable;
