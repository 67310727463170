import { ActionCreator } from 'redux';
import columnedActionTypes, { IColumnedAction, IColumnedResponse } from './types';

export const columned: ActionCreator<IColumnedAction> = (path: string) => ({
  type: columnedActionTypes.COLUMNED,
  payload: {
    path,
  },
});

export const columnedByNode: ActionCreator<IColumnedAction> = (path: string) => ({
  type: columnedActionTypes.COLUMNED_BY_NODE,
  payload: {
    path,
  },
});

export const newsArticle: ActionCreator<IColumnedAction> = (path: string) => ({
  type: columnedActionTypes.NEWS_ARTICLE,
  payload: {
    path,
  },
});

export const getArchive: ActionCreator<IColumnedAction> = (path: string) => ({
  type: columnedActionTypes.NEWS_ARCHIVE,
  payload: {
    path,
  },
});

export const getEventArticle: ActionCreator<IColumnedAction> = (path: string) => ({
  type: columnedActionTypes.EVENTS_ARTICLE,
  payload: {
    path,
  },
});

export const getEventArchive: ActionCreator<IColumnedAction> = (path: string) => ({
  type: columnedActionTypes.EVENTS_ARCHIVE,
  payload: {
    path,
  },
});

export const returnColumnedSuccess: ActionCreator<IColumnedResponse> = (parsedBody, path) => ({
  type: columnedActionTypes.COLUMNED_RETURN_SUCCESS,
  payload: {
    [path]: parsedBody,
  },
});
