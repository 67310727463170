import { Dialog } from "@headlessui/react";
import styled from "styled-components";
import React from "react";
import Media from "components/Media/Media";
import { useHistory, useLocation } from "react-router-dom";

type Props = {};

const Panel = styled(Dialog.Panel)`
  margin: 0 auto; /* Equivalent to mx-auto */
  width: auto;
  border-radius: 0.375rem; /* Equivalent to rounded */
  background: #fff;
  border: 3px solid ${props => props.theme.gpwmd.darkTan};
  border-radius: 8px;
  max-width: 1000px;
  color: #605a52;
  padding: 3rem 6rem;
  width: 100%;
  max-height: 90vh;
  overflow: auto;

  h2 {
    font-size: 32px;
    color: #605a52;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
`;

const CenterContainer = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  width: 100vw; /* Equivalent to w-screen */
  align-items: center;
  justify-content: center;
`;

const ActionRow = styled.div`
  display: flex;
  column-gap: 1rem;
  justify-content: flex-end;
  margin-top: 4rem;
`;

export const VideoContainer = styled.div`
  min-width: 700px;
  width: 100%;

  @media screen and (max-width: 800px) {
    min-width: 300px;
    width: 100%;
  }
`;

export const ECHBannerButton = styled.button`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 22px;
  color: #fff;
  background-color: ${props => props.theme.gpwmd.darkTan};
  border-radius: 4px;
  font-weight: 700;
  text-indent: 0px;
  text-shadow: none;
  cursor: pointer;
  width: 270px;
  height: 75px;
  border: none;
  :hover,
  :focus {
    background-color: ${props => props.theme.gpwmd.tan};
  }
`;

const MemberLoginDialog = (props: Props) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <React.Fragment>
      <Dialog
        style={{ position: "relative", zIndex: 50 }}
        open={location.search.includes("show")}
        onClose={() => history.push(`/en/home`)}
      >
        <Backdrop aria-hidden="true" />
        <CenterContainer>
          <Panel>
            <Dialog.Title as="h2">Welcome to the GP Portal</Dialog.Title>
            <Media
              video="https://gpwmd-portal.org/backend/web/sites/default/files/2023-11/GP%20Portal%20-%20Member%20State%20Walkthrough.mp4"
              posterImage="/images/videos/member-login-banner.png"
            />
            <ActionRow>
              <ECHBannerButton
                autoFocus
                style={{ transform: "unset" }}
                onClick={() => history.push(`/en/home`)}
              >
                Close
              </ECHBannerButton>
            </ActionRow>
          </Panel>
        </CenterContainer>
      </Dialog>
    </React.Fragment>
  );
};

export default MemberLoginDialog;
