import React, { useEffect } from "react";
import styled from "styled-components";
import { IColumnedState } from "state/ducks/columned/types";
import { columned } from "state/ducks/columned/actions";
import { ParsedLayout } from "view/parsed-layout/parsedLayout";

export interface ActionPlanProps {
  columned: IColumnedState;
  columnedAction: typeof columned;
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ContainerTwo = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export default function Home(props: ActionPlanProps) {
  const { columned, columnedAction } = props;
  const path = "action-plan"

  useEffect(() => {
    columnedAction(path);
  }, [columnedAction]);
  const data = columned[`en/${path}`];

  return (
    <Container>
      <ContainerTwo>
        {data && <ParsedLayout data={data.value} />}
      </ContainerTwo>
    </Container>
  );
}
