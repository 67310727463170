import React, { useState } from "react";
import { Route, Redirect,Switch } from "react-router-dom";
import { publicPathToURL } from "state/utils";
import { IConnectState } from "state/ducks/connect/types";
import { ILanguageState } from "state/ducks/languages/types";
import { connectActions } from "state/ducks/connect";
import Registration from "./registration";
import ThankYou from "./thankYou";

interface Props {
  connect: IConnectState;
  languages: ILanguageState;
  becomeAMemberRequest: typeof connectActions.becomeAMember;
  becomeAMemberReset: typeof connectActions.becomeAMemberReset;
  match:any;
  isFetchLoading: boolean;
}

const Progression = (props: Props) => {
  const [name, setName] = useState("");
  return (
    <Switch>
      <Route
        exact
        path={publicPathToURL(`${props.match.path}`)}
        render={() => (
          <Registration
            submitFirstName={setName}
            becomeAMemberRequest={props.becomeAMemberRequest}
            becomeAMemberReset={props.becomeAMemberReset}
            connect={props.connect}
            languages={props.languages}
            isFormLoading={props.isFetchLoading}
          />
        )}
      />
      <Route
        exact
        path={publicPathToURL(`${props.match.path}/thank-you`)}
        render={() => (
          <ThankYou name={name} />
        )}
      />
      <Route
        render={() => (
          <Redirect to={`${props.match.url}`} />
        )}
      />
    </Switch>
  );
};

export default Progression;
