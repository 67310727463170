import React from "react";
import { createGlobalStyle, css } from "styled-components";

import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
// import LayoutListener from 'view/layout/_layout';
import { publicPathToURL } from "state/utils/path";
import { ScrollToTop } from "state/utils";
import { LanguageCode } from "state/ducks/languages/types";
import { changeLanguage } from "state/ducks/languages/actions";
import { IApplicationState } from "state/ducks";
import { languagesTypes } from "state/ducks/languages";
import { LanguageDirection } from "state/ducks/languages/types";
import Public from "view/public/_public";
import Notify from "view/notifications/_notifications";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";

import "antd/lib/form/style/css"; // tslint:disable-line
import "antd/lib/input/style/css"; // tslint:disable-line
import "antd/lib/notification/style/css"; // tslint:disable-line
import "antd/lib/table/style/css"; // tslint:disable-line
import "antd/lib/tag/style/css"; // tslint:disable-line
import "antd/lib/list/style/css"; // tslint:disable-line
import "antd/lib/avatar/style/css"; // tslint:disable-line
import "antd/lib/skeleton/style/css"; // tslint:disable-line
import "antd/lib/breadcrumb/style/css"; // tslint:disable-line
import "antd/lib/radio/style/css"; // tslint:disable-line
import "antd/lib/badge/style/css"; // tslint:disable-line
import "antd/lib/tooltip/style/css"; // tslint:disable-line
import "antd/lib/alert/style/css"; // tslint:disable-line
import { usePageTracking } from "lib/hooks/usePageTracking";
import PublicExportControl from "view/ExportControl/PublicExportControl";
// import "antd/lib/select/style/css"; // tslint:disable-line

interface BodyStyleProps {
  languageDirection: LanguageDirection;
}

export const HTMLBodyStyle = createGlobalStyle`
  html, body, #root{
    margin:0;
    padding:0;
    height:100%;
    width: 100%;
    min-height:100%;
    min-width: 100%;
    font-size: 12px;

    @media (min-width: 320px) {
      font-size: 12px;
    }
    @media (min-width: 600px) {
      font-size: 14px;
    }
    @media (min-width: 768px) {
      font-size: 16px;
    }

    .grecaptcha-badge {
      visibility: var(--captcha-visible) !important;
    }
  }

  #root{
    position: relative;
  }

  * {
    ${(props: BodyStyleProps) =>
      props.languageDirection === LanguageDirection.RIGHT_TO_LEFT
        ? css`
            direction: rtl;
            > div {
              text-align: right !important;
            }
          `
        : css`
            /* transition: all 100ms linear; */
          `}
  }
`;

const checkCode = (url: string): boolean => {
  switch (url) {
    case LanguageCode.ENGLISH:
      return true;
    case LanguageCode.ARABIC:
      return false;
    case LanguageCode.ESPANOL:
      return false;
    case LanguageCode.FRENCH:
      return false;
    case LanguageCode.RUSSIAN:
      return false;
    default:
      return false;
  }
};

interface IFCProps extends languagesTypes.ILanguageState {
  setLanguageCode: typeof changeLanguage;
  match: any;
}

export const GatewayComp: React.FC<IFCProps> = (props) => {
  const { lang } = props.match.params;
  const validLanguage = checkCode(lang);

  if (validLanguage && lang !== props.code) {
    props.setLanguageCode(lang);
  }

  return (
    <>
      {validLanguage ? <Public /> : <Redirect to={publicPathToURL("/en/")} />}
      <ScrollToTop />
      <Notify />
      <HTMLBodyStyle languageDirection={props.direction} />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  ...state.languages,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setLanguageCode: changeLanguage,
    },
    dispatch
  );

const hosts = ["hub.gpwmd-portal.org", "hub.gpwmd-staging-client.pnl.gov"];

export const Gateway = withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(GatewayComp)
);

function AppRoutes() {
  usePageTracking();

  const origin = window.location.host;

  if (hosts.some((host) => host === origin)) {
    return <PublicExportControl />;
  }

  return (
    <>
      <Switch>
        <Route path={"/:lang"} component={Gateway} />
        {/* For Routes like Downloads - PDFS or Images */}
        <Route path={"/content/:name"} render={() => <div />} />
        <Route path={"/"} component={Gateway} />
      </Switch>
    </>
  );
}

export default AppRoutes;
