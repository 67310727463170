import { publicPathToURL } from "state/utils";
import styled, { css } from "styled-components";

export const SearchBar = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 8px;
  background-color: #ffffff;
  color: #000;
  font-family: ${(props) => props.theme.fonts.main};
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-color: ${(props) => props.theme.gpwmd.darkTan};
  :active,
  :focus {
    border-color: transparent;
    outline: none;
  }
`;
export const SearchButton = styled.input`
  border-radius: 4px;
  text-indent: -1000px;

  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 40px;
  width: 50px;
  border: none;
  background-color: ${(props) => props.theme.gpwmd.darkTan};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 100px;
  background-image: url(${publicPathToURL("/images/icons/search.svg")});

  :hover {
    background-color: ${(props) => props.theme.gpwmd.slate};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 100px;
    background-image: url(${publicPathToURL("/images/icons/search.svg")});
  }

  :active,
  :focus {
    outline: none;
    box-shadow: 0 0 0 3px ${(props) => props.theme.gpwmd.green};
  }
`;

interface SearchContainerProps {
  isActive: boolean;
}

export const SearchContainer = styled.form<SearchContainerProps>`
  height: 40px;
  display: flex;
  white-space: nowrap;
  border-radius: 4px;
  margin-right: 8px;

  ${(props) =>
    props.isActive &&
    css`
      box-shadow: 0 0 0 3px ${(props) => props.theme.gpwmd.green};
    `}
`;
