import React from "react";
import { layoutTypes } from "state/ducks/layout";
import { useHistory, useLocation } from "react-router-dom";
import { publicPathToURL } from "state/utils";
import { logout } from "state/ducks/connect/actions";
import { searchFor } from "state/ducks/search/actions";
import { languagesTypes } from "state/ducks/languages";
import { topNavigationItems, TopItem } from "./utils/topItems";
import { INavigationObject, IMenuItems } from "state/ducks/navigation/types";
import { getApiAttribute, genFetch, withToken } from "state/utils/endpoints";
import { ILanguageState } from "state/ducks/languages/types";

import {
  NavigationContainer,
  StyledMenuContainer,
  GPWMDLogo,
  StyledSubMenuItem,
  StyledMenuSubContainer,
  BarItemOverride,
  MenuContainer,
  BottomMenu,
  NavLink,
  NestedLink,
  Dropdown,
  DropdownItem,
  DropdownMenuContainer,
  DropdownMenu,
  NavButton,
  TopNavLink,
  HeaderContainer,
  HeaderText,
} from "./styles";
import { authentication } from "state/api/authentication";
import {
  UkraineBanner,
  UkraineBannerWrapper,
  BannerLink,
} from "view/common/Banner";

const TopBanner = (props: TopNavigationProps) => {
  const { languages } = props;
  return (
    <UkraineBannerWrapper>
      <UkraineBanner minHeight="30px" bgColor="#0057B7" />
      <UkraineBanner minHeight="30px" bgColor="#FFD700">
        <div style={{ maxWidth: 1600, width: "100%", padding: "0 2rem" }}>
          <BannerLink
            to={publicPathToURL(`/${languages.code}/ukraine-requests`)}
          >
            <p>Ukraine Assistance Requests</p>
          </BannerLink>
        </div>
      </UkraineBanner>
    </UkraineBannerWrapper>
  );
};

interface TopNavigationProps {
  breakpoint: layoutTypes.Breakpoints;
  isPublic: boolean;
  member: any;
  languages: languagesTypes.ILanguageState;
}

const TopNavigation = (props: TopNavigationProps) => {
  const { languages, isPublic, member } = props;
  const menuKey = isPublic ? "public" : "member";

  const topNavItems: React.ReactNodeArray = [];

  const history = useHistory();

  if (
    !isPublic &&
    member.roles &&
    member.roles.some((item: any) => item.target_id === "appro")
  ) {
    topNavItems.push(
      <StyledSubMenuItem key={"approvals"}>
        <TopNavLink to={publicPathToURL(`/${languages.code}/admin/approvals`)}>
          Approvals
        </TopNavLink>
      </StyledSubMenuItem>
    );
  }

  topNavigationItems[menuKey].forEach((item: TopItem) => {
    const { label, to } = item;
    topNavItems.push(
      <StyledSubMenuItem key={to}>
        <TopNavLink to={publicPathToURL(`/${languages.code}${to}`)}>
          {label}
        </TopNavLink>
      </StyledSubMenuItem>
    );
  });

  return (
    <NavigationContainer>
      <StyledMenuContainer style={{ justifyContent: "space-between" }}>
        <GPWMDLogo
          $needsMargin={
            !isPublic &&
            member.roles &&
            member.roles.some((item: any) => item.target_id === "appro")
          }
          src="/images/icons/italy24/gp-g7-logo.png"
          onClick={() => history.push(`/${languages.code}/home`)}
        />
        <StyledMenuSubContainer>{topNavItems}</StyledMenuSubContainer>
      </StyledMenuContainer>
    </NavigationContainer>
  );
};

interface MenuItemProps {
  item: IMenuItems;
  languages: ILanguageState;
}

interface BottomNavigationProps {
  breakpoint: layoutTypes.Breakpoints;
  navigation: INavigationObject;
  isPublic: boolean;
  languages: languagesTypes.ILanguageState;
  member: any;
}

export const isExternalURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

const divideChildren = (items: IMenuItems[] = []) => {
  const left: IMenuItems[] = [];
  const left1: IMenuItems[] = [];
  const right: IMenuItems[] = [];

  items.forEach((nav) => {
    if (nav.item.text.length < 40) {
      if (left.length < 4) {
        left.push(nav);
      } else {
        left1.push(nav);
      }
    } else {
      right.push(nav);
    }
  });

  return [left, left1, right];
};

const BottomNavigation = (props: BottomNavigationProps) => {
  const { navigation, isPublic, languages, member } = props;
  const location = useLocation();

  const renderMenuItem = (props: MenuItemProps) => {
    const { side, to, text, primary } = props.item.item;

    const isExternal = isExternalURL(to);

    if (props.item.item.type === "link") {
      return (
        <BarItemOverride
          side={side}
          react_key={`/${props.languages.code}${to}`}
          key={to}
        >
          {isExternal ? (
            <NavButton
              as="a"
              primary={primary ? primary.toString() : undefined}
              target="_blank"
              rel="noopener noreferrer"
              href={to}
            >
              {text}
            </NavButton>
          ) : (
            <NavLink
              primary={primary ? primary.toString() : undefined}
              to={`/${props.languages.code}${to}`}
            >
              {text}
            </NavLink>
          )}
        </BarItemOverride>
      );
    } else {
      return (
        <BarItemOverride
          side={side}
          react_key={`/${props.languages.code}${to}`}
          key={to}
        >
          <NavButton
            primary={primary ? primary.toString() : undefined}
            onClick={props.item.item.action}
          >
            {text}
          </NavButton>
        </BarItemOverride>
      );
    }
  };

  const renderDropdownItem = (props: MenuItemProps) => {
    const { side, to, text, children } = props.item.item;
    const [left, left1, right] = divideChildren(children);
    return (
      <DropdownMenuContainer
        side={side}
        react_key={`/${props.languages.code}${to}`}
        key={to}
      >
        {text}
        <DropdownMenu id="dropdown">
          <Dropdown width="350px">
            {left.map((item) => (
              <DropdownItem key={item.item.to}>
                <NestedLink
                  href={item.item.to}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {item.item.text}
                </NestedLink>
              </DropdownItem>
            ))}
          </Dropdown>
          <Dropdown width="350px">
            {left1.map((item) => (
              <DropdownItem key={item.item.to}>
                <NestedLink
                  href={item.item.to}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {item.item.text}
                </NestedLink>
              </DropdownItem>
            ))}
          </Dropdown>
          <Dropdown width="auto%">
            {right.map((item) => (
              <DropdownItem key={item.item.to}>
                <NestedLink
                  href={item.item.to}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {item.item.text}
                </NestedLink>
              </DropdownItem>
            ))}
          </Dropdown>
        </DropdownMenu>
      </DropdownMenuContainer>
    );
  };

  let menuItems = JSON.parse(
    JSON.stringify(navigation[getApiAttribute(isPublic)])
  );

  return (
    <MenuContainer>
      <BottomMenu selectedKey={location.pathname}>
        {menuItems.map((menuItem: IMenuItems) =>
          menuItem.item.children && menuItem.item.children.length > 0
            ? renderDropdownItem({ languages, item: menuItem })
            : renderMenuItem({ languages, item: menuItem })
        )}
      </BottomMenu>
    </MenuContainer>
  );
};

interface NavigationProps {
  breakpoint: layoutTypes.Breakpoints;
  navigation: INavigationObject;
  isPublic: boolean;
  member: any;
  languages: languagesTypes.ILanguageState;
  logout: typeof logout;
  searchFor: typeof searchFor;
}

const Navigation = (props: NavigationProps) => {
  const { breakpoint, isPublic, member, navigation, languages } = props;
  return (
    <NavigationContainer>
      {!isPublic &&
        member.roles &&
        member.roles.some((item: any) => item.target_id === "member_state") && (
          <TopBanner
            languages={languages}
            breakpoint={breakpoint}
            isPublic={isPublic}
            member={member}
          />
        )}
      <TopNavigation
        languages={languages}
        breakpoint={breakpoint}
        isPublic={isPublic}
        member={member}
      />
      {!isPublic && (
        <BottomNavigation
          languages={languages}
          breakpoint={breakpoint}
          navigation={navigation}
          isPublic={isPublic}
          member={member}
        />
      )}
    </NavigationContainer>
  );
};

export default Navigation;
