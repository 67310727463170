import { Listbox } from "@headlessui/react";
import React, { useState } from "react";
import queryString from "query-string";
import {
  ListBoxContainer,
  OptionsListLabel,
  OptionsSpacer,
  StyledListBoxOption,
  StyledListBoxOptions,
  StyledListboxButton,
} from "./styles";
import {
  CaretDownOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { SEARCH_OPTIONS, echQueryBuilder, useECHQuery } from "lib/hooks/useECHQuery";
import { useHistory } from "react-router-dom";

function ExportControlListBox() {

  const query = useECHQuery();
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(() => {
    const {sort_by, sort_order} = query;
    const test = JSON.stringify({sort_by, sort_order});
    const option = SEARCH_OPTIONS.find(opt => JSON.stringify(opt.update) === test);
    
    if (option) {
      return option
    }
    return SEARCH_OPTIONS[0];
  });

  const handler = (option: typeof SEARCH_OPTIONS[0]) => {

    const update  = {...query, ...option.update}
    setSelectedOption(option);
    history.push(echQueryBuilder(update))

  }

  return (
    <ListBoxContainer>
      <Listbox value={selectedOption} onChange={handler}>
        <StyledListboxButton>
          {selectedOption.label}{" "}
          <OptionsSpacer>
            <CaretDownOutlined />
          </OptionsSpacer>
        </StyledListboxButton>

        <StyledListBoxOptions $length={SEARCH_OPTIONS.length}>
          {SEARCH_OPTIONS.map((option) => (
            <Listbox.Option key={option.id} value={option} as={React.Fragment}>
              {({ active, selected }) => (
                <StyledListBoxOption
                  $active={active}
                  key={option.id}
                  value={option}
                >
                  <OptionsSpacer>{selected && <CheckOutlined />}</OptionsSpacer>
                  <OptionsListLabel>{option.label}</OptionsListLabel>
                </StyledListBoxOption>
              )}
            </Listbox.Option>
          ))}
        </StyledListBoxOptions>
      </Listbox>
    </ListBoxContainer>
  );
}

export default ExportControlListBox;
