import React, { useState, useEffect } from "react";
import { ParseItem } from "state/ducks/columned/types";

interface Props {
  data: ParseItem[];
  componentMap?: any;
}

/**
 * Creates a page of react nodes based on component data and definitions for component
 * types.
 * @param props
 */
export default function useParsedLayout(props: Props) {
  const { data, componentMap } = props;
  const [layout, setLayout] = useState<any>(
    renderContentNode(data, componentMap)
  );

  useEffect(() => {
    setLayout(renderContentNode(data, componentMap));
  }, [data, componentMap]);

  return [{ layout }, { setLayout }];
}


/**
 * Recursively builds react nodes based on types and attributes defined in
 * a data object
 * @param data html item definitions array
 * @param page name of the page to control custom styles or media queries
 * @param rowLevel number representing the nested row level
 */
const renderContentNode = (data: ParseItem[], componentMap: any) => {
  const Column = componentMap.get("col");
  const Row = componentMap.get("row");
  const Text = componentMap.get("text");
  const Image = componentMap.get("image");
  const BulletList = componentMap.get("bullet_list");
  const Link = componentMap.get('link');
  const ImageLink = componentMap.get('image_link');
  const Media = componentMap.get('media');
  const ParticipantsList = componentMap.get('participants_list');
  

  const renderNode = (node: ParseItem) => {
    const { type, items, id } = node;
    switch (type) {
      case "col":
        return (
          <Column
            key={id[0].value}
            data={node}
            renderContentNode={renderContentNode}
            componentMap={componentMap}
            items={items}
          />
        );
      case "row":
        return (
          <Row
            key={id[0].value}
            data={node}
            renderContentNode={renderContentNode}
            componentMap={componentMap}
            items={items}
          />
        );
      case "text":
        return <Text key={id[0].value} data={items} />;
      case "image":
        return <Image key={id[0].value} data={items} />;
      case "bullet_list":
        return (
          <BulletList
          key={id[0].value}
            data={node}
            renderContentNode={renderContentNode}
            componentMap={componentMap}
            items={items}
          />
        );
      case "media":
      return <Media key={id[0].value} data={items} />;
      case "link":
      return <Link key={id[0].value} data={items} />;
      case "image_link":
      return <ImageLink key={id[0].value} data={items} />;
      case "participants_list": 
        return <ParticipantsList key={id[0].value} data={node}/>
      default:
        return <></>;
    }
  };

  const content = Array.isArray(data)
    ? data.map((node: ParseItem) => renderNode(node))
    : renderNode(data);

  return content;
};
