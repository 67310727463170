import { SearchDefinition } from "../useArichiveQuery";
import { NoDataContainer } from "./styles";

interface Props {}

function NoDataMessage({}: Props) {
  return (
    <NoDataContainer>
      <h3>No search results were found for your current search criteria.</h3>
    </NoDataContainer>
  );
}

export default NoDataMessage;
