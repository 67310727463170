export enum Columns {
  LEFT = 'left_column',
  RIGHT = 'right_column',
}

export enum ColumnedResponseTypes {
  ENTITY = 'entity',
  LABEL = 'label',
  PAGE_CONTENT = 'field_page_content',
  FIELD_PARAGRAPH = 'field_paragraph',
  FIELD_TEXT = 'field_plain_text',
  TYPE = 'target_id',
  FIELD_LINK = 'field_link',
  FIELD_IMAGE = 'field_image',
  URL = 'url',
  VALUE = 'value',
}

export enum ParseTypes {
  ROW = 'row',
  COL = 'col',
  IMAGE = 'image',
  CARD = 'card',
  BULLET_LIST = 'bullet_list',
  FAQ_CATEGORY = 'faq_category',
  FAQ_ITEM = 'faq_item',
  LINK = 'link',
  PARTICIPANTS_LIST = 'participants_list',
  NOTYPE = 'noType',
}

export enum TypographyVariants {
  X_SMALL = 'x-small',
  SMALL = 'small',
  DEFAULT = 'default',
  MEDIUM = 'medium',
  LARGE = 'large',
  X_LARGE = 'x-large',
}

export enum TypographyContent {
  VALUE = 'value',
  VARIANT = 'variant',
}

export enum RowType {
  TYPE = 'type',
  PARAGRAPH = 'paragraph',
  IMAGE = 'image',
}
