import React, { useEffect, useState } from "react";
import { genFetch } from "state/utils/endpoints";
import { authentication } from "state/api/authentication";
import { OK } from "http-status-codes";
import styled, { css } from "styled-components";
import { Redirect, useHistory } from "react-router-dom";
import { MainContainer, Logo, Button } from "view/utils/forms/styles";
import { publicPathToURL } from "state/utils";

export interface IAppProps {
  match: any;
  location: any;
}

const BodyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1600px;
  padding: 15rem 0;
`;
const FormBodyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  padding: 2rem;
  max-width: 500px;
`;

const ConfirmContainer = styled.div`
  padding: 1rem 4rem;
  width: 100%;
  max-width: 500px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 8px;
  }
`;

interface ButtonProps {
  highlighted?: boolean;
}

export const StyledButton = styled.button<ButtonProps>`
  font-family: ${(props) => props.theme.fonts.heading};
  border-radius: 5px;
  padding: 0.9rem 1.1rem;
  border: 1px solid;
  font-weight: 700;
  text-indent: 0px;
  text-shadow: none;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  ${(props) =>
    props.highlighted &&
    css`
      color: ${(props) => props.theme.colors.main};
    `}

  ${(props) =>
    !props.highlighted &&
    css`
      background-color: transparent;
      border: none;
    `}

  :focus {
    outline: none;
  }

  :hover,
  :focus {
    background-color: ${(props) => props.theme.gpwmd.green};
    color: ${(props) => props.theme.colors.secondary};
  }

  :disabled {
    cursor: default;
    background-color: lightgray;
    color: grey;
    border-color: lightgray;
  }

  :active {
    transform: scale(1.02);
  }
`;

const StyledSpan = styled.span`
  display: block;
  margin-bottom: 16px;
`;

const EmailApproval = (props: IAppProps) => {
  const { uuid, status } = props.match.params;
  const [confirm, setConfirm] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (confirm)
      genFetch(authentication.sendApprovals(uuid, status))()().then((res) => {
        switch (res.status) {
          case OK: {
          }
        }
      });
  }, [uuid, status, confirm]);

  return (
    <MainContainer style={{ height: "100%" }}>
      <BodyContainer>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Logo width="350px" src="/images/icons/italy24/gp-g7-stacked.png" />
        </div>
        {!confirm && (
          <ConfirmContainer>
            <h1 style={{ margin: 0 }}>Are you sure?</h1>
            <StyledSpan>
              Please confirm below if you would like to {status}.{" "}
            </StyledSpan>
            <ButtonGroup>
              <StyledButton highlighted onClick={() => setConfirm(true)}>
                Yes
              </StyledButton>
              <StyledButton
                onClick={() => history.push(publicPathToURL("/en/home"))}
              >
                No
              </StyledButton>
            </ButtonGroup>
          </ConfirmContainer>
        )}
        {confirm && (
          <ConfirmContainer>
            <h1>Thank You!</h1>
            <p>Your response has been successfully submitted!</p>
            <ButtonGroup>
              <StyledButton
                highlighted
                onClick={() => history.push("/en/home")}
              >
                Let's go home
              </StyledButton>
            </ButtonGroup>
          </ConfirmContainer>
        )}
      </BodyContainer>
    </MainContainer>
  );
};

export default EmailApproval;
