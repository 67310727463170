import React from "react";
import styled, { css } from "styled-components";
import {
  Row,
  ParagraphBorder,
  ParseItemParent,
} from "state/ducks/columned/types";

interface Props extends ParseItemParent {
  data: Row;
  className?: string;
}

export interface RowProps {
  border?: ParagraphBorder;
  imageContainer?: boolean;
}
const StyledRow = styled.div<RowProps>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  ${(props) => css`
    padding: ${props.imageContainer ? 0 : "2rem"};
    ${props.border &&
    css`
      border-radius: 1px;
      border-right: ${props.border.field_right[0].value
        ? "solid black 1px"
        : "none"};
      border-left: ${props.border.field_left[0].value
        ? "solid black 1px"
        : "none"};
      border-top: ${props.border.field_top[0].value
        ? "solid black 1px"
        : "none"};
      border-bottom: ${props.border.field_bottom[0].value
        ? "solid black 1px"
        : "none"};
    `}
  `}
`;

export const RowNode: React.FC<Props> = (props) => {
  const { data, componentMap, items, renderContentNode, className } = props;
  const { border, images } = data;

  const imageContainer = images.length > 0 ? images[0].value : undefined;
  const hasBorder = border.length > 0 ? border[0] : undefined;

  return (
    <StyledRow
      className={className}
      border={hasBorder}
      imageContainer={imageContainer}
    >
      {renderContentNode(items, componentMap)}
    </StyledRow>
  );
};
