import { useEffect, useMemo, useState } from "react";
import { authentication } from "state/api/authentication";
import { genFetch, withToken } from "state/utils/endpoints";

export interface Request {
  field_country: string;
  field_organization: string;
  field_point_of_contact: string;
  field_responses: RequestResponse[];
  field_comments_file: string;
  field_ukraine_request_file: string;
  field_funding_level?: string;
  field_ukraine_tags: string;
  field_request_id: string;
  nid: string;
  title: string;
}

export interface RequestResponse {
  field_date_submitted: string;
  field_plain_text: string;
  field_user_name: string;
  field_user_organization: string;
  id: string;
}

interface FixedResponse {
  tid: string;
  name: string;
}

export interface AggregatedRequest extends Request {
  fixedResponses: FixedResponse[];
}

const useGeneralUkraineFixedResponses = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fixedResponses, setFixedResponses] = useState<FixedResponse[]>([]);

  useEffect(() => {
    const fetchResponses = async () => {
      setIsLoading(true);
      try {
        const response = await genFetch(
          authentication.getGeneralUkraineRequestFixedResponses()
        )()(withToken());

        if (response.ok) {
          const body = await response.json();

          setFixedResponses(body);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchResponses();
  }, [setFixedResponses]);

  return {
    isLoading,
    fixedResponses,
  };
};

export default useGeneralUkraineFixedResponses;
