import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Table } from "antd";
import { approvalColumns } from "./utils/table";
import { OK } from "http-status-codes";
import { authentication } from "state/api/authentication";
import { getToken } from "state/utils";
import { addNotification } from "state/ducks/notifications/actions";
import { IMessage } from "state/ducks/notifications/types";
import { MainContainer, Button } from "view/utils/forms/styles";

interface IFCProps {
  addNotification: typeof addNotification;
}

const TableOverride = styled(Table)`
  & .max-w-250 {
    max-width: 250px;
    word-break: break-word;
  }
`;

// const actionItems = [
//   {
//     value: "default",
//     text: "-- Select Action --",
//   },
//   {
//     value: "approve",
//     text: "Approve User(s)",
//   },
//   {
//     value: "requestInfo",
//     text: "Request Information",
//   },
// ];

const failedMessage: IMessage = {
  type: "error",
  message: "Request Failed!",
  description: "ught oh.. Something went wrong",
};

const successMessage: IMessage = {
  type: "success",
  message: "Request Sent!",
  description: "",
};

/**
 * User Approvals Page.
 *
 * (1): A User needs to be authenticated with a role of Reviewer.
 * (2): A User needs to able to edit a felid
 * (3): Many Select users for Approval.
 *
 */

export const Approvals: React.FC<IFCProps> = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [content, setContent] = useState<[]>([]);
  const [sending, setSending] = useState<boolean>(false);
  const [approver, setApprover] = useState<boolean>(false);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState("approve"); //eslint-disable-line
  const { addNotification } = props;

  useEffect(() => {
    fetch(authentication.getPendingUsers().route(), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ContentType: "application/json",
        Accept: "application/json",
      },
    }).then((res) => {
      if (res.status === OK) {
        res.json().then((body) => {
          if (!body.content) {
            setContent(body);
          }
        });
      }
    });

    authentication.memberToken().then((res) => {
      if (res.status === OK) {
        res.json().then((body) => {
          const hasPermission = body.roles.some(
            (o: any) => o === "approve"
          );
          setApprover(hasPermission);
        });
      }
    });
  }, []);

  const rowSelection: any = {
    selectedRowKeys,
    onChange: (selectedRows: number[]) => setSelectedRowKeys(selectedRows),
  };

  const handleSave = (record: any, InputText: any) => {
    fetch(authentication.getCSRFToken().route()).then((res) => {
      if (res.status === OK) {
        res.text().then((text) => {
          fetch(authentication.patchUserComment().route(record.uid), {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${getToken()}`,
              ContentType: "application/json",
              Accept: "application/json",
              "X-CSRF-TOKEN": text,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              field_approval_comments: [
                {
                  value: InputText,
                },
              ],
            }),
          }).then((res2) => {
            if (res2.status === OK) {
              res2.json().then((body) => {
                const contentCopy = JSON.parse(JSON.stringify(content));
                const userIndex = contentCopy.findIndex(
                  (item: any) => item.email === body.mail[0].value
                );
                contentCopy[userIndex].field_approval_comments =
                  body.field_approval_comments[0].value;
                setContent(contentCopy);
              });
            }
          });
        });
      }
    });
  };

  const editableColumns = approvalColumns.map((column: any) => {
    if (column.editable) {
      return {
        ...column,
        onCell: (record: any) => ({
          record,
          editable: true,
          dataIndex: "field_approval_comments",
          title: "Approval Comments",
          handleSave: handleSave,
        }),
      };
    }
    return column;
  });

  const approveAction = (text: any) => {
    return fetch(authentication.sendUserApprovals().route(), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ContentType: "application/json",
        Accept: "application/json",
        "X-CSRF-TOKEN": text,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedRowKeys),
    }).then((res2) => {
      if (res2.status === OK) {
        res2.json().then((body) => {
          // not going crazy because this isn't the final process.
          setSelectedRowKeys([]);
          addNotification(successMessage);
          fetch(authentication.getPendingUsers().route(), {
            method: "GET",
            headers: {
              Authorization: `Bearer ${getToken()}`,
              ContentType: "application/json",
              Accept: "application/json",
            },
          }).then((res) => {
            if (res.status === OK) {
              res.json().then((body) => {
                if (!body.content) {
                  setContent(body);
                } else {
                  setContent([]);
                }
                setSending(false);
              });
            }
          });
        });
      } else {
        failNReset();
      }
    });
  };

  const requestMoreInformationAction = (text: any) => {
    return fetch(authentication.requestUserMoreInformation().route(), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ContentType: "application/json",
        Accept: "application/json",
        "X-CSRF-TOKEN": text,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedRowKeys),
    }).then((res2) => {
      if (res2.status === OK) {
        res2.json().then((body) => {
          // not going crazy because this isn't the final process.
          setSelectedRowKeys([]);
          addNotification(successMessage);
          fetch(authentication.getPendingUsers().route(), {
            method: "GET",
            headers: {
              Authorization: `Bearer ${getToken()}`,
              ContentType: "application/json",
              Accept: "application/json",
            },
          }).then((res) => {
            if (res.status === OK) {
              res.json().then((body) => {
                if (!body.content) {
                  setContent(body);
                } else {
                  setContent([]);
                }
                setSending(false);
              });
            }
          });
        });
      } else {
        failNReset();
      }
    });
  };

  const sendAction = (text: any) => {
    switch (selectedDropdownItem) {
      case "approve":
        return approveAction(text);
      case "requestInfo":
        return requestMoreInformationAction(text);
      default:
        return Promise.reject();
    }
  };

  const handleApprove = () => {
    if (!sending) {
      setSending(true);
      fetch(authentication.getCSRFToken().route())
        .then((res) => {
          if (res.status === OK) {
            res.text().then((text) => {
              sendAction(text).catch(() => failNReset());
            });
          }
        })
        .catch(() => failNReset());
    }
  };

  const failNReset = () => {
    addNotification(failedMessage);
    setSending(false);
    setSelectedRowKeys([]);
  };

  return (
    <MainContainer style={{ height: "100%" }}>
      <div style={{ padding: "2rem 0", width: "100%", maxWidth: "1600px" }}>
        <TableOverride
          rowSelection={rowSelection}
          columns={editableColumns}
          dataSource={content}
          pagination={false}
          rowKey={(record: any) => record.email} // TODO: Juan Change me if this does not work :)
        />
        {approver && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "1rem 0",
            }}
          >
            <Button
              style={{ maxWidth: "250px" }}
              disabled={
                selectedRowKeys.length === 0 ||
                selectedDropdownItem === "default"
              }
              onClick={() => handleApprove()}
            >
              {sending ? "Submitting" : "Approve"}
            </Button>
          </div>
        )}
      </div>
    </MainContainer>
  );
};

export default Approvals;
