import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IApplicationState } from "state/ducks";
import {
  notificationActions,
  notificationTypes,
} from "state/ducks/notifications";
import Notify from "./notifications";

const mapStateToProps = (state: IApplicationState) => ({
  messages: state.notifications.messages,
});

type actions = notificationTypes.NotificationActions;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators(
    {
      removeNotification: notificationActions.removeNotification,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notify);
