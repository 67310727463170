import { Badge, BadgeProps, Form, Input, List, ListProps, Tag } from "antd";
import { RequestResponse } from "lib/hooks/useUkraineRequests";
import styled, { css } from "styled-components";

interface ActionContainerProps {
  isOpen: boolean;
}
export const RequestContainer = styled.div<ActionContainerProps>`
  height: auto;
  display: flex;
  flex-wrap: wrap;
`;

export const FormHref = styled.a`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 400;
  color: ${(props) => props.theme.colors.main};
  font-size: 1.15rem;
  max-width: 1000px;
  padding-right: 1rem;
  :hover,
  :focus,
  :active {
    color: ${(props) => props.theme.gpwmd.red} !important;
  }
`;

export const FormOpenHref = styled.a`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  color: ${(props) => props.theme.gpwmd.red} !important;

  font-size: 1rem;
  max-width: 1000px;
  padding-right: 1rem;
  :hover,
  :focus,
  :active {
    color: ${(props) => props.theme.gpwmd.grey};
  }
`;

export const StyledButton = styled.button`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 400;
  color: ${(props) => props.theme.colors.main};
  font-size: 1rem;
  height: 32px;
  min-width: 100px;
  background-color: transparent;
  border: 1px solid black;
  :hover,
  :focus,
  :active {
    color: ${(props) => props.theme.gpwmd.darkTan} !important;
    border-color: ${(props) => props.theme.gpwmd.green} !important;
    outline-color: ${(props) => props.theme.gpwmd.green} !important;
  }
`;

export const RowId = styled.p`
  font-size: 1rem;
  font-weight: 800;
  padding: 0 1rem;
  margin: 0;
`;

export const RowHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 0.5rem 0;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-wrap: unset;
    justify-content: space-between;
  }
`;

export const RowBody = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem 1rem;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    padding: 0;
  }
`;

export const TitleText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 500;
  color: ${(props) => props.theme.colors.main};
  font-size: 0.95rem;
  width: 100%;
  margin: 0;
`;

export const ResponseText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  color: ${(props) => props.theme.colors.main};
  font-size: 1rem;
  width: 100%;
  margin: 0;
`;

interface FundingProps {
  fundingLevel: string;
}
export const FundingTag = styled(Tag)<FundingProps>`
  font-weight: 600;
  ${(props) => {
    const level = props.fundingLevel;
    switch (level) {
      case "Fully Funded":
        return css`
          color: #36b37e;
          /* background-color: #36B37E; */
          border-color: #36b37e;
        `;
      case "Partially Funded":
        return css`
          color: #ffab00;
          /* background-color: #ffab00; */
          border-color: #ffab00;
        `;
      case "Not Funded":
        return css`
          color: ${(props) => props.theme.gpwmd.red};
          /* background-color: ${props.theme.gpwmd.red}; */
          border-color: ${props.theme.gpwmd.red};
        `;
      default:
        return css`
          color: ${(props) => props.theme.gpwmd.grey};
          /* background-color: ${props.theme.gpwmd.grey}; */
          border-color: ${props.theme.gpwmd.grey};
        `;
    }
  }}
`;

export const CommentHeader = styled.div`
  min-height: 50px;
  background: #e1e1e1;
  margin-top: 1rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border-radius: 1px;
`;

export const CommentHeaderText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 500;
  color: ${(props) => props.theme.colors.main};
  font-size: 1.5rem;
  width: 100%;
  margin: 0;
`;

export const TextArea = styled(Input.TextArea)`
  min-height: 350px !important;

  @media (max-width: 1000px) {
    min-height: 150px !important;
  }
`;
export const StyledForm = styled(Form)`
  max-width: 350px;
  width: 100%;
  @media (max-width: 1000px) {
    max-width: unset;
    padding: 1rem 2rem;
  }
`;

export const StyledList = styled(List)<ListProps<RequestResponse>>`
  overflow: auto;
  max-height: 600px;
  padding: 0 1rem 0 0.25rem;
  @media (max-width: 1000px) {
    overflow: unset;
    max-height: unset;
  }
`;

export const StyledButtonHolder = styled.div`
  margin: 1rem 3rem 0 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media (min-width: 768px) {
    width: unset;
    margin: 0;
  }
`;

export const StyledBadge = styled(Badge)<BadgeProps>`
  .ant-badge-count {
    background: ${(props) => props.theme.gpwmd.green};
  }
`;

export const StyledTable = styled.table`
  width: auto !important;
  margin-bottom: 1rem;
  & th {
    font-size: 0.9rem;
    text-align: right;
    vertical-align: top;
  }
  & td {
    font-size: 0.9rem;
    padding-left: 1rem;
    text-align: left;
    vertical-align: top;
    height: max-content;
  }
`;
export const TextDivArea = styled(TextArea)`
  border: 1px solid #d9d9d9;
`;
