import { useEffect, useState } from "react";
import { search } from "state/api/search";
import { genFetch, withToken } from "state/utils/endpoints";
import NoDataMessage from "view/Archive/utils/NoDataMessage";
import { SearchDefinition } from "view/Archive/utils/useArichiveQuery";
import { discussionBoardColumns } from "view/discussionBoard/util/table";
import { StyledTable, DiscussionContainer } from "./styles";

interface Props {
  query: SearchDefinition;
}

function DiscussionBoard(props: Props) {
  const { query } = props;
  const [data, setData] = useState([]);
  useEffect(() => {
    genFetch(search.archiveDiscussionBoards(query))()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) =>
            data && data.length > 0 ? setData(data) : setData([])
          )
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [query, setData]);

  return (
    <DiscussionContainer
      style={{ display: `${query.plenary ? "none" : "block"}` }}
    >
      <h1>Discussion Board</h1>
      {data.length > 0 ? (
        <StyledTable
          rowKey={(record: any) => record?.title[0].value}
          columns={discussionBoardColumns}
          dataSource={data}
        />
      ) : (
        <NoDataMessage />
      )}
    </DiscussionContainer>
  );
}

export default DiscussionBoard;
