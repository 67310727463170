import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';

export interface ClickParam {
  key: string;
  keyPath: Array<string>;
  item: any;
  domEvent: any;
}

export type BarItemSide = 'left' | 'right';
export type BarItemSelectedSize = 'thin' | 'regular' | 'thick';

/**
 * Menu Properties
 */
export interface IAppItemProps {
  className?: any;
  /**
   * Allows you to add Menu Items
   * @param {React.ReactNode}
   * @default null
   */
  children?: any;

  /** Allows onClick Handling */
  // onClick?: (param: ClickParam) => void;

  /**
   * Adds Selected Menu Animation
   * @param {boolean}
   * @default false
   */
  active?: boolean;

  /**
   * Adds Selected Menu Animation
   * @param {string} left
   * @param {string} right
   * @default false
   */
  side?: BarItemSide;

  /**
   * React Key
   */
  react_key?: string;

  /**
   * Determines Selected Color
   */
  barColor?: string;

  /**
   * Determines the selected Bar Size
   * @param {string} thin
   * @param {string} regular
   * @param {string} thick
   * @default regular
   */
  barSize?: BarItemSelectedSize;

  id?: string;

  onClick?: any;
}

const SelectedItemDetail = {
  thin: '1px',
  regular: '2px',
  thick: '4px',
};

const StyledBarItem = styled.div`
  height: 100%;
  margin: 0 18px;
  display: flex;
  width: auto;

  align-items: center;
  position: relative;
`;

const leftToRight = keyframes`
  from { width: 0%;}
  to { width:100%;}
`;

interface StyledWrapperProps {
  active?: boolean;
  barSize?: BarItemSelectedSize;
  barColor?: string;
}

const StyledWrapper = styled.div<StyledWrapperProps>`
  display: none;
  ${props =>
    props.active &&
    css`
      position: absolute;
      left: 0;
      transform: translate(-7%);
      transition-duration: 2s ease-in;
      transition: all;
      width: calc(100% + 20%);
      animation: ${leftToRight} 0.2s linear;
      padding: 1rem 0;

      @media (min-width: 1024px) {
        padding: 1.25rem 0;
      }
      @media (min-width: 1440px) {
        padding: 1.5rem 0;
      }
    `}

  ${props =>
    props.barSize &&
    props.barColor &&
    css`
      border-bottom: ${SelectedItemDetail[props.barSize]} solid ${props.barColor};
    `}
`;

function BarItem(props: IAppItemProps) {
  const { barColor, barSize, className, children, active, side, react_key, id, onClick } = props;
  return (
    <StyledBarItem id={id} className={`${className}`} onClick={onClick} key={react_key}>
      {children}
      <StyledWrapper barColor={barColor} barSize={barSize} active={active} />
    </StyledBarItem>
  );
}

export default BarItem;
