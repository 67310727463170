import { List } from "antd";
import styled from "styled-components";

export const CoChairContainer = styled.div`
  padding: 0 3rem;
  width: 100%;
  margin-bottom: 36px;
`;

export const ResourceLink = styled.a`
  display: block;
  font-style: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.gpwmd.slate};
  font-weight: 300;
  text-decoration: none;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: block;
  border-bottom: solid 1px ${(props) => props.theme.gpwmd.grey};

  :hover {
    color: ${(props) => props.theme.gpwmd.green};
  }
`;

export const StyledList = styled(List)`
  .ant-list-item {
    padding: 0;
  }

  &.ant-list-vertical .ant-list-item-meta-title {
    margin-bottom: 0;
  }
  h4 p {
    margin: 0;
  }
`;
