import { Breadcrumb, Checkbox, Radio } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { authentication } from "state/api/authentication";
import { Vocabulary } from "state/ducks/vocabulary/types";
import { publicPathToURL } from "state/utils";
import { genFetch, withToken } from "state/utils/endpoints";
import { Banner, Overlay } from "view/common/Banner";
import ArchiveGroupToggle from "./Contents/ArchiveGroupToggle";
import { BodyContainer, LeftColumn } from "view/common/Layout";
import { Logo, MainContainer } from "view/utils/forms/styles";
import CoChairs from "./Contents/Chairs/CoChairs";
import DiscussionBoard from "./Contents/DiscussionBoard/DiscussionBoard";
import Resources from "./Contents/Documents/Resources";
import MemberPages from "./Contents/MemberPages/MemberPages";
import View from "./Contents/MemberPages/View";
import Webinars from "./Contents/Webinars/Webinars";
import Proposal from "./Contents/Proposals/Proposals";
import ProtectedContent from "./utils/ProtectedContent";
import {
  OptionTitle,
  StyledRadio,
  RightColumn,
  TwoColumnLayout,
} from "./styles";
import { archiveQueryBuilder, useArchiveQuery } from "./utils/useArichiveQuery";
interface Props {}

interface ArchiveVocabulary extends Vocabulary {
  value: string;
  label: string;
}

/**
 * Function - To Provide filters to children components.
 */
function Archive(props: Props) {
  const [contentTypes, setContentTypes] = useState<ArchiveVocabulary[]>([]);
  const [contentYear, setContentYear] = useState<ArchiveVocabulary[]>([]);
  const [presidency, setPresidency] = useState<ArchiveVocabulary[]>([]);
  const [viewName, setViewName] = useState<string[]>([]);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    genFetch(authentication.getVocabularyByMachineName("content_type"))()(
      withToken()
    )
      .then((res) =>
        res
          .json()
          .then((data: Vocabulary[]) => {
            setContentTypes(
              data && data.length > 0
                ? data.map((item) => ({
                    ...item,
                    value: item.tid,
                    label: item.name,
                  }))
                : []
            );
          })
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
    genFetch(authentication.getVocabularyByMachineName("content_year"))()(
      withToken()
    )
      .then((res) =>
        res
          .json()
          .then((data: Vocabulary[]) => {
            setContentYear(
              data && data.length > 0
                ? data
                    .map((item) => ({
                      ...item,
                      value: item.tid,
                      label: item.name,
                    }))
                    .sort((a, b) => Number(a.weight) - Number(b.weight))
                : []
            );
          })
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
    genFetch(authentication.getVocabularyByMachineName("presidency"))()(
      withToken()
    )
      .then((res) =>
        res
          .json()
          .then((data: Vocabulary[]) => {
            setPresidency(
              data && data.length > 0
                ? data.map((item) => ({
                    ...item,
                    value: item.tid,
                    label: item.name,
                  }))
                : []
            );
          })
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [setContentTypes, setContentYear, setPresidency]);

  const [querystring, query] = useArchiveQuery(location.search);

  return (
    <MainContainer>
      <BodyContainer>
        <Banner>
          <Logo
            maxWidth="1600px"
            src="/images/banners/past-presidencies-1600x400.jpg"
          />
          <Overlay>
            <div>
              <h1>Past Presidencies</h1>
            </div>
          </Overlay>
        </Banner>
        <ArchiveGroupToggle all allOption="PLENARY" query={query} />
        <TwoColumnLayout>
          <LeftColumn
            style={{
              float: "right",
              backgroundColor: "transparent",
              padding: "1rem 0",
            }}
          >
            <Breadcrumb>
              <Link
                to={archiveQueryBuilder({
                  ...query,
                  view: undefined,
                  alias: undefined,
                })}
              >
                <Breadcrumb.Item>Past Presidencies</Breadcrumb.Item>
              </Link>
              {query.view &&
                viewName.length > 0 &&
                viewName.map((item) => (
                  <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
                ))}
            </Breadcrumb>
          </LeftColumn>
        </TwoColumnLayout>
        <TwoColumnLayout>
          <RightColumn>
            <div>
              <OptionTitle>
                <strong>Presidency</strong>
              </OptionTitle>
              {contentYear.map((item) => (
                <StyledRadio
                  key={item.tid}
                  checked={item.tid === query.contentYear}
                  value={item.tid}
                  onClick={(e: any) =>
                    history.push(
                      archiveQueryBuilder({
                        ...query,
                        contentYear:
                          query.contentYear === e.target.value
                            ? undefined
                            : e.target.value,
                      })
                    )
                  }
                >
                  {item.name}
                </StyledRadio>
              ))}
            </div>
          </RightColumn>
          <LeftColumn
            style={{
              float: "right",
              padding: "2rem",
              display: `${!query.view ? "none" : "inline-block"}`,
            }}
          >
            {query.view && <View node={query.view} alias={query.alias} />}
          </LeftColumn>

          <LeftColumn
            style={{
              float: "right",
              padding: "2rem  0",
              display: `${query.view ? "none" : "inline-block"}`,
            }}
          >
            <Webinars query={query} setViewName={setViewName} />
            <MemberPages query={query} setViewName={setViewName} />
            <ProtectedContent>
              <Proposal query={query} />
            </ProtectedContent>
            <Resources query={query} />
            <CoChairs query={query} />
            <DiscussionBoard query={query} />
          </LeftColumn>
        </TwoColumnLayout>
      </BodyContainer>
    </MainContainer>
  );
}

export default Archive;
