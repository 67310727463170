import { OK } from "http-status-codes";
import { useState } from "react";
import { authentication } from "state/api/authentication";
import { genFetch, withToken } from "state/utils/endpoints";

type FileDownloadState = "NOT_STARTED" | "STARTED" | "COMPLETED" | "FAILURE";

export function useFileDownload(): [
  (url: string, name: string, extension: string) => any,
  boolean,
  FileDownloadState
] {
  const [isLoading, setIsLoading] = useState(false);
  const [downloadState, setDownloadState] = useState<FileDownloadState>(
    "NOT_STARTED"
  );

  const action = async (url: string, name: string, extension: string) => {
    setIsLoading(true);
    setDownloadState("STARTED");
    try {
      const response = await genFetch(authentication.getFileDownload(url))()(
        withToken()
      );
      if (response.status === OK) {
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);
        setDownloadState("COMPLETED");
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = url;
        a.download = `${name}.${extension}`;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        setDownloadState("FAILURE");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setDownloadState("FAILURE");
    }
  };

  const download = (url: string, name: string, extension: string) =>
    action(url, name, extension);

  return [download, isLoading, downloadState];
}
