import { GenericTypes } from "state/utils";
import { IAction } from "state/utils/redux/types";

/**
 * Layout's Typed Reducer State
 */
export interface IEventsState {
  [key: string]: IEventSearchStateObject;
}

export interface IEventSearchStateObject {
  label: string;
  pager: IPagerObject;
  pages: {
    [key: number]: IEventsMessageObject[];
  };
}

export interface IEventQueryMessage {
  rows: IEventsMessageObject[];
  pager: IPagerObject;
}

export interface IEventsMessageObject {
  title: string;
  nid: string;
  field_banner_image: string;
  field_caption: string;
  field_location: string;
  field_learn_more: string;
  field_date_range: string;
  field_sub_working_group: string;
  field_event_type: string;
  field_country: string;
  field_event_files: string;
}
export interface IPagerObject {
  current_page: number;
  total_items: number;
  total_pages: number;
  items_per_page: number;
}

export const eventSearchActionTypes = {
  EVENTS_SEARCH: "EVENTS_SEARCH",
  EVENTS_NEXT_PAGE: "EVENTS_NEXT_PAGE",
  EVENTS_SEARCH_SUCCESS: "EVENTS_SEARCH_SUCCESS",
  EVENTS_SEARCH_FAILURE: "EVENTS_SEARCH_FAILURE",
};

/**
 * User Event for Specifying Query Events
 */
export interface IEventsSearchPayload {
  query: string;
}

export interface IQueryPayloadAction extends GenericTypes.IAction {
  payload: IEventsSearchPayload;
}

/**
 *  Action for updating Redux
 */
export interface IQueryResultsPayload {
  query: string;
  results: IEventQueryMessage;
}
export interface IQueryResultsAction extends GenericTypes.IAction {
  payload: IQueryResultsPayload;
}

/**
 * Action for a User Event to change the current page.
 */
export interface ISearchChangePagePayload {
  query: string;
  page: number;
}
export interface IQueryChangeSearchPageAction extends GenericTypes.IAction {
  payload: ISearchChangePagePayload;
}

export type EventSearchActionsInterfaces =
  | IAction
  | IQueryPayloadAction
  | IQueryResultsAction;

export default eventSearchActionTypes;
