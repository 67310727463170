import React from "react";
import { publicPathToURL } from "state/utils";
import { createSelector } from "reselect";
import { IApplicationState } from "state/ducks";
import { useSelector } from "react-redux";
import { Main, SideLink } from "./styles";
import { Skeleton } from "antd";

const getMenuState = () =>
  createSelector(
    (state: IApplicationState) => ({
      subWorkingGroupsMenu: state.vocabulary.subWorkingGroupsMenu,
    }),
    (state) => {
      const { subWorkingGroupsMenu } = state;
      return {
        subWorkingGroupsMenu,
      };
    }
  );

function ColumnMenu() {
  const { subWorkingGroupsMenu } = useSelector(getMenuState());

  return (
    <Main>
      {subWorkingGroupsMenu.length === 0 &&
        [1, 2, 3, 4, 5, 6].map((item) => (
          <div key={item} style={{ width: "100%" }}>
            <Skeleton key={item} active />
          </div>
        ))}
      {subWorkingGroupsMenu.map((item) => (
        <>
          <SideLink isPrimary to={publicPathToURL(item.data_url)}>
            {item.title}
          </SideLink>
          {item.children?.map((child) => (
            <SideLink to={publicPathToURL(child.data_url)}>
              {child.title}
            </SideLink>
          ))}
        </>
      ))}
    </Main>
  );
}

export default ColumnMenu;
