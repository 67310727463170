import { Reducer } from 'redux';
import connectActionTypes, {
  ConnectActions,
  IConnectState,
  IMemberAccountInfoObject,
} from './types';
import { hasToken } from 'state/utils';

export const initialState: IConnectState = {
  loaded: false,
  sending: false,
  failure: false,
  loginHappened: false,
  authenticated: hasToken(),
  member: {},
  public: !hasToken(),
  initialized: false,
  memberUpdated: false,
  memberSending: false,
  memberFailure: false,
  memberUpdateFailure: false,
  memberRequestSuccess: false,
  memberMessage: '',
  maintenance: false,
  maintenanceMessage: '',
};

const reducer: Reducer<IConnectState> = (state: IConnectState = initialState, action) => {
  const stateCopy: IConnectState = JSON.parse(JSON.stringify(state));
  switch ((action as ConnectActions).type) {
    case connectActionTypes.AUTHENTICATE_SENDING_REQUEST: {
      return { ...stateCopy, sending: true };
    }
    case connectActionTypes.AUTHENTICATE_RECEIVED_RESPONSE: {
      return { ...stateCopy, sending: false };
    }
    case connectActionTypes.UPDATE_MEMBER_INFO_REQUEST: {
      return { ...stateCopy, sending: true };
    }
    case connectActionTypes.AUTHENTICATE_SUCCESS: {
      const { isPublic } = action.payload;
      return {
        ...stateCopy,
        authenticated: true,
        failure: false,
        public: isPublic,
        loginHappened: true,
      };
    }
    case connectActionTypes.ACKNOWLEDGE_WARNING: {
      return { ...stateCopy, loginHappened: false };
    }
    case connectActionTypes.AUTHENTICATE_FAILURE: {
      return { ...stateCopy, authenticated: false, failure: true };
    }
    case connectActionTypes.GET_MEMBER_INFO_SUCCESS: {
      const member: IMemberAccountInfoObject = action.payload;
      return { ...stateCopy, member: member };
    }
    case connectActionTypes.BECOME_A_MEMBER_SENDING_REQUEST: {
      return { ...stateCopy, memberSending: true };
    }
    case connectActionTypes.BECOME_A_MEMBER_RECEIVED_RESPONSE: {
      return { ...stateCopy, memberSending: false };
    }
    case connectActionTypes.BECOME_A_MEMBER_SUCCESS: {
      return {
        ...stateCopy,
        memberFailure: false,
        memberRequestSuccess: true,
        memberMessage: 'Your request has been sent.',
      };
    }
    case connectActionTypes.UPDATE_MEMBER_FAILURE: {
      return {
        ...stateCopy,
        memberUpdateFailure: true,
        memberMessage:
          'Unable to update account. Please confirm your current password is correct and try again.',
      };
    }
    case connectActionTypes.UPDATE_MEMBER_SUCCESS: {
      return {
        ...stateCopy,
        memberUpdated: true,
        memberMessage: 'Account has been updated.',
      };
    }
    case connectActionTypes.GET_MEMBER_INFO_FAILURE: {
      return {
        ...stateCopy,
        memberFailure: true,
        memberMessage: 'Unable to retrieve account. Please try again.',
      };
    }
    case connectActionTypes.BECOME_A_MEMBER_FAILURE: {
      return {
        ...stateCopy,
        memberRequestSuccess: false,
        memberFailure: true,
        memberMessage: 'Oops. Something went wrong. Please Try Again.',
      };
    }
    case connectActionTypes.BECOME_A_MEMBER_RESET: {
      return {
        ...stateCopy,
        memberSending: false,
        memberFailure: false,
        memberRequestSuccess: false,
        memberMessage: '',
      };
    }

    case connectActionTypes.VERIFY_AUTHENTICATION_SUCCESS: {
      const { isVerified } = action.payload;
      return {
        ...stateCopy,
        authenticated: isVerified,
        public: isVerified ? false : true,
      };
    }

    case connectActionTypes.INITIALIZE_APPLICATION_SUCCESS: {
      return {
        ...stateCopy,
        initialized: true,
      };
    }

    case connectActionTypes.LOGOUT_SUCCESS: {
      const { isPublic } = action.payload;
      return {
        ...stateCopy,
        public: isPublic,
        authenticated: false,
      };
    }

    case connectActionTypes.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...stateCopy,
        memberSending: false,
      };
    }

    case connectActionTypes.FORGOT_PASSWORD_FAILURE: {
      return {
        ...stateCopy,
        memberSending: false,
      };
    }

    case connectActionTypes.FORGOT_PASSWORD: {
      return {
        ...stateCopy,
        memberSending: false,
      };
    }

    case connectActionTypes.MAINTENANCE_BANNER_RESPONSE: {
      const { maintenance, message } = action.payload;

      return {
        ...stateCopy,
        maintenance,
        maintenanceMessage: message,
      };
    }

    default:
      return stateCopy;
  }
};

export default reducer;
