import { Redirect, useLocation, useParams } from "react-router-dom";
import { useFileDownload } from "view/utils/hooks/useFileDownload";
import { MainContainer, FormContainer, Logo } from "view/utils/forms/styles";
import { useEffect, useState } from "react";
import { publicPathToURL } from "state/utils";

const queryString = require("query-string");

interface Props {}

const fileExtensionRegex = /[^\\]*\.(\w+)$/;

const AccessProtectedFile = (props: Props) => {
  const location = useLocation();
  const [download, isLoading, state] = useFileDownload(); //eslint-disable-line
  const [hasError, setHasError] = useState(false);

  // const search = queryString.parse(location.search);
  const { route } = useParams<any>();

  if (state === "NOT_STARTED") {
    if (route) {
      try {
        const url = atob(route);
        const total = url.toLowerCase().match(fileExtensionRegex);
        let extension = "";
        const paths = url.split("/");
        if (paths.length > 0) {
          const file = paths[paths.length - 1].split(".");
          if (file.length > 0) {
            let fileName = decodeURI(file[0]);
            if (total) {
              extension = total[1];
            }
            download(url, fileName, extension);
          }
        }
      } catch (error) {
        if (!hasError) setHasError(true);
      }
    } else {
      if (!hasError) setHasError(true);
    }
  }

  const [[hrs, mins, secs], setTime] = useState([0, 0, 5]);
  const reset = () => setTime([parseInt("0"), parseInt("0"), parseInt("60")]);

  const tick = () => {
    if (hrs === 0 && mins === 0 && secs === 0) reset();
    else if (mins === 0 && secs === 0) {
      setTime([hrs - 1, 59, 59]);
    } else if (secs === 0) {
      setTime([hrs, mins - 1, 59]);
    } else {
      setTime([hrs, mins, secs - 1]);
    }
  };

  useEffect(() => {
    let timerId: any = null;
    if (state === "COMPLETED") {
      timerId = setInterval(() => tick(), 1000);
    }
    return () => timerId && clearInterval(timerId);
  }, [state, setTime, tick]);

  return (
    <MainContainer
      style={{ height: "100%", background: "white", paddingTop: "6rem" }}
    >
      <div style={{ padding: "1rem" }}>
        <Logo maxWidth="300px" src="/images/icons/italy24/gp-g7-stacked.png" />
        <FormContainer>
          <div>
            {state === "COMPLETED" && (
              <div>
                <h1>Download Complete</h1>
                <h3
                  style={{ textAlign: "center" }}
                >{`You'll be redirected in ${secs}.`}</h3>
                {secs === 0 && <Redirect to={publicPathToURL("/en/home")} />}
              </div>
            )}
            {state === "STARTED" && (
              <div>
                <h1>Download in progress</h1>
              </div>
            )}
            {(state === "FAILURE" || hasError) && (
              <div>
                <h1>Download cannot be completed</h1>
              </div>
            )}
          </div>
        </FormContainer>
      </div>
    </MainContainer>
  );
};

export default AccessProtectedFile;
