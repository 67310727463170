import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import {
  columned as getColumnedData,
  columnedByNode,
} from "state/ducks/columned/actions";
import { ParsedLayout } from "view/parsed-layout/parsedLayout";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "state/ducks";

export interface IAppProps {
  node?: string;
  alias?: 1;
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ContainerTwo = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

// TODO Add loading fill
export default function ViewMemberPage(props: IAppProps) {
  const { node, alias } = props;

  const dispatch = useDispatch();
  const { columned } = useSelector((state: IApplicationState) => ({
    columned: state.columned,
    languages: state.languages,
  }));
  const columnedNodeAction = useCallback(
    (path: string) => {
      dispatch(columnedByNode(path));
    },
    [dispatch]
  );

  const columnedAliasAction = useCallback(
    (path: string) => {
      dispatch(getColumnedData(path));
    },
    [dispatch]
  );

  useEffect(() => {
    if (node && !alias) {
      columnedNodeAction(node);
    } else if (node && alias) {
      columnedAliasAction(node);
    }
  }, [columnedNodeAction, columnedAliasAction, node, alias]);

  const data = columned[`en/${node}`];

  return (
    <Container>
      <ContainerTwo>{data && <ParsedLayout data={data.value} />}</ContainerTwo>
    </Container>
  );
}
