import { IRequest } from "state/utils/endpoints";
import { SearchDefinition } from "view/Archive/utils/useArichiveQuery";

// https://test-ors-admin.pnl.gov:30443/web/tag-search?search_api_fulltext=to+develop+and+enhance+national
export const search = {
  for: (args: string): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/tag-search${args}`,
    options: {
      method: "GET",
    },
  }),
  archiveEvents: (query: SearchDefinition): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member/webinar-archive?_format=json${
        query.presidency ? `&field_presidency=${query.presidency}` : ""
      }${query.contentYear ? `&field_content_year=${query.contentYear}` : ""}${
        query.group ? `&sub_working_group=${query.group}` : ""
      }${query.plenary ? `&field_is_plenary=1` : ""}`,
    options: {
      method: "GET",
    },
  }),
  archiveDocuments: (query: SearchDefinition): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member/resource-document-archive/?_format=json${
        query.presidency ? `&field_presidency=${query.presidency}` : ""
      }${query.contentYear ? `&field_content_year=${query.contentYear}` : ""}${
        query.group ? `&sub_working_group=${query.group}` : ""
      }${query.plenary ? `&field_is_plenary=1` : ""}`,
    options: {
      method: "GET",
    },
  }),
  archiveProposals: (query: SearchDefinition): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member-state/proposals-archive/?_format=json${
        query.presidency ? `&field_presidency=${query.presidency}` : ""
      }${query.contentYear ? `&field_content_year=${query.contentYear}` : ""}${
        query.group ? `&sub_working_group=${query.group}` : ""
      }${query.plenary ? `&field_is_plenary=1` : ""}`,
    options: {
      method: "GET",
    },
  }),
  archiveMemberPages: (query: SearchDefinition): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member/content-page-archive?_format=json${
        query.presidency ? `&field_presidency=${query.presidency}` : ""
      }${query.contentYear ? `&field_content_year=${query.contentYear}` : ""}${
        query.group ? `&sub_working_group=${query.group}` : ""
      }${query.contentType ? `&field_content_type=${query.contentType}` : ""}`,
    options: {
      method: "GET",
    },
  }),
  archiveCoChair: (query: SearchDefinition): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member/co-chair-archive?_format=json${
        query.presidency ? `&field_presidency=${query.presidency}` : ""
      }${query.contentYear ? `&field_content_year=${query.contentYear}` : ""}${
        query.group ? `&sub_working_group=${query.group}` : ""
      }`,
    options: {
      method: "GET",
    },
  }),
  archiveDiscussionBoards: (query: SearchDefinition): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member/discussion-archive?_format=json${
        query.presidency ? `&field_presidency=${query.presidency}` : ""
      }${query.contentYear ? `&field_content_year=${query.contentYear}` : ""}${
        query.group ? `&sub_working_group=${query.group}` : ""
      }`,
    options: {
      method: "GET",
    },
  }),
};
