import { publicPathToURL } from "state/utils";
import styled, { css } from "styled-components";

export const SearchBar = styled.input`
  width: 100%;
  height: 50px;
  padding: 0 8px;
  background-color: #ffffff;
  color: #000;
  font-family: ${(props) => props.theme.fonts.main};
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: solid 1px ${(props) => props.theme.gpwmd.grey};

  :active,
  :focus {
    border-color: transparent;
    outline: none;
  }
`;

export const SearchClearButton = styled.button`
  border-radius: 16px;

  border: none;

  height: 32px;
  width: 32px;

  position: absolute;
  top: 0;

  right: 0px;
  z-index: 2;
  border: none;
  top: calc(50% - 16px);
  height: 30px;
  color: black;
  transform: translateX(-60px);

  :hover,
  :active,
  :focus {
    outline: none;
    box-shadow: 0 0 0 3px ${(props) => props.theme.gpwmd.green};
  }
`;

export const SearchButton = styled.input`
  border-radius: 4px;
  text-indent: -1000px;

  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 50px;
  width: 50px;
  border: none;
  background-color: ${(props) => props.theme.gpwmd.darkTan};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 100px;
  background-image: url(${publicPathToURL("/images/icons/search.svg")});

  :hover {
    background-color: ${(props) => props.theme.gpwmd.tan};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 100px;
    background-image: url(${publicPathToURL("/images/icons/search.svg")});
  }

  :active,
  :focus {
    outline: none;
    box-shadow: 0 0 0 3px ${(props) => props.theme.gpwmd.green};
  }
`;

interface SearchContainerProps {
  isActive: boolean;
}

export const SearchContainer = styled.form<SearchContainerProps>`
  height: 50px;
  display: flex;
  white-space: nowrap;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;

  ${(props) =>
    props.isActive &&
    css`
      box-shadow: 0 0 0 3px ${(props) => props.theme.gpwmd.darkTan};
    `}
`;

export const Search = {
  Form: SearchContainer,
  Button: SearchButton,
  Input: SearchBar,
};
