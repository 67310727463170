const APP_TOKEN = 'GPDMD_PORTAL';

/**
 * Looks for signed token in local storage of the browser.
 *
 * @return {boolean} returns true if there is a token in local storage
 *  or returns false if there is no a token in local storage.
 */
export const hasToken = (): boolean => {
  const token = localStorage.getItem(APP_TOKEN);
  return !token || token === '' ? false : true;
};

/**
 * Grabs the signed token in local storage of the browser.
 *
 * @returns {string} returns either an empty string if there
 *  is no token in local storage, or it returns the signed token.
 */
export const getToken = (): string => {
  const token = localStorage.getItem(APP_TOKEN);
  return !token ? '' : token;
};

/**
 * Stores the token into the signed section of the local storage
 *  of the browser.
 *
 * @param token
 */
export const setToken = (token: string) => {
  localStorage.setItem(APP_TOKEN, token);
};
/**
 * Clears the signed token key and value from the local Storage
 *  of the browser.
 */
export const removeToken = () => {
  localStorage.removeItem(APP_TOKEN);
};
