import {
  FormHref,
  FundingTag,
  RequestContainer,
  RowBody,
  RowHeader,
  StyledBadge,
  StyledButton,
  StyledButtonHolder,
  StyledForm,
  TextArea,
  CommentHeader,
  CommentHeaderText,
  FormOpenHref,
  ResponseText,
  StyledList,
  StyledTable,
  TitleText,
  RowId,
} from "./styles";
import { Button, LabeledFormItem } from "view/utils/forms/styles";
import { ExportControlHubEntry } from "lib/hooks/useECHSearch";
import { Tag } from "antd";
import styled from "styled-components";
import { sendEvent } from "lib/gtag";

export const ECHTag = styled(Tag)`
  margin-bottom: 8px;
`;

interface Props {
  record: ExportControlHubEntry;
}

const EntryCell = (props: Props) => {
  const { record } = props;
  const {
    description,
    filters,
    keywords,
    scope,
    source,
    source_link,
    source_year,
    title,
  } = record;

  return (
    <RequestContainer style={{ padding: "1rem 0" }}>
      <RowBody>
        <TitleText>{title}</TitleText>
        <FormOpenHref
          href={source_link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => sendEvent("hub_link_clicked", {
            title: title,
            link: source_link,
            date: new Date().toISOString(),
          } )}
        >
          {source_link}
        </FormOpenHref>
        <RowBody style={{ justifyContent: "space-between" }}>
          <div style={{ width: "60%" }}>
            <TitleText>Description:</TitleText>
            <ResponseText>{description}</ResponseText>
          </div>
          <div style={{ width: "35%" }}>
            <TitleText style={{ margin: "8px 0px" }}>
              Source Year: {source_year}
            </TitleText>
            <TitleText style={{ margin: "8px 0px" }}>
              Source: {source}
            </TitleText>
            <TitleText style={{ margin: "8px 0px" }}>Filters:</TitleText>
            {filters.split(",").map((filter) => (
              <ECHTag>{filter}</ECHTag>
            ))}
            <TitleText style={{ margin: "8px 0px" }}>Keywords:</TitleText>
            {keywords.split(",").map((keyword) => (
              <ECHTag>{keyword}</ECHTag>
            ))}
            <TitleText style={{ margin: "8px 0px" }}>Scopes:</TitleText>
            {scope.split(",").map((_scope) => (
              <ECHTag>{_scope}</ECHTag>
            ))}
          </div>
        </RowBody>
      </RowBody>
    </RequestContainer>
  );
};

export default EntryCell;
