import connect, { connectTypes } from "./connect";
import columned, { columnedTypes } from "./columned";
import search, { searchTypes } from "./search";
import layout, { layoutTypes } from "./layout";
import navigation, { navigationTypes } from "./navigation";
import notifications, { notificationTypes } from "./notifications";
import events, { eventSearchTypes } from "./events";
import languages, { languagesTypes } from "./languages";
import vocabulary from "./vocabulary";
import { VocabularyState } from "./vocabulary/types";
import formLoading from "./formLoading";
import { FormLoadingState } from "./formLoading/types";
/**
 * export your features sagas here
 */
import { connectSagas } from "./connect";
import { columnedSagas } from "./columned";
import { searchSagas } from "./search";
import { eventSearchSagas } from "./events";
import { ReducersMapObject } from "redux";

/**
 * ADD your feature's stores state definitions here
 *  This Necessary for Typescript and to maintain a typed store.
 */
export interface IApplicationState {
  connect: connectTypes.IConnectState;
  columned: columnedTypes.IColumnedState;
  layout: layoutTypes.ILayoutState;
  search: searchTypes.ISearchState;
  navigation: navigationTypes.INavigationState;
  notifications: notificationTypes.INotificationState;
  events: eventSearchTypes.IEventsState;
  languages: languagesTypes.ILanguageState;
  vocabulary: VocabularyState;
  formLoading: FormLoadingState;
}

export const sagas: any = {
  connectSagas,
  columnedSagas,
  searchSagas,
  eventSearchSagas,
};

export const reducers: ReducersMapObject = {
  connect,
  columned,
  layout,
  search,
  navigation,
  notifications,
  events,
  languages,
  vocabulary,
  formLoading,
};
