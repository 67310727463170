import { Reducer } from 'redux';
import layoutActionsTypes, {
  ILayoutState,
  LayoutState,
  FontState,
  Breakpoints,
  LayoutActions,
} from './types';

const initialState: ILayoutState = {
  font: FontState.FONT786,
  layout: LayoutState.DESKTOP,
  breakpoint: Breakpoints.LARGE,
};

const reducer: Reducer<ILayoutState> = (state: ILayoutState = initialState, action) => {
  const stateCopy: ILayoutState = JSON.parse(JSON.stringify(state));
  switch ((action as LayoutActions).type) {
    case layoutActionsTypes.CHANGE_BREAKPOINT:
      stateCopy.breakpoint = action.payload.breakpoint;
      return stateCopy;
    default:
      return stateCopy;
  }
};

export default reducer;
