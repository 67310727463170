import React from "react";
import { WebinarItemProps } from "../WebinarsArchive";
import { Link } from "react-router-dom";
import {
  FullLength,
  WebinarContainer,
  Column,
  TextContainer,
  FormHeader,
  StyledTag,
  Hr,
} from "../styles";
import { imagePathToURL } from "state/utils";
import styled from "styled-components";

interface Props {
  webinars: WebinarItemProps[];
}

const WebinarContainerV2 = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  padding: 1rem;
  background: white;
  border-radius: 5px;
  margin: 0.5rem 1rem;
  border: solid ${(props) => props.theme.gpwmd.green};
  :hover {
    border: solid ${(props) => props.theme.gpwmd.darkTan};
  }
  @media (min-width: 900px) {
    max-width: 400px;
    justify-content: unset;
  }

  @media (min-width: 1100px) {
    margin: 0 1rem 2rem 1rem;
  }
`;

export const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: center;

  @media (min-width: 900px) {
    max-width: 864px;
    justify-content: unset;
  }
`;

const StyledImg = styled.img`
  height: 169px;
  width: 300px;
  padding: 1rem;
  object-fit: scale-down;
  object-position: left;

  @media (min-width: 800px) {
    height: 196px;
    width: 350px;
  }
`;

function Webinar(props: Props) {
  const { webinars } = props;
  return (
    <Container>
      {webinars &&
        webinars.length > 0 &&
        webinars.map((item, index) => (
          //JUAN MAKES ME DO THIS TOO
          <Link
            to={`/en/webinar/${item.field_video_page.replace(
              /\/member\//g,
              ""
            )}`}
            title={`Navigate to ${item.title}`}
          >
            <WebinarContainerV2>
              <div style={{ width: "100%", padding: "0 1rem" }}>
                <StyledTag>{item.field_topic}</StyledTag>
              </div>
              <StyledImg
                src={imagePathToURL(item.field_thumbnail)}
                alt={item.title}
              />
              <TextContainer>
                <Header>{item.title}</Header>
                <p>Duration: {item.field_duration}</p>
              </TextContainer>
            </WebinarContainerV2>
          </Link>
        ))}
    </Container>
  );
}

export default Webinar;

/**
 * {webinars &&
        webinars.length > 0 &&
        webinars.map((item, index) => (
          //JUAN MAKES ME DO THIS TOO
          <Link
            to={`/en/webinar/${item.field_video_page.replace(
              /\/member\//g,
              ""
            )}`}
          >
            <WebinarContainer>
              <FullLength>
                <StyledTag>{item.field_topic}</StyledTag>
              </FullLength>
              <FullLength>
                <img
                  src={imagePathToURL(item.field_thumbnail)}
                  alt={item.title}
                  height="auto"
                  width="350px"
                />
                <TextContainer>
                  <Column>
                    <FormHeader>{item.title}</FormHeader>
                  </Column>
                  <p>Duration: {item.field_duration}</p>
                </TextContainer>
              </FullLength>
              {index < webinars.length - 1 && <Hr />}
            </WebinarContainer>
          </Link>
        ))}
 */
