import { useEffect, useState } from "react";
import { search } from "state/api/search";
import { publicPathToURL } from "state/utils";
import { genFetch, withToken } from "state/utils/endpoints";
import NoDataMessage from "view/Archive/utils/NoDataMessage";
import {
  archiveQueryBuilder,
  SearchDefinition,
} from "view/Archive/utils/useArichiveQuery";
import { WebinarItemProps } from "view/webinars/WebinarsArchive";
import { DocumentContainer } from "../Documents/styles";
import { StyledTable, WebinarLink } from "../Webinars/styles";

interface Props {
  query: SearchDefinition;
  setViewName: any;
}

function MemberPages(props: Props) {
  const { query, setViewName } = props;
  const [data, setData] = useState([]);
  useEffect(() => {
    genFetch(search.archiveMemberPages(query))()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) => data && data.length > 0 ? setData(data) : setData([]))
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [query, setData]);

  const MemberPagesColumns: any = [
    {
      title: " ",
      key: "title",
      render: (text: any, record: any) => (
        <WebinarLink to={archiveQueryBuilder({ ...query, view: record.nid })}>
          {record.title}
        </WebinarLink>
      ),
    },
  ];

  useEffect(() => {
    if (query && query.view && data) {
      const find: any = data.find((item: any) => item.nid === query.view);
      if (find) {
        setViewName(["Archived Pages", find.title]);
      }
    }
  }, [query, data]);

  return (
    <DocumentContainer>
      <h1>Resources</h1>
      {data.length > 0 ? (
         <StyledTable columns={MemberPagesColumns} dataSource={data} />
      ) : (
        <NoDataMessage/>
      )}
     
    </DocumentContainer>
  );
}

export default MemberPages;
