import { Reducer } from 'redux';
import layoutActionsTypes, {
  ILanguageState,
  LanguagesActions,
  LanguageCode,
  IChangeLanguageAction,
  getLanguageDirection,
} from './types';

const initialState: ILanguageState = {
  code: LanguageCode.ENGLISH,
  direction: getLanguageDirection(LanguageCode.ENGLISH),
};

const reducer: Reducer<ILanguageState> = (state: ILanguageState = initialState, action) => {
  const stateCopy: ILanguageState = JSON.parse(JSON.stringify(state));
  switch ((action as LanguagesActions).type) {
    case layoutActionsTypes.CHANGE_LANGUAGE: {
      const payload = (action as IChangeLanguageAction).payload;
      stateCopy.code = payload.languageCode;
      stateCopy.direction = getLanguageDirection(payload.languageCode);
      return stateCopy;
    }
    default:
      return stateCopy;
  }
};

export default reducer;
