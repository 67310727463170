import React, { useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { SearchBar, SearchButton, SearchContainer } from "./styles";

// Also, set a default value for wrapperId prop if none provided
export function ReactPortal({
  children,
  wrapperId = "search-table-portal",
}: any) {
  const [wrapperElement, setWrapperElement] = useState<any>(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (element) {
      setWrapperElement(element);
    }
  }, [wrapperId]);

  // wrapperElement state will be null on very first render.
  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
}

type Props = {
  setSearchText: (a: string) => void;
};

function SearchBox({ setSearchText }: Props) {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    if (isSearchActive) {
      if (ref.current && document.activeElement !== ref.current) {
        setIsSearchActive(false);
      }
    } else {
      if (ref.current && document.activeElement === ref.current) {
        setIsSearchActive(true);
      }
    }
  };

  return (
    <ReactPortal>
      <SearchContainer isActive={isSearchActive}>
        <SearchBar
          ref={ref}
          placeholder="Search by Request ID"
          defaultValue={""}
          onFocus={handleFocus}
          onBlur={handleFocus}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          // onKeyPress={(e) => {
          //   if (e.key === "Enter") {
          //     // handleSearch();
          //   }
          // }}
        />
        <SearchButton
          type="submit"
          value="Go"
          onClick={(e) => {
            e.preventDefault();
            // handleSearch();
          }}
        />
      </SearchContainer>
    </ReactPortal>
  );
}

export default SearchBox;
