import { Reducer } from 'redux';
import { ColumnedActions, IColumnedState, columnedActionTypes } from './types';

export const initialState: IColumnedState = {};

const reducer: Reducer<IColumnedState> = (state: IColumnedState = initialState, action) => {
  const stateCopy: IColumnedState = JSON.parse(JSON.stringify(state));

  switch ((action as ColumnedActions).type) {
    case columnedActionTypes.COLUMNED_RETURN_SUCCESS:
      const payload: IColumnedState = action.payload;
      return {
        ...stateCopy,
        ...payload,
      };

    default:
      return stateCopy;
  }
};

export default reducer;
