import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IApplicationState } from "../../state/ducks";
import { columnedTypes } from "../../state/ducks/columned";
import { notificationActions } from "state/ducks/notifications";
import Approvals from "./approvals";

const mapStateToProps = (state: IApplicationState) => ({
  ...state,
});

type actions = columnedTypes.ColumnedActions;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators(
    {
      addNotification: notificationActions.addNotification,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Approvals);
