import styled, { css } from "styled-components";
import { StyledLink } from "view/footer/styles";
import { Alert, Form, Input } from "antd";

export const FormLink = styled(StyledLink)`
  display: block;
  padding: 0.5rem 0;
  color: ${(props) => props.theme.colors.main};

  :hover {
    color: ${(props) => props.theme.gpwmd.red};
  }
`;

export const HelpText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.main};
  font-size: 14px;
  padding: 0 1rem;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f9f9f9;
`;

export const StyledAlert = styled(Alert)`
  margin: 1rem 0;
`;

interface ContainerProps {
  padding?: string;
}

export const FormContainer = styled.div<ContainerProps>`
  width: 100%;
  justify-content: center;
  display: flex;
  padding: ${(props) => (props.padding ? props.padding : "2rem 0")};
  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  & .ant-form-item {
    font-family: ${(props) => props.theme.fonts.main};
    margin-bottom: 0;
    > .ant-form-item-label {
      > label {
        font-size: 1rem;
      }
    }

    > .ant-form-item-control {
      .ant-input-password {
        height: 48px;
        border-width: 1px;
        border-style: solid;
        border-image: initial;
        padding: 0px 1em;
        border-radius: 4px;
        font-size: 1rem;
      }

      > .ant-form-item-control-input {
        > .ant-form-item-control-input-content {
          > .ant-select {
            > .ant-select-selector {
              height: 48px;
              border-width: 1px;
              border-style: solid;
              border-image: initial;
              padding: 0px 1em;
              border-radius: 4px;
              font-size: 1rem;
            }
          }
          > input {
            height: 48px;
            border-width: 1px;
            border-style: solid;
            border-image: initial;
            padding: 0px 1em;
            border-radius: 4px;
            font-size: 1rem;
          }
        }
      }
    }
  }
`;

interface LabeledFormItemProps {
  height?: string;
}
export const LabeledFormItem = styled(Form.Item)<LabeledFormItemProps>`
  min-height: ${(props) => (props.height ? props.height : "100px")};
`;

interface FormInputProps {
  marginBottom?: string;
}
export const FormInput = styled(Input)<FormInputProps>`
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "2rem"};
`;

export const FormTextArea = styled(Input.TextArea)<FormInputProps>`
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "2rem"};
`;

export const ECHBannerButton = styled.button`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 22px;
  color: #fff;
  background-color: #ca1228;
  border-radius: 4px;
  font-weight: 700;
  text-indent: 0px;
  text-shadow: none;
  cursor: pointer;
  width: 270px;
  height: 75px;
  border: none;
  
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;

  :hover,
  :focus {
    color: #fff;
    background-color: #b51024;
  }
`;

export const Button = styled.button`
  font-family: ${(props) => props.theme.fonts.heading};
  border-radius: 2rem;
  border: 1px solid;
  width: 100%;
  height: 48px;
  font-weight: 700;
  text-indent: 0px;
  text-shadow: none;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  :focus {
    outline: none;
  }

  :hover,
  :focus {
    background-color: ${(props) => props.theme.backgrounds.tertiary};
    color: ${(props) => props.theme.colors.secondary};
  }

  :disabled {
    cursor: default;
    background-color: lightgray;
    color: grey;
    border-color: lightgray;
  }

  :active {
    transform: scale(1.02);
  }
`;

export const StyledForm = styled(Form)`
  max-width: 350px;
  width: 100%;
`;

export const FormText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.main};
  font-size: 1rem;
  padding: 0 1rem;
`;

interface LogoProps {
  maxWidth?: string;
  radius?: string;
}
export const Logo = styled.img<LogoProps>`
  ${(props) =>
    props.maxWidth
      ? css`
          max-width: ${props.maxWidth};
          width: 100%;
          padding: 0 2rem;
          @media (min-width: 768px) {
            padding: 0;
          }
        `
      : null}

  ${(props) =>
    props.radius
      ? css`
          border-radius: ${props.radius};
        `
      : null}
`;
