import { takeLatest, put } from 'redux-saga/effects';
import { OK } from 'http-status-codes';
import { eventSearchActions, eventSearchTypes } from '.';
import { events } from 'state/api/events';
import { genFetch, withToken } from 'state/utils/endpoints';
import eventSearchActionTypes from './types';
import { removePage, getQueryStringFromObject } from './utility';

export function* searchAction(action: eventSearchTypes.IQueryPayloadAction):any {
  try {
    const { query } = action.payload;
    const queryObject: any = JSON.parse(query);
    const queryString = getQueryStringFromObject(queryObject);
    const saveQ = removePage(JSON.parse(query));
    const searchResponse = yield genFetch(events.getEventsBySearch(queryString))()(withToken());

    switch (searchResponse.status) {
      case OK:
        const body = yield searchResponse.json();

        yield put(eventSearchActions.searchResults(body, saveQ));
        break;
      default:
        break;
    }

    yield;
  } catch (error) {
    console.log(error); // tslint:disable-line no-console
  }
}

export default function* connectSaga() {
  yield takeLatest(eventSearchActionTypes.EVENTS_SEARCH, searchAction);
}
