import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IApplicationState } from "state/ducks";
import { connectTypes, connectActions } from "state/ducks/connect";
import { withRouter } from "react-router-dom";
import Progression from "./progression";

const mapStateToProps = (state: IApplicationState) => ({
  languages: state.languages,
  connect: state.connect,
  isFetchLoading: state.formLoading.isLoading
});

type actions = connectTypes.ConnectActions;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators(
    {
      becomeAMemberRequest: connectActions.becomeAMember,
      becomeAMemberReset: connectActions.becomeAMemberReset,
    },
    dispatch
  );

export default withRouter<any,any>(connect(mapStateToProps, mapDispatchToProps)(Progression));
