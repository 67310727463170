
export default function ExportControlDisclaimer() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '4rem 6rem',
      }}
    >
      <p style={{ maxWidth: '1300px' }}>
        This material was prepared as an account of work sponsored by an agency of the United States
        Government. Neither the United States Government nor the United States Department of Energy,
        nor the Contractor, nor any or their employees, nor any jurisdiction or organization that
        has cooperated in the development of these materials,{' '}
        <i>
          <strong>
            makes any warranty, express or implied, or assumes any legal liability or responsibility
            for the accuracy, completeness, or usefulness or any information, apparatus, product,
            software, or process disclosed, or represents that its use would not infringe privately
            owned rights.
          </strong>
        </i>{' '}
        <br />
        <br />
        Reference herein to any specific commercial product, process, or service by trade name,
        trademark, manufacturer, or otherwise does not necessarily constitute or imply its
        endorsement, recommendation, or favoring by the United States Government or any agency
        thereof, or Battelle Memorial Institute. The views and opinions of authors expressed herein
        do not necessarily state or reflect those of the United States Government or any agency
        thereof.
      </p>
      <p style={{ textAlign: 'center' }}>
        PACIFIC NORTHWEST NATIONAL LABORATORY <br />
        <i>operated by</i> <br />
        BATTELLE <br />
        <i>for the</i> <br />
        UNITED STATES DEPARTMENT OF ENERGY <br />
        <i>under Contract DE-AC05-76RL01830</i>
      </p>
      <p style={{ textAlign: 'center' }}><u>CONTENTS ARE MADE PUBLICLY AVAILABLE FOR INFORMATION PURPOSES ONLY</u></p>
    </div>
  );
}
