import { ActionCreator } from 'redux';
import layoutActionTypes, { Breakpoints, IChangeBreakpointAction } from './types';

/**
 * Action Creator that will accept a breakpoint to update the store.
 * @param breakpoint
 */
export const changeBreakpoint: ActionCreator<IChangeBreakpointAction> = (
  breakpoint: Breakpoints
) => ({
  type: layoutActionTypes.CHANGE_BREAKPOINT,
  payload: {
    breakpoint,
  },
});
