import React from "react";
import styled from "styled-components";
import LinkNode from "view/parsed-layout/fieldTypes/link";

const TextWallContainer = styled.div`
  width: 100%;
  min-height: 420px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;

  & > div {
    max-width: 900px;
    padding: 0 2rem;
    @media (min-width: 900px) {
      padding: 0;
    }
  }

  h1 {
    font-size: 2rem;
    font-family: ${(props) => props.theme.fonts.main};
    color: ${(props) => props.theme.colors.main};
    font-weight: 700;
    line-height: 2rem;
    margin-bottom: 4rem;
    @media (min-width: 1025px) {
      line-height: 42px;
    }
  }

  p {
    font-size: 1.2rem;
    font-family: ${(props) => props.theme.fonts.main};
    color: ${(props) => props.theme.colors.main};
    font-weight: 300;
    line-height: 2rem;
    @media (min-width: 1025px) {
      line-height: 36px;
    }
  }
`;

export function WelcomeMessage() {
  return (
    <TextWallContainer>
      <div>
        <h1>A Message from Italy as Incoming GP President (2024)</h1>
        <p>Dear Members of the GP Community,</p>
        <p>
          At the outset, we wish to congratulate Japan for its successful
          presidency of the GP last year and its excellent guidance. Italy is
          now ready to provide full support to the initiative.
        </p>
        <p>
          As the whole disarmament and non-proliferation architecture may seem
          challenged by various and ever-evolving threats, it becomes
          increasingly important to strengthen our common effort aimed at
          combatting the re-emergence of the use of WMD.
        </p>

        <p>
          The use of CBRN agents is a high-consequence transnational threat and
          an international and coordinated response is needed to face such
          issues. CBRN events may simultaneously affect several nations, and the
          international community must stand united, resolute, and determined to
          preserve global security.
        </p>

        <p>
          In the framework of the GP funding principles, the Italian Presidency
          identified the following priorities for the current year and
          encourages Global Partnership Working Group (GPWG) members and
          international stakeholders to present project proposals on these main
          areas of concern:
        </p>
        <ol>
          <li>
            <p>Strengthening global Biological Safety and Security</p>
          </li>
          <li>
            <p>Reaffirming the relevance of Chemical Safety and Security</p>
          </li>
          <li>
            <p>
              Increasing common understanding of Emerging Disruptive
              Technologies
            </p>
          </li>
          <li>
            <p>Countering Proliferation Financing</p>
          </li>
          <li>
            <p>Countering CBRN Disinformation</p>
          </li>
        </ol>
        <p>
          Within the context of these priorities, Italy will leverage its
          position as President to promote the exchange of information and
          expertise between governments, international organizations, academia,
          the scientific community, and civil society.
        </p>
        <p>
          Today more than ever, a wide range of innovative skills and holistic
          responses is required, and this process needs a deeper understanding
          of risk scenarios across the whole CBRN spectrum.
        </p>
        <p>
          Italy is proud to host the first GPWG meeting from the 7th to the 9th
          of February 2024 at the Italian Ministry of Foreign Affairs in Rome,
          which will include the first project matchmaking sessions and side
          events with relevant international stakeholders.
        </p>
        <p>
          We look forward to seeing you in the upcoming weeks and working
          together for a world free of WMD.
        </p>
        <p>Sincerely,</p>
        <p>
          <strong>Maurizio Antonini</strong>
          <br />
          <strong>
            Chair Italian Presidency of the Global Partnership 2024
          </strong>
        </p>
        <p></p>
      </div>
    </TextWallContainer>
  );
}
