import React, { useMemo } from "react";
import { publicPathToURL } from "state/utils";
import { createSelector } from "reselect";
import { IApplicationState } from "state/ducks";
import { useSelector } from "react-redux";
import { SubWorkingGroupContainer, WorkingGroupLink } from "./styles";
import { useLocation } from "react-router-dom";
import { Skeleton } from "antd";
import { archiveQueryBuilder, SearchDefinition } from "view/Archive/utils/useArichiveQuery";
const queryString = require("query-string");

const getHomeState = () =>
  createSelector(
    (state: IApplicationState) => ({
      subWorkingGroups: state.vocabulary.subWorkingGroups,
    }),
    (state) => {
      const { subWorkingGroups } = state;
      return {
        subWorkingGroups,
      };
    }
  );

interface GroupToggleProps {
  all?: boolean;
  allOption?: string;
  query: SearchDefinition
}

function GroupToggle(props: GroupToggleProps) {
  const { all, allOption, query } = props;
  const { subWorkingGroups } = useSelector(getHomeState());



  const location = useLocation();

  const tid = useMemo(() => {
    const query = queryString.parse(location.search);
    const tid = query["group"] || null;

    return tid;
  }, [location.search]);

  return (
    <SubWorkingGroupContainer>
      {all && (
        <WorkingGroupLink isActive={!tid} to={archiveQueryBuilder({...query, plenary: 1, group: undefined })}>
          {allOption ? allOption : "ALL"}
        </WorkingGroupLink>
      )}
      {subWorkingGroups.length === 0 &&
        [1, 2, 3, 4].map((item) => (
          <WorkingGroupLink to="#">
            <Skeleton.Button active />
          </WorkingGroupLink>
        ))}
      {subWorkingGroups.map((group) => (
        <WorkingGroupLink
          isActive={group.tid === tid}
          to={archiveQueryBuilder({...query, plenary: undefined, group: group.tid })}
        >
          {group.field_acronym}
        </WorkingGroupLink>
      ))}
    </SubWorkingGroupContainer>
  );
}

export default GroupToggle;
