import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

interface BannerProps {
  minHeight?: string;
  bgColor?: string;
}

export const UkraineBannerWrapper = styled.div`
  padding: 0;
  margin: 0;
  border-bottom: 5px solid white;
  width: 100%;

  & a {
    text-decoration: none;
    color: none;
  }
`;

export const UkraineBanner = styled.div<BannerProps>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${(props) => (props.minHeight ? props.minHeight : "400px")};
  background: ${(props) => (props.bgColor ? props.bgColor : "default")};
  & p {
    font-weight: 600;
    margin: 0;
    font-size: 1.15rem;
  }

  }
`;

export const BannerLink = styled(Link)`
  text-decoration: none;
  text-align: center;
  color: ${(props) => props.theme.colors.main};
  font-weight: 600;

  :hover {
    color: #0057b7;
  }
`;

export const Banner = styled.div<BannerProps>`
  position: relative;
  min-height: 400px;
  width: 100%;

  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `}

  & img {
    min-height: 300px;
    object-fit: cover;
    object-position: center;

    @media (min-width: 1025px) {
      object-fit: unset;
      object-position: unset;
    }
  }
`;

/**
 *     @media (min-width: 1024px) {
    min-width: 250px;
    max-height: 125px;
    height: 150px;

    margin-right: 2rem;
    margin-bottom: 2rem;
  }
 */

export const Overlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(32, 44, 71, 0.5);
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 3rem 3rem;

  & h1 {
    font-size: 2rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.secondary};
    font-family: ${(props) => props.theme.fonts.heading};
    margin-bottom: 1rem;
  }

  & h4 {
    font-size: 1.1rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.secondary};
    font-family: ${(props) => props.theme.fonts.main};
    line-height: 32px;
    max-width: 550px;
    height: auto;
    overflow: hidden;
  }

  @media (min-width: 1025px) {
    left: 50%;
  }
  @media (min-width: 1200px) {
    padding: 5rem 3rem;
  }
`;
