import React from "react";
import { Select } from "antd";
export const countries = (
  <>
    <Select.Option value="none">---Select---</Select.Option>
    <Select.Option value="US">United States</Select.Option>
    <Select.Option value="AF">Afghanistan</Select.Option>
    <Select.Option value="AX">Åland Islands</Select.Option>
    <Select.Option value="AL">Albania</Select.Option>
    <Select.Option value="DZ">Algeria</Select.Option>
    <Select.Option value="AS">American Samoa</Select.Option>
    <Select.Option value="AD">Andorra</Select.Option>
    <Select.Option value="AO">Angola</Select.Option>
    <Select.Option value="AI">Anguilla</Select.Option>
    <Select.Option value="AQ">Antarctica</Select.Option>
    <Select.Option value="AG">Antigua &amp; Barbuda</Select.Option>
    <Select.Option value="AR">Argentina</Select.Option>
    <Select.Option value="AM">Armenia</Select.Option>
    <Select.Option value="AW">Aruba</Select.Option>
    <Select.Option value="AC">Ascension Island</Select.Option>
    <Select.Option value="AU">Australia</Select.Option>
    <Select.Option value="AT">Austria</Select.Option>
    <Select.Option value="AZ">Azerbaijan</Select.Option>
    <Select.Option value="BS">Bahamas</Select.Option>
    <Select.Option value="BH">Bahrain</Select.Option>
    <Select.Option value="BD">Bangladesh</Select.Option>
    <Select.Option value="BB">Barbados</Select.Option>
    <Select.Option value="BY">Belarus</Select.Option>
    <Select.Option value="BE">Belgium</Select.Option>
    <Select.Option value="BZ">Belize</Select.Option>
    <Select.Option value="BJ">Benin</Select.Option>
    <Select.Option value="BM">Bermuda</Select.Option>
    <Select.Option value="BT">Bhutan</Select.Option>
    <Select.Option value="BO">Bolivia</Select.Option>
    <Select.Option value="BA">Bosnia &amp; Herzegovina</Select.Option>
    <Select.Option value="BW">Botswana</Select.Option>
    <Select.Option value="BV">Bouvet Island</Select.Option>
    <Select.Option value="BR">Brazil</Select.Option>
    <Select.Option value="IO">British Indian Ocean Territory</Select.Option>
    <Select.Option value="VG">British Virgin Islands</Select.Option>
    <Select.Option value="BN">Brunei</Select.Option>
    <Select.Option value="BG">Bulgaria</Select.Option>
    <Select.Option value="BF">Burkina Faso</Select.Option>
    <Select.Option value="BI">Burundi</Select.Option>
    <Select.Option value="KH">Cambodia</Select.Option>
    <Select.Option value="CM">Cameroon</Select.Option>
    <Select.Option value="CA">Canada</Select.Option>
    <Select.Option value="IC">Canary Islands</Select.Option>
    <Select.Option value="CV">Cape Verde</Select.Option>
    <Select.Option value="BQ">Caribbean Netherlands</Select.Option>
    <Select.Option value="KY">Cayman Islands</Select.Option>
    <Select.Option value="CF">Central African Republic</Select.Option>
    <Select.Option value="EA">Ceuta &amp; Melilla</Select.Option>
    <Select.Option value="TD">Chad</Select.Option>
    <Select.Option value="CL">Chile</Select.Option>
    <Select.Option value="CN">China</Select.Option>
    <Select.Option value="CX">Christmas Island</Select.Option>
    <Select.Option value="CP">Clipperton Island</Select.Option>
    <Select.Option value="CC">Cocos (Keeling) Islands</Select.Option>
    <Select.Option value="CO">Colombia</Select.Option>
    <Select.Option value="KM">Comoros</Select.Option>
    <Select.Option value="CG">Congo - Brazzaville</Select.Option>
    <Select.Option value="CD">Congo - Kinshasa</Select.Option>
    <Select.Option value="CK">Cook Islands</Select.Option>
    <Select.Option value="CR">Costa Rica</Select.Option>
    <Select.Option value="CI">Côte d’Ivoire</Select.Option>
    <Select.Option value="HR">Croatia</Select.Option>
    <Select.Option value="CU">Cuba</Select.Option>
    <Select.Option value="CW">Curaçao</Select.Option>
    <Select.Option value="CY">Cyprus</Select.Option>
    <Select.Option value="CZ">Czechia</Select.Option>
    <Select.Option value="DK">Denmark</Select.Option>
    <Select.Option value="DG">Diego Garcia</Select.Option>
    <Select.Option value="DJ">Djibouti</Select.Option>
    <Select.Option value="DM">Dominica</Select.Option>
    <Select.Option value="DO">Dominican Republic</Select.Option>
    <Select.Option value="EC">Ecuador</Select.Option>
    <Select.Option value="EG">Egypt</Select.Option>
    <Select.Option value="SV">El Salvador</Select.Option>
    <Select.Option value="GQ">Equatorial Guinea</Select.Option>
    <Select.Option value="ER">Eritrea</Select.Option>
    <Select.Option value="EE">Estonia</Select.Option>
    <Select.Option value="SZ">Eswatini</Select.Option>
    <Select.Option value="ET">Ethiopia</Select.Option>
    <Select.Option value="FK">Falkland Islands</Select.Option>
    <Select.Option value="FO">Faroe Islands</Select.Option>
    <Select.Option value="FJ">Fiji</Select.Option>
    <Select.Option value="FI">Finland</Select.Option>
    <Select.Option value="FR">France</Select.Option>
    <Select.Option value="GF">French Guiana</Select.Option>
    <Select.Option value="PF">French Polynesia</Select.Option>
    <Select.Option value="TF">French Southern Territories</Select.Option>
    <Select.Option value="GA">Gabon</Select.Option>
    <Select.Option value="GM">Gambia</Select.Option>
    <Select.Option value="GE">Georgia</Select.Option>
    <Select.Option value="DE">Germany</Select.Option>
    <Select.Option value="GH">Ghana</Select.Option>
    <Select.Option value="GI">Gibraltar</Select.Option>
    <Select.Option value="GR">Greece</Select.Option>
    <Select.Option value="GL">Greenland</Select.Option>
    <Select.Option value="GD">Grenada</Select.Option>
    <Select.Option value="GP">Guadeloupe</Select.Option>
    <Select.Option value="GU">Guam</Select.Option>
    <Select.Option value="GT">Guatemala</Select.Option>
    <Select.Option value="GG">Guernsey</Select.Option>
    <Select.Option value="GN">Guinea</Select.Option>
    <Select.Option value="GW">Guinea-Bissau</Select.Option>
    <Select.Option value="GY">Guyana</Select.Option>
    <Select.Option value="HT">Haiti</Select.Option>
    <Select.Option value="HM">Heard &amp; McDonald Islands</Select.Option>
    <Select.Option value="HN">Honduras</Select.Option>
    <Select.Option value="HK">Hong Kong SAR China</Select.Option>
    <Select.Option value="HU">Hungary</Select.Option>
    <Select.Option value="IS">Iceland</Select.Option>
    <Select.Option value="IN">India</Select.Option>
    <Select.Option value="ID">Indonesia</Select.Option>
    <Select.Option value="IR">Iran</Select.Option>
    <Select.Option value="IQ">Iraq</Select.Option>
    <Select.Option value="IE">Ireland</Select.Option>
    <Select.Option value="IM">Isle of Man</Select.Option>
    <Select.Option value="IL">Israel</Select.Option>
    <Select.Option value="IT">Italy</Select.Option>
    <Select.Option value="JM">Jamaica</Select.Option>
    <Select.Option value="JP">Japan</Select.Option>
    <Select.Option value="JE">Jersey</Select.Option>
    <Select.Option value="JO">Jordan</Select.Option>
    <Select.Option value="KZ">Kazakhstan</Select.Option>
    <Select.Option value="KE">Kenya</Select.Option>
    <Select.Option value="KI">Kiribati</Select.Option>
    <Select.Option value="XK">Kosovo</Select.Option>
    <Select.Option value="KW">Kuwait</Select.Option>
    <Select.Option value="KG">Kyrgyzstan</Select.Option>
    <Select.Option value="LA">Laos</Select.Option>
    <Select.Option value="LV">Latvia</Select.Option>
    <Select.Option value="LB">Lebanon</Select.Option>
    <Select.Option value="LS">Lesotho</Select.Option>
    <Select.Option value="LR">Liberia</Select.Option>
    <Select.Option value="LY">Libya</Select.Option>
    <Select.Option value="LI">Liechtenstein</Select.Option>
    <Select.Option value="LT">Lithuania</Select.Option>
    <Select.Option value="LU">Luxembourg</Select.Option>
    <Select.Option value="MO">Macao SAR China</Select.Option>
    <Select.Option value="MG">Madagascar</Select.Option>
    <Select.Option value="MW">Malawi</Select.Option>
    <Select.Option value="MY">Malaysia</Select.Option>
    <Select.Option value="MV">Maldives</Select.Option>
    <Select.Option value="ML">Mali</Select.Option>
    <Select.Option value="MT">Malta</Select.Option>
    <Select.Option value="MH">Marshall Islands</Select.Option>
    <Select.Option value="MQ">Martinique</Select.Option>
    <Select.Option value="MR">Mauritania</Select.Option>
    <Select.Option value="MU">Mauritius</Select.Option>
    <Select.Option value="YT">Mayotte</Select.Option>
    <Select.Option value="MX">Mexico</Select.Option>
    <Select.Option value="FM">Micronesia</Select.Option>
    <Select.Option value="MD">Moldova</Select.Option>
    <Select.Option value="MC">Monaco</Select.Option>
    <Select.Option value="MN">Mongolia</Select.Option>
    <Select.Option value="ME">Montenegro</Select.Option>
    <Select.Option value="MS">Montserrat</Select.Option>
    <Select.Option value="MA">Morocco</Select.Option>
    <Select.Option value="MZ">Mozambique</Select.Option>
    <Select.Option value="MM">Myanmar (Burma)</Select.Option>
    <Select.Option value="NA">Namibia</Select.Option>
    <Select.Option value="NR">Nauru</Select.Option>
    <Select.Option value="NP">Nepal</Select.Option>
    <Select.Option value="NL">Netherlands</Select.Option>
    <Select.Option value="NC">New Caledonia</Select.Option>
    <Select.Option value="NZ">New Zealand</Select.Option>
    <Select.Option value="NI">Nicaragua</Select.Option>
    <Select.Option value="NE">Niger</Select.Option>
    <Select.Option value="NG">Nigeria</Select.Option>
    <Select.Option value="NU">Niue</Select.Option>
    <Select.Option value="NF">Norfolk Island</Select.Option>
    <Select.Option value="KP">North Korea</Select.Option>
    <Select.Option value="MK">North Macedonia</Select.Option>
    <Select.Option value="MP">Northern Mariana Islands</Select.Option>
    <Select.Option value="NO">Norway</Select.Option>
    <Select.Option value="OM">Oman</Select.Option>
    <Select.Option value="PK">Pakistan</Select.Option>
    <Select.Option value="PW">Palau</Select.Option>
    <Select.Option value="PS">Palestinian Territories</Select.Option>
    <Select.Option value="PA">Panama</Select.Option>
    <Select.Option value="PG">Papua New Guinea</Select.Option>
    <Select.Option value="PY">Paraguay</Select.Option>
    <Select.Option value="PE">Peru</Select.Option>
    <Select.Option value="PH">Philippines</Select.Option>
    <Select.Option value="PN">Pitcairn Islands</Select.Option>
    <Select.Option value="PL">Poland</Select.Option>
    <Select.Option value="PT">Portugal</Select.Option>
    <Select.Option value="PR">Puerto Rico</Select.Option>
    <Select.Option value="QA">Qatar</Select.Option>
    <Select.Option value="RE">Réunion</Select.Option>
    <Select.Option value="RO">Romania</Select.Option>
    <Select.Option value="RU">Russia</Select.Option>
    <Select.Option value="RW">Rwanda</Select.Option>
    <Select.Option value="WS">Samoa</Select.Option>
    <Select.Option value="SM">San Marino</Select.Option>
    <Select.Option value="ST">São Tomé &amp; Príncipe</Select.Option>
    <Select.Option value="SA">Saudi Arabia</Select.Option>
    <Select.Option value="SN">Senegal</Select.Option>
    <Select.Option value="RS">Serbia</Select.Option>
    <Select.Option value="SC">Seychelles</Select.Option>
    <Select.Option value="SL">Sierra Leone</Select.Option>
    <Select.Option value="SG">Singapore</Select.Option>
    <Select.Option value="SX">Sint Maarten</Select.Option>
    <Select.Option value="SK">Slovakia</Select.Option>
    <Select.Option value="SI">Slovenia</Select.Option>
    <Select.Option value="SB">Solomon Islands</Select.Option>
    <Select.Option value="SO">Somalia</Select.Option>
    <Select.Option value="ZA">South Africa</Select.Option>
    <Select.Option value="GS">
      South Georgia &amp; South Sandwich Islands
    </Select.Option>
    <Select.Option value="KR">South Korea</Select.Option>
    <Select.Option value="SS">South Sudan</Select.Option>
    <Select.Option value="ES">Spain</Select.Option>
    <Select.Option value="LK">Sri Lanka</Select.Option>
    <Select.Option value="BL">St. Barthélemy</Select.Option>
    <Select.Option value="SH">St. Helena</Select.Option>
    <Select.Option value="KN">St. Kitts &amp; Nevis</Select.Option>
    <Select.Option value="LC">St. Lucia</Select.Option>
    <Select.Option value="MF">St. Martin</Select.Option>
    <Select.Option value="PM">St. Pierre &amp; Miquelon</Select.Option>
    <Select.Option value="VC">St. Vincent &amp; Grenadines</Select.Option>
    <Select.Option value="SD">Sudan</Select.Option>
    <Select.Option value="SR">Suriname</Select.Option>
    <Select.Option value="SJ">Svalbard &amp; Jan Mayen</Select.Option>
    <Select.Option value="SE">Sweden</Select.Option>
    <Select.Option value="CH">Switzerland</Select.Option>
    <Select.Option value="SY">Syria</Select.Option>
    <Select.Option value="TW">Taiwan</Select.Option>
    <Select.Option value="TJ">Tajikistan</Select.Option>
    <Select.Option value="TZ">Tanzania</Select.Option>
    <Select.Option value="TH">Thailand</Select.Option>
    <Select.Option value="TL">Timor-Leste</Select.Option>
    <Select.Option value="TG">Togo</Select.Option>
    <Select.Option value="TK">Tokelau</Select.Option>
    <Select.Option value="TO">Tonga</Select.Option>
    <Select.Option value="TT">Trinidad &amp; Tobago</Select.Option>
    <Select.Option value="TA">Tristan da Cunha</Select.Option>
    <Select.Option value="TN">Tunisia</Select.Option>
    <Select.Option value="TR">Turkey</Select.Option>
    <Select.Option value="TM">Turkmenistan</Select.Option>
    <Select.Option value="TC">Turks &amp; Caicos Islands</Select.Option>
    <Select.Option value="TV">Tuvalu</Select.Option>
    <Select.Option value="UM">U.S. Outlying Islands</Select.Option>
    <Select.Option value="VI">U.S. Virgin Islands</Select.Option>
    <Select.Option value="UG">Uganda</Select.Option>
    <Select.Option value="UA">Ukraine</Select.Option>
    <Select.Option value="AE">United Arab Emirates</Select.Option>
    <Select.Option value="GB">United Kingdom</Select.Option>
    <Select.Option value="UY">Uruguay</Select.Option>
    <Select.Option value="UZ">Uzbekistan</Select.Option>
    <Select.Option value="VU">Vanuatu</Select.Option>
    <Select.Option value="VA">Vatican City</Select.Option>
    <Select.Option value="VE">Venezuela</Select.Option>
    <Select.Option value="VN">Vietnam</Select.Option>
    <Select.Option value="WF">Wallis &amp; Futuna</Select.Option>
    <Select.Option value="EH">Western Sahara</Select.Option>
    <Select.Option value="YE">Yemen</Select.Option>
    <Select.Option value="ZM">Zambia</Select.Option>
  </>
);
