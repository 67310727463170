import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { authentication } from "state/api/authentication";
import { withToken, genFetch } from "state/utils/endpoints";
import { OK } from "http-status-codes";
import {
  MainContainer,
  FormContainer,
  LabeledFormItem,
  Logo,
  Button,
  StyledForm,
  FormText,
  FormLink,
} from "view/utils/forms/styles";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Form, Input } from "antd";
import { connectActions } from "state/ducks/connect";
import {
  HeaderContainer,
  HeaderText,
  NavigationContainer,
  StyledMenuContainer,
} from "view/navigation/styles";
interface Props {
  logInAfterPasswordChange: typeof connectActions.logInAfterPasswordChange;
}

const ResetPassword = (props: Props) => {
  const [isResetValid, setIsResetValid] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [hasFailed, setHasFailed] = useState(false);
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const [username, setUsername] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const verifyRecaptchaCallback = useCallback((token: string) => {
    setRecaptchaToken(token);
  }, []);

  const [form] = Form.useForm();
  const { token } = useParams<any>();
  const history = useHistory();
  useEffect(() => {
    genFetch(authentication.validateResetToken(token))()()
      .then((res: any) => {
        if (res.status === OK) {
          res.json().then((body: any) => {
            setUsername(body.username);
          });
          setIsResetValid(true);
        } else {
          setIsResetValid(false);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setHasFailed(true);
      });
  }, [setIsResetValid, setIsLoading, setHasFailed, token]);

  const isValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo) {
      return false;
    }
  };

  const handleSubmit = async () => {
    const formIsValid = await isValid();
    if (formIsValid && recaptchaToken) {
      const { password } = form.getFieldsValue();

      genFetch(authentication.resetPassword(token, password))()()
        .then((res: any) => {
          if (res.status === OK) {
            props.logInAfterPasswordChange({
              username: username,
              password: password,
            });
            //TODO I NEED JUAN TO PULL HIS SHIT TOGETHER.
            setHasSucceeded(true);
            history.push("/en/home");
          } else {
            setHasFailed(true);
          }
        })
        .catch((error) => {
          setHasFailed(true);
        });
    }
  };

  return (
    <>
      <NavigationContainer style={{ backgroundColor: "#f9f9f9" }}>
        <StyledMenuContainer style={{ backgroundColor: "#f9f9f9" }}>
          <HeaderContainer>
            <HeaderText purpose="title" style={{ color: "#202C47" }}>
              GLOBAL PARTNERSHIP
            </HeaderText>
            <HeaderText style={{ color: "#202C47" }}>
              Against the Spread of Weapons and Materials of Mass Destruction
            </HeaderText>
          </HeaderContainer>
        </StyledMenuContainer>
      </NavigationContainer>
      <MainContainer style={{ height: "calc(100% - 128px)" }}>
        <div>
          <Logo maxWidth="300px" src="/images/icons/italy24/gp-g7-stacked.png" />
          <FormContainer>
            {isLoading && (
              <FormText>
                We are validating your request to reset your password...
              </FormText>
            )}
            {isResetValid && !isLoading && !hasFailed && !hasSucceeded && (
              <StyledForm layout="vertical" form={form}>
                <div>
                  In order to protect your account, make sure your password:
                  <ul>
                    <li> is longer than 6 characters</li>
                    <li> has one uppercase letter </li>
                    <li> has one special character </li>
                    <li> has one number </li>
                    <li>
                      does not match or significantly contain your username
                    </li>
                  </ul>
                </div>
                <LabeledFormItem
                  height="140px"
                  label="New Password"
                  name="password"
                  style={{ minHeight: "100px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      pattern: /^(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?=.*[0-9]).{6}/,
                      message: `Make sure your password has at least: 6 characters, 1 Special Character, 1 Uppercase, and 1 Number`,
                    },
                  ]}
                >
                  <Input.Password />
                </LabeledFormItem>
                <LabeledFormItem
                  height="140px"
                  label="Confirm New Password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  style={{ minHeight: "100px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </LabeledFormItem>
                <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
                <Form.Item style={{ padding: "1rem 0" }}>
                  <Button onClick={handleSubmit}>Reset Password</Button>
                </Form.Item>
              </StyledForm>
            )}
            {!isResetValid && !isLoading && !hasFailed && !hasSucceeded && (
              <div style={{ padding: "1rem" }}>
                <FormText>Your one-time link has expired</FormText>
                <div style={{ padding: "" }}>
                  <FormText>
                    To protect your account, one-time links are valid for a
                    short duration of time. If you still need to reset your
                    password, please request a new password.
                  </FormText>
                </div>
                <div style={{ padding: ".5rem 0" }}>
                  <FormLink to={`/en/forgot/password`}>
                    Request a new password
                  </FormLink>
                  <FormLink
                    as="a"
                    href="https://www.pnnl.gov/security-privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </FormLink>
                </div>
              </div>
            )}
          </FormContainer>
        </div>
      </MainContainer>
    </>
  );
};

export default ResetPassword;
