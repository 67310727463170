import styled, { css } from "styled-components";

interface LogoProps {
  maxWidth?: string;
  radius?: string;
}

export const Logo = styled.img<LogoProps>`
  position: absolute;
  left: 0;

  ${(props) =>
    props.maxWidth
      ? css`
          max-width: ${props.maxWidth};
          width: 100%;
          padding: 0 2rem;
          @media (min-width: 768px) {
            padding: 0;
          }
        `
      : null};

  ${(props) =>
    props.radius
      ? css`
          border-radius: ${props.radius};
        `
      : null};
`;

export const RightColumn = styled.div`
  width: auto;
  flex: auto 1 auto;
  flex-wrap: wrap;
  background: #fff;
  padding: 1rem;
  display: inline-flex;
  float: right;
  @media (min-width: 1100px) {
    width: 20%;
  }
`;

interface LeftColumnProps {
  flex?: boolean;
  transparent?: boolean;
}

export const LeftColumn = styled.div<LeftColumnProps>`
  width: 100%;

  flex: auto 1 auto;
  margin: 0 0 1rem 0;
  display: inline-flex;
  flex-wrap: wrap;
  ${(props) => css`
    background: ${props.transparent ? "transparent" : "#FFF"};
  `}
  ${(props) =>
    props.flex &&
    css`
      display: inline-flex;
      justify-content: center;
      flex-wrap: wrap;
    `}

    @media (min-width: 1100px) {
    margin: 0;
    width: 78%;
    ${(props) =>
      props.flex &&
      css`
        height: unset;
      `}
  }
`;
