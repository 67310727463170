/* tslint:disable:max-line-length */
import { IRequest } from 'state/utils/endpoints';

const pathHelper = (rawPath: string): string => {
  let path = rawPath.charAt(0) === '/' ? rawPath.substr(1) : rawPath;
  if (path === 'about') {
    path = path + '-ors';
  }
  return path;
};

/**
 * Based on path passed in and if the user has a token, then returns an
 * object to be parsed for a view - Specifically used for Remove, Reduce, Protect
 * and About pages.
 * @returns {Object} Returns a response from api
 */

export const genRequest = {
  blueprint(rawPath: string) {
    let path = rawPath.charAt(0) === '/' ? rawPath.substr(1) : rawPath;
    if (path === 'about') {
      path = path + '-ors';
    }
    return fetch(
      `${
        process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API : ''
      }/web/router/translate-path?path=/public/${path}?_format=json`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          ContentType: 'application/json',
        },
      }
    );
  },
  getColumnedData: (arg: string, lang: string = ''): IRequest => ({
    route: (prefix: string) =>
      `${process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API : ''}/web/${
        lang !== '' ? `${lang}/` : ''
      }router/translate-path?path=/${prefix}/${pathHelper(arg)}?_format=json`,
    options: {
      method: 'GET',
    },
  }),
  getColumnedDataByNode: (arg: string, lang: string = ''): IRequest => ({
    route: (prefix: string) =>
      `${process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API : ''}/web/${
        lang !== '' ? `${lang}/` : ''
      }router/translate-path?path=/${pathHelper(arg)}?_format=json`,
    options: {
      method: 'GET',
    },
  }),

  /**
   * Example:
   * https://test-ors-admin.pnl.gov:30443/web/tag-search?search_api_fulltext=to+develop+and+enhance+national
   *
   */
  getAllEvents: (): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API : ''
      }/web/events?_format=json`,
    options: {
      method: 'GET',
    },
  }),

  blueprintNews(rawPath: string, lang: string) {
    let path = rawPath.charAt(0) === '/' ? rawPath.substr(1) : rawPath;
    if (path === 'about') {
      path = path + '-ors';
    }
    return fetch(
      `${process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API : ''}/web/${
        lang !== '' ? `${lang}/` : ''
      }router/translate-path?path=${path}?_format=json`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          ContentType: 'application/json',
        },
      }
    );
  },

  getArchiveNews(lang: string, rawPath: string) {
    let path = rawPath.charAt(0) === '/' ? rawPath.substr(1) : rawPath;

    return fetch(
      `${process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API : ''}/web/${
        lang !== '' ? `${lang}/` : ''
      }${path}?_format=json`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          ContentType: 'application/json',
        },
      }
    );
  },
};
