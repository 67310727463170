import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  OK,
  UNAUTHORIZED,
  UNPROCESSABLE_ENTITY,
  INTERNAL_SERVER_ERROR,
  SERVICE_UNAVAILABLE,
} from "http-status-codes";
import { connectTypes, connectActions } from ".";
import { authentication } from "state/api/authentication";
import { setToken, hasToken, removeToken } from "state/utils";
import { genFetch, withToken } from "state/utils/endpoints";
import { IMenuItems, INavigationItem } from "state/ducks/navigation/types";
import { notificationActions } from "../notifications";
import { getAPILangCode } from "../languages/selectors";
import { isApiPublic } from "../connect/selectors";
import LanguagesActionTypes from "../languages/types";
import {
  setWorkingGroupMenu,
  setWorkingGroupVocabulary,
} from "../vocabulary/actions";
import { updateFormLoading } from "../formLoading/actions";

/**
 * Takes the username and password and sends a authentication request
 *  to the server.
 *
 * Success: it will set the token
 *  into local storage on the user browser and update the store to tell
 *  the UI that the user has been successfully authenticated.
 *
 * Failure: Updates store to denote the user has failed and not authenticated.
 *
 * @param action - has a payload that contains the
 *  strings: username and password; for the authentication request.
 */
export function* authenticate(action: connectTypes.IAuthenticationAction): any {
  try {
    const languageCode = yield select(getAPILangCode);
    const { username, password } = action.payload;
    yield put(connectActions.sendingAuthRequest());
    const response = yield call(authentication.authenticate, {
      username,
      password,
    });
    yield put(connectActions.receivedAuthResponse());
    const { status } = response;
    switch (status) {
      case OK: {
        const body = yield response.json();
        if (body.access_token) {
          const { access_token } = body;
          setToken(access_token);
          const navigationResponse = yield genFetch(
            authentication.getMenuItems(languageCode)
          )(false)(withToken());

          let items: IMenuItems[] = [];
          if (navigationResponse.status === OK) {
            const navBody: INavigationItem[] = yield navigationResponse.json();
            items = menuHelper(navBody);
          }
          yield put(connectActions.authenticateSuccess(items, false));
          yield put(
            notificationActions.addNotification({
              message: "Success",
              description: "You authenticated successfully!",
              type: "success",
            })
          );
        } else {
          yield put(connectActions.authenticateFailure());
          yield put(
            notificationActions.addNotification({
              message: "Error",
              description:
                "You did not authenticate successfully! Please try again.",
              type: "error",
            })
          );
        }
        break;
      }
      case UNAUTHORIZED: {
        yield put(connectActions.authenticateFailure());
        yield put(
          notificationActions.addNotification({
            message: "Error",
            description:
              "You did not authenticate successfully! Please try again.",
            type: "error",
          })
        );
        break;
      }
    }
  } catch (e) {
    console.log(e); // tslint:disable-line no-console
  }
}

export function* authenticateAfterPasswordChange(
  action: connectTypes.IAuthenticateAfterPasswordReset
): any {
  try {
    const languageCode = yield select(getAPILangCode);
    const { username, password } = action.payload;
    yield put(connectActions.sendingAuthRequest());
    const response = yield call(authentication.authenticate, {
      username,
      password,
    });
    yield put(connectActions.receivedAuthResponse());
    const { status } = response;
    switch (status) {
      case OK: {
        const body = yield response.json();
        if (body.access_token) {
          const { access_token } = body;
          setToken(access_token);
          const navigationResponse = yield genFetch(
            authentication.getMenuItems(languageCode)
          )(false)(withToken());
          let items: IMenuItems[] = [];
          if (navigationResponse.status === OK) {
            const navBody: INavigationItem[] = yield navigationResponse.json();
            items = menuHelper(navBody);
          }
          yield put(connectActions.authenticateSuccess(items, false));
          yield put(connectActions.LIPC_2(action.payload));
          yield put(
            notificationActions.addNotification({
              message: "Success",
              description: "You authenticated successfully!",
              type: "success",
            })
          );
        } else {
          yield put(connectActions.authenticateFailure());
          yield put(
            notificationActions.addNotification({
              message: "Error",
              description:
                "You did not authenticate successfully! Please try again.",
              type: "error",
            })
          );
        }
        break;
      }
      case UNAUTHORIZED: {
        yield put(connectActions.authenticateFailure());
        yield put(
          notificationActions.addNotification({
            message: "Error",
            description:
              "You did not authenticate successfully! Please try again.",
            type: "error",
          })
        );
        break;
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export function* logInAfterPasswordChangeStepTwo(
  action: connectTypes.IAuthenticateAfterPasswordReset
): any {
  const { username, password } = action.payload;

  const bodyObject: connectTypes.IMemberAccountPassObject = {
    mail: { value: username },
    name: { value: username },
    pass: [{ existing: "", value: password }],
  };

  yield genFetch(authentication.syncMemberPassword(bodyObject))()(withToken());
}

/**
 * Takes new user account information and sends object to server.
 *
 * Success: It will update the user account.
 * Failure: It will indicate to the user their account was not updated.
 *
 *
 * @param action payload that contains user account information
 */
export function* updateMemberInfo(
  action: connectTypes.IUpdateMemberInfoAction
): any {
  try {
    const {
      field_name,
      field_affiliation,
      field_title,
      field_citizenship,
      field_phone,
      field_primary_lab_poc,
      field_country_of_citizenship,
    } = action.payload;

    const bodyObject: connectTypes.IUpdateMemberObject = {
      field_name: { value: field_name },
      field_phone: { value: field_phone },
      field_title: { value: field_title },
      field_affiliation: { value: field_affiliation },
      field_citizenship: { value: field_citizenship },
      field_country_of_citizenship: { value: field_country_of_citizenship },
      field_primary_lab_poc: [{ target_id: field_primary_lab_poc }],
    };

    yield put(connectActions.sendingMemberRequest());

    // get CSRF token
    const response = yield genFetch(authentication.getCSRFToken())()();
    const { status } = response;

    switch (status) {
      case OK: {
        // do update
        const csrfToken = yield response.text();
        const memResponse = yield call(
          authentication.updateMember,
          bodyObject,
          csrfToken,
          action.payload.id
        );

        const { status } = memResponse;

        switch (status) {
          case OK: {
            yield put(connectActions.updateMemberSuccess());
            yield put(
              notificationActions.addNotification({
                message: "Success",
                description: "You updated your information successfully!",
                type: "success",
              })
            );
            break;
          }
          case UNPROCESSABLE_ENTITY: {
            yield put(connectActions.updateMemberFailure());
            yield put(
              notificationActions.addNotification({
                message: "Error",
                description: "Failed to update information!",
                type: "error",
              })
            );
            break;
          }
          default:
            yield put(connectActions.updateMemberFailure());
            yield put(
              notificationActions.addNotification({
                message: "Error",
                description: "Failed to update information!",
                type: "error",
              })
            );
            break;
        }
        break;
      }
      case UNAUTHORIZED: {
        yield put(connectActions.updateMemberFailure());
        yield put(
          notificationActions.addNotification({
            message: "Error",
            description: "Failed to update information!",
            type: "error",
          })
        );
        break;
      }
      default:
        yield put(connectActions.updateMemberFailure());
        break;
    }

    yield put(connectActions.receivedMemberResponse());
  } catch (e) {
    console.log(e); // tslint:disable-line no-console
  }
}

export function* updateMemberPassword(
  action: connectTypes.IUpdateMemberPasswordAction
): any {
  try {
    const { mail, newPassword, oldPassword, id } = action.payload;

    const bodyObject: connectTypes.IMemberAccountPassObject = {
      mail: { value: mail },
      name: { value: mail },
      pass: [{ existing: oldPassword, value: newPassword }],
    };

    if (hasToken()) {
      yield put(connectActions.sendingMemberRequest());

      // get CSRF token
      const response = yield genFetch(authentication.getCSRFToken())()();
      const { status } = response;

      switch (status) {
        case OK: {
          // do update
          const csrfToken = yield response.text();
          const memResponse = yield call(
            authentication.updateMemberPassword,
            bodyObject,
            csrfToken,
            id
          );

          const { status } = memResponse;

          switch (status) {
            case OK: {
              yield genFetch(authentication.syncMemberPassword(bodyObject))()(
                withToken()
              );
              yield put(connectActions.updateMemberSuccess());
              yield put(
                notificationActions.addNotification({
                  message: "Success",
                  description: "Updated information successfully!",
                  type: "success",
                })
              );
              break;
            }
            case UNPROCESSABLE_ENTITY: {
              yield put(connectActions.updateMemberFailure());
              yield put(
                notificationActions.addNotification({
                  message: "Error",
                  description: "Failed to update information!",
                  type: "error",
                })
              );
              break;
            }
            default:
              yield put(connectActions.updateMemberFailure());
              yield put(
                notificationActions.addNotification({
                  message: "Error",
                  description: "Failed to update information!",
                  type: "error",
                })
              );
              break;
          }
          break;
        }
        case UNAUTHORIZED: {
          yield put(connectActions.updateMemberFailure());
          yield put(
            notificationActions.addNotification({
              message: "Error",
              description: "Failed to update information!",
              type: "error",
            })
          );
          break;
        }
        default:
          yield put(connectActions.updateMemberFailure());
          break;
      }

      yield put(connectActions.receivedMemberResponse());
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getMemberInfo(): any {
  if (hasToken()) {
    //TODO: Need to curry and break things ups... maybe curry will not require the breakup?
    try {
      //get id
      const tokenResponse = yield call(authentication.memberToken);

      const { status } = tokenResponse;

      switch (status) {
        case OK: {
          //get member id
          const body = yield tokenResponse.json();
          const { id, roles } = body;

          //get member info
          const response = yield call(authentication.memberInfo, id);
          const { status } = response;
          switch (status) {
            case OK: {
              const memberBody = yield response.json();
              memberBody["nid"] = id;
              // Hack to put roles into user;
              memberBody["roles"] = roles.map((role: string) => ({
                target_id: role,
              }));
              yield put(connectActions.getMemberAccountSuccess(memberBody));

              break;
            }
            case UNAUTHORIZED:
              yield put(connectActions.getMemberAccountFailure());
              break;
          }
          break;
        }
        case UNAUTHORIZED: {
          yield put(connectActions.getMemberAccountFailure());
          break;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export function* verifyToken(action: connectTypes.IBecomeMemberAction): any {
  try {
    let isAuthenticated = false;

    if (hasToken()) {
      const authenticatedResponse = yield genFetch(
        authentication.verifyToken()
      )()(withToken());

      switch (authenticatedResponse.status) {
        case OK:
          isAuthenticated = true;
          break;
        default:
          removeToken();
          break;
      }
    }

    yield put(
      connectActions.verifiedTokenResults({ isVerified: isAuthenticated })
    );
  } catch (error) {
    console.log(error); // tslint:disable-line no-console
  }
}

interface NoMenuItems {
  content: [];
}
const menuHelper = (body: INavigationItem[] | NoMenuItems): IMenuItems[] => {
  let items: IMenuItems[] = [];
  if (Array.isArray(body)) {
    items = body.map((NavItem: INavigationItem) => {
      const primary: boolean = Number(NavItem.primary) === 1 ? true : false;

      // TODO: Fix - API Alignment.
      // TODO: Fix - data_url -> substring '/' character.
      let align: "left" | "right" = "left";
      switch (NavItem.align.toLocaleLowerCase()) {
        case "left":
          align = "left";
          break;
        case "right":
          align = "right";
          break;
      }

      const item: IMenuItems = {
        item: {
          type: "link",
          primary,
          text: NavItem.title,
          to: NavItem.data_url,
          side: align,
          image_url: NavItem.icon,
        },
        props: {},
      };

      if (NavItem.children && Array.isArray(NavItem.children)) {
        item.item.children = menuHelper(NavItem.children);
      }

      return item;
    });
  }

  return items;
};

/**
 * (1) Does a user have a Token
 *     (a) is it valid? - Update the store.
 * (2) Grab the appropriate Navigation items.
 */
export function* initializeApplication(
  action: connectTypes.IBecomeMemberAction
): any {
  try {
    let isAuthenticated: boolean = false;
    const languageCode = yield select(getAPILangCode);

    if (hasToken()) {
      const authenticatedResponse = yield genFetch(
        authentication.verifyToken()
      )()(withToken());

      switch (authenticatedResponse.status) {
        case OK:
          isAuthenticated = true;
          break;
        default:
          removeToken();
          break;
      }

      yield put(
        connectActions.verifiedTokenResults({ isVerified: isAuthenticated })
      );
    }

    const response = yield genFetch(authentication.getMenuItems(languageCode))(
      !isAuthenticated
    )(withToken());

    const workingGroups = yield genFetch(
      authentication.getVocabularyByMachineName("sub_working_groups")
    )()(withToken());

    if (workingGroups.status === OK) {
      const data = yield workingGroups.json();
      yield put(setWorkingGroupVocabulary(data));
    }

    let isMemberState = false;
    const userResponse = yield authentication.memberToken();

    if (userResponse.status === OK) {
      const user = yield userResponse.json();
      if (user.roles) {
        const isMember = user.roles.some((o: any) => o === "member_state");
        if (isMember) {
          isMemberState = isMember;
        }
      }
    }

    const workingGroupsMenu = yield genFetch(
      authentication.getWorkingGroupMenuItems(isMemberState)
    )()(withToken());

    if (workingGroupsMenu.status === OK) {
      const data = yield workingGroupsMenu.json();
      yield put(setWorkingGroupMenu(data));
    }

    const { status } = response;
    switch (status) {
      case OK: {
        const body: INavigationItem[] = yield response.json();
        const items: IMenuItems[] = menuHelper(body);
        yield put(
          connectActions.initializeApplicationSuccess(items, !isAuthenticated)
        );
        break;
      }
      case UNAUTHORIZED: {
        break;
      }
      default:
        yield put(connectActions.initializeApplicationFailure());
        break;
    }
  } catch (error) {
    console.log(error); // tslint:disable-line no-console
  }
}

export function* getMenuItems(action: any): any {
  try {
    const code =
      action.payload.languageCode === "en" ? "" : action.payload.languageCode;
    const isPublic = yield select(isApiPublic);
    const response = yield genFetch(authentication.getMenuItems(code))(
      isPublic
    )(withToken());

    const { status } = response;
    switch (status) {
      case OK: {
        const body: INavigationItem[] = yield response.json();
        const items: IMenuItems[] = menuHelper(body);
        yield put(connectActions.setMenuItems(items, isPublic));
        break;
      }
      case UNAUTHORIZED: {
        break;
      }
      default:
        yield put(connectActions.initializeApplicationFailure());
        break;
    }
  } catch (error) {
    console.log(error); // tslint:disable-line no-console
  }
}

export function* becomeAMember(action: connectTypes.IBecomeMemberAction): any {
  try {
    const {
      fullName,
      address,
      business,
      city,
      country,
      emailAddress,
      password,
      position,
      workNumber,
    } = action.payload;

    yield put(updateFormLoading(true));

    const bodyObject: connectTypes.IBecomeMember = {
      name: { value: emailAddress },
      pass: { value: password },
      mail: { value: emailAddress },
      field_business_street_address: { value: address },
      field_city: { value: city },
      field_country_of_citizenship: { value: country },
      field_employer_business_name: { value: business },
      field_name: { value: fullName },
      field_phone: { value: workNumber },
      field_title: { value: position }
    };

    yield put(connectActions.sendingMemberRequest());
    /**
     * (1) Get a CSRF Session Token from Drupal
     * (2) Make Request as normal.
     */
    const CSRFResponse = yield genFetch(authentication.getCSRFToken())()();
    const CSRFToken = yield CSRFResponse.text();
    const response = yield call(
      authentication.becomeAMember,
      bodyObject,
      CSRFToken
    );

    yield put(connectActions.receivedMemberResponse());
    const { status } = response;
    switch (status) {
      case OK: {
        yield put(connectActions.becomeAMemberSuccess());
        yield put(updateFormLoading(false));
        break;
      }
      case UNAUTHORIZED: {
        yield put(connectActions.becomeAMemberFailure());
        yield put(updateFormLoading(false));
        break;
      }
      default: {
        yield put(connectActions.becomeAMemberFailure());
        yield put(updateFormLoading(false));
        break;
      }
    }
  } catch (e) {
    console.log(e); // tslint:disable-line no-console
    yield put(updateFormLoading(false));
  }
}

export function* contactUs(action: connectTypes.ISubmitContactUsAction): any {
  try {
    const { name, message, organization, mail, token, history } =
      action.payload;

    const bodyObject: connectTypes.IContactForm = {
      name,
      mail,
      organization,
      message,
      GOOGLE_VERIFICATION_KEY: token,
    };

    yield put(connectActions.sendingMemberRequest());

    /**
     * (1) Get a CSRF Session Token from Drupal
     * (2) Make Request as normal.
     */
    const CSRFResponse = yield genFetch(authentication.getCSRFToken())()();
    const CSRFToken = yield CSRFResponse.text();
    const response = yield call(
      authentication.submitContactForm,
      bodyObject,
      CSRFToken
    );
    yield put(connectActions.receivedMemberResponse());
    const { status } = response;
    switch (status) {
      case OK: {
        yield put(connectActions.becomeAMemberSuccess());
        history.push({ pathname: `/home` });
        yield put(
          notificationActions.addNotification({
            message: "Success",
            description: "Contact email sent successfully!",
            type: "success",
          })
        );
        break;
      }
      case UNAUTHORIZED: {
        yield put(connectActions.becomeAMemberFailure());
        yield put(
          notificationActions.addNotification({
            message: "Error",
            description: "Failed to send contact email!",
            type: "error",
          })
        );
        break;
      }
    }
  } catch (e) {
    console.log(e); // tslint:disable-line no-console
  }
}

export function* logMeOut(action: connectTypes.IAuthenticationAction): any {
  try {
    const languageCode = yield select(getAPILangCode);
    const isPublic = true;
    const navigationResponse = yield genFetch(
      authentication.getMenuItems(languageCode)
    )()();
    let items: IMenuItems[] = [];
    if (navigationResponse.status === OK) {
      const navBody: INavigationItem[] = yield navigationResponse.json();
      items = menuHelper(navBody);
    }
    removeToken();
    yield put(connectActions.logoutSucesss(items, isPublic));
    yield put(
      notificationActions.addNotification({
        message: "Success",
        description: "Logged out successfully!",
        type: "success",
      })
    );
  } catch (e) {
    console.log(e); // tslint:disable-line no-console
  }
}

export function* userForgotPassword(action: any): any {
  try {
    const { email } = action.payload;

    const body = {
      email,
    };

    yield put(connectActions.sendingMemberRequest());
    const response = yield genFetch(
      authentication.sendForgotPassword(body)
    )()();
    yield put(connectActions.receivedMemberResponse());

    const { status } = response;
    switch (status) {
      // tslint:disable-next-line:no-bitwise
      case UNPROCESSABLE_ENTITY | UNAUTHORIZED | INTERNAL_SERVER_ERROR: {
        yield put(connectActions.forgotPasswordFailure());
        yield put(
          notificationActions.addNotification({
            message: "Error",
            description: "Failed to send password reset email!",
            type: "error",
          })
        );
        break;
      }
      default:
        yield put(connectActions.forgotPasswordSuccess());
        yield put(
          notificationActions.addNotification({
            message: "Success",
            description: "Password reset email sent successfully!",
            type: "success",
          })
        );
        break;
    }
  } catch (e) {
    console.log(e); // tslint:disable-line no-console
    yield put(connectActions.forgotPasswordSuccess());
    yield put(
      notificationActions.addNotification({
        message: "Success",
        description: "Password reset email sent successfully!",
        type: "success",
      })
    );
  }
}

export function* maintenanceModeCheck(): any {
  try {
    const response = yield call(authentication.maintenanceCheck);

    const { status } = response;

    switch (status) {
      case SERVICE_UNAVAILABLE: {
        const message = yield response.text();
        yield put(connectActions.maintenanceResponse(message));
        break;
      }
      default:
        break;
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* connectSaga(): any {
  const { connectActionTypes } = connectTypes;
  yield takeLatest(connectActionTypes.AUTHENTICATE, authenticate);
  yield takeLatest(LanguagesActionTypes.CHANGE_LANGUAGE, getMenuItems);
  yield takeLatest(connectActionTypes.BECOME_A_MEMBER, becomeAMember);
  yield takeLatest(connectActionTypes.CONTACT_US_SUBMISSION, contactUs);
  yield takeLatest(connectActionTypes.AUTHENTICATE_SUCCESS, getMemberInfo);
  yield takeLatest(connectActionTypes.GET_MEMBER_INFO, getMemberInfo);
  yield takeLatest(
    connectActionTypes.UPDATE_MEMBER_INFO_REQUEST,
    updateMemberInfo
  );
  yield takeLatest(
    connectActionTypes.UPDATE_MEMBER_PASSWORD,
    updateMemberPassword
  );
  yield takeLatest(connectActionTypes.VERIFY_AUTHENTICATION, verifyToken);
  yield takeLatest(
    connectActionTypes.INITIALIZE_APPLICATION,
    initializeApplication
  );
  yield takeLatest(connectActionTypes.LOGOUT, logMeOut);
  yield takeLatest(connectActionTypes.FORGOT_PASSWORD, userForgotPassword);
  yield takeLatest(
    connectActionTypes.MAINTENANCE_BANNER_RESPONSE,
    maintenanceModeCheck
  );

  yield takeLatest(
    connectActionTypes.LOGIN_AFTER_PASSWORD_CHANGE,
    authenticateAfterPasswordChange
  );
  yield takeLatest(
    connectActionTypes.LOGIN_AFTER_PASSWORD_CHANGE_STEP_TWO,
    logInAfterPasswordChangeStepTwo
  );
}
