import { Action } from "redux";
import { INavigationItem } from "../navigation/types";
export interface IConnectState {
  loaded: boolean;
  sending: boolean;
  loginHappened: boolean;
  authenticated: boolean;
  public: boolean;
  failure: boolean;
  member: {};
  memberSending: boolean;
  memberFailure: boolean;
  memberUpdated: boolean;
  memberUpdateFailure: boolean;
  memberRequestSuccess: boolean;
  memberMessage: string;
  initialized: boolean;
  maintenance: boolean;
  maintenanceMessage: string;
}

export const connectActionTypes = {
  AUTHENTICATE: "AUTHENTICATE",
  AUTHENTICATE_SUCCESS: "AUTHENTICATE_SUCCESS",
  AUTHENTICATE_FAILURE: "AUTHENTICATE_FAILURE",
  AUTHENTICATE_SENDING_REQUEST: "AUTHENTICATE_SENDING_REQUEST",
  AUTHENTICATE_RECEIVED_RESPONSE: "AUTHENTICATE_RECEIVED_RESPONSE",
  VERIFY_AUTHENTICATION: "VERIFY_AUTHENTICATION",
  VERIFY_AUTHENTICATION_SUCCESS: "VERIFY_AUTHENTICATION_SUCCESS",
  VERIFY_AUTHENTICATION_FAILURE: "VERIFY_AUTHENTICATION_FAILURE",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  BECOME_A_MEMBER: "BECOME_A_MEMBER",
  BECOME_A_MEMBER_SUCCESS: "BECOME_A_MEMBER_SUCCESS",
  BECOME_A_MEMBER_FAILURE: "BECOME_A_MEMBER_FAILURE",
  BECOME_A_MEMBER_SENDING_REQUEST: "BECOME_A_MEMBER_SENDING_REQUEST",
  BECOME_A_MEMBER_RECEIVED_RESPONSE: "BECOME_A_MEMBER_RECEIVED_RESPONSE",
  BECOME_A_MEMBER_RESET: "BECOME_A_MEMBER_RESET",
  CONTACT_US_SUBMISSION: "CONTACT_US_SUBMISSION",
  CONTACT_US_SUBMISSION_SUCCESS: "CONTACT_US_SUBMISSION_SUCCESS",
  CONTACT_US_SUBMISSION_FAILURE: "CONTACT_US_SUBMISSION_FAILURE",
  CONTACT_US_SUBMISSION_SENDING_REQUEST:
    "CONTACT_US_SUBMISSION_SENDING_REQUEST",
  CONTACT_US_SUBMISSION_RECEIVED_RESPONSE:
    "CONTACT_US_SUBMISSION_RECEIVED_RESPONSE",
  GET_MEMBER_INFO: "GET_MEMBER_INFO",
  GET_MEMBER_INFO_SUCCESS: "GET_MEMBER_INFO_SUCCESS",
  GET_MEMBER_INFO_FAILURE: "GET_MEMBER_INFO_FAILURE",
  UPDATE_MEMBER_INFO_REQUEST: "UPDATE_MEMBER_INFO_REQUEST",
  UPDATE_MEMBER_PASSWORD: "UPDATE_MEMBER_PASSWORD",
  UPDATE_MEMBER_SUCCESS: "UPDATE_MEMBER_SUCCESS",
  UPDATE_MEMBER_FAILURE: "UPDATE_MEMBER_FAILURE",
  GET_NAVIGATION_ITEMS: "GET_NAVIGATION_ITEMS",
  GET_NAVIGATION_ITEMS_SUCCESS: "GET_NAVIGATION_ITEMS_SUCCESS",
  GET_NAVIGATION_ITEMS_FAILURE: "GET_NAVIGATION_ITEMS_FAILURE",
  INITIALIZE_APPLICATION: "INITIALIZE_APPLICATION",
  INITIALIZE_APPLICATION_SUCCESS: "INITIALIZE_APPLICATION_SUCCESS",
  INITIALIZE_APPLICATION_FAILURE: "INITIALIZE_APPLICATION_FAILURE",
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  SEND_FORGOT_PASSWORD: "SEND_FORGOT_PASSWORD",
  SEND_FORGOT_PASSWORD_SUCESSS: "SEND_FORGOT_PASSWORD",
  SEND_FORGOT_PASSWORD_FAILURE: "SEND_FORGOT_PASSWORD_FAILURE",
  ACKNOWLEDGE_WARNING: "ACKNOWLEDGE_WARNING",
  MAINTENANCE_BANNER_RESPONSE: "MAINTENANCE_BANNER_RESPONSE",
  LOGIN_AFTER_PASSWORD_CHANGE: "LOGIN_AFTER_PASSWORD_CHANGE",
  LOGIN_AFTER_PASSWORD_CHANGE_STEP_TWO: "LOGIN_AFTER_PASSWORD_CHANGE_STEP_TWO",
};

export interface IAction extends Action {
  type: string;
}

interface AuthObject {
  username: string;
  password: string;
}

export interface IBecomeMemberObject {
  fullName: string;
  emailAddress: string;
  title: string;
  password: string;
  position: string;
  business: string;
  address: string;
  city: string;
  country: string;
  workNumber: string;
}

export interface IMemberObject {
  field_name: string;
  field_affiliation: string;
  field_title: string;
  field_citizenship: string;
  field_country_of_citizenship: string;
  field_phone: string;
  field_primary_lab_poc: string;
  id: string;
}

export interface IMemberPassObject {
  mail: string;
  oldPassword: string;
  newPassword: string;
  id: string;
}

export interface IMemberAccountPassObject {
  pass: DrupalPassValueObjects[];
  mail: DrupalValueObjects;
  name: DrupalValueObjects;
}

export interface IMemberAccountInfoObject {
  field_affiliation: DrupalValueObjects;
  field_citizenship: DrupalValueObjects[];
  field_country_of_citizenship: DrupalValueObjects[];
  field_name: DrupalValueObjects[];
  field_phone: DrupalValueObjects[];
  field_primary_lab_poc: DrupalValueObjects[];
  field_title: DrupalValueObjects[];
  mail: DrupalValueObjects[];
  name: DrupalValueObjects[];
  roles: string[];
  id: string;
}

export interface IContactFormObject {
  mail: string;
  message: string;
  name: string;
  organization: string;
  token: string;
  history: any;
}

export interface IUser {
  username: string;
  password: string;
}

export interface IBecomeMember {
  name: DrupalValueObjects;
  pass: DrupalValueObjects;
  mail: DrupalValueObjects;
  field_business_street_address: DrupalValueObjects;
  field_city: DrupalValueObjects;
  field_country_of_citizenship: DrupalValueObjects;
  field_employer_business_name: DrupalValueObjects;
  field_name: DrupalValueObjects;
  field_title: DrupalValueObjects;
  field_phone: DrupalValueObjects;
}

export interface IUpdateMemberObject {
  field_name: DrupalValueObjects;
  field_affiliation: DrupalValueObjects;
  field_title?: DrupalValueObjects;
  field_citizenship: DrupalValueObjects;
  field_country_of_citizenship: DrupalValueObjects;
  field_phone: DrupalValueObjects;
  field_primary_lab_poc: any;
}

export interface IContactForm {
  name: string;
  mail: string;
  organization: string;
  message: string;
  GOOGLE_VERIFICATION_KEY: string;
}

export interface IForgotPasswordForm {
  name: string;
  op?: string;
  form_id?: string;
}

export interface IBannerObject {
  message: any;
}

export interface IBannerResponse extends IAction {
  payload: IBannerObject;
}
interface DrupalValueObjects {
  value: string;
}

interface DrupalTargetObjects {
  target_id: string;
}

interface DrupalPassValueObjects {
  existing: string;
  value: string;
}

interface ForgotPasswordObject {
  username: string;
}

interface INavigation {
  items: INavigationItem[];
}

export interface ITokenResults {
  isVerified: boolean;
}

export interface IPasswordSync {
  username: string;
  password: string;
}

export interface IAuthenticationAction extends IAction {
  payload: AuthObject;
}

export interface IVerifiedTokenAction extends IAction {
  payload: ITokenResults;
}

export interface IBecomeMemberAction extends IAction {
  payload: IBecomeMemberObject;
}

export interface IUpdateMemberInfoAction extends IAction {
  payload: IMemberObject;
}

export interface IGetMemberInfoAction extends IAction {
  payload: IMemberAccountInfoObject;
}

export interface IUpdateMemberPasswordAction extends IAction {
  payload: IMemberPassObject;
}

export interface ISubmitContactUsAction extends IAction {
  payload: IContactFormObject;
}

export interface IForgotPassword extends IAction {
  payload: ForgotPasswordObject;
}

export interface IInitializeApplication extends IAction {
  payload: INavigation;
}

export interface IForgotPasswordFormPayload extends IAction {
  payload: IForgotPasswordForm;
}

export interface IAuthenticateAfterPasswordReset extends IAction {
  payload: IPasswordSync;
}

export type ConnectActions =
  | IAction
  | IAuthenticationAction
  | IInitializeApplication;

export default connectActionTypes;
