import styled from "styled-components";
import { StyledForm } from "view/utils/forms/styles";

export const TwoColumnFrom = styled(StyledForm)`
  display: flex;
  max-width: 700px;
  width: 100%;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  width: 100%;
  padding: 0 1rem;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

export const FullColumn = styled.div`
  padding: 0 1rem;
`;
