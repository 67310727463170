import { GenericTypes } from 'state/utils';

/**
 * Language's Typed Reducer State
 */
export interface ILanguageState {
  code: LanguageCode;
  direction: LanguageDirection;
}

export type LangType = 'left2Right' | 'Right2Left';

export enum LanguageDirection {
  LEFT_TO_RIGHT = 'LEFT_TO_RIGHT',
  RIGHT_TO_LEFT = 'RIGHT_TO_LEFT',
}

/**
 * Enum class that determines the
 * the users language
 */
export enum LanguageCode {
  ENGLISH = 'en',
  ARABIC = 'ar',
  ESPANOL = 'es',
  FRENCH = 'fr',
  RUSSIAN = 'ru',
}

export interface ITranslations {
  [x: string]: string;
}
export interface ITranslationArray {
  [x: string]: string[];
}

export interface Content {
  [x: string]: ContentItem;
}

export interface ContentItem {
  text: ITranslations;
}

export const getLanguageDirection = (LangCode: LanguageCode): LanguageDirection => {
  switch (LangCode) {
    case LanguageCode.ENGLISH: {
      return LanguageDirection.LEFT_TO_RIGHT;
    }
    case LanguageCode.ARABIC: {
      return LanguageDirection.RIGHT_TO_LEFT;
    }
    case LanguageCode.ESPANOL: {
      return LanguageDirection.LEFT_TO_RIGHT;
    }
    case LanguageCode.FRENCH: {
      return LanguageDirection.LEFT_TO_RIGHT;
    }
    case LanguageCode.RUSSIAN: {
      return LanguageDirection.LEFT_TO_RIGHT;
    }
  }
};

export const LanguagesActionTypes = {
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  CHANGE_LANGUAGE_SUCCESS: 'CHANGE_LANGUAGE_SUCCESS',
};

export interface IChangeLanguagePayload {
  languageCode: LanguageCode;
}

export interface IChangeLanguageAction extends GenericTypes.IAction {
  payload: IChangeLanguagePayload;
}

export type LanguagesActions = GenericTypes.IAction | IChangeLanguageAction;

export default LanguagesActionTypes;
