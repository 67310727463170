import React, { useCallback, useState } from "react";
import { forgotPassword } from "state/ducks/connect/actions";
import { Form, Input } from "antd";
import { ILanguageState } from "state/ducks/languages/types";
import { IConnectState } from "state/ducks/connect/types";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  MainContainer,
  FormContainer,
  LabeledFormItem,
  StyledForm,
  Button,
  FormLink,
  Logo,
  FormText,
} from "view/utils/forms/styles";
import {
  HeaderContainer,
  HeaderText,
  NavigationContainer,
  StyledMenuContainer,
} from "view/navigation/styles";

interface ForgotPasswordProps {
  languages: ILanguageState;
  connect: IConnectState;
  forgotPassword: typeof forgotPassword;
}
const ForgotPassword = (props: ForgotPasswordProps) => {
  const [form] = Form.useForm();
  const [token, setToken] = useState<string | null>(null);

  const verifyRecaptchaCallback = useCallback((token: string) => {
    setToken(token);
  }, []);

  const isValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo) {
      return false;
    }
  };

  const handleSubmit = async () => {
    const formIsValid = await isValid();
    if (formIsValid && token) {
      const { email } = form.getFieldsValue();
      props.forgotPassword({ email });
    }
  };

  return (
    <>
      <NavigationContainer style={{ backgroundColor: "#f9f9f9" }}>
        <StyledMenuContainer style={{ backgroundColor: "#f9f9f9" }}>
          <HeaderContainer>
            <HeaderText purpose="title" style={{ color: "#202C47" }}>
              GLOBAL PARTNERSHIP
            </HeaderText>
            <HeaderText style={{ color: "#202C47" }}>
              Against the Spread of Weapons and Materials of Mass Destruction
            </HeaderText>
          </HeaderContainer>
        </StyledMenuContainer>
      </NavigationContainer>
      <MainContainer style={{ height: "calc(100% - 128px)" }}>
        <div>
          <Logo maxWidth="350px" src="/images/icons/italy24/gp-g7-stacked.png" />
          <FormContainer>
            <StyledForm layout="vertical" form={form}>
              <FormText>
                {
                  "Please enter and submit the email address associated with your account. \
             Instructions for resetting your password will be sent to this email address."
                }
              </FormText>
              <LabeledFormItem
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input />
              </LabeledFormItem>
              <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
              <Form.Item style={{ padding: "1rem 0" }}>
                <Button onClick={handleSubmit}>Request Password Reset</Button>
              </Form.Item>
              <FormLink to={`/${props.languages.code}/login`}>
                Back to Login
              </FormLink>
              <FormLink
                as="a"
                href="https://www.pnnl.gov/security-privacy"
                target="_blank"
              >
                Privacy Policy
              </FormLink>
            </StyledForm>
          </FormContainer>
        </div>
      </MainContainer>
    </>
  );
};

export default ForgotPassword;
