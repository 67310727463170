import React from "react";
import styled from "styled-components";
import { Image } from "state/ducks/columned/types";
import { imagePathToURL } from "state/utils";

interface Props {
  data: Image;
}

interface ImageProps {
  padding?: string;
}

export const StyledImage = styled.img<ImageProps>`
  margin: 0;
  width: 100%;
  height: auto;
  padding: ${(props) => (props.padding ? props.padding : 0)};
`;

const ImageNode: React.FC<Props> = (props) => {
  const { data } = props;
  const { field_image, field_padding } = data;

  let linkHref = "";
  let altText = "";
  let fileMimeType = "";
  let fileSize = "";
  let titleText = "";

  const hasPadding =
    field_padding && field_padding.length > 0
      ? `${field_padding[0].value}rem`
      : undefined;

  if (field_image.length > 0) {
    const image = field_image[0];
    const { uri, alt, filemime, filesize, title } = image;
    linkHref = uri.length > 0 ? imagePathToURL(uri[0].url) : ""; //eslint-disable-line
    altText = alt.length > 0 ? alt[0].value : ""; //eslint-disable-line
    fileMimeType = filemime.length > 0 ? filemime[0].value : ""; //eslint-disable-line
    fileSize = filesize.length > 0 ? filesize[0].value : ""; //eslint-disable-line
    titleText = title.length > 0 ? title[0].value : ""; //eslint-disable-line
  }

  return <StyledImage src={linkHref} alt={altText} padding={hasPadding} />;
};

export default ImageNode;
