
export enum VocabularyTypes {
  SET_WORKING_GROUP_VOCABULARY = "SET_WORKING_GROUP_VOCABULARY",
  SET_WORKING_GROUP_MENU = "SET_WORKING_GROUP_MENU"
  
}

export const setWorkingGroupVocabulary = (data:any) => ({
  type: VocabularyTypes.SET_WORKING_GROUP_VOCABULARY,
  payload: data
})

export const setWorkingGroupMenu = (data:any) => ({
  type: VocabularyTypes.SET_WORKING_GROUP_MENU,
  payload: data
})

