import React, { useEffect } from "react";
import { notification } from "antd";
import { IMessage } from "state/ducks/notifications/types";
import { notificationActions } from "state/ducks/notifications";

interface INotifyProps {
  messages: IMessage[];
  removeNotification: typeof notificationActions.removeNotification;
}

// Render all notifications
const renderNotifications = (
  messages: IMessage[],
  removeNotification: typeof notificationActions.removeNotification
) => {
  messages.map((message: IMessage) => {
    notification[message.type]({
      message: message.message,
      description: message.description,
    });
    removeNotification();
    return null;
  });
};

/**
 * Used to render app notifications.
 *
 * @param props IMessage
 */
const Notify = (props: INotifyProps): any => {
  const { messages, removeNotification } = props;

  useEffect(() => renderNotifications(messages, removeNotification), [
    messages,
    removeNotification,
  ]);

  // Render empty component to spawn notifications
  return <div style={{ display: "none" }} />;
};

export default Notify;
