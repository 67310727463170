import { Reducer } from "redux";
import { VocabularyState } from "state/ducks/vocabulary/types";
import { VocabularyTypes } from "./actions";

const initialState: VocabularyState = {
  subWorkingGroups: [],
  subWorkingGroupsMenu: [],
  archive:{},
};

const reducer: Reducer<VocabularyState> = (
  state: VocabularyState = initialState,
  action
) => {
  const stateCopy: VocabularyState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case VocabularyTypes.SET_WORKING_GROUP_VOCABULARY:
      return { ...stateCopy, subWorkingGroups: action.payload };
    case VocabularyTypes.SET_WORKING_GROUP_MENU:
      return { ...stateCopy, subWorkingGroupsMenu: action.payload };
    default:
      return stateCopy;
  }
};

export default reducer;
