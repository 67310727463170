import styled from "styled-components";

export const NoDataContainer = styled.div`
  background-color: rgb(242, 242, 242);
  border: solid 4px ${(props) => props.theme.gpwmd.green};
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  > h3 {
    margin-bottom: 0;
    color: ${(props) => props.theme.gpwmd.darkTan};
  }
`;
