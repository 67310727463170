import { isNumber } from 'util';

/**
 * Builds a Querystring using a object key'd with params
 *   To query a date range you must include params 'max' and 'min' with an appropriate date.
 *   To query a Domestic or International you must include a 'type' parameter.
 *   To Change a page - you must include a 'page' parameter.
 * @param o A object with keyed Query Parameters
 */
export const getQueryStringFromSearchObject = (o: any): string => {
  let queryString = '';
  let typeString = '';
  let pageString = '';
  let pageNum = 1;

  if (o.query) {
    queryString = `search_api_fulltext=${o.query}`;
  }

  if (o.type && o.type === 'news') {
    typeString = `${queryString ? '&' : ''}type[0]=${o.type}`;
  } else {
    // We can add to these later and generate the filters
    const types = ['news', 'public_page', 'member_page'];

    // Build the string array
    const filters = types.map((type, index) => {
      return `type[${index}]=${type}`;
    });
    // Join for the url
    const combinedFilters = filters.join('&');
    typeString = `${queryString ? '&' : ''}${combinedFilters}`;
  }

  if (o['page'] && isNumber(Number(o['page']))) {
    const num = Number(o['page']);
    pageNum = num - 1 <= 0 ? 0 : num - 1;
  }

  pageString = `${queryString || typeString ? '&' : ''}page=${pageNum}`;

  const JSONAdd = `${queryString || typeString || pageString ? '&' : ''}_format=json`;

  return `?${queryString}${typeString}${pageString}${JSONAdd}`;
};
