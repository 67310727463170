export interface TopMenu {
  public: TopItem[];
  member: TopItem[];
}

export interface TopItem {
  label: string;
  to: string;
}

export const topNavigationItems: TopMenu = {
  public: [
    // {
    //   label: "Join GPWMD",
    //   to: "/register",
    // },
    // {
    //   label: "Login",
    //   to: "/login",
    // },
  ],
  member: [
    {
      label: "Logout",
      to: "/logout",
    },
  ],
};
