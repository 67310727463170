import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IApplicationState } from "state/ducks";
import { eventSearchActions, eventSearchTypes } from "state/ducks/events";
import Calendar from "./calendar";

const mapStateToProps = (state: IApplicationState) => ({
  ...state,
});

type actions = eventSearchTypes.EventSearchActionsInterfaces;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators(
    {
      getEventArchive: eventSearchActions.searchFor,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
