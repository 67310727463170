import { ParseTypes } from "../../utils/parser/parserTypes";
import { ParseItem } from "state/ducks/columned/types";

const findType = (type: string): ParseTypes => {
  // TODO: Missing Parse Types: IMAGE, NOTYPE
  const {
    CARD,
    COL,
    FAQ_CATEGORY,
    ROW,
    FAQ_ITEM,
    BULLET_LIST,
    LINK,
    PARTICIPANTS_LIST,
  } = ParseTypes;
  switch (type) {
    case ROW:
      return ROW;
    case COL:
      return COL;
    case CARD:
      return CARD;
    case BULLET_LIST:
      return BULLET_LIST;
    case LINK:
      return LINK;
    case FAQ_CATEGORY:
      return FAQ_CATEGORY;
    case PARTICIPANTS_LIST:
      return PARTICIPANTS_LIST;
    case FAQ_ITEM:
      return FAQ_ITEM;
    default:
      return ParseTypes.NOTYPE;
  }
};

/**
 * Called by @see {@link columnedParser}
 * Takes object and parses out needed information to put in store of app
 * @param data
 */

/**
 * Function called from by columnedData saga
 * Takes a returned reponse from api
 * @param {Object}
 */

export const columnedParser = {
  filterContent(content: ParseItem[]): any {
    // TODO: Missing Parse Types: IMAGE, NOTYPE
    const {
      CARD,
      COL,
      FAQ_CATEGORY,
      ROW,
      FAQ_ITEM,
      BULLET_LIST,
      PARTICIPANTS_LIST,
    } = ParseTypes;
    const data = content.map((contentItem: any) => {
      const { type, field_paragraph } = contentItem;
      if (type.length === 1) {
        const { target_id } = type[0];

        const target = findType(target_id);
        if (
          target === ROW ||
          target === COL ||
          target === CARD ||
          target === FAQ_CATEGORY ||
          target === FAQ_ITEM ||
          target === BULLET_LIST ||
          target === PARTICIPANTS_LIST
        ) {
          switch (target) {
            case CARD: {
              return {
                id: contentItem['id'],
                type: target_id,
                header: contentItem["field_header"],
                header_paragraph: contentItem["field_header_paragraph"],
                items: columnedParser.filterContent(field_paragraph),
              };
            }
            case BULLET_LIST: {
              return {
                id: contentItem['id'],
                type: target_id,
                header_paragraph: columnedParser.filterContent(
                  contentItem["field_header_paragraph"]
                ),
                items: columnedParser.filterContent(field_paragraph),
              };
            }
            case FAQ_CATEGORY: {
              return {
                id: contentItem['id'],
                type: target_id,
                header: contentItem["field_header"],
                header_paragraph: contentItem["field_header_paragraph"],
                items: columnedParser.filterContent(field_paragraph),
              };
            }
            case FAQ_ITEM: {
              return {
                id: contentItem['id'],
                type: target_id,
                header: contentItem["field_header"],
                header_paragraph: contentItem["field_header_paragraph"],
                items: columnedParser.filterContent(field_paragraph),
              };
            }
            case COL: {
              return {
                id: contentItem['id'],
                type: target_id,
                span: contentItem["field_span"],
                images: contentItem["field_image_flag"],
                border: contentItem["field_border"],
                items: columnedParser.filterContent(field_paragraph),
              };
            }
            case PARTICIPANTS_LIST: {
              return {
                id: contentItem['id'],
                type: target_id,
                field_list: contentItem["field_list"],
              };
            }
            default: {
              return {
                id: contentItem['id'],
                type: target_id,
                images: contentItem["field_image_flag"],
                border: contentItem["field_border"],
                items: columnedParser.filterContent(field_paragraph),
              };
            }
          }
        }
        return {
          id: contentItem['id'],
          type: target_id,
          items: contentItem,
        };
      }
      return null;
    });
    return data;
  },
};
