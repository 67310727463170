import { Link } from "react-router-dom";
import styled from "styled-components";

export const ParticipateHeader = styled.div`
  background: ${(props) => props.theme.gpwmd.slate};
  display: flex;
  padding: 4rem 4rem;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
export const QuestionnaireLink = styled(Link)`
  padding: 1rem 4rem;
  height: 60px;
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.secondary};
  background-color: ${(props) => props.theme.gpwmd.green};
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  & h1 {
    font-size: 1.1rem;
  }
`;

export const TextContainerBanner = styled.div`
  max-width: 600px;
  margin-right: 2rem;

  & h1 {
    font-family: ${(props) => props.theme.fonts.heading};
    font-size: 1.5rem;
    font-weight: 600;
  }
`;
