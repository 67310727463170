import { ActionCreator } from 'redux';
import eventSearchActionTypes, {
  IQueryPayloadAction,
  IQueryResultsAction,
  IEventQueryMessage,
  IQueryChangeSearchPageAction,
} from './types';

export const searchFor: ActionCreator<IQueryPayloadAction> = (queryString: string) => ({
  type: eventSearchActionTypes.EVENTS_SEARCH,
  payload: {
    query: queryString,
  },
});

export const changeSearchPage: ActionCreator<IQueryChangeSearchPageAction> = (
  queryString: string,
  page: number
) => ({
  type: eventSearchActionTypes.EVENTS_NEXT_PAGE,
  payload: {
    page,
    query: queryString,
  },
});

export const searchResults: ActionCreator<IQueryResultsAction> = (
  queryResults: IEventQueryMessage,
  queryString: string
) => ({
  type: eventSearchActionTypes.EVENTS_SEARCH_SUCCESS,
  payload: {
    results: queryResults,
    query: queryString,
  },
});
