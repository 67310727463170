import { ActionCreator } from 'redux';
import { INotificationAction, notificationActionTypes, IMessage } from './types';
import { IAction } from 'state/utils/redux/types';

export const addNotification: ActionCreator<INotificationAction> = (message: IMessage) => ({
  type: notificationActionTypes.ADD_NOTIFICATION,
  payload: message,
});

export const removeNotification: ActionCreator<IAction> = (message: IMessage) => ({
  type: notificationActionTypes.REMOVE_NOTIFICATION,
});
