import { getToken } from '../authentication';
import { OK } from 'http-status-codes';

export type IRoute = ((prefix: string) => string) | (() => string);

export interface IRequest {
  route: any;
  options: RequestInit;
}

export const genericHeaders = (): HeadersInit => ({
  ContentType: 'application/json',
  Accept: 'application/json',
});

export const getApiAttribute = (isPublic: boolean) => (isPublic ? 'public' : 'member');

export const genFetch = (request: IRequest) => {
  return (isPublic: boolean = true) => {
    return (headers: HeadersInit = genericHeaders()): Promise<Response> => {
      // tslint:disable-next-line:no-console
      // console.log(`Fetch: ${request.route(getApiAttribute(isPublic))}  || Public: ${isPublic}`);
      return fetch(request.route(getApiAttribute(isPublic)), {
        ...request.options,
        headers,
      });
    };
  };
};

export const withToken = (headers = genericHeaders()): HeadersInit => ({
  ...headers,
  Authorization: `Bearer ${getToken()}`,
});

type IFallbackReturn = object | undefined;

export function* memberFallback(routeObject: IRequest): IFallbackReturn {
  try {
    const response: Response = yield genFetch(routeObject)()(withToken());
    const { status } = response;
    switch (status) {
      case OK: {
        const content = yield response.json();
        return content;
      }
      default: {
        return undefined;
      }
    }
  } catch (error) {
    return undefined;
  }
}
