import { Action } from "redux";

export interface IColumnedState {
  [key: string]: {
    label: string;
    value: [];
  };
}

export const columnedActionTypes = {
  COLUMNED: "COLUMNED",
  COLUMNED_BY_NODE: "COLUMNED_BY_NODE",
  NEWS_ARTICLE: "NEWS_ARTICLE",
  NEWS_ARCHIVE: "NEWS_ARCHIVE",
  EVENTS_ARCHIVE: "EVENTS_ARCHIVE",
  EVENTS_ARTICLE: "EVENT_ARTICLE",
  COLUMNED_RETURN_SUCCESS: "COLUMNED_RETURN_SUCCESS",
};

/**
 * ACTIONS
 */
export interface IAction extends Action {
  type: string;
}

interface ColumnedObject {
  path: string;
}

export interface IColumnedAction extends IAction {
  payload: ColumnedObject;
}

export interface IColumnedResponse extends IAction {
  payload: IColumnedState;
}

export type ColumnedActions = IAction | IColumnedAction | IColumnedResponse;


/** COLUMNED CONTENT */
export interface ParseItem {
  items: ParseItem[] & Paragraph & Image & Card & BulletedList & Paragraph[] & Link & VideoMedia;
  type: string;
  header: ParagraphType[] & CardHeader[] & Paragraph;
  header_paragraph: ParseItem[] & Paragraph & Image & Card & BulletedList & Paragraph[] & Link;
  span?: ParagraphType[];
  id: ParagraphType[];
}

export interface VideoMedia {
  field_url: ParagraphType[];
  id: ParagraphType[];
}

export interface BulletedList {
  header_paragraph: ParagraphType[];
  items: ParseItem[];
}

export interface FaqItemProps {
  header_paragraph: ParagraphType[];
  items: ParseItem[];
}

export interface Link {
  field_link: LinkType[];
  field_type: ParagraphType[];
}

export interface ImageLink extends Link {
  field_media_image: ParagraphImageMediaType[];
}

export interface ParagraphImageMediaType {
  field_description: ParagraphType[];
  field_media_image: ImageLinkURL[];
}

export interface LinkType {
  uri: string;
  title: string;
}

export interface ParseItemParent {
  renderContentNode: any;
  componentMap: any;
  items: ParseItem[];
}

export interface ParticipantsList {
  field_list: any;
}

export interface Column {
  span: ParagraphType[];
  images: ParagraphBoolean[];
  border: ParagraphBorder[];
}
export interface Row {
  images: ParagraphBoolean[];
  border: ParagraphBorder[];
}

export interface ParagraphBorder {
  field_bottom: ParagraphBoolean[];
  field_left: ParagraphBoolean[];
  field_right: ParagraphBoolean[];
  field_top: ParagraphBoolean[];
}
export interface Paragraph {
  type: string;
  field_plain_text: ParagraphType[];
  field_bold: ParagraphBoolean[];
  field_italic: ParagraphBoolean[];
  field_underline: ParagraphBoolean[];
  field_highlight: ParagraphBoolean[];
  field_font_size: ParagraphAttribute[];
  field_font: ParagraphAttribute[];
  field_highlight_color: ParagraphColorType[];
  field_inline: ParagraphBoolean[];
}

interface ParagraphAttribute {
  name: ParagraphType & string;
}
export interface BulletedListItem {
  type: ImageTypes[];
  field_plain_text: ParagraphType[];
  field_bold: ParagraphBoolean[];
  field_highlight: ParagraphBoolean[];
  field_link: LinkType[];
}

export interface Image {
  type: ImageTypes[];
  field_image: ImageURL[];
  field_padding: ParagraphType[];
  field_caption: ParseItem[] & Paragraph & Image & Card & BulletedList & Paragraph[] & Link;
}
export interface ImageURL {
  uri: ParagraphTypeURL[];
  filemime: ParagraphType[];
  filesize: ParagraphType[];
  title: ParagraphType[];
  alt: ParagraphType[];
}

export interface ImageLinkURL {
  uri: ParagraphTypeURL[];
  filemime: ParagraphType[];
  filesize: ParagraphType[];
  filename: ParagraphType[];
}

export interface ImageTypes {
  target_id: string;
  target_type: string;
  target_uuid: string;
}

export interface Card {
  type: string;
  item: Paragraph[];
  header: CardHeader[];
}

export interface CardHeader {
  value: ParagraphType;
}

export interface ParagraphType {
  value: string;
}

export interface ParagraphTypeURL {
  url: string;
}

export interface ParagraphColorType {
  color: string;
}
export interface ParagraphBoolean {
  value: boolean;
}


export default columnedActionTypes;
