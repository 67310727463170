import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MainContainer, Logo, Button, FormText } from "view/utils/forms/styles";
import { useHistory, useParams } from "react-router-dom";
import { authentication } from "state/api/authentication";
import { genFetch, withToken } from "state/utils/endpoints";
import { Radio } from "antd";
import { useDispatch } from "react-redux";
import { addNotification } from "state/ducks/notifications/actions";
import { IMessage } from "state/ducks/notifications/types";

interface Props {}
const BodyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1600px;
`;
const FormBodyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  padding: 2rem;
  max-width: 500px;
`;

const FormTextOverride = styled(FormText)`
  margin-bottom: 5px;
  padding-right: 0;
  text-align: right;
  font-weight: 500;
`;
const FormRightTextOverride = styled(FormText)`
  margin-bottom: 5px;
`;

const UserDataRow = styled.div`
  background: #f1f1f1;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px !important;
  width: 368px;
`;
const RoleSection = styled.div`
  padding: 1rem 1rem;
  border: 1px solid;
  margin: 1rem 0;
  width: 368px;
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
`;

const SectionLabel = styled.div`
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  font-size: 1rem;
  line-height: 1rem;
  background: white;
  font-weight: 600;
`;

const RoleRadio = styled(Radio)`
  :hover {
    span.ant-radio + * {
      font-weight: 500 !important;
    }
  }

  width: 150px;
  font-size: 1rem !important;
  font-family: ${(props) => props.theme.fonts.main};
`;

enum Roles {
  limited = "LIMITED",
  full = "FULL",
}

const failedMessage: IMessage = {
  type: "error",
  message: "The request failed",
  description: "ught oh.. Something went wrong",
};

export const AdminApproval = (props: Props) => {
  const { uuid } = useParams<any>();
  const [approvalData, setApprovalData] = useState<any>(null);
  const [accessType, setAccessType] = useState<Roles>(Roles.limited);
  const dispatch = useDispatch();
  const history = useHistory();
  // /admin/approvals/4ee5ae98-defa-44ff-8064-1845d2eb0600

  useEffect(() => {
    genFetch(authentication.getUserApprovalData(uuid))()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) => {
            setApprovalData(data);
          })
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [uuid]);

  // GET USER INFO
  // GET ROLES
  // GENFETCH action Approve with limited / full

  const ok = [
    { label: "Name:", dataSource: "name" },
    { label: "Email:", dataSource: "email" },
    { label: "Title:", dataSource: "title" },
    { label: "Organization:", dataSource: "employer_business_name" },
    { label: "Country:", dataSource: "country" },
    { label: "Phone number:", dataSource: "phone_number" },
  ];

  const sendUpdate = () => {
    genFetch(authentication.sendApprovalWithRole(uuid, { role: accessType }))()(
      withToken()
    )
      .then((res) =>
        res
          .text()
          .then((data: any) => {
            dispatch(
              addNotification({
                type: "info",
                message: data,
                description: "",
              })
            );
            setTimeout(() => {
              history.push("/en/home");
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
            dispatch(addNotification(failedMessage));
          })
      )
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainContainer style={{ height: "100%" }}>
      <BodyContainer>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Logo width="300px" src="/images/icons/italy24/gp-g7-stacked.png" />
        </div>
        <FormBodyContainer>
          <h2 style={{ textAlign: "center" }}>User Pending Approval</h2>
          <div>
            {approvalData && (
              <table>
                <thead></thead>
                <tbody>
                  <tr>
                    <UserDataRow>
                      <td style={{ padding: "1rem 0 1rem 1rem" }}>
                        {ok.map((item: any) => (
                          <FormTextOverride>{item.label}</FormTextOverride>
                        ))}
                      </td>
                      <td>
                        {ok.map((item: any) => (
                          <FormRightTextOverride>
                            {approvalData[item.dataSource]}
                          </FormRightTextOverride>
                        ))}
                      </td>
                    </UserDataRow>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <RoleSection>
            <SectionLabel>Select User Role</SectionLabel>
            <Radio.Group
              onChange={(e) => setAccessType(e.target.value)}
              value={accessType}
            >
              <RoleRadio value={Roles.limited}>Limited Access</RoleRadio>
              <RoleRadio value={Roles.full}>Full Access</RoleRadio>
            </Radio.Group>
          </RoleSection>
          <div style={{ width: "368px", padding: ".5rem" }}>
            <Button onClick={() => sendUpdate()}>Submit Approval</Button>
          </div>
        </FormBodyContainer>
      </BodyContainer>
    </MainContainer>
  );
};

export default AdminApproval;
