import ComboBoxFilterList from "components/ComboBoxFilterList/ComboBoxFilterList";
import {
  SEARCH_OPTIONS,
  SearchECHDefinition,
  useECHQuery,
} from "lib/hooks/useECHQuery";
import useECHVocabularies from "lib/hooks/useECHVocabularies";
import useECHSearch from "lib/hooks/useECHSearch";
import queryString from "query-string";
import { useCallback, useEffect } from "react";
import { BodyContainer } from "view/common/Layout";
import { ECHBannerButton, MainContainer } from "view/utils/forms/styles";
import {
  BannerLayout,
  ButtonContainer,
  ExportControlInfoSection,
  LeftColumn,
  ResetButton,
  RightColumn,
  TwoColumnLayout,
} from "./styles";
import ExportControlTable from "./ExportControlTable/ExportControlTable";
import { echQueryBuilder, getDefaultECHQuery } from "lib/hooks/useECHQuery";
import { useHistory } from "react-router-dom";
import SearchBar from "components/SearchBar/SearchBar";
import AboutECHDialog from "components/AboutECHDialog/AboutECHDialog";
import HowToUseDialog from "components/HowToUseDialog/HowToUseDialog";
import { sendEvent } from "lib/gtag";
import ExportControlDisclaimer from "./ExportControlDisclaimer";
import ExportControlListBox from "./ExportControlListBox/ExportControlListBox";
import ExportControlActiveFilters from "./ExportControlActiveFilters/ExportControlActiveFilters";
import { StyledListboxButton } from "./ExportControlListBox/styles";

type Props = {};

const ExportControl = (props: Props) => {
  const { vocabularies, isLoading: isVocabLoading } = useECHVocabularies();
  const query = useECHQuery();
  const { ech_filters, ech_keywords, ech_scope, ech_source } = vocabularies;
  const { results, searchECH, isLoading } = useECHSearch();
  const history = useHistory();

  const decodeQuery = useCallback(
    (query: SearchECHDefinition) => {
      const { filters, keywords, scope, sources, search } = query;

      const payload: any = {};

      if (filters.length > 0) {
        payload["filters"] = filters
          .map(
            (filter) =>
              ech_filters.find((echFilter) => echFilter.tid === filter)?.name
          )
          .filter((item) => item !== undefined);
      }

      if (keywords.length > 0) {
        payload["keywords"] = keywords
          .map(
            (keyword) =>
              ech_keywords.find((echKeyword) => echKeyword.tid === keyword)
                ?.name ?? "could not be found"
          )
          .filter((item) => item !== undefined);
      }

      if (scope.length > 0) {
        payload["scope"] = scope
          .map(
            (item) =>
              ech_scope.find((echFilter) => echFilter.tid === item)?.name ??
              "could not be found"
          )
          .filter((item) => item !== undefined);
      }

      if (sources.length > 0) {
        payload["sources"] = sources
          .map(
            (source) =>
              ech_source.find((echSource) => echSource.tid === source)?.name ??
              "could not be found"
          )
          .filter((item) => item !== undefined);
      }
      if (search) {
        payload["search"] = search;
      }

      payload["querystring"] = queryString.stringify(payload, {
        arrayFormat: "comma",
      });
      sendEvent("hub_search_query", payload);
    },
    [ech_filters, ech_keywords, ech_scope, ech_source]
  );

  useEffect(() => {
    if (!isVocabLoading) {
      const search = queryString.stringify(query, { arrayFormat: "index" });
      decodeQuery(query);
      searchECH(search);
    }
  }, [searchECH, query, decodeQuery, isVocabLoading]);

  return (
    <MainContainer>
      <BodyContainer>
        <ExportControlInfoSection>
          <BannerLayout style={{ backgroundColor: "unset" }}>
            <div>
              <h1>GP Nonproliferation and Strategic Trade Knowledge Hub</h1>
              <p>
                This tool is a central point for information and resources on
                nonproliferation of Weapons of Mass Destruction (WMD) and
                strategic trade topics. The Hub is a comprehensive, up-to-date,
                publicly available, and easily searchable library that enables
                users to be connected to resources on nonproliferation and
                strategic trade topics, covering the nuclear, chemical,
                biological, and radiological spectrum as well as export controls
                and compliance.
              </p>
            </div>
            <ButtonContainer>
              <AboutECHDialog />
              <HowToUseDialog />
              <ECHBannerButton
                as="a"
                style={{ transform: "unset" }}
                href="https://www.surveymonkey.com/r/QXYN9MR"
                target="_blank"
                rel="noopener noreferrer"
              >
                Feedback Form
              </ECHBannerButton>
            </ButtonContainer>
          </BannerLayout>
        </ExportControlInfoSection>
        <TwoColumnLayout>
          <LeftColumn
            style={{
              float: "right",
              padding: "0 0 2rem 0px",
              display: "inline-block",
              rowGap: "2rem",
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                padding: "2rem 2rem",
                width: "100%",
                backgroundColor: "#FFF",
                marginBottom: "2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "4px",
                }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ margin: 0 }}>
                    <b>Sort By:</b>
                  </p>
                  <ExportControlListBox />
                </span>
                <StyledListboxButton
                  as="button"
                  style={{ width: "auto" }}
                  onClick={() => {
                    history.push(echQueryBuilder(getDefaultECHQuery()));
                  }}
                >
                  Reset Filters
                </StyledListboxButton>
              </div>
              <div style={{ marginBottom: "4px" }}>
                <SearchBar query={query} queryBuilder={echQueryBuilder} />
              </div>
              <div style={{ marginBottom: "4px" }}>
                <ExportControlActiveFilters vocabularies={vocabularies} />
              </div>
            </div>

            <ExportControlTable entries={results} isLoading={isLoading} />
          </LeftColumn>
          <RightColumn>
            <ComboBoxFilterList
              label="Keyword"
              list={ech_keywords}
              query={query}
              dataIndex="keywords"
            />
            <ComboBoxFilterList
              label="Source"
              list={ech_source}
              query={query}
              dataIndex="sources"
            />
            <ComboBoxFilterList
              label="Resource Type"
              list={ech_filters}
              query={query}
              dataIndex="filters"
            />
            <ComboBoxFilterList
              label="WMD Scope"
              list={ech_scope}
              query={query}
              dataIndex="scope"
            />
            <ResetButton
              onClick={() => {
                history.push(echQueryBuilder(getDefaultECHQuery()));
              }}
            >
              Reset Filters
            </ResetButton>
          </RightColumn>
        </TwoColumnLayout>
        <ExportControlDisclaimer />
      </BodyContainer>
    </MainContainer>
  );
};

function ExportControlParamValidator() {
  const query = useECHQuery();
  const history = useHistory();

  const { sort_by, sort_order } = query;
  const test = JSON.stringify({ sort_by, sort_order });
  const option = SEARCH_OPTIONS.find(
    (opt) => JSON.stringify(opt.update) === test
  );

  if (option) {
    return <ExportControl />;
  }

  history.push(echQueryBuilder({ ...query, ...SEARCH_OPTIONS[0].update }));

  return null;
}

export default ExportControlParamValidator;
