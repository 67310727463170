import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

interface ILinkProps {
  primary?: string;
}

export const StyledLink = styled(Link)<ILinkProps>`
  text-decoration: none;
  width: 100%;
  ${(props) => css`
    font-size: 1rem;
    color: ${props.primary
      ? props.theme.colors.secondary
      : props.theme.gpwmd.slate};
    font-weight: ${props.primary ? 600 : 400};
    font-family: ${props.primary
      ? props.theme.fonts.heading
      : props.theme.fonts.main};
    line-height: 16px;
    letter-spacing: 1px;

    :focus {
      outline: none;
    }

    :hover,
    :focus {
      color: ${props.primary
        ? props.theme.gpwmd.slate
        : props.theme.colors.secondary};
    }
  `}
`;

export const StyledATag = styled.a<ILinkProps>`
  text-decoration: none;
  width: 100%;
  ${(props) => css`
    font-size: 1rem;
    color: ${props.primary
      ? props.theme.colors.secondary
      : props.theme.gpwmd.slate};
    font-weight: ${props.primary ? 700 : 400};
    font-family: ${props.primary
      ? props.theme.fonts.heading
      : props.theme.fonts.main};
    line-height: 16px;
    letter-spacing: 1px;

    :hover,
    :focus {
      color: ${props.primary
        ? props.theme.gpwmd.red
        : props.theme.colors.secondary};
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: ${(props) => props.theme.gpwmd.green};
`;

export const FooterContainer = styled.div`
  display: flex;
  min-height: 250px;
  justify-content: center;
  width: 100%;
  max-width: 1600px;
  height: auto;
  align-items: center;
  background: ${(props) => props.theme.gpwmd.green};

  @media (min-width: 320px) {
    flex-wrap: wrap;
  }
  @media (min-width: 768px) {
    flex-wrap: wrap;
  }
  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;
export const StyledRouteContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  @media (min-width: 320px) {
    padding: 1.5rem;
  }

  @media (min-width: 768px) {
    padding: 1.8rem;
  }

  @media (min-width: 1024px) {
    width: auto;
  }
`;

interface IfooterImgProps {
  middle?: boolean;
  fit?: string;
}

export const StyledLinkHolder = styled.div<IfooterImgProps>`
  width: 100%;
  text-align: center;
  @media (min-width: 1024px) {
    text-align: left;
  }

  ${(props) =>
    props.middle
      ? css`
          @media (min-width: 320px) {
            margin: 0.8rem 0;
          }
          @media (min-width: 768px) {
            margin: 1.8rem 0;
          }
          @media (min-width: 1024px) {
            margin: 1rem 0;
          }
        `
      : css``}
`;

export const LinkSeparator = styled.span`
  font-size: 1rem;
  color: #ffffff;
  font-weight: 800;
  font-family: ${(props) => props.theme.fonts.main};
  line-height: 16px;
  letter-spacing: 1px;
`;
