import React, { useState, useEffect } from 'react';
import { hasToken, getToken } from 'state/utils';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { authentication } from 'state/api/authentication';
import { OK } from 'http-status-codes';
import Login from 'view/login/_login';

interface IProtectedProps extends RouteProps {
  // pathname to component to load
  loadModule?: string;
  languageCode: string;
}

export class PrivateRoute extends React.PureComponent<IProtectedProps, any> {
  render() {
    const { loadModule, languageCode, component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props: any) =>
          hasToken() ? (
            Component ? (
              <Component {...props} />
            ) : rest.render ? (
              rest.render(props)
            ) : null
          ) : (
            <Redirect to={`/${languageCode}/login`} />
          )
        }
      />
    );
  }
}

export const MemberStateRoute: React.FC<any> = props => {
  const [admin, setAdmin] = useState<boolean | null>(null);

  useEffect(() => {
    authentication.memberToken()
      .then(res => {
        if (res.status === OK) {
          res.json().then(body => {
            let authenticated = false;

            // I did this because Drupal sends me a empty user object with empty arrays.
            if (body.roles) {
              authenticated = body.roles.some((o: any) => o === 'member_state');
            }

            setAdmin(authenticated);
          });
        } else {
          setAdmin(false);
        }
      })
      .catch(() => setAdmin(false));
  }, []);

  return admin === null ? <div /> : admin ? <Route {...props} /> : <Login />;
};
