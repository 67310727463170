import { ActionCreator, AnyAction } from 'redux';
import connectActionTypes, {
  IAction,
  IAuthenticationAction,
  IBecomeMemberAction,
  ISubmitContactUsAction,
  IBecomeMemberObject,
  IContactFormObject,
  IUpdateMemberPasswordAction,
  IMemberPassObject,
  IGetMemberInfoAction,
  IMemberAccountInfoObject,
  IInitializeApplication,
  IVerifiedTokenAction,
  ITokenResults,
  IForgotPasswordFormPayload,
  IForgotPasswordForm,
  IUpdateMemberInfoAction,
  IMemberObject,
  IBannerResponse,
  IPasswordSync,
} from './types';
import LanguagesActionTypes from 'state/ducks/languages/types';
import { INavigationItem } from 'state/ducks/navigation/types';

export const authenticate: ActionCreator<IAuthenticationAction> = (username, password) => ({
  type: connectActionTypes.AUTHENTICATE,
  payload: {
    username,
    password,
  },
});

export const becomeAMember: ActionCreator<IBecomeMemberAction> = (
  memberInfo: IBecomeMemberObject
) => ({
  type: connectActionTypes.BECOME_A_MEMBER,
  payload: memberInfo,
});

export const updateMemberInfo: ActionCreator<IUpdateMemberInfoAction> = (
  memberInfo: IMemberObject
) => ({
  type: connectActionTypes.UPDATE_MEMBER_INFO_REQUEST,
  payload: memberInfo,
});

export const updateMemberPassword: ActionCreator<IUpdateMemberPasswordAction> = (
  memberPassword: IMemberPassObject
) => ({
  type: connectActionTypes.UPDATE_MEMBER_PASSWORD,
  payload: memberPassword,
});

export const updateMemberFailure: ActionCreator<IAction> = () => ({
  type: connectActionTypes.UPDATE_MEMBER_FAILURE,
});

export const updateMemberSuccess: ActionCreator<IAction> = () => ({
  type: connectActionTypes.UPDATE_MEMBER_SUCCESS,
});

export const receivedMemberInfoUpdate: ActionCreator<IAction> = () => ({
  type: connectActionTypes.BECOME_A_MEMBER_RECEIVED_RESPONSE,
});

export const getMemberAccount: ActionCreator<IAction> = () => ({
  type: connectActionTypes.GET_MEMBER_INFO,
});

export const getMemberAccountSuccess: ActionCreator<IGetMemberInfoAction> = (
  memberAccount: IMemberAccountInfoObject
) => ({
  type: connectActionTypes.GET_MEMBER_INFO_SUCCESS,
  payload: memberAccount,
});

export const getMemberAccountFailure: ActionCreator<IAction> = () => ({
  type: connectActionTypes.GET_MEMBER_INFO_FAILURE,
});

export const submitContactUs: ActionCreator<ISubmitContactUsAction> = (
  formData: IContactFormObject
) => ({
  type: connectActionTypes.CONTACT_US_SUBMISSION,
  payload: formData,
});

export const authenticateSuccess: ActionCreator<IInitializeApplication> = (
  navigationItems: INavigationItem[],
  isPublic: boolean
) => ({
  type: connectActionTypes.AUTHENTICATE_SUCCESS,
  payload: {
    isPublic,
    items: navigationItems,
  },
});

export const forgotPassword: ActionCreator<IForgotPasswordFormPayload> = (
  object: IForgotPasswordForm
) => ({
  type: connectActionTypes.FORGOT_PASSWORD,
  payload: {
    ...object,
  },
});

export const forgotPasswordSuccess: ActionCreator<any> = () => ({
  type: connectActionTypes.FORGOT_PASSWORD_SUCCESS,
  payload: {},
});

export const forgotPasswordFailure: ActionCreator<any> = () => ({
  type: connectActionTypes.FORGOT_PASSWORD_FAILURE,
  payload: {},
});

export const logout: ActionCreator<IAction> = () => ({
  type: connectActionTypes.LOGOUT,
});

export const logoutSucesss: ActionCreator<IInitializeApplication> = (
  navigationItems: INavigationItem[],
  isPublic: boolean
) => ({
  type: connectActionTypes.LOGOUT_SUCCESS,
  payload: {
    isPublic,
    items: navigationItems,
  },
});

export const logoutFailure: ActionCreator<IAction> = () => ({
  type: connectActionTypes.LOGOUT_FAILURE,
});

export const authenticateFailure: ActionCreator<IAction> = () => ({
  type: connectActionTypes.AUTHENTICATE_FAILURE,
});

export const verifyUserToken: ActionCreator<IAction> = () => ({
  type: connectActionTypes.VERIFY_AUTHENTICATION,
});

export const initializeApplication: ActionCreator<IAction> = () => ({
  type: connectActionTypes.INITIALIZE_APPLICATION,
});

export const initializeApplicationFailure: ActionCreator<IAction> = () => ({
  type: connectActionTypes.INITIALIZE_APPLICATION_FAILURE,
});

export const acknowledgeWarning: ActionCreator<IAction> = () => ({
  type: connectActionTypes.ACKNOWLEDGE_WARNING,
});

export const initializeApplicationSuccess: ActionCreator<IInitializeApplication> = (
  navigationItems: INavigationItem[],
  isPublic: boolean
) => ({
  type: connectActionTypes.INITIALIZE_APPLICATION_SUCCESS,
  payload: {
    isPublic,
    items: navigationItems,
  },
});

export const setMenuItems: ActionCreator<IInitializeApplication> = (
  navigationItems: INavigationItem[],
  isPublic: boolean
) => ({
  type: LanguagesActionTypes.CHANGE_LANGUAGE_SUCCESS,
  payload: {
    items: navigationItems,
    isPublic,
  },
});

export const verifiedTokenResults: ActionCreator<IVerifiedTokenAction> = (
  verified: ITokenResults
) => ({
  type: connectActionTypes.VERIFY_AUTHENTICATION_SUCCESS,
  payload: verified,
});

export const sendingAuthRequest: ActionCreator<IAction> = () => ({
  type: connectActionTypes.AUTHENTICATE_SENDING_REQUEST,
});

export const receivedAuthResponse: ActionCreator<IAction> = () => ({
  type: connectActionTypes.AUTHENTICATE_RECEIVED_RESPONSE,
});

export const sendingMemberRequest: ActionCreator<IAction> = () => ({
  type: connectActionTypes.BECOME_A_MEMBER_SENDING_REQUEST,
});

export const receivedMemberResponse: ActionCreator<IAction> = () => ({
  type: connectActionTypes.BECOME_A_MEMBER_RECEIVED_RESPONSE,
});
export const becomeAMemberSuccess: ActionCreator<IAction> = () => ({
  type: connectActionTypes.BECOME_A_MEMBER_SUCCESS,
});

export const becomeAMemberReset: ActionCreator<IAction> = () => ({
  type: connectActionTypes.BECOME_A_MEMBER_RESET,
});

export const becomeAMemberFailure: ActionCreator<IAction> = () => ({
  type: connectActionTypes.BECOME_A_MEMBER_FAILURE,
});

export const logInAfterPasswordChange: ActionCreator<AnyAction> = (payload: IPasswordSync) => ({
  type: connectActionTypes.LOGIN_AFTER_PASSWORD_CHANGE,
  payload,
});

export const LIPC_2: ActionCreator<AnyAction> = (payload: IPasswordSync) => ({
  type: connectActionTypes.LOGIN_AFTER_PASSWORD_CHANGE_STEP_TWO,
  payload,
});

export const maintenanceResponse: ActionCreator<IBannerResponse> = response => ({
  type: connectActionTypes.MAINTENANCE_BANNER_RESPONSE,
  payload: response,
});
