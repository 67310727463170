import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { MainContainer } from "view/utils/forms/styles";

export const Main = styled.div`
  padding: 1rem 1rem;
  width: 100%;
`;

interface LinkProps {
  isPrimary?: boolean;
}

export const SideLink = styled(Link)<LinkProps>`
  text-decoration: none;
  color: ${(props) => props.theme.colors.main};
  ${(props) => css`
    font-family: ${props.isPrimary
      ? props.theme.fonts.heading
      : props.theme.fonts.main};
    color: ${props.isPrimary
      ? props.theme.gpwmd.green
      : props.theme.gpwmd.grey};
    font-weight: ${props.isPrimary ? 400 : 300};
  `}

  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: block;
  :hover {
    color: ${(props) => props.theme.colors.main};
  }
`;
