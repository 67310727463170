// import original module declarations
import "styled-components";
import { DefaultTheme } from "styled-components";

// and extend them!
declare module "styled-components" {
  export interface DefaultTheme {
    colors: {
      main: string;
      secondary: string;
      tertiary: string;
    };
    backgrounds: {
      main: string;
      secondary: string;
      tertiary: string;
    };
    gpwmd: {
      red: string;
      blue: string;
      grey: string;
      tan: string;
      darkTan: string;
      green: string;
      slate: string;
    };
    fonts: {
      heading: string;
      main: string;
    };
  }
}

export const themeGPWMD: DefaultTheme = {
  colors: {
    main: "#000000",
    secondary: "#ffffff",
    tertiary: "#939598",
  },
  backgrounds: {
    main: "#000000",
    secondary: "#202C47",
    tertiary: "#E81C4B",
  },
  gpwmd: {
    red: "#E81C4B",
    blue: "#202C47",
    grey: "#939598",
    tan: "#B4A799",
    darkTan: "#605A52",
    green: "#557170",
    slate: "#d1cfc8",
  },
  fonts: {
    heading: "montserrat",
    main: "roboto",
  },
};
