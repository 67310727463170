import React from "react";
import styled from "styled-components";
import { ImageLink } from "state/ducks/columned/types";
import { imagePathToURL } from "state/utils";
import { useFileDownload } from "view/utils/hooks/useFileDownload";
import { LoadingOutlined } from "@ant-design/icons";

interface Props {
  data: ImageLink;
}

const ExternalLink = styled.a``;

const StyledImage = styled.img`
  margin: 0;
  width: 100%;
  height: auto;
`;

export const ContainedSpan = styled.span`
  width: 100%;
  height: auto;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const fileExtensionRegex = /[^\\]*\.(\w+)$/;

const LinkNode: React.FC<Props> = (props) => {
  const { data } = props;

  const [download, isLoading] = useFileDownload();
  const { field_link, field_media_image, field_type } = data;
  const { uri, title } = field_link[0];

  const linkType = field_type.length > 0 ? field_type[0].value : "external";
  let LinkText = "";
  let linkURI = "";

  if (field_link.length > 0) {
    LinkText = title; //eslint-disable-line
    linkURI = imagePathToURL(uri);
  }

  let linkHref = "";
  let fileMimeType = "";
  let fileSize = "";

  if (field_media_image.length > 0) {
    const imageObject = field_media_image[0];
    const { field_media_image: image } = imageObject;
    if (image.length > 0) {
      const { uri, filemime, filesize } = image[0];
      linkHref = uri.length > 0 ? imagePathToURL(uri[0].url) : ""; //eslint-disable-line
      fileMimeType = filemime.length > 0 ? filemime[0].value : ""; //eslint-disable-line
      fileSize = filesize.length > 0 ? filesize[0].value : ""; //eslint-disable-line
    }
  }
  if (linkType === "media") {
    let image = "";
    const total = uri.toLowerCase().match(fileExtensionRegex);
    let extension = "";
    if (total) {
      const filename = total[0]; // eslint-disable-line
      extension = total[1];
      switch (extension) {
        case "pdf":
          image = "/images/icons/pdf.svg";
          break;
        case "doc":
        case "docx":
          image = "/images/icons/word.svg";
          break;
        case "pptx":
        case "ppt":
          image = "/images/icons/ppt.png";
          break;
        default:
          break;
      }
    }

    return (
      <ContainedSpan>
        {isLoading && (
          <LoadingContainer>
            <LoadingOutlined
              spin
              style={{ fontSize: "2rem", color: "white" }}
            />
          </LoadingContainer>
        )}
        <StyledImage
          src={linkHref}
          onClick={() =>
            typeof download === "function" &&
            download(linkURI, LinkText, extension)
          }
        />
      </ContainedSpan>
    );
  }

  return (
    <ExternalLink href={linkURI} target="_blank">
      <StyledImage src={linkHref} />
    </ExternalLink>
  );
};

export default LinkNode;
