import { publicPathToURL, getToken } from "state/utils";
import {
  IUser,
  IBecomeMember,
  IContactForm,
  IUpdateMemberObject,
  IMemberAccountPassObject,
} from "state/ducks/connect/types";
import { IRequest } from "state/utils/endpoints";

export const authentication = {
  authenticate(user: IUser) {
    return fetch(publicPathToURL("/authorize"), {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  verifyRECAPTCHA(response: string) {
    return fetch(publicPathToURL("/authorize/recaptcha/verify"), {
      method: "POST",
      body: JSON.stringify({ response }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  requestUserMoreInformation: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/user/more-information?_format=json`,
    options: {
      method: "POST",
    },
  }),
  webinars: (tid?: number): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member/webinars?_format=json${
        tid ? `&sub_working_group=${tid}` : ""
      }`,
    options: {
      method: "GET",
    },
  }),
  getProposals: (tid?: number): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member-state/proposals?_format=json${
        tid ? `&sub_working_group=${tid}` : ""
      }`,
    options: {
      method: "GET",
    },
  }),
  getUkraineRequests: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/ukraine-requests?_format=json`,
    options: {
      method: "GET",
    },
  }),
  getSurvey: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member-state/templates?_format=json`,
    options: {
      method: "GET",
    },
  }),
  getSurveyDownload: (route: string): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }${route}`,
    options: {
      method: "GET",
    },
  }),
  getFileDownload: (route: string): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }${route}`,
    options: {
      method: "GET",
    },
  }),
  getProposalDownload: (route: string): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }${route}`,
    options: {
      method: "GET",
    },
  }),
  getVocabularyByMachineName: (machineName: string): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/vocabulary/${machineName}?_format=json
      `,
    options: {
      method: "GET",
    },
  }),
  getMenuByMachineName: (machineName: string): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/menu-resource?machine-name=${machineName}`,
    options: {
      method: "GET",
    },
  }),
  getWorkingGroupMenuItems: (isMemberState: boolean): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/menu-resource?machine-name=${
        isMemberState ? "working0groups0member0state" : "working-groups"
      }`,
    options: {
      method: "GET",
    },
  }),
  getUpcomingEvent: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/upcoming-event?_format=json
      `,
    options: {
      method: "GET",
    },
  }),
  getRecentWebinar: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member/recent-webinar?_format=json
      `,
    options: {
      method: "GET",
    },
  }),
  getCoChair: (tid?: string, isPlenary?: boolean): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/co-chairs?${tid ? `sub_working_group=${tid}` : ""}${
        tid && isPlenary ? "&" : ""
      }${isPlenary ? "field_is_plenary=1" : ""}&_format=json
      `,
    options: {
      method: "GET",
    },
  }),
  getPlenaryWebinars: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member/plenary?_format=json
      `,
    options: {
      method: "GET",
    },
  }),
  getProposalsFixedResponses: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/vocabulary/proposal_fixed_responses?_format=json
      `,
    options: {
      method: "GET",
    },
  }),
  getUkraineRequestFixedResponses: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/vocabulary/ukraine_request_fixed_responses?_format=json
      `,
    options: {
      method: "GET",
    },
  }),
  getGeneralUkraineRequestFixedResponses: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/vocabulary/general_ukraine_request_fixed_re?_format=json
      `,
    options: {
      method: "GET",
    },
  }),
  getDiscussionBoard: (tid?: number): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/discussion-board?_format=json${
        tid ? `&sub_working_group=${tid}` : ""
      }
      `,
    options: {
      method: "GET",
    },
  }),
  sendProposalComment: (
    nid: string,
    tid: string,
    message: string
  ): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/member-state/proposals/comment?_format=json
      `,
    options: {
      method: "POST",
      body: JSON.stringify({ nid, tid, message }),
    },
  }),
  sendUkraineRequestComment: (
    nid: string,
    tid: string,
    message: string
  ): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/ukraine-request/comment?_format=json
      `,
    options: {
      method: "POST",
      body: JSON.stringify({ nid, tid, message }),
    },
  }),
  sendGeneralUkraineRequestComment: (
    subject: string,
    message: string
  ): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/ukraine-request/general/comment?_format=json
      `,
    options: {
      method: "POST",
      body: JSON.stringify({ subject, message }),
    },
  }),
  sendDiscussionBoardSubmission: (args: any): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/discussion-board/survey?_format=json
      `,
    options: {
      method: "POST",
      body: JSON.stringify(args),
    },
  }),
  getMenuItems: (languageCode: string): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/${
        languageCode ? `${languageCode}/` : ""
      }menu/${apiPrefix}?_format=json`,
    options: {
      method: "GET",
    },
  }),
  sendForgotPassword: (args: any): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/simple_onetime_reset/generate-reset`,
    options: {
      method: "POST",
      body: JSON.stringify(args),
    },
  }),
  validateResetToken: (token: string): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/simple_onetime_reset/validate-reset/${token}`,
    options: {
      method: "GET",
    },
  }),
  resetPassword: (token: string, password: string): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/simple_onetime_reset/reset-password/${token}`,
    options: {
      method: "POST",
      body: JSON.stringify({ password }),
    },
  }),
  verifyToken: (): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/user/validation?_format=json`,
    options: {
      method: "GET",
    },
  }),

  getCSRFToken: (): IRequest => ({
    route: (apiPrefix: string) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/session/token`,
    options: {
      method: "GET",
    },
  }),

  patchUserComment: (): IRequest => ({
    route: (user: number) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/user/${user}?_format=json`,
    options: {
      method: "GET",
    },
  }),
  getUserViaToken: (): IRequest => ({
    route: (user: number) =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/user?_format=json`,
    options: {
      method: "GET",
    },
  }),
  getUserRoles: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/user/roles?_format=json`,
    options: {
      method: "GET",
    },
  }),
  sendUserApprovals: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/user/approve?_format=json`,
    options: {
      method: "GET",
    },
  }),
  getUserApprovalData: (uuid: string): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/admin/approvals/${uuid}?_format=json`,
    options: {
      method: "GET",
    },
  }),
  sendApprovalWithRole: (uuid: string, body: any): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/admin/approvals/${uuid}/approve?_format=json`,
    options: {
      method: "POST",
      body: JSON.stringify(body),
    },
  }),
  sendApprovals: (uuid: string, status: string): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/approvals/${uuid}/${status}`,
    options: {
      method: "GET",
    },
  }),
  getPendingUsers: (): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/approvals/pending-users?_format=json`,
    options: {
      method: "GET",
    },
  }),
  becomeAMember(memberInfo: IBecomeMember, token: string) {
    const route = `${
      process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
    }/web/become-member?_format=json`;
    return fetch(route, {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(memberInfo),
    });
  },
  submitContactForm(contactForm: IContactForm, token: string) {
    const route = `${
      process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
    }/web/simple-contact/send`;
    return fetch(route, {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactForm),
    });
  },
  updateMember(memberInfo: IUpdateMemberObject, csrfToken: string, id: string) {
    let bearer = `Bearer ${getToken()}`;
    const route = `${
      process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
    }/web/user/${id}?_format=json`;
    return fetch(route, {
      method: "PATCH",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrfToken,
      },
      body: JSON.stringify(memberInfo),
    });
  },
  updateMemberPassword(
    memberInfo: IMemberAccountPassObject,
    csrfToken: string,
    id: string
  ) {
    let bearer = `Bearer ${getToken()}`;
    const route = `${
      process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
    }/web/user/${id}?_format=json`;
    return fetch(route, {
      method: "PATCH",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrfToken,
      },
      body: JSON.stringify(memberInfo),
    });
  },

  syncMemberPassword: (memberInfo: IMemberAccountPassObject): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/user/sync-password?_format=json`,
    options: {
      method: "POST",
      body: JSON.stringify(memberInfo),
    },
  }),
  memberToken() {
    let bearer = `Bearer ${getToken()}`;
    const route = `${
      process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
    }/web/oauth/debug?_format=json`;
    return fetch(route, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    });
  },
  memberInfo(id: string) {
    let bearer = `Bearer ${getToken()}`;

    const route = `${
      process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
    }/web/user/${id}?_format=json`;
    return fetch(route, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    });
  },
  maintenanceCheck() {
    // Call to front page should trigger maintenance response
    const route = `${
      process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
    }/web?_format=json`;
    return fetch(route, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getECHSearch: (query: string = ""): IRequest => ({
    route: () =>
      `${
        process.env.NODE_ENV !== "development" ? process.env.REACT_APP_API : ""
      }/web/export-control-hub?${query}
      `,
    options: {
      method: "GET",
    },
  }),
};
