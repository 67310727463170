import { useEffect, useState } from "react";
import { search } from "state/api/search";
import { imagePathToURL } from "state/utils";
import { genFetch, withToken } from "state/utils/endpoints";
import NoDataMessage from "view/Archive/utils/NoDataMessage";
import { SearchDefinition } from "view/Archive/utils/useArichiveQuery";
import { StyledTable } from "../Webinars/styles";
import { DocumentContainer, ResourceLink } from "./styles";

interface Props {
  query: SearchDefinition;
}

const resourceColumns: any = [
  {
    title: " ",
    key: "title",
    render: (text: any, record: any) => (
      <ResourceLink
        key={record.field_media_file}
        href={imagePathToURL(record.field_media_file)}
        target="_blank"
        rel="noreferrer"
      >
        {record.name}
      </ResourceLink>
    ),
  },
];

function Resources(props: Props) {
  const { query } = props;
  const [data, setData] = useState([]);
  useEffect(() => {
    genFetch(search.archiveDocuments(query))()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) =>
            data && data.length > 0 ? setData(data) : setData([])
          )
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [query, setData]);

  return (
    <DocumentContainer>
      <h1>Reference Documents</h1>
      {data.length > 0 ? (
        <StyledTable columns={resourceColumns} dataSource={data} />
      ) : (
        <NoDataMessage />
      )}
    </DocumentContainer>
  );
}

export default Resources;
