import { Table } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { authentication } from "state/api/authentication";
import { search } from "state/api/search";
import { imagePathToURL, publicPathToURL } from "state/utils";
import { genFetch, withToken } from "state/utils/endpoints";
import NoDataMessage from "view/Archive/utils/NoDataMessage";
import {
  archiveQueryBuilder,
  SearchDefinition,
} from "view/Archive/utils/useArichiveQuery";
import { WebinarItemProps } from "view/webinars/WebinarsArchive";
import {
  StyledTable,
  WebinarContainer,
  WebinarTitle,
  WebinarLink,
} from "./styles";

interface Props {
  query: SearchDefinition;
  setViewName: any;
}

interface WebinarProps {
  record: WebinarItemProps;
}

function WebinarRow(props: WebinarProps) {
  const { record } = props;
  return (
    <WebinarContainer>
      <div>
        <WebinarTitle>{record.title}</WebinarTitle>
      </div>
    </WebinarContainer>
  );
}

function Webinars(props: Props) {
  const { query, setViewName } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    genFetch(search.archiveEvents(query))()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) =>
            data && data.length > 0 ? setData(data) : setData([])
          )
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [setData, query]);

  const webinarsColumns: any = [
    {
      title: " ",
      key: "title",
      render: (text: any, record: WebinarItemProps) => (
        <WebinarLink
          to={archiveQueryBuilder({
            ...query,
            alias: 1,
            view: record.field_video_page.replace("/member", ""),
          })}
        >
          {record.title}
        </WebinarLink>
      ),
    },
  ];

  useEffect(() => {
    if (query && query.view && data) {
      const find: any = data.find(
        (item: any) =>
          item.field_video_page.replace("/member", "") === query.view
      );
      if (find) {
        setViewName(["Webinars", find.title]);
      }
    }
  }, [query, data]);

  return (
    <WebinarContainer>
      <h1>Recorded Events</h1>
      {data.length > 0 ? (
        <StyledTable columns={webinarsColumns} dataSource={data} />
      ) : (
        <NoDataMessage/>
      )}
    </WebinarContainer>
  );
}

export default Webinars;
