import React from "react";
import styled, { css } from "styled-components";
import { Link } from "state/ducks/columned/types";
import { publicPathToURL } from "state/utils/path";
import { Link as RouterLink } from "react-router-dom";
import { useFileDownload } from "view/utils/hooks/useFileDownload";
import { LoadingOutlined } from "@ant-design/icons";
interface Props {
  data: Link;
}

interface LinkProps {
  linkType: string;
}
const StyledHref = styled.a<LinkProps>`
  text-decoration: none;
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.main};
  margin: 0 5px 0 0;
  ${(props) =>
    props.linkType === "internal" &&
    css`
      color: ${props.theme.colors.main};
    `}
`;
const StyledLink = styled(RouterLink)<LinkProps>`
  text-decoration: none;
  font-size: 1.13rem;
  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.main};
  margin: 0 5px 0 0;
  ${(props) =>
    props.linkType === "internal" &&
    css`
      color: ${props.theme.gpwmd.red};
    `}
`;

const StyledDownloadButton = styled.button<LinkProps>`
  text-decoration: none;
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.main};
  margin: 0 5px 0 0;
  ${(props) =>
    props.linkType === "internal" &&
    css`
      color: ${props.theme.colors.main};
    `}
  border-color: transparent;
  background: transparent;
  outline: none;
  transition: color 150ms;
  cursor: pointer;
  :focus,
  :active,
  :hover {
    color: ${(props) => props.theme.gpwmd.darkTan};
  }
`;

export const StyledIcon = styled.img`
  width: 16px;
  margin-right: 5px;
`;

export const StyledSpan = styled.span`
  display: flex;
  align-items: baseline;
  padding-bottom: 5px;
`;
const fileExtensionRegex = /[^\\]*\.(\w+)$/;

const LinkNode: React.FC<Props> = (props) => {
  const { data } = props;
  const { field_link, field_type } = data;
  const [download, isLoading] = useFileDownload();

  const linkType = field_type.length > 0 ? field_type[0].value : "external";
  let linkHref = "";
  let linkText = "";

  if (field_link.length > 0) {
    const { uri, title } = field_link[0];
    linkHref = uri;
    linkText = title;
  }

  if (linkType === "media") {
    let image = "";
    const total = linkHref.toLowerCase().match(fileExtensionRegex);
    let extension = "";
    if (total) {
      const filename = total[0]; // eslint-disable-line
      extension = total[1];

      switch (extension) {
        case "pdf":
          image = "/images/icons/pdf.svg";
          break;
        case "doc":
        case "docx":
          image = "/images/icons/word.svg";
          break;
        case "pptx":
        case "ppt":
          image = "/images/icons/ppt.png";
          break;
        case "xlsx":
          image = "/images/icons/excel.png";
          break;
        default:
          break;
      }
    }

    return (
      <StyledSpan>
        {isLoading ? (
          <LoadingOutlined spin />
        ) : (
          <StyledIcon src={publicPathToURL(image)} />
        )}
        <StyledDownloadButton
          linkType={linkType}
          onClick={() =>
            typeof download === "function" &&
            download(linkHref, linkText, extension)
          }
        >
          {linkText}
        </StyledDownloadButton>
      </StyledSpan>
    );
  }

  if (linkType === "external") {
    return (
      <StyledHref
        linkType={linkType}
        target="_blank"
        rel="noopener"
        href={linkHref}
      >
        {linkText}
      </StyledHref>
    );
  }

  return (
    <StyledLink linkType={linkType} to={publicPathToURL(linkHref)}>
      {linkText}
    </StyledLink>
  );
};

export default LinkNode;
