import { Avatar, List } from "antd";
import { useEffect, useState } from "react";
import { search } from "state/api/search";
import { imagePathToURL } from "state/utils";
import { genFetch, withToken } from "state/utils/endpoints";
import styled from "styled-components";
import NoDataMessage from "view/Archive/utils/NoDataMessage";
import { SearchDefinition } from "view/Archive/utils/useArichiveQuery";
import Chair from "view/workingGroup/CoChair";
import { CoChairContainer, StyledList } from "./styles";

interface Props {
  query: SearchDefinition;
}

const StyledHr = styled.hr`
  border-color: ${(props) => props.theme.gpwmd.green};
  width: 100%;
  margin: 0 2rem;
  outline: none;
  border-style: solid;
`;

function CoChairs(props: Props) {
  const { query } = props;
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    genFetch(search.archiveCoChair(query))()(withToken())
      .then((res) =>
        res
          .json()
          .then((data: any) =>
            data && data.length > 0 ? setData(data) : setData([])
          )
          .catch((error) => console.log(error))
      )
      .catch((error) => console.log(error));
  }, [query, setData]);

  return (
    <CoChairContainer
      style={{ display: `${query.plenary ? "none" : "block"}`, width: "100%" }}
    >
      <h1>Co Chairs</h1>
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        {data.length > 0 ? (
          data.map((chair: any, index: number) => (
            <>
              <Chair
                key={chair.name}
                splitLayout={data.length > 0 && data[0].field_bio.length === 0}
                {...chair}
                numOfChairs={data.length}
              />
              {data.length > 0 &&
                data[0].field_bio.length > 1 &&
                index < data.length - 1 && <StyledHr />}
            </>
          ))
        ) : (
          <NoDataMessage />
        )}
      </div>
    </CoChairContainer>
  );
}

export default CoChairs;
