import { AggregatedRequest } from "lib/hooks/useUkraineRequests";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { authentication } from "state/api/authentication";
import { addNotification } from "state/ducks/notifications/actions";
import { IMessage } from "state/ducks/notifications/types";
import { genFetch, withToken } from "state/utils/endpoints";
import { Form, Select } from "antd";
import {
  RequestContainer,
  RowBody,
  RowHeader,
  StyledButtonHolder,
  StyledForm,
  FormOpenHref,
  TextDivArea,
} from "./styles";
import { Button, LabeledFormItem } from "view/utils/forms/styles";
import useGeneralUkraineFixedResponses from "lib/hooks/useGeneralUkraineFixedResponses";

interface Props {}

// App  Messages.
const failedMessage: IMessage = {
  type: "error",
  message: "The request failed",
  description: "ught oh.. Something went wrong",
};

const successMessage: IMessage = {
  type: "success",
  message: "Your message has been sent",
  description: "Thank you",
};

const UkraineGeneralAssistanceSection = (props: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { fixedResponses, isLoading } = useGeneralUkraineFixedResponses();

  const isValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo) {
      return false;
    }
  };

  const handleSubmit = async () => {
    const formIsValid = await isValid();
    if (formIsValid) {
      const { subject } = form.getFieldsValue();
      const message = document.getElementById(
        "general-assistance-submission"
      )?.innerText;
      setIsSubmitting(true);
      genFetch(
        authentication.sendGeneralUkraineRequestComment(subject, message || "")
      )()(withToken())
        .then((res) => {
          if (res.ok) dispatch(addNotification(successMessage));
          else dispatch(addNotification(failedMessage));
          form.resetFields();
          setIsSubmitting(false);
        })
        .catch((error) => dispatch(addNotification(failedMessage)));
    }
  };

  const downloadProposal = (
    route = "/web/system/files/ukraine/2023-10/Ukraine-GP_Assistance_International_Summary-October_2023.pdf"
  ) => {
    //TODO Download Media rather than file
    // https://gpwmd-staging-admin.pnl.gov/web/media/238/edit
    genFetch(authentication.getProposalDownload(route))()(withToken())
      .then((res) => {
        res.blob().then((blob) => {
          const linkURL = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          if (link) {
            link.href = linkURL;
            const filename = decodeURI(
              route.substr(route.lastIndexOf("/") + 1)
            );
            link.setAttribute("download", filename);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            if (link.parentNode) link.parentNode.removeChild(link);
          }
        });
      })
      .catch((error) => dispatch(addNotification(failedMessage)));
  };
  return (
    <RequestContainer isOpen={true}>
      <RowHeader>
        <StyledButtonHolder></StyledButtonHolder>
      </RowHeader>
      <RowBody style={{ padding: "0 3rem" }}>
        <StyledForm form={form} layout="vertical">
          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true, message: "Please select an Option!" }]}
          >
            <Select>
              {fixedResponses.map((item) => (
                <Select.Option key={item.tid} value={item.tid}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <LabeledFormItem name="general-assistance-submission">
            <TextDivArea
              as="div"
              id="general-assistance-submission"
              style={{ padding: "1rem " }}
              contentEditable
            >
              - Summary of items GP donor Ministry/Agency is providing:
              <br />
              &emsp; <br />
              - GP Donor Country and Ministry / Agency:
              <br />
              &emsp; <br />
              - A Donor Point of Contact Name and Email:
              <br />
              &emsp; <br />
              - Request Origin (E.g., direct from Ukraine, IAEA, other):
              <br />
              &emsp; <br />
              - End User(s): <br />
              &emsp; <br />
              - Request Status (Complete or In process): <br />
              &emsp; <br />- Please refrain from providing quantities{" "}
              <i>
                <u>unless specifically requested by the Ukrainians.</u>
              </i>{" "}
              <br />
              &emsp; <br />
            </TextDivArea>
          </LabeledFormItem>
          <Form.Item style={{ padding: "1rem 0" }}>
            <Button disabled={isSubmitting} onClick={handleSubmit}>
              {isSubmitting ? "Sending Message..." : "Send Message"}
            </Button>
          </Form.Item>
        </StyledForm>
        <div
          style={{
            padding: "1rem 2rem",
            width: "100%",
          }}
        >
          <p>
            <strong>Directions:</strong> If your country is providing assistance
            to Ukraine and it is not already reflected in one of the numbered
            requests listed below, please use the newly established message
            field to the left to note your country’s contributions.{" "}
            <i>
              (Note: Assistance recorded in the individual assistance requests
              below will be monitored and populated in this summary tracker.)
            </i>
          </p>
          <hr></hr>
          <FormOpenHref onClick={() => downloadProposal()}>
            VIEW SUMMARY ASSISTANCE TRACKER
          </FormOpenHref>
        </div>
      </RowBody>
    </RequestContainer>
  );
};

export default UkraineGeneralAssistanceSection;
