import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IApplicationState } from "state/ducks";
import { connectTypes } from "state/ducks/connect";
import Proposal from "./proposals";
import { searchTypes } from "state/ducks/search";


const mapStateToProps = (state: IApplicationState) => ({
  isPublic: state.connect.public,
  member: state.connect.member,
});

type actions = connectTypes.ConnectActions &
  searchTypes.SearchActionsInterfaces;

const mapDispatchToProps = (dispatch: Dispatch<actions>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Proposal);
