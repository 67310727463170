import { Dialog } from "@headlessui/react";
import styled from "styled-components";
import React, { useState } from "react";
import { ECHBannerButton } from "view/utils/forms/styles";
import Media from "components/Media/Media";

type Props = {};

const Panel = styled(Dialog.Panel)`
  margin: 0 auto; /* Equivalent to mx-auto */
  width: auto;
  border-radius: 0.375rem; /* Equivalent to rounded */
  background: #fff;
  border: 3px solid #ca1228;
  border-radius: 8px;
  max-width: 1000px;
  color: #605a52;
  padding: 3rem 6rem;
  max-height: 90vh;
  overflow: auto;

  h2 {
    font-size: 32px;
    color: #605a52;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
`;

const CenterContainer = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  width: 100vw; /* Equivalent to w-screen */
  align-items: center;
  justify-content: center;
`;

const ActionRow = styled.div`
  display: flex;
  column-gap: 1rem;
  justify-content: flex-end;
  margin-top: 4rem;
`;

export const VideoContainer = styled.div`
  min-width: 700px;
  width: 100%;

  @media screen and (max-width: 800px) {
    min-width: 300px;
    width: 100%;
  }
`;

const HowToUseDialog = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <Dialog
        style={{ position: "relative", zIndex: 50 }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Backdrop aria-hidden="true" />
        <CenterContainer>
          <Panel>
            <Dialog.Title as="h2">How to Use the Hub</Dialog.Title>
            <Dialog.Description>
              <p>
                To find the resources you are looking for, simply open the Hub
                page and type your search terms in the search box.
              </p>
              <p>
                You may narrow your search with the help of the following
                filters:
              </p>
              <ul>
                <li>Keyword</li>
                <li>
                  Primary source (e.g., treaties, international organization
                  documents) or secondary source (e.g., research organization
                  reports)
                </li>
                <li>
                  Type of organization (e.g., international organization,
                  research organization, academia)
                </li>
                <li>
                  Type of document (e.g., treaty, handbook, best practice guide,
                  database, regulation, report, article)
                </li>
                <li>Date</li>
                <li>
                  Position on the WMD spectrum (nuclear, chemical, biological,
                  radiological, or all)
                </li>
              </ul>
              <p>
                Once you hit the search button, results will be listed. You can
                click on the resource you want for more information, including a
                brief description of the source and a link.
              </p>
            </Dialog.Description>
            <Media
              video="https://gpwmd-portal.org/backend/web/sites/default/files/2023-11/GP%20Portal%20-%20Knowledge%20Hub.mp4"
              posterImage="/images/videos/ech-howto-banner.png"
            />
            <ActionRow>
              <ECHBannerButton
                autoFocus
                style={{ transform: "unset" }}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Close
              </ECHBannerButton>
            </ActionRow>
          </Panel>
        </CenterContainer>
      </Dialog>
      <ECHBannerButton onClick={() => setIsOpen(true)}>
        How To Use
      </ECHBannerButton>
    </React.Fragment>
  );
};

export default HowToUseDialog;
