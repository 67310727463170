import React, { useState } from "react";
import { DiscussionBoardData } from "../discussionBoard";
import styled from "styled-components";
import { List } from "antd";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
interface Props {
  record: DiscussionBoardData;
}
interface DiscussionContainerProps {
  isOpen: boolean;
}

const DiscussionContainer = styled.div<DiscussionContainerProps>`
  transition: max-height 0.3s ease-in-out;
  height: auto;
  display: flex;
  flex-wrap: wrap;
`;

const RowHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const QuestionText = styled.p`
  font-family: ${(props) => props.theme.fonts.heading};
  font-weight: 600;
  color: ${(props) => props.theme.colors.main};
  font-size: 1.1rem;
  max-width: 750px;
  padding: 0;
  margin-bottom: 8px;
`;
export const SubText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  color: ${(props) => props.theme.colors.main};
  font-size: 1rem;
  font-style: italic;
  max-width: 700px;
  margin: 0;
`;

export const TitleText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 500;
  color: ${(props) => props.theme.colors.main};
  font-size: 0.95rem;
  width: 100%;
  margin: 0;
`;

export const ResponseText = styled.p`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 300;
  color: ${(props) => props.theme.colors.main};
  font-size: 1rem;
  width: 100%;
  margin: 0;
`;

export const StyledButton = styled.button`
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 400;
  color: ${(props) => props.theme.colors.main};
  font-size: 1rem;
  height: 32px;
  min-width: 100px;
  background-color: transparent;
  border: 1px solid black;
  :hover,
  :focus,
  :active {
    color: ${(props) => props.theme.gpwmd.red} !important;
    border-color: ${(props) => props.theme.gpwmd.red} !important;
    outline-color: ${(props) => props.theme.gpwmd.red} !important;
  }
`;

const StyledListItem = styled(List.Item)`
  border-left: solid 2px #c7b299;
  padding-left: 1rem;
`;

const DiscussionRow = (props: Props) => {
  const { record } = props;
  const [isOpen, setIsOpen] = useState(true);

  const question = record.title.length > 0 ? record.title[0].value : "";
  const subtext =
    record.field_sub_text.length > 0 ? record.field_sub_text[0].value : "";

  const responses = record.field_responses;

  return (
    <DiscussionContainer isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      <RowHeader>
        <div>
          <QuestionText>{question}</QuestionText>
          <SubText>{subtext}</SubText>
        </div>
        <DownOutlined
          rotate={isOpen ? 0 : 90}
          onClick={() => setIsOpen(!isOpen)}
        />
      </RowHeader>
      {isOpen && responses.length > 0 && (
        <List
          style={{
            padding: "0 1rem",
            marginTop: "1rem",
            width: "100%",
          }}
          itemLayout="horizontal"
          dataSource={responses}
          renderItem={(item) => {
            const id = item.id.length > 0 ? item.id[0].value : "";
            const date =
              item.field_date_submitted.length > 0
                ? moment(item.field_date_submitted[0].value).format(
                    "MMMM Do YYYY"
                  )
                : "";
            const username =
              item.field_user_name.length > 0
                ? item.field_user_name[0].value
                : "";
            const organization =
              item.field_user_organization.length > 0
                ? item.field_user_organization[0].value
                : "";
            const response =
              item.field_plain_text.length > 0
                ? item.field_plain_text[0].value
                : "";
            return (
              <StyledListItem key={id}>
                <StyledListItem.Meta
                  title={
                    <TitleText>{`${username}, ${organization} - ${date}`}</TitleText>
                  }
                  description={<ResponseText>{response}</ResponseText>}
                />
              </StyledListItem>
            );
          }}
        />
      )}
    </DiscussionContainer>
  );
};

export const discussionBoardColumns: any = [
  {
    title: " ",
    key: "title",
    render: (text: any, record: DiscussionBoardData) => (
      <DiscussionRow record={record} />
    ),
  },
];
