
import { createBrowserHistory as createHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import applicationState from './combineReducers';
import forkedSagas from './forkSagas';
import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createHistory();
const sagaMiddleware = createSagaMiddleware();

const middleware:any[] = [
  sagaMiddleware,
];

let store: any = null;

if (process.env.REACT_APP_DEVTOOLS && process.env.REACT_APP_DEVTOOLS === 'true') {
  middleware.push(logger);
  store = createStore(
    applicationState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
} else {
  store = createStore(applicationState, applyMiddleware(...middleware));
}

sagaMiddleware.run(forkedSagas);

export default store;
