import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input } from "antd";
import { IConnectState } from "state/ducks/connect/types";
import { ILanguageState } from "state/ducks/languages/types";
import { connectActions } from "state/ducks/connect";
import {
  MainContainer,
  FormContainer,
  LabeledFormItem,
  Button,
  FormLink,
  Logo,
} from "view/utils/forms/styles";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { TwoColumnFrom, Column } from "view/registration/styles";
import styled from "styled-components";

interface Props {
  connect: IConnectState;
  languages: ILanguageState;
  submitContactUs: typeof connectActions.submitContactUs;
}
interface HeaderProps {
  highlight?: boolean;
}
const FormHeader = styled.h1<HeaderProps>`
  font-family: ${(props) => props.theme.fonts.heading};
  display: inline-block;
  padding-right: 1rem;
  color: ${(props) =>
    props.highlight ? props.theme.gpwmd.green : props.theme.colors.main};
`;

const ContactUs = (props: Props) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [token, setToken] = useState<string | null>(null);

  const verifyRecaptchaCallback = useCallback((token: string) => {
    setToken(token);
  }, []);

  const isValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo) {
      return false;
    }
  };

  const handleSubmit = async () => {
    const formIsValid = await isValid();
    if (formIsValid && token) {
      const { name, email, organization, message } = form.getFieldsValue();
      props.submitContactUs({
        name,
        mail: email,
        organization,
        message,
        token,
        history,
      });
    }
  };

  return (
    <MainContainer
      style={{
        padding: "4rem 0",
        backgroundColor: "transparent",
      }}
    >
      <Logo width="300px" src="/images/icons/italy24/gp-g7-stacked.png" />
      <FormContainer>
        <TwoColumnFrom layout="vertical" form={form}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormHeader>Have a Question? </FormHeader>
            <FormHeader highlight> Leave us a message!</FormHeader>
          </div>
          <Column>
            <LabeledFormItem
              label="Full Legal Name"
              name="name"
              style={{}}
              rules={[
                {
                  required: true,
                  message: "Please input your Full Legal Name!",
                },
              ]}
            >
              <Input />
            </LabeledFormItem>

            <LabeledFormItem
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input />
            </LabeledFormItem>
            <LabeledFormItem
              label="Organization"
              name="organization"
              rules={[
                {
                  required: true,
                  message: "Please input your Organization!",
                },
              ]}
            >
              <Input />
            </LabeledFormItem>
          </Column>
          <Column>
            <LabeledFormItem
              label="Message"
              name="message"
              rules={[
                { required: true, message: "Please input your Message!" },
              ]}
            >
              <Input.TextArea style={{ minHeight: "240px" }} />
            </LabeledFormItem>
          </Column>
          <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
            <Column>
              <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
              <FormLink to={`/${props.languages.code}/forgot/password`}>
                Forgot your Password?
              </FormLink>
            </Column>
            <Column>
              <Form.Item style={{ padding: "1rem 0" }}>
                <Button onClick={handleSubmit}>Submit</Button>
              </Form.Item>
            </Column>
          </div>
          <div
            style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
          ></div>
        </TwoColumnFrom>
      </FormContainer>
    </MainContainer>
  );
};

export default ContactUs;
