import styled from "styled-components";

export const SecondaryBanner = styled.div`
  background: rgb(242, 242, 242);
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3rem 6.5rem;
  flex-wrap: wrap;
`;

export const TextContainer = styled.div`
  margin-right: 2rem;
  & h1 {
    margin-bottom: 5px;
  }
  & p {
    margin-bottom: 3px;
  }
`;
