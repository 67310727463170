import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { IConnectState } from "state/ducks/connect/types";
import { ILanguageState } from "state/ducks/languages/types";
import { connectActions } from "state/ducks/connect";
import { Logo, MainContainer } from "view/utils/forms/styles";
import { genFetch, withToken } from "state/utils/endpoints";
import { authentication } from "state/api/authentication";
import { Table } from "antd";
import { discussionBoardColumns } from "./util/table";
import { Link, useLocation } from "react-router-dom";
import { Banner, Overlay } from "view/common/Banner";
import GroupToggle from "view/common/GroupToggle";
import {
  TwoColumnLayout,
  LeftColumn,
  RightColumn,
  BodyContainer,
} from "view/common/Layout";
import {
  ParticipateHeader,
  QuestionnaireLink,
  TextContainerBanner,
} from "./styles";
import ColumnMenu from "view/common/ColumnMenu";
import { createSelector } from "reselect";
import { IApplicationState } from "state/ducks";
import { useSelector } from "react-redux";
const queryString = require("query-string");
interface Props {
  connect: IConnectState;
  languages: ILanguageState;
  submitContactUs: typeof connectActions.submitContactUs;
}

const StyledTable = styled(Table)`
  width: 100%;
  max-width: 1300px;
  padding: 2rem;

  .ant-table-thead tr {
    height: 50px;
  }
  .ant-table-thead {
    display: none;
  }

  .min-w-500 {
    min-width: 400px;
  }
`;

export interface Value {
  value: string;
}

export interface Response {
  field_date_submitted: Value[];
  field_plain_text: Value[];
  field_user_name: Value[];
  field_user_organization: Value[];
  id: Value[];
}
export interface DiscussionBoardData {
  field_responses: Response[];
  field_sub_text: Value[];
  title: Value[];
}

const getWorkingGroupState = () =>
  createSelector(
    (state: IApplicationState) => ({
      subWorkingGroups: state.vocabulary.subWorkingGroups,
    }),
    (state) => {
      const { subWorkingGroups } = state;
      return {
        subWorkingGroups,
      };
    }
  );

const StyledErrorHandler = styled.div`
  width: 100%;
  height: 100%;
  padding: 3rem;
`;
const DiscussionBoard = (props: Props) => {
  const { subWorkingGroups } = useSelector(getWorkingGroupState());
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [discussionBoard, setDiscussionBoard] = useState<DiscussionBoardData[]>(
    []
  );

  const location = useLocation();

  const tid = useMemo(() => {
    const query = queryString.parse(location.search);
    const tid = query["group"] || null;

    return tid;
  }, [location.search]);

  useEffect(() => {
    let group = null;
    if (tid && subWorkingGroups) {
      const ok = subWorkingGroups.find((wgroup) => wgroup.tid === tid);
      if (ok) group = ok.field_acronym.toLowerCase();
    }
    setSelectedGroup(group);
  }, [tid, subWorkingGroups, setSelectedGroup]);

  useEffect(() => {
    if (tid) {
      genFetch(authentication.getDiscussionBoard(tid))()(withToken())
        .then((res) =>
          res
            .json()
            .then((data: any) =>
              setDiscussionBoard(data && data.length ? data : [])
            )
            .catch((error) => console.log(error))
        )
        .catch((error) => console.log(error));
    }
  }, [tid]);

  return (
    <MainContainer>
      <BodyContainer>
        <Banner>
          <Logo
            maxWidth="1600px"
            src="/images/banners/discussion-1600x400.jpg"
          />
          <Overlay>
            <div>
              <h1>Discussion Board</h1>
              <h4>
                View Discussion Boards for the Global Partnership Working
                Groups. Submissions will be reviewed and posted to the
                Discussion Board. Please note that anyone with access to the
                portal can view your submission.
              </h4>
            </div>
          </Overlay>
        </Banner>
        <GroupToggle pathname="/en/discussion-board" />
        <TwoColumnLayout>
          <LeftColumn
            style={{
              height:
                !tid || (tid && discussionBoard.length === 0) ? "auto" : "",
            }}
          >
            {tid && discussionBoard.length > 0 && (
              <>
                <ParticipateHeader>
                  <TextContainerBanner>
                    <h1>Want to participate?</h1>
                    <p>
                      Click here to fill out our questionnaire and be a part of
                      the conversation!
                    </p>
                  </TextContainerBanner>
                  <QuestionnaireLink
                    to={`/en/discussion-board/questionnaire${
                      tid ? `?group=${tid}` : ""
                    }`}
                  >
                    Fill Out Questionnaire
                  </QuestionnaireLink>
                  <TextContainerBanner>
                    <p>
                      To initiate a conversation on this discussion board,
                      please select the button below to send us your
                      comment/question. The submission will be reviewed by the
                      Sub-Working group chair and posted to this board upon
                      approval. Please note all GPWMD members will be able to
                      review and comment on the conversation thread.
                    </p>
                    <QuestionnaireLink to={"/en/contact-us"}>
                      Send us your question(s)
                    </QuestionnaireLink>
                  </TextContainerBanner>
                </ParticipateHeader>

                <StyledTable
                  rowKey={(record: any) => record?.title[0].value}
                  columns={discussionBoardColumns}
                  dataSource={discussionBoard}
                />
              </>
            )}
            {subWorkingGroups.length > 0 &&
              tid &&
              discussionBoard.length === 0 && (
                <ParticipateHeader>
                  <TextContainerBanner>
                    <h1>{`${selectedGroup?.toUpperCase()} Discussion Board is coming soon.`}</h1>
                    <p>
                      No Discussion Board available at this time. Please check
                      back later or select another Sub-Working Group.
                    </p>
                    <p>
                      To initiate a conversation on this discussion board,
                      please select the button below to send us your
                      comment/question. The submission will be reviewed by the
                      Sub-Working group chair and posted to this board upon
                      approval. Please note all GPWMD members will be able to
                      review and comment on the conversation thread.
                    </p>
                    <QuestionnaireLink to={"/en/contact-us"}>
                      Send us your question(s)
                    </QuestionnaireLink>
                  </TextContainerBanner>
                </ParticipateHeader>
              )}
            {subWorkingGroups.length > 0 && !tid && (
              <ParticipateHeader>
                <TextContainerBanner>
                  <h1>{`Select a Sub-Working Group!`}</h1>
                  <p>
                    Select a Sub-Working Group to get started on the discussion.
                  </p>
                </TextContainerBanner>
              </ParticipateHeader>
            )}
          </LeftColumn>
          <RightColumn>
            <ColumnMenu />
          </RightColumn>
        </TwoColumnLayout>
      </BodyContainer>
    </MainContainer>
  );
};

export default DiscussionBoard;
