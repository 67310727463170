import React from 'react';
import Typography from 'sanskrit/Typography/Typography';

import styled from 'styled-components';

const TypographyOverride = styled<any>(Typography)`
  color: ${(props: any) => `${props.color} !important` || 'inherit'};
`;


interface IStatus {
  created: string;
  email: string;
  group: string;
  status: string;
  type: string;
  updated: string;
}

const getColor = (status: string) => {
  switch (status) {
    case 'APPROVED': {
      return 'green';
    }
    case 'REVIEWED': {
      return 'green';
    }
    case 'PENDING': {
      return 'black';
    }
    case 'DENIED': {
      return 'red';
    }
    default: {
      return 'black';
    }
  }
};

export const approvalColumns: any = [
  {
    title: 'Name',
    dataIndex: 'field_name',
    key: 'field_name',
  },
  {
    title: 'Title',
    key: 'field_title',
    dataIndex: 'field_title',
    className: 'max-w-250',
  },
  {
    title: 'Citizenship',
    key: 'field_country_of_citizenship',
    dataIndex: 'field_country_of_citizenship',
  },
  {
    title: 'Created',
    key: 'created',
    dataIndex: 'created',
  },
  {
    title: 'Affiliation',
    key: 'field_affiliation',
    dataIndex: 'field_affiliation',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Approvals',
    dataIndex: 'status',
    key: 'status',
    render: (status: IStatus[]) => (
      <table>
        <tbody>
          {status.map((o: IStatus) => (
            <tr>
              <td style={{ padding: '0 .5rem 0 0' }}>
                <Typography align="right" weight="bold">
                  {o.group}:
                </Typography>
              </td>
              <td>
                <TypographyOverride color={getColor(o.status)} weight="bold">
                  {o.status}
                </TypographyOverride>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ),
  },

  // {
  //   title: 'Phone',
  //   key: 'field_phone',
  //   dataIndex:'field_phone',
  // },
  // {
  //   title: 'Approval Comments',
  //   key: 'field_approval_comments',
  //   dataIndex: 'field_approval_comments',
  //   editable: true,
  //   width: '40%',
  //   // render: (text:string) => (<TextArea style={{tabSize: 1}}>{text}</TextArea>),
  // },
];
