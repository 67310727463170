import { OK } from "http-status-codes";
import { Children, useEffect, useState } from "react";
import { authentication } from "state/api/authentication";
import { getToken } from "state/utils";

import React, { ReactElement } from "react";

interface Props {
  children: any;
}

function ProtectedContent(props: Props) {
  const { children } = props;
  const [admin, setAdmin] = useState<boolean | null>(null);
  useEffect(() => {
    authentication.memberToken()
      .then((res) => {
        if (res.status === OK) {
          res.json().then((body) => {
            let authenticated = false;

            // I did this because Drupal sends me a empty user object with empty arrays.
            if (body.roles) {
              authenticated = body.roles.some(
                (o: any) => o === "member_state"
              );
            }

            setAdmin(authenticated);
          });
        } else {
          setAdmin(false);
        }
      })
      .catch(() => setAdmin(false));
  }, []);

  return admin ? children : null;
}

export default ProtectedContent;
