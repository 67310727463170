import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IApplicationState } from "state/ducks";
import Public from "./public";
import { isApiPublic } from "state/ducks/connect/selectors";
const mapStateToProps = (state: IApplicationState) => ({
  isApiPublic: isApiPublic(state),
});

export default withRouter<any, any>(connect(mapStateToProps)(Public));
