import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import { authentication } from "state/api/authentication";
import { IApplicationState } from "state/ducks";
import { columnedActions } from "state/ducks/columned";
import { INavigationItem } from "state/ducks/navigation/types";
import { imagePathToURL } from "state/utils";
import { genFetch, memberFallback, withToken } from "state/utils/endpoints";
import styled from "styled-components";
import { Banner, Overlay } from "view/common/Banner";
import ColumnMenu from "view/common/ColumnMenu";
import GroupToggle from "view/common/GroupToggle";
import {
  BodyContainer,
  LeftColumn,
  RightColumn,
  TwoColumnLayout,
} from "view/common/Layout";
import {
  ParticipateHeader,
  QuestionnaireLink,
  TextContainerBanner,
} from "view/discussionBoard/styles";
import { ParsedLayout } from "view/parsed-layout/parsedLayout";
import { Logo, MainContainer } from "view/utils/forms/styles";

const queryString = require("query-string");

// Need columned pages for resources
const getWorkingGroupState = () =>
  createSelector(
    (state: IApplicationState) => ({
      subWorkingGroups: state.vocabulary.subWorkingGroups,
      user: state.connect.member,
    }),
    (state) => {
      const { subWorkingGroups, user } = state;
      const member: any = user;
      let isMemberState = false;
      if (member && member.roles?.length > 0) {
        if (member.roles) {
          isMemberState = member.roles.some(
            (o: any) => o.target_id === "member_state"
          );
        }
      }
      return {
        subWorkingGroups,
        isMemberState,
      };
    }
  );

const hosts = ["gpwmd-portal.org", "gpwmd-staging-client.pnl.gov"];
const exportControlHost =
  window.location.host === hosts[0]
    ? `https://hub.${hosts[0]}`
    : `https://hub.${hosts[1]}`;

interface ResourceMenu {
  [x: string]: INavigationItem[];
}

const Link = styled.a`
  display: block;
  font-style: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.gpwmd.green};
  font-weight: 300;
  text-decoration: none;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: block;

  border-bottom: solid 1px ${(props) => props.theme.gpwmd.grey};

  :hover {
    color: ${(props) => props.theme.colors.main};
  }
`;

export const PrimaryButton = styled.button`
  padding: 1rem 4rem;
  height: 60px;
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.secondary};
  background-color: ${(props) => props.theme.gpwmd.slate};
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  & h1 {
    font-size: 1.1rem;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 3rem;
`;

function Resources() {
  const { subWorkingGroups, isMemberState } = useSelector(
    getWorkingGroupState()
  );
  const location = useLocation();
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [resourceMenu, setResourceMenu] = useState<ResourceMenu>({});
  const [memberResourceMenu, setMemberResourceMenu] = useState<ResourceMenu>(
    {}
  );

  const tid = useMemo(() => {
    const query = queryString.parse(location.search);
    let tid = query["group"] || null;

    return tid;
  }, [location.search]);

  useEffect(() => {
    let group = null;
    if (tid && subWorkingGroups) {
      const ok = subWorkingGroups.find((wgroup) => wgroup.tid === tid);
      if (ok) group = ok.field_acronym.toLowerCase();
    } else {
      group = "plenary";
    }

    setSelectedGroup(group);
  }, [tid, subWorkingGroups, setSelectedGroup]);

  useEffect(() => {
    if (selectedGroup) {
      genFetch(
        authentication.getMenuByMachineName(`${selectedGroup}-resources`)
      )()(withToken())
        .then((res) =>
          res
            .json()
            .then((data: any) => {
              if (data && data.length > 0) {
                const copy = { ...resourceMenu };
                copy[`${selectedGroup}`] = data;
                setResourceMenu(copy);
              }
            })
            .catch((error) => console.log(error))
        )
        .catch((error) => console.log(error));

      if (isMemberState) {
        genFetch(
          authentication.getMenuByMachineName(
            `${selectedGroup}-resources-member-stat`
          )
        )()(withToken())
          .then((res) =>
            res
              .json()
              .then((data: any) => {
                if (data && data.length > 0) {
                  const copy = { ...memberResourceMenu };
                  copy[`${selectedGroup}-member-state`] = data;
                  setMemberResourceMenu(copy);
                }
              })
              .catch((error) => console.log(error))
          )
          .catch((error) => console.log(error));
      }
    }
  }, [isMemberState, selectedGroup]);

  return (
    <MainContainer>
      <BodyContainer>
        <Banner>
          <Logo
            maxWidth="1600px"
            src="/images/banners/resources-1600x400.jpg"
          />
          <Overlay>
            <div>
              <h1>Resources</h1>
            </div>
          </Overlay>
        </Banner>
        <GroupToggle all allOption="PLENARY" pathname="/en/resources" />
        <TwoColumnLayout>
          <LeftColumn
            style={{
              height:
                selectedGroup && resourceMenu[selectedGroup]?.length > 0
                  ? "100%"
                  : "auto",
            }}
          >
            {!selectedGroup && (
              <ParticipateHeader>
                <TextContainerBanner>
                  <h1>{`No Reference Documents available`}</h1>
                  <p>
                    {`No Plenary reference documents or resources available at this time. Please check back later or select another Sub-Working Group. `}
                  </p>
                </TextContainerBanner>
              </ParticipateHeader>
            )}
            {selectedGroup && !resourceMenu[selectedGroup] && (
              <ParticipateHeader>
                <TextContainerBanner>
                  <h1>{`No Reference Documents available`}</h1>
                  <p>
                    {`${selectedGroup.toUpperCase()} has no reference documents or resources available at this time. Please check back later or select another Sub-Working Group. `}
                  </p>
                </TextContainerBanner>
              </ParticipateHeader>
            )}
            {selectedGroup && resourceMenu[selectedGroup]?.length > 0 && (
              <Container>
                {selectedGroup === "plenary" && (
                  <div style={{ padding: "1rem 1.5rem" }}>
                    <h1>
                      GP Nonproliferation and Strategic Trade Knowledge Hub
                    </h1>
                    <p style={{ lineHeight: "2" }}>
                      This tool is a central point for information and resources
                      on nonproliferation of Weapons of Mass Destruction (WMD)
                      and strategic trade topics. The Hub is a comprehensive,
                      up-to-date, publicly available, and easily searchable
                      library that enables users to be connected to resources on
                      nonproliferation and strategic trade topics, covering the
                      nuclear, chemical, biological, and radiological spectrum
                      as well as export controls and compliance.
                    </p>
                    <QuestionnaireLink
                      as="a"
                      href={exportControlHost}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go To Knowledge Hub
                    </QuestionnaireLink>
                  </div>
                )}

                <>
                  <div style={{ padding: "1rem" }}>
                    {resourceMenu[selectedGroup].map((item) => {
                      const isParent =
                        item.children && item.children?.length > 0;

                      if (isParent) {
                        const children =
                          item.children && item.children.length > 0
                            ? item.children
                            : [];
                        return (
                          <div style={{ padding: "1rem" }}>
                            <h1>{item.title}</h1>
                            {children.map((item) => {
                              if (item.type === "media") {
                                return (
                                  <Link
                                    key={item.data_url}
                                    href={imagePathToURL(
                                      `/web${item.data_url}`
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item.title}
                                  </Link>
                                );
                              }
                              return (
                                <Link
                                  key={item.data_url}
                                  href={item.data_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.title}
                                </Link>
                              );
                            })}
                          </div>
                        );
                      }
                      if (item.type === "media") {
                        return (
                          <Link
                            key={item.data_url}
                            href={imagePathToURL(`/web${item.data_url}`)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.title}
                          </Link>
                        );
                      }
                      return (
                        <Link
                          key={item.data_url}
                          href={item.data_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.title}
                        </Link>
                      );
                    })}
                  </div>
                </>

                {isMemberState &&
                  selectedGroup &&
                  memberResourceMenu[`${selectedGroup}-member-state`] &&
                  memberResourceMenu[`${selectedGroup}-member-state`].length >
                    0 && (
                    <div style={{ width: "100%" }}>
                      {memberResourceMenu[`${selectedGroup}-member-state`].map(
                        (item) => {
                          const isParent =
                            item.children && item.children?.length > 0;

                          if (isParent) {
                            const children =
                              item.children && item.children.length > 0
                                ? item.children
                                : [];
                            return (
                              <div style={{ padding: "1rem" }}>
                                <h1>{item.title}</h1>
                                {children.map((item) => {
                                  if (item.type === "media") {
                                    return (
                                      <Link
                                        key={item.data_url}
                                        href={imagePathToURL(
                                          `/web${item.data_url}`
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item.title}
                                      </Link>
                                    );
                                  }
                                  return (
                                    <Link
                                      key={item.data_url}
                                      href={item.data_url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {item.title}
                                    </Link>
                                  );
                                })}
                              </div>
                            );
                          }
                          if (item.type === "media") {
                            return (
                              <Link
                                key={item.data_url}
                                href={imagePathToURL(`/web${item.data_url}`)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.title}
                              </Link>
                            );
                          }
                          return (
                            <Link
                              key={item.data_url}
                              href={item.data_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.title}
                            </Link>
                          );
                        }
                      )}
                    </div>
                  )}
              </Container>
            )}
          </LeftColumn>
          <RightColumn>
            <ColumnMenu />
          </RightColumn>
        </TwoColumnLayout>
      </BodyContainer>
    </MainContainer>
  );
}

export default Resources;
