import { Table } from "antd";
import styled from "styled-components";

export const StyledTable = styled(Table)`
  width: 100%;

  .ant-table-thead tr {
    height: 50px;
    
  }
  .ant-table-thead {
    display: none;

  }

  .min-w-500 {
    min-width: 400px;
  }
  .ant-table-cell {
  }
`;

export const DiscussionContainer = styled.div`
  width: 100%;
  padding: 0 3rem;
`;


export const WebinarTitle = styled.p`
  margin:0;
`;
