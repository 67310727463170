import {
  RequestContainer,
  RowBody,
} from "./styles"; 
import { Skeleton } from "antd";
import { ListItemLoader } from "components/ComboBoxFilterList/styles";

const LoaderCell = () => {
  return (
    <RequestContainer style={{ padding: "3rem", background: "#fff" }}>
      <RowBody>
        <div style={{ width: "50%" }}>
          <ListItemLoader active />
          <ListItemLoader active />
        </div>
        <RowBody style={{ justifyContent: "space-between" }}>
          <div style={{ width: "60%" }}>
            <Skeleton active paragraph={{ rows: 6 }} />
          </div>
          <div style={{ width: "35%" }}>
            <div style={{ width: "60%" }}>
              <ListItemLoader active />
            </div>
            <div style={{ width: "60%" }}>
              <ListItemLoader active />
            </div>

            <div style={{ width: "100%" }}>
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
            </div>
            <div style={{ width: "100%" }}>
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
            </div>
            <div style={{ width: "100%" }}>
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
              <Skeleton.Button active style={{ margin: "1rem .5rem 0 0" }} />
            </div>
          </div>
        </RowBody>
      </RowBody>
    </RequestContainer>
  );
};

export default LoaderCell;
