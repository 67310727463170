import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { VideoMedia } from "state/ducks/columned/types";
import Hls from "hls.js";
interface Props {
  data: VideoMedia;
}

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
`;

const MediaNode = (props: Props) => {
  const [hls, setHls] = useState<any>(null);
  const videoPlayer = useRef<HTMLVideoElement>(null);
  const playerId = Date.now();
  const autoplay = false;

  const { data } = props;
  const { field_url } = data;
  const videoURL = field_url?.length > 0 ? field_url[0].value : "";

  const { current } = videoPlayer;
  useEffect(() => {
    const newHls = new Hls({});
    if (current) {
      newHls.loadSource(videoURL);
      newHls.attachMedia(current);
      newHls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoplay) {
          current?.play();
        }
      });
    }
    setHls(newHls);
  }, [current, autoplay, videoURL]);

  useEffect(() => {
    return () => {
      if (hls) hls.destroy();
    };
  }, [hls]);

  return (
    <StyledVideo ref={videoPlayer} id={`react-hls-${playerId}`} controls />
  );
};

export default MediaNode;
