import { useCallback, useEffect, useState } from "react";
import { genFetch, withToken } from "state/utils/endpoints";
import { genRequest } from "state/api/subrequests";
import { OK } from "http-status-codes";
import { columnedParser } from "state/ducks/columned/columnedparser";

export default function useColumnedParser(
  url: string,
  auto: boolean = true
): [any, boolean, any] {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<null | any[]>(null);

  const fetch = useCallback(
    async (url: string) => {
      setIsLoading(true);
      const response = await genFetch(genRequest.getColumnedDataByNode(url))()(
        withToken()
      );

      if (response.status === OK) {
        const json = await response.json();
        if (
          json &&
          json.entity.field_page_content &&
          json.entity.field_page_content.length > 0
        ) {
          const parsedContent = columnedParser.filterContent(
            json.entity.field_page_content
          );
          setData(parsedContent);
        }
        setIsLoading(false);
      }
    },
    [setIsLoading, setData]
  );

  useEffect(() => {
    if (auto) {
      fetch(url);
    }
  }, [auto, url]);

  const action = useCallback(
    (ok: string) => {
      setData(null);
      fetch(ok);
    },
    [fetch, setData]
  );

  return [data, isLoading, action];
}
