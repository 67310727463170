import { GenericTypes } from 'state/utils';
import { IAction } from 'state/utils/redux/types';

/**
 * Layout's Typed Reducer State
 */
export interface ISearchState {
  [key: string]: ISearchStateObject;
}

export interface ISearchStateObject {
  label: string;
  pager: ISearchPager;
  pages: {
    [key: number]: ISearchObject[];
  };
}
export interface ISearchMessage {
  rows: ISearchObject[];
  pager: ISearchPager;
}

export interface ISearchObject {
  title: string;
  search_api_excerpt: string;
  field_tags: string[];
  public_path: string;
}
export interface ISearchPager {
  current_page: number;
  total_items: number;
  total_pages: number;
  items_per_page: number;
}

export const searchActionTypes = {
  SEARCH: 'SEARCH',
  NEXT_PAGE: 'NEXT_PAGE',
  SEARCH_SUCCESS: 'SEARCH_SUCCESS',
  SEARCH_FAILURE: 'SEARCH_FAILURE',
};

export interface ISearchPayload {
  query: string;
}

export interface ISearchChangePagePayload {
  query: string;
  page: number;
}

export interface IQueryResultsPayload {
  query: string;
  results: ISearchMessage;
}

export interface IQueryPayloadAction extends GenericTypes.IAction {
  payload: ISearchPayload;
}

export interface IQueryResultsAction extends GenericTypes.IAction {
  payload: IQueryResultsPayload;
}

export interface IQueryChangeSearchPageAction extends GenericTypes.IAction {
  payload: ISearchChangePagePayload;
}

export type SearchActionsInterfaces = IAction | IQueryPayloadAction | IQueryResultsAction;

export default searchActionTypes;
