import React from "react";
import styled, { css } from "styled-components";
import { Paragraph } from "state/ducks/columned/types";
import {
  convertToTypographyVariants,
  convertToTypographyFont,
} from "state/utils/parser/helpers";

interface Props {
  data: Paragraph;
}

interface TextProps {
  highlight?: boolean;
  fontSize?: string;
  fontFamily?: string;
  fontWeight?: boolean;
  inline?: boolean;
  italics?: boolean;
  hasHighlightedColor?: string;
}
const Text = styled.p<TextProps>`
  display: block;
  width: 100%;
  ${(props) => css`
    font-size: ${props.fontSize ? props.fontSize : "1rem"};
    font-weight: ${props.fontWeight ? 600 : 300};
    font-family: ${props.fontFamily ? props.fontFamily : "roboto"};
    font-style: ${props.italics ? "italic" : "normal"};
    color: ${props.hasHighlightedColor
      ? props.hasHighlightedColor
      : props.theme.colors.main};
  `}
  margin: 0;
  line-height: 34px;
`;

const TextNode: React.FC<Props> = (props) => {
  const { data } = props;
  const {
    field_bold,
    field_plain_text,
    field_font_size,
    field_font,
    field_italic,
    field_highlight,
    field_highlight_color,
    // field_inline,
  } = data;

  const hasText =
    field_plain_text?.length > 0 ? field_plain_text[0].value : undefined;
  const hasSizeAttribute =
    field_font_size?.length > 0 ? field_font_size[0].name : undefined;
  const hasFontAttribute =
    field_font?.length > 0 ? field_font[0].name : undefined;
  const hasFontWeight =
    field_bold?.length > 0 ? field_bold[0].value : undefined;
  const hasItalics =
    field_italic?.length > 0 ? field_italic[0].value : undefined;

  // const inline = field_inline[0] ? field_inline[0].value : false;

  const hasHighlight =
    field_highlight?.length > 0 ? field_highlight[0].value : undefined;

  let hasHighlightedColor = undefined;
  if (hasHighlight) {
    if (field_highlight_color?.length > 0)
      hasHighlightedColor = field_highlight_color[0].color;
  }

  const fontSize: string = hasSizeAttribute
    ? convertToTypographyVariants(hasSizeAttribute)
    : "1rem";
  const fontFamily: string = hasFontAttribute
    ? convertToTypographyFont(hasFontAttribute)
    : "roboto";

  return (
    <Text
      fontFamily={fontFamily}
      fontWeight={hasFontWeight}
      fontSize={fontSize}
      italics={hasItalics}
      hasHighlightedColor={hasHighlightedColor}
    >
      {hasText}
    </Text>
  );
};

export default TextNode;
